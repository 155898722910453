import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <>
        {/* Footer */}
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Contact */}
                <section className="contact">
                  <div className="footer-title">
                    👏Welcome and thank you for visiting my site, This is a free
                    FC game site.
                  </div>
                  <ul>
                    <li>
                      <a
                        href="https://github.com/RyuSeiri"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">Github</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://f1-calendar.eu.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">F1 Calendar</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://f2-calendar.eu.org"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">F2 Calendar</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://html-online-game.github.io"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">Html Online Game</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://newsround.github.io/github-trends/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">Github Trends</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wallpapers-collection.github.io/bing-wallpaper"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">Bing Wallpaper </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://olympics-news.github.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">Olympics News</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://chrome-tool.github.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="label">Chrome Extension</span>
                      </a>
                    </li>
                  </ul>
                </section>
                {/* Copyright */}
                <div className="copyright">
                  &copy; {new Date().getFullYear()} Seiri All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
