import React, { Component } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import ControlsModal from "./ControlsModal";
import Emulator from "./Emulator";
import Footer from "./Footer";
import RomLibrary from "../utitl/RomLibrary";
import { loadBinary } from "../utitl/Utils";
import "./RunPage.css";

export default class RunPage extends Component {
  state = {
    romName: null,
    romData: null,
    running: false,
    paused: false,
    controlsModalOpen: false,
    loading: true,
    loadedPercent: 3,
    error: null,
    homeApplicationIdJson: {},
    postApplicationIdJson: {},
  };

  /**
   * 初期化
   */
  componentDidMount() {
    window.addEventListener("resize", this.layout);
    this.layout();
    this.load();
  }

  /**
   * 卸载
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.layout);
    if (this.currentRequest) {
      this.currentRequest.abort();
    }
  }

  /**
   * 加载
   */
  load = () => {
    if (this.props.match.params.slug) {
      const slug = this.props.match.params.slug;
      const isLocalROM = /^local-/.test(slug);
      const romHash = slug.split("-")[1];
      const romInfo = isLocalROM ? RomLibrary.getRomInfoByHash(romHash) : slug;
      //google analytics
      document.title = `Family Computer Games - ${String(slug).replaceAll("_", " ")}`;
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        page_path: this.props.location.pathname,
      });
      if (!romInfo) {
        this.setState({ error: `ROM Not Exist: ${slug}` });
        return;
      }

      //判断是否为本地的ROM
      if (isLocalROM) {
        this.setState({ romName: romInfo.name });
        const localROMData = localStorage.getItem("blob-" + romHash);
        this.handleLoaded(localROMData);
      } else {
        this.setState({
          romName: romInfo,
          postApplicationIdJson: {
            "@context": "http://schema.org",
            "@type": "website",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://fc-game.github.io/#/run/${romInfo}`,
            },
            headline: `Family Computer Games ${romInfo}`,
            description: `FC Game ${romInfo}`,
            publisher: {
              "@type": "Person",
              name: "Family Computer Games",
            },
            author: {
              url: "https://github.com/MADAOU",
              "@type": "Person",
              name: "Seiriryu",
            },
          },
          breadcrumbListApplicationIdJson: {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  name: "home",
                  "@id": "https://fc-game.github.io",
                },
              },
              {
                "@type": "ListItem",
                position: 2,
                item: {
                  name: `${romInfo}`,
                  "@id": `https://fc-game.github.io/#/run/${romInfo}`,
                },
              },
            ],
          },
        });
        this.currentRequest = loadBinary(
          `${romInfo}.nes`,
          (err, data) => {
            if (err) {
              this.setState({ error: `ROM Load failed: ${err.message}` });
            } else {
              this.handleLoaded(data);
            }
          },
          this.handleProgress
        );
      }
    } else if (this.props.location.state && this.props.location.state.file) {
      let reader = new FileReader();
      reader.readAsBinaryString(this.props.location.state.file);
      reader.onload = (e) => {
        this.currentRequest = null;
        this.handleLoaded(reader.result);
      };
    } else {
      this.setState({ error: "ROM not Provided!!!" });
    }
  };

  handleBack = (e) => {
    this.props.history.push("/");
  };

  /**
   * 进度条
   * @param {*} e
   */
  handleProgress = (e) => {
    if (e.lengthComputable) {
      this.setState({ loadedPercent: (e.loaded / e.total) * 100 });
    }
  };

  /**
   * 加载
   * @param {*} data
   */
  handleLoaded = (data) => {
    this.setState({ running: true, loading: false, romData: data });
  };

  /**
   * 暂停
   */
  handlePauseResume = () => {
    this.setState({ paused: !this.state.paused });
  };

  /**
   * 画面样式
   */
  layout = () => {
    let navbarHeight = parseFloat(window.getComputedStyle(this.navbar).height);
    this.screenContainer.style.height = `${
      window.innerHeight - navbarHeight -35
    }px`;
    if (this.emulator) {
      this.emulator.fitInParent();
    }
  };

  /**
   * 显示不显示
   */
  toggleControlsModal = () => {
    this.setState({ controlsModalOpen: !this.state.controlsModalOpen });
  };

  /**
   * 渲染
   */
  render() {
    return (
      <>
        <div className="run-page">
          {/* description */}
          <meta name="description" content={`Family Computer Games ${String(this.state.romName).replaceAll("_", " ")}`} />
          <meta property="og:description" content={`Family Computer Games ${String(this.state.romName).replaceAll("_", " ")}`} />
          <meta name="twitter:description" content={`Family Computer Games ${String(this.state.romName).replaceAll("_", " ")}`} />
          {/* Open Graph / Facebook */}
          <meta content="Family Computer Games" property="og:site_name" />
          <meta content={`https://fc-game.github.io/run/${this.state.romName}`} property="og:url" />
          <meta content="website" property="og:type" />
          {/* Twitter */}
          <meta content="@Seiriryu" name="twitter:site" />
          <meta content="@Seiriryu" name="twitter:creator" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="https://fc-game.github.io" name="twitter:url" />
          <meta content="https://fc-game.github.io" name="twitter:domain" />
          <meta content={`Family Computer Games - ${String(this.state.romName).replaceAll("_", " ")}`} name="twitter:title" />
          <meta content="fcgames,game,FamilyComputerGames" name="keywords" />
          {/* JSON+Id */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.homeApplicationIdJson)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(this.state.postApplicationIdJson)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(this.state.breadcrumbListApplicationIdJson)}
          </script>

          <div className="container"
            ref={(el) => {
              this.navbar = el;
            }}
          >
            <Container>
              <Row className="header-container">
                <Col>
                  <button onClick={this.handleBack} className="custom-btn">
                    Back
                  </button>
                </Col>
                <Col className="game-title-center">{String(this.state.romName).replaceAll("_", " ")}</Col>
                <Col className="right-btn-container">
                  <button
                    onClick={this.toggleControlsModal}
                    className="custom-btn d-none d-sm-block"
                  >
                    Keyboard
                  </button>
                  <button
                    onClick={this.handlePauseResume}
                    disabled={!this.state.running}
                    className="custom-btn"
                  >
                    {this.state.paused ? "Continue" : "Pause"}
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
          {this.state.error ? (
            <div className="screen-container">
              <p className="description">{this.state.error}</p>
            </div>
          ) : (
            <div
              className="screen-container"
              ref={(el) => {
                this.screenContainer = el;
              }}
            >
              {this.state.loading ? (
                <div>
                  {this.state.loadedPercent.toFixed()}%
                  <ProgressBar
                    animated
                    variant="success"
                    now={this.state.loadedPercent}
                    style={{
                      position: "absolute",
                      width: "70%",
                      height: "5%",
                      left: "15%",
                      top: "48%",
                    }}
                  />
                </div>
              ) : this.state.romData ? (
                <Emulator
                  romData={this.state.romData}
                  paused={this.state.paused}
                  ref={(emulator) => {
                    this.emulator = emulator;
                  }}
                />
              ) : null}
              {/* TODO: lift keyboard and gamepad state up */}
              {this.state.controlsModalOpen && (
                <ControlsModal
                  isOpen={this.state.controlsModalOpen}
                  toggle={this.toggleControlsModal}
                  keys={this.emulator.keyboardController.keys}
                  setKeys={this.emulator.keyboardController.setKeys}
                  promptButton={this.emulator.gamepadController.promptButton}
                  gamepadConfig={this.emulator.gamepadController.gamepadConfig}
                  setGamepadConfig={
                    this.emulator.gamepadController.setGamepadConfig
                  }
                />
              )}
            </div>
          )}
        </div>
        <Footer />
        {/* <AdSense.GoogleAd
          client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
          slot={process.env.REACT_APP_GOOGLE_AD2_SLOT} />
        <AdSense.GoogleAd
          client={process.env.REACT_APP_GOOGLE_AD_CLIENT}
          slot={process.env.REACT_APP_GOOGLE_AD1_SLOT} /> */}
      </>
    );
  }
}
