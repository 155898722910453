import React, { Component } from "react";
import {  Modal, Table } from "react-bootstrap";
import { Controller } from "jsnes";
import ControlMapperRow from "./ControlMapperRow";
import "./ControlsModal.css";

const GAMEPAD_ICON = "../../img/nes_controller.png";
const KEYBOARD_ICON = "../../img/keyboard.png";

export default class ControlsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gamepadConfig: props.gamepadConfig,
      keys: props.keys,
      button: undefined,
      modified: false,
    };
    //按键按下
    this.handleKeyDown = this.handleKeyDown.bind(this);
    //手柄按下
    this.handleGamepadButtonDown = this.handleGamepadButtonDown.bind(this);
    //监听
    this.listenForKey = this.listenForKey.bind(this);
    //按键配置
    this.state.gamepadConfig = this.state.gamepadConfig || {};
    //按键的ID
    this.state.gamepadConfig.playerGamepadId = this.state.gamepadConfig
      .playerGamepadId || [null, null];
    this.state.gamepadConfig.configs = this.state.gamepadConfig.configs || {};
    //控制的Icon
    this.state.controllerIcon = this.state.gamepadConfig.playerGamepadId.map(
      (gamepadId) => (gamepadId ? GAMEPAD_ICON : KEYBOARD_ICON)
    );
    //控制器的说明
    this.state.controllerIconAlt = this.state.gamepadConfig.playerGamepadId.map(
      (gamepadId) => (gamepadId ? "gamepad" : "keyboard")
    );
    this.state.currentPromptButton = -1;
  }

  /**
   * 卸载
   */
  componentWillUnmount() {
    if (this.state.modified) {
      this.props.setKeys(this.state.keys);
      this.props.setGamepadConfig(this.state.gamepadConfig);
    }
    this.removeKeyListener();
  }

  /**
   * 按键监听
   * @param {*} button 按钮
   */
  listenForKey(button) {
    var currentPromptButton = button[1];

    this.removeKeyListener();
    this.setState({ button, currentPromptButton });
    this.props.promptButton(this.handleGamepadButtonDown);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  /**
   * 手柄按下
   * @param {*} buttonInfo 按钮信息
   */
  handleGamepadButtonDown(buttonInfo) {
    this.removeKeyListener();
    var button = this.state.button;
    const playerId = button[0];
    const buttonId = button[1];
    const gamepadId = buttonInfo.gamepadId;
    const gamepadConfig = this.state.gamepadConfig;

    // link player to gamepad
    const playerGamepadId = gamepadConfig.playerGamepadId.slice(0);
    const newConfig = {};

    playerGamepadId[playerId - 1] = gamepadId;

    const rejectButtonId = (b) => {
      return b.buttonId !== buttonId;
    };

    const newButton = {
      code: buttonInfo.code,
      type: buttonInfo.type,
      buttonId: buttonId,
      value: buttonInfo.value,
    };
    newConfig[gamepadId] = {
      buttons: (gamepadConfig.configs[gamepadId] || { buttons: [] }).buttons
        .filter(rejectButtonId)
        .concat([newButton]),
    };
    //配置
    const configs = Object.assign({}, gamepadConfig.configs, newConfig);
    //按键状态
    this.setState({
      gamepadConfig: {
        configs: configs,
        playerGamepadId: playerGamepadId,
      },
      currentPromptButton: -1,
      controllerIcon: playerGamepadId.map((gamepadId) =>
        gamepadId ? GAMEPAD_ICON : KEYBOARD_ICON
      ),
      modified: true,
    });
  }

  /**
   * 按键按下
   * @param {*} event 事件
   */
  handleKeyDown(event) {
    this.removeKeyListener();

    var button = this.state.button;
    var keys = this.state.keys;
    var newKeys = {};
    for (var key in keys) {
      if (keys[key][0] !== button[0] || keys[key][1] !== button[1]) {
        newKeys[key] = keys[key];
      }
    }

    const playerGamepadId = this.state.gamepadConfig.playerGamepadId.slice(0);
    const playerId = button[0];
    playerGamepadId[playerId - 1] = null;

    this.setState({
      keys: {
        ...newKeys,
        [event.keyCode]: [
          ...button.slice(0, 2),
          event.key.length > 1 ? event.key : String(event.key).toUpperCase(),
        ],
      },
      button: undefined,
      gamepadConfig: {
        configs: this.state.gamepadConfig.configs,
        playerGamepadId: playerGamepadId,
      },
      currentPromptButton: -1,
      controllerIcon: playerGamepadId.map((gamepadId) =>
        gamepadId ? GAMEPAD_ICON : KEYBOARD_ICON
      ),
      controllerIconAlt: playerGamepadId.map((gamepadId) =>
        gamepadId ? "gamepad" : "keyboard"
      ),
      modified: true,
    });
  }

  /**
   * 移除监听
   */
  removeKeyListener() {
    this.props.promptButton(null);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.toggle}
        className="control-modal"
      >
        <Modal.Header toggle={this.props.toggle} closeButton>
          <Modal.Title>Control Keys</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Keyboard</th>
                <th>
                  Player1
                  <img
                    className="controller-icon"
                    style={{ height: "100px", widht: "200px" }}
                    src={this.state.controllerIcon[0]}
                    alt={this.state.controllerIconAlt[0]}
                  />
                </th>
                <th>
                  Player2
                  <img
                    className="controller-icon"
                    style={{ height: "100px", widht: "200px" }}
                    src={this.state.controllerIcon[1]}
                    alt={this.state.controllerIconAlt[1]}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <ControlMapperRow
                buttonName="Left"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_LEFT}
                prevButton={Controller.BUTTON_SELECT}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="Right"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_RIGHT}
                prevButton={Controller.BUTTON_LEFT}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="Up"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_UP}
                prevButton={Controller.BUTTON_RIGHT}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="Down"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_DOWN}
                prevButton={Controller.BUTTON_UP}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="A"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_A}
                prevButton={Controller.BUTTON_DOWN}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="B"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_B}
                prevButton={Controller.BUTTON_A}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="Start"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_START}
                prevButton={Controller.BUTTON_B}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
              <ControlMapperRow
                buttonName="Select"
                currentPromptButton={this.state.currentPromptButton}
                button={Controller.BUTTON_SELECT}
                prevButton={Controller.BUTTON_START}
                keys={this.state.keys}
                handleClick={this.listenForKey}
                gamepadConfig={this.state.gamepadConfig}
              />
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button className="custom-btn" onClick={this.props.toggle}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
