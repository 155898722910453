import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./NotFoundPage.css";

export default class NotFoundPage extends Component {
  /**
   * 渲染
   */
  render() {
    return (
      <>
        {/* Header */}
        <Header />
        <div className="error-page-container">
          <h1 className="error-title">404</h1>
          <p className="error-description">Page Not Found</p>
          <p className="error-description">
            click <a href="./">here</a> to go home page
          </p>
        </div>
        <Footer />
      </>
    );
  }
}
