import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./ErrorPage.css";

export default class ErrorPage extends Component {
  state = {
    currentSecends: 3,
  };

  /**
   * 初期化
   */
  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.currentSecends > 0)
        this.setState({ currentSecends: this.state.currentSecends - 1 });
      else window.location.href = "/";
    }, 1000);
  }

  /**
   * 卸载
   */
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * 渲染
   */
  render() {
    return (
      <>
        <Header />
        <div className="error-page-container">
          <p className="error-description">
            Game Load Error, The System Will Go Home Page in{" "}
            {this.state.currentSecends} Seconds
          </p>
        </div>
        <Footer />
      </>
    );
  }
}
