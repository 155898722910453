const config = {
  ROMS: {
    1942: {
      description:
        "1942 is a vertical-scrolling shoot 'em up arcade game developed and published by Capcom. The game's premise is set in the Pacific theater of World War II, and players take control of a fighter aircraft to engage in dogfights with enemy planes. 1942 is known for its challenging gameplay, iconic power-ups, and classic arcade style.",
      developer: "Capcom",
      platform: "Arcade",
      genre: "Vertical-scrolling shooter",
    },
    1943: {
      description:
        "1943 is The Battle of Midway is a vertical-scrolling shoot 'em up arcade game, developed and published by Capcom. It is the sequel to 1942 and is set in the Pacific theater of World War II. Players control a fighter plane, battling against waves of enemy aircraft and ships. The game introduced new features, including the energy meter and special weapons, adding strategic depth to the gameplay.",
      developer: "Capcom",
      platform: "Arcade",
      genre: "Vertical-scrolling shooter",
    },
    1944: {
      description:
        "1944 is The Loop Master is a vertical-scrolling shoot 'em up arcade game developed by Capcom. It is the fourth installment in the 19XX series and features similar gameplay to its predecessors. Players control a fighter aircraft and engage in intense aerial battles against enemy planes and bosses. The game is set in the European theater of World War II.",
      developer: "Capcom",
      platform: "Arcade",
      genre: "Vertical-scrolling shooter",
    },
    "1991赛马": {
      description:
        "1991赛马是一款由Nintendo开发和发行的竞速赛马游戏。在游戏中，玩家可以选择不同的赛马，参加各种锦标赛和比赛。游戏具有简单易上手的操作，但需要玩家巧妙运用策略来赢得比赛。1991赛马是红白机平台上备受欢迎的赛马游戏之一。",
      developer: "Nintendo",
      platform: "红白机",
      genre: "竞速赛马",
    },
    "1999Hore_MitakotokaSeikimatsu": {
      description:
        "1999 Hore MitakotokaSeikimatsu is a puzzle-solving adventure game developed for the Famicom Disk System. In the game, players navigate various scenarios, interacting with characters and objects to solve puzzles and advance the storyline. The game features a unique blend of puzzle challenges and narrative elements, making it a distinctive title in the Famicom library.",
      developer: "Unknown",
      platform: "Famicom Disk System",
      genre: "Adventure, Puzzle",
    },
    "3STOOGES": {
      description:
        "3STOOGES is a video game based on the classic comedy trio, The Three Stooges. Players control the Stooges as they navigate different mini-games and challenges. The game incorporates elements of strategy and timing, requiring players to make quick decisions to succeed in various comedic scenarios. 3STOOGES captures the humor and antics of the beloved characters.",
      developer: "Beam Software",
      platform: "Various platforms",
      genre: "Mini-games, Comedy",
    },
    417: {
      description:
        "417 is a numeric puzzle game where players manipulate digits to reach a target number. The game presents players with a grid of numbers and mathematical operators. Players must strategically select and arrange the digits and operators to create equations that result in the target number. 417 challenges players' mathematical skills and problem-solving abilities.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Numeric Puzzle",
    },
    435: {
      description:
        "435 is a challenging puzzle game that revolves around arranging colored blocks in a specific order. Players are presented with a grid of blocks of different colors. The objective is to rearrange the blocks according to a given pattern or sequence. Players need to think critically and plan their moves to solve each puzzle. 435 tests players' spatial reasoning and logic.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Puzzle",
    },
    508: {
      description:
        "508 is a platformer game featuring a protagonist navigating through various levels filled with obstacles and enemies. Players control the character, guiding them through platforms, jumps, and hazards. The game's challenges include precise movements and timing to overcome obstacles and enemies. 508 offers classic platformer gameplay with increasing difficulty.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Platformer",
    },
    "53times": {
      description:
        "53times is a puzzle game that requires players to arrange objects or tiles in a specific order within a limited number of moves. The game presents players with a grid or board containing various elements. Players must strategize and plan their moves to achieve the goal within 53 moves. 53times offers brain-teasing challenges and encourages logical thinking.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Puzzle",
    },
    634: {
      description:
        "634 is a numeric puzzle game that challenges players to manipulate numbers and mathematical operations to achieve a target number. The game provides players with a set of numbers and operators. Players must use these elements to create equations that result in the target number 634. The game requires mathematical skills and logical thinking to solve the puzzles.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Numeric Puzzle",
    },
    720: {
      description:
        "720 Degrees is an arcade skateboarding game that simulates various skateboarding events and challenges. Players control a skateboarder navigating through parks and streets, performing tricks and stunts to earn points. The game features multiple skateboarding events, each with its unique objectives and obstacles. 720 Degrees offers exciting gameplay for skateboarding enthusiasts.",
      developer: "Atari Games",
      platform: "Arcade",
      genre: "Skateboarding",
    },
    "89_Dennou_Kyuusei_Uranai": {
      description:
        "89 Dennou Kyuusei Uranai is a fortune-telling simulation game developed for the Famicom Disk System. In the game, players can receive virtual fortune-telling sessions. The game provides horoscopes, personality analysis, and other fortune-telling methods. Players can explore different aspects of fortune-telling and enjoy the predictions provided by the virtual fortune-teller.",
      developer: "Unknown",
      platform: "Famicom Disk System",
      genre: "Fortune-Telling Simulation",
    },
    "8EYES": {
      description:
        "8EYES is a side-scrolling action-platformer game set in a post-apocalyptic world. Players control a falconer named Orin, who must traverse different regions and defeat guardians to retrieve magical jewels. Orin is accompanied by a falcon companion that aids in combat. The game features challenging platforming sections and combat against various enemies and bosses.",
      developer: "Thinking Rabbit",
      platform: "NES",
      genre: "Action-Platformer",
    },
    Aa_Yakyuu_Jinsei_Itchokusen: {
      description:
        "Aa Yakyuu Jinsei Itchokusen is a baseball simulation game that allows players to manage a baseball team and participate in league matches. Players are responsible for team management, including player roster, strategies, and in-game decisions. The game combines strategic elements with baseball gameplay, offering a comprehensive baseball simulation experience.",
      developer: "ASCII",
      platform: "Famicom Disk System",
      genre: "Baseball Simulation",
    },
    Abadox: {
      description:
        "Abadox: The Deadly Inner War is a side-scrolling shoot 'em up game developed by Natsume. The game is set inside a giant alien organism, and players control a small spaceship navigating through biological environments filled with enemies and obstacles. The game features a variety of power-ups and challenging boss battles. Abadox offers classic shoot 'em up gameplay with a unique setting.",
      developer: "Natsume",
      platform: "NES",
      genre: "Side-Scrolling Shooter",
    },
    Abarenbou_Tengu: {
      description:
        "Abarenbou Tengu is a platformer game where players control a Tengu warrior on a quest to rescue a kidnapped princess. The game features side-scrolling levels filled with enemies and hazards. Players can defeat enemies by throwing knives and utilize platforming skills to overcome obstacles. Abarenbou Tengu offers classic platformer gameplay with a Japanese folklore-inspired theme.",
      developer: "Irem",
      platform: "NES",
      genre: "Platformer",
    },
    ABOYBLOB: {
      description:
        "ABOYBLOB is a puzzle-platformer game where players control a boy character and a blob creature. Players must navigate through levels, solving puzzles and utilizing the abilities of both characters to progress. The blob can be used as a platform or a tool to solve environmental challenges. ABOYBLOB offers unique cooperative gameplay and creative puzzle-solving.",
      developer: "Imagineering Inc.",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    Aces_Iron_Eagle3: {
      description:
        "Aces: Iron Eagle III is a flight simulation game that puts players in the cockpit of various fighter aircraft. Players engage in aerial dogfights and missions against enemy planes and ground targets. The game features realistic flight physics and a variety of missions to complete. Aces: Iron Eagle III offers an immersive experience for flight simulation enthusiasts.",
      developer: "New World Computing",
      platform: "NES",
      genre: "Flight Simulation",
    },
    Action52: {
      description:
        "Action 52 is a compilation of 52 individual games, each offering a different gameplay experience. The games cover various genres, including action, puzzle, and platformer. However, the overall quality of the games varies widely, with some being notably better than others. Action 52 is known for its ambitious attempt to provide a large number of games in a single cartridge.",
      developer: "Active Enterprises",
      platform: "NES",
      genre: "Various Genres",
    },
    Action_in_New_York: {
      description:
        "Action in New York is a run-and-gun game featuring side-scrolling gameplay. Players control a character armed with various weapons, battling against waves of enemies in a New York City setting. The game offers fast-paced action with intense combat scenarios. Action in New York provides challenging gameplay for fans of the run-and-gun genre.",
      developer: "Kyugo Boueki",
      platform: "NES",
      genre: "Run-and-Gun",
    },
    Addams_Family: {
      description:
        "Addams Family is an action-platformer game based on the Addams Family franchise. Players control Gomez Addams as he explores various levels, battling enemies and solving puzzles. The game features classic platformer gameplay with a spooky theme. Players can collect power-ups and items to aid them in their adventure. Addams Family captures the essence of the Addams Family universe.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action-Platformer",
    },
    Addams_FamilyPugsleys_Scavenger_Hunt: {
      description:
        "Addams Family: Pugsley's Scavenger Hunt is a platformer game featuring characters from the Addams Family series. Players control Pugsley Addams as he embarks on a scavenger hunt within the Addams Mansion. The game features side-scrolling levels filled with enemies and traps. Players must collect specific items to progress through the game. Pugsley's Scavenger Hunt offers classic platformer gameplay with a scavenger hunt twist.",
      developer: "Enigma Variations",
      platform: "NES",
      genre: "Platformer",
    },
    ADVENTUR: {
      description:
        "ADVENTUR is an adventure game that follows a protagonist's quest in a fantasy world. Players navigate through various locations, interact with characters, and solve puzzles to advance the story. The game offers a blend of exploration, puzzle-solving, and narrative elements. ADVENTUR provides an immersive adventure experience for players.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Adventure",
    },
    Adventures_of_Captain_Comic: {
      description:
        "Adventures of Captain Comic is a platformer game where players control Captain Comic on a mission to rescue a kidnapped princess and retrieve the treasures of Osmic. The game features side-scrolling levels with enemies and obstacles. Players can collect power-ups and items to enhance their abilities. Adventures of Captain Comic offers classic platformer gameplay with exploration and item collection.",
      developer: "Michael Denio",
      platform: "NES",
      genre: "Platformer",
    },
    Adventures_of_Dino_Riki: {
      description:
        "Adventures of Dino-Riki is a platformer game where players control a character named Riki, who is transformed into a dinosaur. Players navigate through levels, defeating enemies and avoiding hazards. The game features colorful graphics and simple gameplay mechanics. Adventures of Dino-Riki offers a lighthearted and enjoyable platforming experience.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Platformer",
    },
    Adventures_of_Lolo: {
      description:
        "Adventures of Lolo is a puzzle game that follows the journey of Lolo and Lala as they navigate puzzle-filled rooms to rescue Princess Lala. Players must solve tile-based puzzles, avoid enemies, and collect hearts to progress through each level. The game features a variety of puzzles with increasing complexity. Adventures of Lolo challenges players' logic and problem-solving skills.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Puzzle",
    },
    Adventures_of_Rocky_and_Bullwinkle_and_Friends: {
      description:
        "Adventures of Rocky and Bullwinkle and Friends is a platformer game featuring characters from the popular animated series. Players can choose to play as Rocky or Bullwinkle, each with unique abilities, as they traverse through levels filled with enemies and obstacles. The game combines platforming challenges with elements of action and exploration. Adventures of Rocky and Bullwinkle offers classic platformer gameplay with a cartoon-inspired theme.",
      developer: "Imagineering Inc.",
      platform: "NES",
      genre: "Platformer",
    },
    Adventure_Island4: {
      description:
        "Adventure Island 4 is a platformer game starring Master Higgins on a quest to rescue his kidnapped wife Tina. Players navigate through levels, battling enemies and avoiding hazards. The game features classic platformer gameplay with vibrant graphics and challenging levels. Players can collect power-ups and ride various creatures to aid them in their adventure. Adventure Island 4 offers an exciting platforming experience.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Platformer",
    },
    ADVILND2: {
      description:
        "ADVILND2 is a platformer game and a sequel to the original Adventure Island. Players control Master Higgins as he embarks on a new adventure to rescue his girlfriend Tina. The game features side-scrolling levels with enemies and traps. Players can collect fruit to replenish their energy and find various power-ups to enhance their abilities. ADVILND2 offers classic platformer gameplay with challenging levels and colorful graphics.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Platformer",
    },
    ADVISL3: {
      description:
        "ADVISL3 is a puzzle game that challenges players to guide a pipe-cleaning robot through a series of pipes and mazes. Players must rotate and connect pipes to create a continuous path for the robot to travel. The game features increasing complexity with each level, requiring strategic planning and quick thinking. ADVISL3 tests players' spatial reasoning and problem-solving skills.",
      developer: "Panesian",
      platform: "NES",
      genre: "Puzzle",
    },
    AD_and_D_Dragon_Strike: {
      description:
        "Advanced Dungeons & Dragons: Dragon Strike is a fantasy-themed rail shooter game. Players assume the role of an adventurer riding a dragon, battling against various enemies and creatures. The game features on-rails movement, and players must aim and shoot at enemies while avoiding incoming attacks. Dragon Strike offers action-packed gameplay in a fantasy world.",
      developer: "Westwood Associates",
      platform: "NES",
      genre: "Rail Shooter",
    },
    AD_and_D_Heroes_of_the_Lance: {
      description:
        "Advanced Dungeons & Dragons: Heroes of the Lance is an action-adventure game set in the Dragonlance universe. Players control a party of adventurers, each with unique abilities, as they explore dungeons and battle enemies. The game features side-scrolling gameplay with combat and puzzle-solving elements. Heroes of the Lance offers a blend of action and strategy for fans of the AD&D universe.",
      developer: "U.S. Gold",
      platform: "NES",
      genre: "Action-Adventure",
    },
    AD_and_D_Hillsfar: {
      description:
        "Advanced Dungeons & Dragons: Hillsfar is a role-playing game that combines elements of adventure and strategy. Players can create a character and explore the city of Hillsfar, completing quests, engaging in combat, and interacting with NPCs. The game features a variety of activities, including mini-games and puzzle challenges. Hillsfar offers a detailed fantasy world for players to explore and immerse themselves in.",
      developer: "U.S. Gold",
      platform: "NES",
      genre: "Role-Playing",
    },
    AD_and_D_Pool_of_Radiance: {
      description:
        "Advanced Dungeons & Dragons: Pool of Radiance is a role-playing game based on the AD&D universe. Players create a party of adventurers and embark on a quest to rid the city of Phlan from evil forces. The game features turn-based combat, exploration, and character development. Pool of Radiance offers a deep and immersive role-playing experience for fans of the AD&D franchise.",
      developer: "Strategic Simulations, Inc.",
      platform: "NES",
      genre: "Role-Playing",
    },
    After_Burner: {
      description:
        "After Burner is an arcade flight simulation game developed and published by Sega. Players take control of an F-14 Tomcat fighter jet, engaging in intense aerial battles against enemy planes and missiles. The game features fast-paced gameplay, realistic graphics (for its time), and a throttle lever for speed control. After Burner offers an adrenaline-pumping experience for flight simulation enthusiasts.",
      developer: "Sega",
      platform: "Arcade",
      genre: "Flight Simulation",
    },
    After_Burner2: {
      description:
        "After Burner II is the sequel to the popular arcade flight simulation game After Burner. Players once again take control of an F-14 Tomcat fighter jet, engaging in dogfights and aerial battles against enemy planes and ships. The game features improved graphics and gameplay mechanics, offering an enhanced version of the original After Burner experience.",
      developer: "Sega",
      platform: "Arcade",
      genre: "Flight Simulation",
    },
    AGLAD: {
      description:
        "AGLAD is a puzzle game that challenges players to manipulate gears and mechanisms to connect power sources to devices. Players are presented with a grid containing gears and other objects. The goal is to arrange the items in the correct sequence to transmit power to the target device. AGLAD tests players' logic and problem-solving skills through intricate puzzle challenges.",
      developer: "Sunsoft",
      platform: "Unknown",
      genre: "Puzzle",
    },
    Aigiina_No_Yogen: {
      description:
        "Aigiina No Yogen is a puzzle-platformer game where players control a character navigating through levels filled with traps and enemies. The game features puzzle elements, requiring players to solve challenges to progress. Players can move blocks, avoid hazards, and defeat enemies using various strategies. Aigiina No Yogen offers a mix of platforming and puzzle-solving.",
      developer: "A.I",
      platform: "Famicom Disk System",
      genre: "Puzzle-Platformer",
    },
    AIRWOLF: {
      description:
        "AIRWOLF is a side-scrolling shoot 'em up game based on the popular TV series of the same name. Players control the AIRWOLF helicopter, battling against enemy aircraft and ground targets. The game features multiple missions set in different environments. Players must use weapons and tactics to complete each mission successfully. AIRWOLF offers intense aerial combat and exciting gameplay.",
      developer: "Kyugo Boueki",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Air_FortressKuuchuu_Yousai: {
      description:
        "Air Fortress: Kuuchuu Yousai is a mix of side-scrolling shooter and action-adventure game. Players control a character exploring massive flying fortresses, battling enemies and collecting energy cells. The game features both interior and exterior sections, each with its challenges. Players must manage resources and combat enemies to progress. Air Fortress offers a unique blend of gameplay styles.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Action-Adventure, Shoot 'em Up",
    },
    Akagawa_Jirou_No_Yuurei_Ressha: {
      description:
        "Akagawa Jirou No Yuurei Ressha is a text-based adventure game developed for the Famicom Disk System. Players assume the role of a detective investigating a mysterious train where strange events are occurring. The game relies on text-based interactions, allowing players to make decisions and solve the mystery based on the information provided. Akagawa Jirou No Yuurei Ressha offers a compelling narrative and detective experience.",
      developer: "Unknown",
      platform: "Famicom Disk System",
      genre: "Text-Based Adventure",
    },
    AKINGDOM: {
      description:
        "AKINGDOM is a strategy simulation game where players take on the role of a king, managing their kingdom and making decisions to ensure its prosperity. Players must balance resources, economy, and the needs of their citizens. The game features strategic elements, including diplomacy, trade, and military planning. AKINGDOM challenges players' leadership skills and strategic thinking.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Strategy Simulation",
    },
    Akira: {
      description:
        "Akira is an action-adventure game based on the popular manga and anime series of the same name. Players control Kaneda, the protagonist, as he explores Neo Tokyo, battles enemies, and unravels the mysteries of the city. The game features side-scrolling gameplay, combat, and exploration. Akira offers a unique interactive experience within the Akira universe.",
      developer: "Taito",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Akumajou_Dracula: {
      description:
        "Akumajou Dracula, known as Castlevania in the West, is an action-platformer game where players control Simon Belmont on a quest to defeat Count Dracula and his minions. Players navigate through Dracula's castle, battling enemies and avoiding traps. The game features classic platformer gameplay with a horror-themed setting. Players can collect sub-weapons and power-ups to aid them in their vampire-hunting adventure.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-Platformer",
    },
    akumason: {
      description:
        "Akumason is a puzzle game where players control a character navigating through maze-like levels filled with blocks and enemies. The objective is to clear the blocks and reach the exit while avoiding enemies and hazards. Players can push blocks to create paths and strategize to solve each level. Akumason offers challenging puzzles and requires logical thinking to complete the stages.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Puzzle",
    },
    Akuma_No_Shoutaijou: {
      description:
        "Akuma No Shoutaijou is a horror-themed action game where players control a character exploring a haunted mansion. The game features side-scrolling gameplay with combat against supernatural enemies. Players must navigate through eerie environments, battle ghosts, and solve puzzles to progress. Akuma No Shoutaijou offers a spooky atmosphere and challenges players' bravery in the face of supernatural threats.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Action, Horror",
    },
    Aladdin: {
      description:
        "Aladdin is an action-platformer game based on Disney's animated film. Players control Aladdin as he embarks on a quest to rescue Princess Jasmine from the evil Jafar. The game features side-scrolling levels with platforming challenges and enemies. Aladdin can jump, swing on ropes, and defeat enemies using his sword. The game captures the magic and adventure of the animated movie.",
      developer: "Capcom",
      platform: "SNES",
      genre: "Action-Platformer",
    },
    Aladdin2: {
      description:
        "Aladdin II is an action-adventure game that follows the events of Disney's Aladdin. Players control Aladdin as he explores various locations, battles enemies, and solves puzzles to rescue Princess Jasmine. The game features platforming sections, combat, and exploration. Aladdin II offers an immersive experience in the Aladdin universe, allowing players to relive the story of the animated film.",
      developer: "Virgin Games",
      platform: "SEGA Genesis",
      genre: "Action-Adventure",
    },
    Aladdin3: {
      description:
        "Aladdin III is a platformer game based on Disney's Aladdin franchise. Players control Aladdin as he navigates through levels, avoiding obstacles and enemies. The game features classic platformer gameplay with colorful graphics and animation. Players can collect gems and power-ups to enhance their abilities. Aladdin III offers a fun and engaging platforming experience for players of all ages.",
      developer: "SIMS",
      platform: "NES",
      genre: "Platformer",
    },
    ALFDCHIK: {
      description:
        "ALFDCHIK is a puzzle game that challenges players to arrange blocks of different shapes and colors to fill a grid. Players are presented with various block shapes and must strategically place them to complete rows and columns. Completed rows and columns disappear, creating space for more blocks. The game becomes progressively challenging as the speed and complexity increase.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Puzzle",
    },
    Alien3: {
      description:
        "Alien 3 is an action-platformer game based on the movie Alien 3. Players control Ellen Ripley as she navigates through a space station infested with aliens. The game features side-scrolling levels with platforming challenges and combat against aliens. Players must rescue hostages and find key items to progress. Alien 3 offers intense action and captures the suspense of the Alien film series.",
      developer: "Probe Software",
      platform: "Various platforms",
      genre: "Action-Platformer",
    },
    Alien_Syndrome: {
      description:
        "Alien Syndrome is a top-down shoot 'em up arcade game developed by Sega. Players control a space marine battling through alien-infested space stations. The game features cooperative gameplay, allowing two players to team up. Players must rescue hostages and defeat alien bosses within a time limit. Alien Syndrome offers intense action and challenging gameplay for arcade enthusiasts.",
      developer: "Sega",
      platform: "Arcade",
      genre: "Top-Down Shooter",
    },
    allamerican: {
      description:
        "All-American Football is a sports simulation game that allows players to participate in American football matches. Players can choose their teams and compete against the computer or another player. The game features realistic football gameplay, including passing, running, and kicking. All-American Football offers a virtual football experience for fans of the sport.",
      developer: "Unknown",
      platform: "Atari 2600",
      genre: "Sports Simulation",
    },
    ALLPRO1: {
      description:
        "ALLPRO1 is a baseball simulation game that offers players the experience of managing a baseball team. Players can create custom teams, manage rosters, and participate in league matches. The game includes strategic elements such as player stats, team formations, and in-game decisions. ALLPRO1 provides a comprehensive baseball management experience for fans of the sport.",
      developer: "Unknown",
      platform: "Atari 2600",
      genre: "Baseball Simulation",
    },
    ALLSTAR1: {
      description:
        "ALLSTAR1 is a baseball simulation game that allows players to manage and play as their favorite baseball teams. Players can participate in league matches, make strategic decisions, and control the gameplay. The game features realistic baseball mechanics, including pitching, hitting, and fielding. ALLSTAR1 offers an immersive baseball experience for fans of the sport.",
      developer: "Unknown",
      platform: "Atari 2600",
      genre: "Baseball Simulation",
    },
    Alpha_Mission: {
      description:
        "Alpha Mission is a vertically scrolling shoot 'em up arcade game developed by SNK. Players control a spacecraft battling against waves of enemy ships and ground targets. The game features power-ups that enhance the player's abilities, including different weapons and shields. Players must navigate through challenging levels and defeat bosses to progress. Alpha Mission offers classic shoot 'em up gameplay.",
      developer: "SNK",
      platform: "Arcade",
      genre: "Shoot 'em Up",
    },
    Al_Unser_Jr_Turbo_Racing: {
      description:
        "Al Unser Jr.'s Turbo Racing is a racing game that features top-down perspective gameplay. Players can choose from different race cars and compete on various tracks. The game offers single-player and multiplayer modes, allowing players to race against AI opponents or challenge friends. Al Unser Jr.'s Turbo Racing provides fast-paced racing action for fans of the genre.",
      developer: "Data East",
      platform: "NES",
      genre: "Racing",
    },
    AMAGON: {
      description:
        "AMAGON is a side-scrolling platformer game where players control Amagon, a Marine with the ability to transform into a powerful mutant form called Megagon. Players navigate through levels, battling enemies and avoiding hazards. The game features shooting mechanics and power-ups to enhance the player's abilities. AMAGON offers classic platformer gameplay with an interesting transformation mechanic.",
      developer: "Aicom",
      platform: "NES",
      genre: "Platformer",
    },
    American_Dream: {
      description:
        "American Dream is a business simulation game that allows players to build and manage their own business empire. Players can start with a small store and expand their operations by buying and selling goods, managing finances, and making strategic decisions. The game features various business opportunities and challenges, including competition and market demand. American Dream tests players' entrepreneurial skills and business acumen.",
      developer: "Cosmi Corporation",
      platform: "NES",
      genre: "Business Simulation",
    },
    America_Daitouryou_Senkyo: {
      description:
        "America Daitouryou Senkyo is a political simulation game that allows players to participate in the American presidential election. Players can choose their political party, create campaign strategies, and compete in debates to win votes. The game features various issues and policies that players must address to gain support from the public. America Daitouryou Senkyo offers a detailed political simulation experience.",
      developer: "Party Room 21",
      platform: "Famicom Disk System",
      genre: "Political Simulation",
    },
    America_Oudan_Ultra_QuizShijou_Saidai_No_Tatakai: {
      description:
        "America Oudan Ultra Quiz: Shijou Saidai no Tatakai is a quiz game that challenges players with trivia questions about various topics. Players can compete in quiz matches and answer questions to earn points. The game features a variety of questions, including general knowledge, history, and pop culture. America Oudan Ultra Quiz offers an engaging quiz experience for players.",
      developer: "Agenda",
      platform: "Famicom Disk System",
      genre: "Quiz",
    },
    anipool: {
      description:
        "AniPool is a pool simulation game that allows players to enjoy various pool games in a virtual environment. Players can choose different game modes, such as 8-ball or 9-ball, and compete against AI opponents or other players. The game features realistic physics and controls, allowing players to perform different shots and strategies. AniPool offers an immersive pool experience for fans of the sport.",
      developer: "Unknown",
      platform: "Super Famicom",
      genre: "Sports Simulation",
    },
    Ankoku_ShinwaYamatotakeru_Densetsu: {
      description:
        "Ankoku Shinwa: Yamatotakeru Densetsu is an action-adventure game based on Japanese mythology. Players control the legendary hero Yamatotakeru as he embarks on a quest to defeat evil forces and save the kingdom. The game features top-down perspective gameplay, combat against enemies, and exploration of various locations. Ankoku Shinwa offers a unique adventure experience inspired by Japanese mythology.",
      developer: "Unknown",
      platform: "Famicom Disk System",
      genre: "Action-Adventure",
    },
    Antarctic_Adventure: {
      description:
        "Antarctic Adventure is a racing game where players control a penguin racing through the Antarctic. The game features top-down perspective gameplay, and players must navigate through icy terrain, avoid obstacles, and collect flags to progress. The game offers simple and addictive gameplay, challenging players' reflexes and coordination. Antarctic Adventure provides an enjoyable racing experience with a charming penguin protagonist.",
      developer: "Konami",
      platform: "NES",
      genre: "Racing",
    },
    ANTICPTE: {
      description:
        "ANTICPTE is a puzzle game that challenges players to connect circuits and create a complete power grid. Players are presented with a grid containing nodes and circuits with specific connection requirements. The goal is to arrange the circuits and connect the nodes to establish a functioning power supply. ANTICPTE tests players' logic and problem-solving skills through intricate circuit puzzles.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Puzzle",
    },
    Aoki_Ookami_To_Shiroki_MejikaGenghis_Khan: {
      description:
        "Aoki Ookami to Shiroki Mejika: Genghis Khan is a strategy simulation game set in the historical period of the Mongol Empire. Players take on the role of Genghis Khan and must conquer various territories, manage resources, and lead armies in battles. The game features turn-based strategy gameplay, diplomacy, and resource management. Aoki Ookami to Shiroki Mejika offers a detailed and challenging strategy experience.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy Simulation",
    },
    Arabian_Dream_Sharezerd: {
      description:
        "Arabian Dream: Sharezerd is an action-adventure game set in ancient Arabia. Players control a young hero on a quest to rescue the princess from an evil sorcerer. The game features side-scrolling gameplay, combat against enemies, and exploration of various environments. Players can collect power-ups and items to enhance their abilities. Arabian Dream offers a classic adventure experience with an Arabian Nights theme.",
      developer: "Kemco",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Archon: {
      description:
        "Archon is a chess-like strategy game with a fantasy theme. Players control a team of mythical creatures on a game board divided into squares. Each type of creature has unique abilities and strengths. When two creatures land on the same square, they engage in combat to determine the winner. The goal is to eliminate the opponent's powerful piece, the Archon. Archon combines strategy and tactical gameplay for an engaging experience.",
      developer: "Free Fall Associates",
      platform: "Various platforms",
      genre: "Strategy, Chess",
    },
    Arch_Rivals: {
      description:
        "Arch Rivals is a basketball arcade game developed and published by Midway. Players compete in two-on-two basketball matches, attempting to outscore their opponents within a limited time. The game features fast-paced gameplay, dunks, steals, and special moves. Players can also engage in aggressive tactics, such as punching opponents to gain an advantage. Arch Rivals offers intense basketball action with an arcade-style approach.",
      developer: "Midway",
      platform: "Arcade",
      genre: "Sports, Basketball",
    },
    Arctic: {
      description:
        "Arctic is a text-based adventure game set in the icy wilderness of the Arctic. Players assume the role of a pilot who crash-lands in the Arctic and must survive the harsh conditions. The game relies on text-based interactions, allowing players to make decisions and solve puzzles to survive. Arctic offers a challenging survival experience in a frigid and unforgiving environment.",
      developer: "Unknown",
      platform: "Various platforms",
      genre: "Text-Based Adventure",
    },
    Arkanoid: {
      description:
        "Arkanoid is a classic arcade game and a Breakout-style brick-breaking game. Players control a paddle at the bottom of the screen and must bounce a ball to break bricks located at the top. The game features various power-ups, such as lasers and multiball, which aid the player in clearing levels. Arkanoid offers addictive gameplay and challenging levels, making it a timeless arcade hit.",
      developer: "Taito",
      platform: "Arcade",
      genre: "Arcade, Brick-Breaker",
    },
    Arkanoid2: {
      description:
        "Arkanoid II is the sequel to the popular arcade game Arkanoid. Players once again control a paddle at the bottom of the screen, bouncing a ball to break bricks located at the top. The game features new levels, power-ups, and challenges. Arkanoid II maintains the addictive gameplay of the original while introducing fresh elements to keep players engaged.",
      developer: "Taito",
      platform: "Arcade",
      genre: "Arcade, Brick-Breaker",
    },
    Arkistas_Ring: {
      description:
        "Arkista's Ring is an action-adventure game with a top-down perspective. Players control the heroine Christine, armed with a bow and arrows, as she battles enemies and explores dungeons. The game features maze-like levels with puzzles and combat challenges. Players can collect power-ups and items to aid them in their quest. Arkista's Ring offers classic action-adventure gameplay with a fantasy theme.",
      developer: "Nexoft",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Armed_Dragon_Fantasy_Villgust: {
      description:
        "Armed Dragon Fantasy: Villgust is an action-adventure game set in a fantasy world. Players control a hero on a quest to defeat monsters and save the kingdom of Villgust from an evil sorcerer. The game features side-scrolling gameplay, combat against various enemies, and exploration of diverse environments. Players can collect power-ups and upgrades to enhance their abilities. Armed Dragon Fantasy offers a classic action-adventure experience.",
      developer: "Namco",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Artelius: {
      description:
        "Artelius is a shoot 'em up arcade game developed by Taito. Players control a spaceship and must battle against waves of enemy ships and bosses. The game features horizontal scrolling gameplay, allowing players to move in multiple directions. Players can collect power-ups and weapons to enhance their firepower. Artelius offers classic shoot 'em up action with challenging enemies and levels.",
      developer: "Taito",
      platform: "Arcade",
      genre: "Shoot 'em Up",
    },
    Arumajiro: {
      description:
        "Arumajiro is a puzzle game that challenges players to guide a ball through a maze to reach the exit. The game features maze-like levels with obstacles and traps. Players must tilt the playfield to control the ball's movement and avoid hazards. Arumajiro offers a simple yet challenging gameplay experience, testing players' coordination and precision.",
      developer: "Unknown",
      platform: "Arcade",
      genre: "Puzzle",
    },
    ashura: {
      description:
        "Ashura is a platformer game where players control a character navigating through levels filled with enemies and traps. The game features side-scrolling gameplay with platforming challenges and combat against foes. Players can collect power-ups and weapons to enhance their abilities. Ashura offers classic platformer action with a variety of enemies and level designs.",
      developer: "Unknown",
      platform: "Arcade",
      genre: "Platformer",
    },
    Asmik_Kun_Land: {
      description:
        "Asmik-kun Land is an action-puzzle game where players control a character navigating through maze-like levels. The game features block-pushing mechanics, requiring players to move blocks to create paths and solve puzzles. Players must avoid enemies and hazards while strategizing to complete each level. Asmik-kun Land offers challenging puzzles and tests players' problem-solving skills.",
      developer: "Asmik Corporation",
      platform: "Game Boy",
      genre: "Action-Puzzle",
    },
    ASOArmored_Scrum_Object: {
      description:
        "ASO: Armored Scrum Object is a vertically scrolling shoot 'em up arcade game developed by Mitchell Corporation. Players control a spacecraft battling against waves of enemy ships and bosses. The game features power-ups that enhance the player's firepower and abilities. Players must navigate through challenging levels and defeat bosses to progress. ASO: Armored Scrum Object offers classic shoot 'em up gameplay.",
      developer: "Mitchell Corporation",
      platform: "Arcade",
      genre: "Shoot 'em Up",
    },
    Asterix: {
      description:
        "Asterix is a side-scrolling action game based on the popular Asterix comic book series. Players control Asterix or Obelix as they travel through various levels, defeating enemies and collecting items. The game features platforming challenges, combat against Roman soldiers, and boss battles. Players can switch between Asterix and Obelix, each with unique abilities. Asterix offers an enjoyable adventure in the Asterix universe.",
      developer: "Infogrames",
      platform: "Various platforms",
      genre: "Action",
    },
    Astro_FangSuper_Machine: {
      description:
        "Astro Fang: Super Machine is an action-adventure game with a top-down perspective. Players control a car equipped with weapons as they navigate through maze-like levels. The game features combat against enemies, exploration, and collecting items. Players can upgrade their car's abilities and face off against bosses. Astro Fang offers a unique blend of action and exploration.",
      developer: "A-Wave",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Astro_Robo_Sasa: {
      description:
        "Astro Robo Sasa is a vertically scrolling shoot 'em up arcade game developed by Nichibutsu. Players control a spacecraft battling against waves of enemy ships and bosses. The game features power-ups that enhance the player's firepower and speed. Players must navigate through challenging levels and defeat bosses to progress. Astro Robo Sasa offers classic shoot 'em up gameplay.",
      developer: "Nichibutsu",
      platform: "Arcade",
      genre: "Shoot 'em Up",
    },
    ASTYANAX: {
      description:
        "Astyanax is a side-scrolling action game where players control a hero armed with a magical axe. Players must battle through fantasy-themed levels, defeating enemies and bosses to rescue Princess Rosebud. The game features platforming challenges, combat against mythical creatures, and power-ups that enhance the player's abilities. Astyanax offers classic action gameplay with a fantasy twist.",
      developer: "Aicom",
      platform: "NES",
      genre: "Action",
    },
    athena: {
      description:
        "Athena is a side-scrolling action game where players control a goddess named Athena on a quest to rescue her sister. Players battle through various levels, defeating enemies and bosses with a sword and shield. The game features platforming challenges, combat against mythological creatures, and power-ups. Athena offers classic action gameplay with a mythological theme.",
      developer: "SNK",
      platform: "NES",
      genre: "Action",
    },
    Athletic_World: {
      description:
        "Athletic World is an exercise video game that utilizes the Power Pad accessory for the NES. Players participate in various athletic events, such as running, hurdles, and long jumps, by stepping on the Power Pad. The game features single-player and multiplayer modes, allowing players to compete against each other. Athletic World encourages physical activity and provides a fun way to exercise.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Exercise, Sports",
    },
    Atlantis_No_Nazo: {
      description:
        "Atlantis no Nazo, known as The Mystery of Atlantis in the West, is a platformer game where players control a character navigating through levels filled with enemies and obstacles. The game features side-scrolling gameplay with platforming challenges and combat against foes. Players can collect power-ups and upgrades to enhance their abilities. Atlantis no Nazo offers classic platformer action.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Platformer",
    },
    ATTACKKI: {
      description:
        "ATTACK! is a puzzle game that challenges players to clear blocks from the playfield. Players control a character equipped with a weapon that can shoot blocks upward. The goal is to match blocks of the same color, causing them to disappear. Players must strategize and aim carefully to create chain reactions and clear the playfield. ATTACK! offers engaging and challenging puzzle gameplay.",
      developer: "Unknown",
      platform: "Game Boy",
      genre: "Puzzle",
    },
    Attack_Animal_Gakuen: {
      description:
        "Attack Animal Gakuen is a fighting game featuring animals as playable characters. Players can choose from a variety of animal fighters and engage in battles against opponents. Each animal character has unique abilities and special moves. Players must master the controls and tactics to defeat their opponents. Attack Animal Gakuen offers fun and quirky fighting gameplay.",
      developer: "KID",
      platform: "Famicom Disk System",
      genre: "Fighting",
    },
    aussie_rules_footy: {
      description:
        "Aussie Rules Footy is a sports simulation game that allows players to experience Australian rules football. Players can choose their teams and compete in matches following the rules of the sport. The game features realistic football gameplay, including passing, kicking, and tackling. Aussie Rules Footy offers an authentic Australian rules football experience for fans of the sport.",
      developer: "Beam Software",
      platform: "Various platforms",
      genre: "Sports Simulation",
    },
    A_Ressha_De_Ikou: {
      description:
        "A Ressha de Ikou is a train simulation game where players assume the role of a train conductor. Players must operate trains, pick up passengers, and follow schedules to reach destinations. The game features realistic train controls and various train models. Players can experience different routes and challenges. A Ressha de Ikou offers an immersive train simulation experience.",
      developer: "Spike Chunsoft",
      platform: "Super Famicom",
      genre: "Simulation",
    },
    Babel_No_Tou: {
      description:
        "Babel no Tō, known as Tower of Babel in the West, is a puzzle game where players must climb a tower by moving and arranging blocks. Players control a character who climbs the tower, and they must create stable platforms using blocks to progress. The game features challenging puzzles, requiring players to plan their moves carefully. Babel no Tō offers engaging and brain-teasing puzzle gameplay.",
      developer: "Namco",
      platform: "Various platforms",
      genre: "Puzzle",
    },
    Baby_Boomer: {
      description:
        "Baby Boomer is a puzzle game where players control a baby navigating through maze-like levels. The goal is to collect bottles and reach the exit while avoiding enemies and obstacles. Players can manipulate objects in the environment to create paths and solve puzzles. Baby Boomer offers challenging puzzles and tests players' problem-solving skills in a cute and colorful setting.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Puzzle",
    },
    Back_to_the_Future: {
      description:
        "Back to the Future is an action-adventure game based on the popular film series. Players control Marty McFly as he travels through different time periods, solving puzzles and interacting with characters from the movies. The game features side-scrolling and top-down gameplay, along with dialogue-driven interactions. Players must navigate through various challenges to ensure the timeline remains intact. Back to the Future offers an immersive adventure in the film universe.",
      developer: "Beam Software",
      platform: "Various platforms",
      genre: "Action-Adventure",
    },
    Back_to_the_Future2and3: {
      description:
        "Back to the Future Part II & III is a video game adaptation of the second and third films in the Back to the Future series. Players control Marty McFly as he travels through time to fix anomalies in the timeline. The game features side-scrolling gameplay and puzzle-solving elements. Players must collect items and interact with characters to progress through the story. Back to the Future Part II & III offers an interactive experience based on the movies.",
      developer: "Rare",
      platform: "Various platforms",
      genre: "Action-Adventure",
    },
    BADDUDES: {
      description:
        "Bad Dudes vs. DragonNinja, known simply as Bad Dudes in the West, is a beat 'em up arcade game developed by Data East. Players control martial artists Blade and Striker as they fight their way through waves of enemies to rescue President Ronnie. The game features side-scrolling gameplay, combat against various foes, and boss battles. Players can punch, kick, and use special moves to defeat opponents. Bad Dudes offers classic beat 'em up action.",
      developer: "Data East",
      platform: "Arcade",
      genre: "Beat 'em Up",
    },
    BADMITON: {
      description:
        "Badmiton is a sports simulation game that allows players to participate in badminton matches. Players can choose their characters and compete in singles or doubles matches against computer-controlled opponents or other players. The game features realistic badminton gameplay, including smashes, lobs, and drop shots. Badmiton offers an authentic badminton experience for fans of the sport.",
      developer: "Shoei",
      platform: "Famicom Disk System",
      genre: "Sports Simulation",
    },
    BADNEWS: {
      description:
        "Bad News Baseball, known as Gekitou!! Stadium in Japan, is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Bad News Baseball offers an immersive baseball experience for fans of the sport.",
      developer: "Tose",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    BADSTREE: {
      description:
        "Bad Street Brawler, known as Street Hassle in Japan, is a beat 'em up action game for the NES. Players control a street brawler named Duke Davis as he fights through levels filled with enemies. The game features side-scrolling gameplay, combat against various foes, and boss battles. Duke can punch, kick, and perform special moves to defeat opponents. Bad Street Brawler offers classic beat 'em up action.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Beat 'em Up",
    },
    Baken_Hisshougaku_Gate_In: {
      description:
        "Baken Hisshou Gaku: Gate In is a puzzle game that challenges players to clear blocks from the playfield. Players control a character armed with a mallet and must break blocks to create paths and eliminate obstacles. The game features various block types and challenging puzzles. Players must plan their moves carefully to clear each level. Baken Hisshou Gaku: Gate In offers engaging and brain-teasing puzzle gameplay.",
      developer: "Jaleco",
      platform: "Game Boy",
      genre: "Puzzle",
    },
    Bakushou_Ai_No_Gekijou: {
      description:
        "Bakushou Ai no Gekijou is a quiz game that challenges players with trivia questions about love and romance. Players can compete in quiz matches and answer questions to earn points. The game features a variety of romantic topics and scenarios. Bakushou Ai no Gekijou offers an engaging quiz experience for players interested in love-themed trivia.",
      developer: "Sachen",
      platform: "NES",
      genre: "Quiz",
    },
    Bakushou_Jinsei_Gekijou: {
      description:
        "Bakushou Jinsei Gekijou is a board game simulation that allows players to experience various life events and challenges. Players spin a wheel to advance on the game board, encountering different scenarios and making decisions that affect their virtual lives. The game features a mix of luck and strategy as players navigate through the ups and downs of life. Bakushou Jinsei Gekijou offers a unique and entertaining board game experience.",
      developer: "Taito",
      platform: "Famicom Disk System",
      genre: "Board Game Simulation",
    },
    Bakushou_Jinsei_Gekijou2: {
      description:
        "Bakushou Jinsei Gekijou 2 is a board game simulation and the sequel to the original Bakushou Jinsei Gekijou. Players spin a wheel to advance on the game board, encountering various life events and challenges. The game features different scenarios and decision-making opportunities, allowing players to shape their virtual lives. Bakushou Jinsei Gekijou 2 offers a mix of luck and strategy in a board game format.",
      developer: "Taito",
      platform: "Famicom Disk System",
      genre: "Board Game Simulation",
    },
    Bakushou_Jinsei_Gekijou3: {
      description:
        "Bakushou Jinsei Gekijou 3 is a board game simulation and the third installment in the series. Players spin a wheel to advance on the game board, experiencing various life events and challenges. The game features different scenarios and decision-making opportunities, allowing players to shape their virtual lives. Bakushou Jinsei Gekijou 3 offers a mix of luck and strategy in a board game format.",
      developer: "Taito",
      platform: "Famicom Disk System",
      genre: "Board Game Simulation",
    },
    Bakushou_Star_Monomane_Shitennou: {
      description:
        "Bakushou Star Monomane Shitennou is a quiz game that challenges players with trivia questions about various topics. Players can compete in quiz matches and answer questions to earn points. The game features questions related to celebrities, movies, history, and more. Bakushou Star Monomane Shitennou offers an engaging quiz experience with a variety of topics to test players' knowledge.",
      developer: "Taito",
      platform: "Famicom Disk System",
      genre: "Quiz",
    },
    Ballblazer: {
      description:
        "Ballblazer is a futuristic sports video game played from a first-person perspective. Players control a rotofoil, a hovering craft, in a one-on-one ball game. The goal is to score points by shooting the ball into the opponent's goal. The game features fast-paced gameplay, competitive matches, and unique visuals. Ballblazer offers an exciting and challenging sports experience in a sci-fi setting.",
      developer: "Lucasfilm Games",
      platform: "Various platforms",
      genre: "Sports, First-Person Shooter",
    },
    Balloon_Fight: {
      description:
        "Balloon Fight is an action game where players control a character equipped with balloons on their back. The goal is to pop the balloons of enemy characters while avoiding getting hit and losing their own balloons. Players can also pop the balloons of environmental hazards to clear the level. The game features single-player and multiplayer modes, offering an enjoyable competitive experience. Balloon Fight offers simple yet addictive gameplay.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Action",
    },
    Baltron: {
      description:
        "Baltron is a shoot 'em up arcade game developed by Jaleco. Players control a spaceship and must battle against waves of enemy ships and bosses. The game features horizontal scrolling gameplay, allowing players to move in multiple directions. Players can collect power-ups and weapons to enhance their firepower. Baltron offers classic shoot 'em up action with challenging enemies and levels.",
      developer: "Jaleco",
      platform: "Arcade",
      genre: "Shoot 'em Up",
    },
    Banana: {
      description:
        "Banana is a puzzle game where players control a monkey navigating through maze-like levels. The goal is to collect bananas while avoiding enemies and hazards. Players can push blocks to create paths and solve puzzles. Banana offers challenging puzzles and tests players' problem-solving skills in a colorful and playful setting.",
      developer: "Ocean Software",
      platform: "Amstrad CPC",
      genre: "Puzzle",
    },
    Bananan_Ouji_No_Daibouken: {
      description:
        "Bananan Ouji no Daibouken is a platformer game where players control a character navigating through levels filled with enemies and obstacles. The game features side-scrolling gameplay with platforming challenges and combat against foes. Players can collect power-ups and upgrades to enhance their abilities. Bananan Ouji no Daibouken offers classic platformer action.",
      developer: "Kaneko",
      platform: "Arcade",
      genre: "Platformer",
    },
    banana_prince: {
      description:
        "Banana Prince is a platformer game where players control a prince on a quest to rescue a kidnapped princess. Players navigate through levels filled with enemies, traps, and obstacles. The game features side-scrolling gameplay, platforming challenges, and combat against foes. Players can collect power-ups and items to enhance their abilities. Banana Prince offers classic platformer action with a whimsical theme.",
      developer: "Takara",
      platform: "Game Boy",
      genre: "Platformer",
    },
    Bandai_GolfChallenge_Pebble_Beach: {
      description:
        "Bandai Golf: Challenge Pebble Beach is a golf simulation game that allows players to experience the iconic Pebble Beach golf course. Players can choose their clubs, aim, and swing to compete in rounds of golf. The game features realistic golf mechanics, including putting, chipping, and driving. Bandai Golf: Challenge Pebble Beach offers an authentic golfing experience for fans of the sport.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Golf Simulation",
    },
    Bao_Qing_Tian: {
      description:
        "Bao Qing Tian is an action game based on a popular Chinese television series. Players control a hero battling against waves of enemies and bosses. The game features side-scrolling gameplay, combat against foes, and power-ups that enhance the player's abilities. Players must defeat enemies and progress through levels to rescue the kidnapped princess. Bao Qing Tian offers classic action gameplay with a martial arts theme.",
      developer: "Cony Soft",
      platform: "NES",
      genre: "Action",
    },
    BARBIE: {
      description:
        "Barbie is a video game featuring the iconic Barbie doll character. Players can dress up Barbie, style her hair, and choose accessories to create various looks. The game offers a virtual fashion experience where players can design outfits and explore creative fashion choices. Barbie provides an interactive and imaginative gameplay experience for players interested in fashion and design.",
      developer: "Unknown",
      platform: "Various platforms",
      genre: "Fashion, Simulation",
    },
    Barcode_World: {
      description:
        "Barcode World is a unique role-playing game that utilizes barcode scanning as a gameplay mechanic. Players can scan barcodes with the Game Boy Camera accessory to generate items and characters in the game. The scanned items determine the player's in-game inventory and attributes. Players can explore the game world, battle enemies, and complete quests. Barcode World offers an innovative and creative RPG experience.",
      developer: "Epoch",
      platform: "Game Boy",
      genre: "Role-Playing",
    },
    Bards_Tale2The_Destiny_Knight: {
      description:
        "The Bard's Tale II: The Destiny Knight is a classic role-playing game and the sequel to The Bard's Tale: Tales of the Unknown. Players create a party of adventurers and embark on a quest to save the realms from an evil sorcerer. The game features first-person exploration, turn-based combat, and a vast world to explore. Players can recruit new party members, solve puzzles, and battle enemies in dungeons. The Bard's Tale II offers a deep and immersive RPG experience.",
      developer: "Interplay Entertainment",
      platform: "Various platforms",
      genre: "Role-Playing",
    },
    Bards_TaleTales_of_the_Unknown: {
      description:
        "The Bard's Tale: Tales of the Unknown is a classic role-playing game where players create a party of adventurers and embark on a quest to save the realms from an evil sorcerer. The game features first-person exploration, turn-based combat, and a vast world to explore. Players can recruit new party members, solve puzzles, and battle enemies in dungeons. The Bard's Tale offers a deep and immersive RPG experience.",
      developer: "Interplay Entertainment",
      platform: "Various platforms",
      genre: "Role-Playing",
    },
    Barker_Bills_Trick_Shooting: {
      description:
        "Barker Bill's Trick Shooting is a shooting gallery game where players control a character shooting at targets. The game features various shooting challenges, including shooting clay pigeons, bottles, and other moving targets. Players must aim accurately and shoot quickly to complete the challenges. Barker Bill's Trick Shooting offers fast-paced shooting gameplay and tests players' marksmanship skills.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Shooting Gallery",
    },
    BARTVSSP: {
      description:
        "Bart vs. the Space Mutants is a platformer game featuring the popular character Bart Simpson from The Simpsons animated series. Players control Bart as he tries to thwart an alien invasion by collecting items and solving puzzles. The game features side-scrolling gameplay, platforming challenges, and various levels based on locations from the show. Players must use their wit and platforming skills to save the day. Bart vs. the Space Mutants offers classic platformer action with a Simpsons twist.",
      developer: "Imagineering",
      platform: "Various platforms",
      genre: "Platformer",
    },
    BARTVSWO: {
      description:
        "Bart vs. the World is a platformer game featuring the popular character Bart Simpson from The Simpsons animated series. Players control Bart as he travels around the world, collecting items and solving puzzles to stop his enemies. The game features side-scrolling gameplay, platforming challenges, and various levels based on global locations. Players must use their wit and platforming skills to overcome obstacles and foes. Bart vs. the World offers classic platformer action with a Simpsons twist.",
      developer: "Imagineering",
      platform: "Various platforms",
      genre: "Platformer",
    },
    Bar_Games: {
      description:
        "Bar Games is a collection of mini-games set in a bar environment. Players can participate in various bar games and activities, such as darts, pool, and trivia. The game features different challenges and competitions, allowing players to test their skills in different bar-related games. Bar Games offers a casual and social gaming experience for players looking for light-hearted fun.",
      developer: "Beam Software",
      platform: "Various platforms",
      genre: "Mini-Games",
    },
    baseair: {
      description:
        "Baseball Stars is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Baseball Stars offers an immersive baseball experience for fans of the sport.",
      developer: "SNK",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    "Baseball (J)": {
      description:
        "Baseball is a sports simulation game that allows players to experience baseball matches. Players can choose their teams and compete in matches following the rules of the sport. The game features realistic baseball gameplay, including pitching, hitting, and fielding. Baseball offers an authentic baseball experience for fans of the sport.",
      developer: "Nintendo R&D2",
      platform: "Game Boy",
      genre: "Sports Simulation",
    },
    baseball: {
      description:
        "Baseball is a sports simulation game that allows players to experience baseball matches. Players can choose their teams and compete in matches following the rules of the sport. The game features realistic baseball gameplay, including pitching, hitting, and fielding. Baseball offers an authentic baseball experience for fans of the sport.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Sports Simulation",
    },
    Baseball_Fighter: {
      description:
        "Baseball Fighter is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Baseball Fighter offers an immersive baseball experience for fans of the sport.",
      developer: "Unknown",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Baseball_Simulator_1000: {
      description:
        "Baseball Simulator 1.000 is a unique baseball simulation game for the NES. In addition to traditional baseball gameplay, the game introduces fantasy elements, such as superpowers and special pitches. Players can customize their team, players, and abilities. The game features various game modes, including a league mode and an all-star game. Baseball Simulator 1.000 offers a fun and imaginative take on baseball simulation.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Baseball_Stars: {
      description:
        "Baseball Stars is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Baseball Stars offers an immersive baseball experience for fans of the sport.",
      developer: "SNK",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Baseball_Stars2: {
      description:
        "Baseball Stars 2 is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Baseball Stars 2 offers an immersive baseball experience for fans of the sport.",
      developer: "SNK",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    "BASELS~1": {
      description:
        "Base Wars: Cyber Stadium Series is a baseball game with a futuristic twist. In addition to standard baseball gameplay, the game allows players to control robots in matches. Players can engage in hand-to-hand combat with opposing robots to win games. The game features fighting game mechanics and strategic baseball gameplay. Base Wars offers a unique and exciting blend of sports and combat.",
      developer: "Konami",
      platform: "NES",
      genre: "Futuristic Baseball",
    },
    Bases_Loaded: {
      description:
        "Bases Loaded is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Bases Loaded offers an immersive baseball experience for fans of the sport.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Bases_Loaded3: {
      description:
        "Bases Loaded 3 is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Bases Loaded 3 offers an immersive baseball experience for fans of the sport.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    BASEWARS: {
      description:
        "Base Wars: Cyber Stadium Series is a baseball game with a futuristic twist. In addition to standard baseball gameplay, the game allows players to control robots in matches. Players can engage in hand-to-hand combat with opposing robots to win games. The game features fighting game mechanics and strategic baseball gameplay. Base Wars offers a unique and exciting blend of sports and combat.",
      developer: "Konami",
      platform: "NES",
      genre: "Futuristic Baseball",
    },
    Batman: {
      description:
        "Batman is an action game based on the 1989 Batman film directed by Tim Burton. Players control Batman as he fights against the criminal elements of Gotham City. The game features side-scrolling gameplay, combat against enemies, and platforming challenges. Batman can use various gadgets and weapons to defeat foes and navigate through levels. The game follows the plot of the movie and offers an immersive Batman experience.",
      developer: "Sunsoft",
      platform: "Various platforms",
      genre: "Action",
    },
    BATMAN3: {
      description:
        "Batman: Return of the Joker, known as Batman: Revenge of the Joker in North America, is an action game featuring the iconic superhero Batman. Players control Batman as he battles against the Joker and his henchmen. The game features side-scrolling gameplay, combat against enemies, and platforming challenges. Batman can collect power-ups and weapons to enhance his abilities. Batman: Return of the Joker offers classic action with a Batman theme.",
      developer: "Sunsoft",
      platform: "Various platforms",
      genre: "Action",
    },
    Battleship: {
      description:
        "Battleship is a video game adaptation of the classic board game where players engage in naval warfare. Players can strategically place their ships on a grid and take turns guessing the coordinates of their opponent's ships. The game features turn-based gameplay and allows players to play against the computer or against a friend. Battleship offers a digital version of the popular board game, testing players' tactical skills in naval combat.",
      developer: "Mindscape",
      platform: "Various platforms",
      genre: "Strategy",
    },
    BATTLETO: {
      description:
        "Battletoads is a beat 'em up action game developed by Rare. Players control anthropomorphic toads named Rash, Zitz, and Pimple as they battle the evil Dark Queen and her minions. The game features side-scrolling gameplay, combat against various foes, and challenging levels with unique obstacles. Players can punch, kick, and perform special moves to defeat enemies. Battletoads offers classic beat 'em up action with a humorous twist.",
      developer: "Rare",
      platform: "Various platforms",
      genre: "Beat 'em Up",
    },
    Battletoads_Double_Dragon: {
      description:
        "Battletoads & Double Dragon: The Ultimate Team is a crossover beat 'em up game featuring characters from the Battletoads and Double Dragon series. Players can choose from characters like Rash, Zitz, Pimple, Billy, and Jimmy Lee as they team up to defeat the evil Dark Queen and Shadow Boss. The game features side-scrolling gameplay, combat against various foes, and boss battles. Players can use special moves and attacks to defeat enemies. Battletoads & Double Dragon offers classic beat 'em up action with characters from two iconic franchises.",
      developer: "Rare",
      platform: "Various platforms",
      genre: "Beat 'em Up",
    },
    Battle_Baseball: {
      description:
        "Battle Baseball is a baseball simulation game for the NES. Players can choose from various teams and compete in baseball matches. The game features realistic baseball mechanics, including pitching, hitting, and fielding. Players can make strategic decisions and control the gameplay. Battle Baseball offers an immersive baseball experience for fans of the sport.",
      developer: "Banpresto",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Battle_City: {
      description:
        "Battle City is a tank battle game for the NES. Players control a tank and must defend their base from enemy tanks. The game features top-down gameplay and players can move in four directions, shooting at enemies and obstacles. Players can also strategically place blocks to create barriers and protect their base. Battle City offers classic tank battle action and tests players' tactical skills.",
      developer: "Namco",
      platform: "NES",
      genre: "Tank Battle",
    },
    Battle_Fleet: {
      description:
        "Battle Fleet is a naval strategy game where players command a fleet of ships in tactical battles. Players can choose from various ship types and engage in turn-based battles against enemy fleets. The game features strategic gameplay, allowing players to maneuver their ships, target enemy vessels, and use different types of weapons. Battle Fleet offers challenging naval battles and tests players' strategic planning.",
      developer: "Capstone Software",
      platform: "Various platforms",
      genre: "Naval Strategy",
    },

    Battle_Formula: {
      description:
        "Battle Formula is a classic action shooting game where players control a spaceship for space battles. Players need to dodge enemy attacks and destroy enemy ships and bases.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action Shooter",
    },
    Battle_StadiumSenbatsu_Pro_Yakyuu: {
      description:
        "Battle Stadium: Senbatsu Pro Yakyuu is a baseball game featuring teams from the Japanese high school baseball tournament. Players can choose their favorite high school team and compete against other teams using realistic baseball mechanics.",
      developer: "Banpresto",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Battle_Storm: {
      description:
        "Battle Storm is a vertical scrolling shooter game where players control futuristic aircraft to fight against enemy forces. Players can collect power-ups and use different weapons to defeat powerful bosses.",
      developer: "Saibu Video/Visco",
      platform: "NES",
      genre: "Vertical Shooter",
    },
    Battle_Tank: {
      description:
        "Battle Tank is a tank combat simulation game for the NES. Players control a tank and engage in battles against enemy tanks, helicopters, and other military vehicles. The game features various missions and challenging gameplay.",
      developer: "Absolute Entertainment",
      platform: "NES",
      genre: "Tank Simulation",
    },
    Bat_and_Tery: {
      description:
        "Bat and Tery is a puzzle-solving action game where players control a character who can transform into a bat or a ball. Players need to solve puzzles, avoid obstacles, and defeat enemies to progress through the levels.",
      developer: "Micronics",
      platform: "NES",
      genre: "Puzzle Action",
    },
    BAYOUBIL: {
      description:
        "BAYOUBIL is an action game where players control a character navigating a bayou environment. Players need to jump over obstacles, avoid dangerous creatures, and collect items to progress through the game.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    BeautyandtheBeast: {
      description:
        "Beauty and the Beast is a platform action game based on the Disney animated film. Players control the Beast as he battles enemies and obstacles to rescue Belle. The game features various levels inspired by scenes from the movie.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action Platformer",
    },
    BeBopHighschool_Koukousei_Gokuraku_Densetsu: {
      description:
        "Be-Bop High School: Koukousei Gokuraku Densetsu is a beat 'em up game based on the manga series. Players control high school students and fight against rival gangs using punches and kicks. The game features multiple playable characters and fast-paced action.",
      developer: "Data East",
      platform: "NES",
      genre: "Beat 'em Up",
    },
    Beetlejuice: {
      description:
        "Beetlejuice is an action-adventure game where players control Beetlejuice, the mischievous ghost, as he explores the afterlife. Players solve puzzles, defeat enemies, and interact with characters from the movie to progress through the game.",
      developer: "Rare",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Bee_52: {
      description:
        "Bee 52 is a side-scrolling shooter game where players control a bee fighting against various insects and enemies. Players can shoot projectiles and collect power-ups to enhance their abilities. The game features multiple levels with different challenges.",
      developer: "Camerica",
      platform: "NES",
      genre: "Shooter",
    },
    BESTBEST: {
      description:
        "BEST OF THE BEST: Championship Karate is a martial arts fighting game for the NES. Players can choose from different martial arts styles and compete in karate tournaments. The game features various moves, combos, and opponents to challenge players' skills.",
      developer: "Loriciel",
      platform: "NES",
      genre: "Fighting",
    },
    Best_PlayPro_Yakyuu: {
      description:
        "Best Play Pro Yakyuu is a baseball simulation game for the NES. Players can select their favorite baseball teams and compete in matches with realistic baseball mechanics. The game allows players to experience the strategic aspects of baseball.",
      developer: "ASCII",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Best_PlayPro_Yakyuu2: {
      description:
        "Best Play Pro Yakyuu 2 is a baseball simulation game and a sequel to the original. Players can choose baseball teams and participate in matches with detailed gameplay mechanics. The game offers an immersive baseball experience for fans of the sport.",
      developer: "ASCII",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Best_PlayPro_Yakyuu_90: {
      description:
        "Best Play Pro Yakyuu '90 is a baseball simulation game for the NES. Players can select their favorite baseball teams and compete in matches with realistic baseball mechanics. The game provides an authentic baseball experience with various teams to choose from.",
      developer: "ASCII",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Best_PlayPro_Yakyuu_Special: {
      description:
        "Best Play Pro Yakyuu Special is a baseball simulation game for the NES. Players can choose their preferred baseball teams and compete in matches with detailed baseball mechanics. The game offers an immersive experience for baseball enthusiasts.",
      developer: "ASCII",
      platform: "NES",
      genre: "Baseball Simulation",
    },
    Bible_Adventures: {
      description:
        "Bible Adventures is a platformer game where players control characters from famous Bible stories. Players can choose between Noah, Baby Moses, or David and guide them through various levels based on biblical events. The game features puzzles and challenges related to the Bible stories.",
      developer: "Wisdom Tree",
      platform: "NES",
      genre: "Platformer",
    },
    Bible_Buffet: {
      description:
        "Bible Buffet is a board game-style video game with a biblical theme. Players spin a wheel to move around the game board and answer trivia questions based on Bible knowledge. The game features mini-games and challenges related to biblical events.",
      developer: "Wisdom Tree",
      platform: "NES",
      genre: "Trivia",
    },
    big: {
      description:
        "Big Nose Freaks Out is a platformer game where players control Big Nose, a caveman, on a quest to rescue his kidnapped girlfriend. Players navigate various levels, avoid traps, and defeat enemies to progress. The game features colorful graphics and classic platforming gameplay.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Platformer",
    },
    BIGFOOT: {
      description:
        "BIGFOOT is a monster truck racing game where players can drive the iconic BIGFOOT monster truck. Players compete in races and perform stunts to earn points. The game features different tracks and challenges for players to master.",
      developer: "Acclaim Entertainment",
      platform: "NES",
      genre: "Racing",
    },
    bigracing: {
      description:
        "Big Racing is a racing game for the NES. Players can choose from different racing cars and compete in races on various tracks. The game features simple controls and classic racing gameplay.",
      developer: "Mega Soft",
      platform: "NES",
      genre: "Racing",
    },
    Big_Birds_Hide_And_Speak: {
      description:
        "Big Bird's Hide and Speak is an educational game featuring characters from Sesame Street. Players help Big Bird find his friends by following clues and solving puzzles. The game focuses on teaching spelling and vocabulary in an interactive way.",
      developer: "Riedel Software Productions",
      platform: "NES",
      genre: "Educational",
    },
    Big_Nose_Freaks_Out: {
      description:
        "Big Nose Freaks Out is a platformer game where players control Big Nose, a caveman, on a quest to rescue his kidnapped girlfriend. Players navigate various levels, avoid traps, and defeat enemies to progress. The game features colorful graphics and classic platforming gameplay.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Platformer",
    },
    Big_Nose_the_Caveman: {
      description:
        "Big Nose the Caveman is a platformer game where players control Big Nose, a prehistoric caveman, on a quest to rescue his girlfriend. Players navigate through various levels, avoid traps, and defeat enemies using a club. The game features classic platforming gameplay with colorful graphics.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Platformer",
    },
    Bikkuriman_WorldGekitou_Sei_Senshi: {
      description:
        "Bikkuriman World: Gekitou Sei Senshi is an action-adventure game where players control a character exploring dungeons and battling enemies. Players collect power-ups, weapons, and armor to enhance their abilities. The game features a fantasy world with challenging enemies and puzzles.",
      developer: "Intec",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Bill_Elliotts_NASCAR_Challenge: {
      description:
        "Bill Elliott's NASCAR Challenge is a racing game featuring NASCAR stock cars. Players can choose their favorite driver and compete in races on famous NASCAR tracks. The game features realistic car handling and competitive AI opponents.",
      developer: "Distinctive Software",
      platform: "NES",
      genre: "Racing",
    },
    Binary_Land: {
      description:
        "Binary Land is a puzzle-platformer game where players control two characters simultaneously, a penguin and a polar bear. Players need to guide the characters through mazes, solve puzzles, and avoid obstacles to reunite them at the end of each level. The game features challenging puzzles and coordination-based gameplay.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    Bingo_75: {
      description:
        "Bingo 75 is a bingo game for the NES. Players can participate in bingo games with different themes and patterns. The game features traditional bingo gameplay with randomized numbers and cards. Players can mark the numbers on their cards to complete the patterns and win the game.",
      developer: "Sachen",
      platform: "NES",
      genre: "Bingo",
    },
    BIONIC_C: {
      description:
        "BIONIC COMMANDO is an action-platformer game where players control a soldier equipped with a bionic arm. Players navigate levels using the bionic arm to swing, climb, and defeat enemies. The game features non-linear gameplay and various weapons and power-ups to enhance the gameplay experience.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    Bio_Miracle_Bokutte_Upa: {
      description:
        "Bio Miracle Bokutte Upa is a platformer game where players control Upa, a baby who uses a rattle to defeat enemies and navigate through levels. Players can bounce on enemies, solve puzzles, and collect items to progress. The game features colorful graphics and cute characters.",
      developer: "Konami",
      platform: "NES",
      genre: "Platformer",
    },
    Bio_Senshi_DanIncreaser_To_No_Tatakai: {
      description:
        "Bio Senshi Dan: Increaser to no Tatakai is an action game where players control a character exploring levels filled with enemies and obstacles. Players can shoot projectiles and use different weapons to defeat enemies and bosses. The game features fast-paced action and challenging gameplay.",
      developer: "Towachiki",
      platform: "NES",
      genre: "Action",
    },
    Bird_Week: {
      description:
        "Bird Week is a puzzle-action game where players control a bird catching falling eggs. Players need to catch the eggs in a specific order to complete each level. The game features various obstacles and challenges as the levels progress, requiring quick reflexes and strategic thinking.",
      developer: "Taiwan Shin-Shin",
      platform: "NES",
      genre: "Puzzle-Action",
    },
    Blackjack: {
      description:
        "Blackjack is a card game simulation for the NES. Players can play the popular casino card game against the dealer. The game follows the standard rules of blackjack, where players aim to have a hand value closer to 21 than the dealer without exceeding 21.",
      developer: "Coei",
      platform: "NES",
      genre: "Card Game",
    },
    Black_Bass: {
      description:
        "The Black Bass is a fishing simulation game for the NES. Players can choose different fishing spots and use various lures to catch different types of fish. The game features realistic fishing mechanics, including casting, reeling, and fish behavior. Players can compete in fishing tournaments to test their skills.",
      developer: "Hot-B",
      platform: "NES",
      genre: "Fishing Simulation",
    },
    Black_Bass2: {
      description:
        "The Black Bass II is a fishing simulation game and a sequel to the original Black Bass. Players can select fishing spots, choose lures, and catch different types of fish. The game features improved graphics and gameplay mechanics, providing a realistic fishing experience for players.",
      developer: "Hot-B",
      platform: "NES",
      genre: "Fishing Simulation",
    },
    BLADES: {
      description:
        "BLADES OF STEEL is an ice hockey sports game for the NES. Players can choose their favorite hockey teams and compete in fast-paced matches. The game features realistic hockey gameplay, including passing, shooting, and checking. Players can participate in tournaments and aim to win the championship.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports (Ice Hockey)",
    },
    BLASTERM: {
      description:
        "BLASTER MASTER is an action-adventure game where players control a vehicle exploring underground caverns. Players can exit the vehicle to explore on foot, solve puzzles, and defeat enemies. The game features non-linear exploration, power-ups, and challenging boss battles.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Blodia_Land: {
      description:
        "Blodia Land: Puzzle Quest is a puzzle game where players control a character solving block puzzles. Players need to match blocks of the same color to clear them and progress to the next levels. The game features challenging puzzles with increasing difficulty as players advance.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Puzzle",
    },
    Bloody_WarriorsShan_Goo_No_Gyakushuu: {
      description:
        "Bloody Warriors: Shan Go no Gyakushuu is an action game where players control a character fighting against enemies using martial arts and weapons. Players can punch, kick, and perform special moves to defeat opponents. The game features side-scrolling action and boss battles.",
      developer: "Mega Soft",
      platform: "NES",
      genre: "Action",
    },
    BLSTMSTR: {
      description:
        "Blaster Master is an action-adventure game where players control a vehicle exploring underground caverns. Players can exit the vehicle to explore on foot, solve puzzles, and defeat enemies. The game features non-linear exploration, power-ups, and challenging boss battles.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Blues_Brothers: {
      description:
        "The Blues Brothers is a platformer game based on the movie of the same name. Players control Jake Blues as he navigates through levels filled with enemies and obstacles. Players can collect musical notes and avoid hazards to progress. The game features classic platforming gameplay and a soundtrack inspired by the movie.",
      developer: "Titus Software",
      platform: "NES",
      genre: "Platformer",
    },
    Blue_Marlin: {
      description:
        "The Blue Marlin is a fishing simulation game for the NES. Players can choose different fishing spots and use various lures to catch different types of fish. The game features realistic fishing mechanics, including casting, reeling, and fish behavior. Players can compete in fishing tournaments to test their skills.",
      developer: "Hot-B",
      platform: "NES",
      genre: "Fishing Simulation",
    },
    Blue_Shadow: {
      description:
        "Blue Shadow, also known as Shadow of the Ninja in North America, is a platformer game where players control ninjas fighting against enemies in a cyberpunk world. Players can attack enemies using swords and shurikens and navigate through levels filled with traps and challenges. The game features two-player cooperative gameplay.",
      developer: "Natsume",
      platform: "NES",
      genre: "Action Platformer",
    },
    BOJACKSO: {
      description:
        "Bo Jackson Baseball is a sports game featuring baseball legend Bo Jackson. Players can choose teams and compete in baseball matches with realistic gameplay mechanics. The game offers various modes, including exhibition matches and full seasons, allowing players to experience the thrill of baseball.",
      developer: "Data East",
      platform: "NES",
      genre: "Sports (Baseball)",
    },
    Bokosuka_Wars: {
      description:
        "Bokosuka Wars is a strategy game where players control King Suren and his soldiers to reclaim the kingdom from the evil King Ogereth. Players navigate through mazes, defeat enemies, and capture castles to progress. The game features simple graphics and strategic gameplay.",
      developer: "ASCII",
      platform: "NES",
      genre: "Strategy",
    },
    bomberman2: {
      description:
        "Bomberman II is an action game and a sequel to the original Bomberman. Players control Bomberman as he navigates maze-like levels, strategically placing bombs to defeat enemies and open pathways. The game features single-player and multiplayer modes, allowing players to compete against each other or team up to complete levels.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action",
    },
    Bomberman_Birdman: {
      description:
        "Bomberman: Birdman is an action game where players control a character navigating levels filled with enemies and obstacles. Players can drop bombs to defeat enemies and clear obstacles. The game features maze-like levels with increasing difficulty and challenging enemies.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    Bomberman_Killerman: {
      description:
        "Bomberman: Killerman is an action game where players control Bomberman as he navigates maze-like levels, strategically placing bombs to defeat enemies and open pathways. The game features single-player and multiplayer modes, allowing players to compete against each other or team up to complete levels.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    Bomber_King: {
      description:
        "Bomber King, also known as RoboWarrior, is an action-adventure game where players control a character exploring labyrinthine levels. Players need to solve puzzles, defeat enemies, and find key items to progress. The game features top-down gameplay and a mix of action and exploration.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Bomber_Man: {
      description:
        "Bomberman is an action game where players control Bomberman as he navigates maze-like levels, strategically placing bombs to defeat enemies and open pathways. The game features single-player and multiplayer modes, allowing players to compete against each other or team up to complete levels.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action",
    },
    BOMBMAN: {
      description:
        "BOMB MAN is an action game where players control a character navigating maze-like levels. Players need to strategically place bombs to defeat enemies and open pathways to progress. The game features single-player and multiplayer modes, allowing players to compete against each other or team up to complete levels.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action",
    },
    Bonks_Adventure: {
      description:
        "Bonk's Adventure is a platformer game where players control Bonk, a prehistoric caveman, as he embarks on a quest to rescue Princess Za. Players navigate through levels, defeat enemies, and avoid hazards to progress. The game features colorful graphics and classic platforming gameplay.",
      developer: "Atlus",
      platform: "NES",
      genre: "Platformer",
    },
    Booby_Kids: {
      description:
        "Booby Kids is a maze action game where players control characters exploring mazes and collecting treasure chests. Players need to avoid traps and enemies while collecting the treasure to advance to the next levels. The game features multiplayer gameplay, allowing players to compete against each other.",
      developer: "Nihon Bussan",
      platform: "NES",
      genre: "Maze Action",
    },
    Boogerman: {
      description:
        "Boogerman: A Pick and Flick Adventure is a platformer game where players control Boogerman, a superhero who battles enemies using unconventional attacks like flatulence and nasal mucus. Players navigate through levels, defeat enemies, and solve puzzles to progress. The game features humorous and gross-out humor.",
      developer: "Interplay Entertainment",
      platform: "NES",
      genre: "Platformer",
    },
    Boulder_Dash: {
      description:
        "Boulder Dash is a puzzle-action game where players control a character exploring caves filled with boulders, diamonds, and enemies. Players need to collect all the diamonds in each level while avoiding falling boulders and enemies. The game features strategic gameplay and challenging puzzles.",
      developer: "First Star Software",
      platform: "NES",
      genre: "Puzzle-Action",
    },
    BrainSeries13in1: {
      description:
        "Brain Series 13-in-1 is a compilation of puzzle games for the NES. The game includes 13 different puzzle games that challenge players' logic, memory, and problem-solving skills. Each game offers unique puzzles and challenges, providing hours of brain-teasing gameplay.",
      developer: "Caltron",
      platform: "NES",
      genre: "Puzzle",
    },
    Bram_Stokers_Dracula: {
      description:
        "Bram Stoker's Dracula is an action-platformer game based on the classic novel. Players control Jonathan Harker as he battles supernatural enemies and explores various locations from the story. Players can use weapons like swords and knives to defeat enemies and progress through the game.",
      developer: "Psygnosis",
      platform: "NES",
      genre: "Action Platformer",
    },
    BREAKTHR: {
      description:
        "BREAKTHRU is an action game where players control a vehicle navigating through enemy territory. Players need to dodge obstacles, destroy enemy vehicles, and reach the exit within a time limit. The game features fast-paced gameplay, challenging levels, and various enemy types.",
      developer: "Data East",
      platform: "NES",
      genre: "Action",
    },
    Break_Time: {
      description:
        "Break Time: The National Pool Tour is a billiards simulation game for the NES. Players can choose from various pool games and compete against computer opponents or friends. The game features realistic pool mechanics, including aiming, shooting, and ball spin. Players can practice their skills or participate in tournaments.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports (Billiards)",
    },
    Brush_Roller: {
      description:
        "Brush Roller is a puzzle game where players control a paintbrush filling in different shapes with color. Players need to cover the entire shape without retracing their steps or crossing the paint lines. The game features challenging puzzles with increasing complexity as players advance.",
      developer: "Intec",
      platform: "NES",
      genre: "Puzzle",
    },
    BTLCHESS: {
      description:
        "Battle Chess is a chess simulation game for the NES. Players can play chess against the computer with animated chess pieces that battle each other when capturing a piece. The game features different difficulty levels and provides an interactive chess experience for players.",
      developer: "Data East",
      platform: "NES",
      genre: "Chess",
    },
    bubble: {
      description:
        "Bubble Bath Babes is a tile-matching puzzle game for the NES. Players need to match tiles of the same color and pattern to clear them from the screen. The game features a variety of levels with increasing difficulty and speed. Players can challenge themselves to achieve high scores in this addictive puzzle game.",
      developer: "Panesian",
      platform: "NES",
      genre: "Puzzle",
    },
    BUBBLEB2: {
      description:
        "Bubble Bath Babes is a tile-matching puzzle game for the NES. Players need to match tiles of the same color and pattern to clear them from the screen. The game features a variety of levels with increasing difficulty and speed. Players can challenge themselves to achieve high scores in this addictive puzzle game.",
      developer: "Panesian",
      platform: "NES",
      genre: "Puzzle",
    },
    Bucky_OHare: {
      description:
        "Bucky O'Hare is a platformer game where players control Bucky O'Hare, the space-faring hero, as he battles against the evil Toad Empire. Players can switch between different characters, each with unique abilities, to overcome obstacles and defeat enemies. The game features colorful graphics and classic platforming gameplay.",
      developer: "Konami",
      platform: "NES",
      genre: "Action Platformer",
    },
    Buggy_Popper: {
      description:
        "Buggy Popper is a puzzle game where players control a character who needs to pop balloons to clear the screen. Players can throw darts to pop the balloons, but they need to strategize their shots to clear all the balloons. The game features various levels with different balloon arrangements and challenges.",
      developer: "Namco",
      platform: "NES",
      genre: "Puzzle",
    },
    BUGSBUNN: {
      description:
        "Bugs Bunny Birthday Blowout is a platformer game featuring the iconic character Bugs Bunny. Players control Bugs Bunny as he navigates through levels, defeats enemies, and avoids traps. The game features colorful graphics, classic platforming gameplay, and a birthday party theme.",
      developer: "Kemco",
      platform: "NES",
      genre: "Platformer",
    },
    BUGSCC: {
      description:
        "Bugs Bunny Crazy Castle is a puzzle-platformer game where players control Bugs Bunny as he navigates through levels filled with enemies and obstacles. Players need to collect carrots and avoid enemies to progress. The game features puzzle elements, requiring players to figure out the best way to reach the exit.",
      developer: "Kemco",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    BUMPJUMP: {
      description:
        "Bump 'n' Jump is a vehicular combat game where players control a car that can jump and bump into other vehicles. Players need to avoid obstacles and enemies while trying to bump other cars off the road or into obstacles. The game features fast-paced gameplay and various tracks to race on.",
      developer: "Data East",
      platform: "NES",
      genre: "Vehicular Combat",
    },
    Burger_Time: {
      description:
        "BurgerTime is an arcade-style platformer game where players control a chef named Peter Pepper. The goal is to assemble burgers by walking over the ingredients (buns, patties, lettuce, etc.) and making them drop to the lower levels of the screen. Players need to avoid enemies while completing the burgers to progress.",
      developer: "Data East",
      platform: "NES",
      genre: "Platformer",
    },
    B_Wings: {
      description:
        "B-Wings is a vertically scrolling shooter game where players control a ship fighting against waves of enemies. Players can collect power-ups and upgrades to enhance their ship's abilities and firepower. The game features various enemy types, challenging boss battles, and fast-paced gameplay.",
      developer: "Data East",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    c06: {
      description:
        "The Cheetahmen is an action platformer game where players control anthropomorphic cheetah characters on a quest to save the world from an evil villain. Players can switch between different Cheetahmen characters, each with unique abilities, to battle enemies and navigate through levels. The game features colorful graphics and classic platforming gameplay.",
      developer: "Active Enterprises",
      platform: "NES",
      genre: "Action Platformer",
    },
    Cabal: {
      description:
        "Cabal is a shooting game where players control a soldier who must fight against enemies in various locations. Players can move horizontally and vertically, take cover behind obstacles, and shoot enemies. The game features fast-paced action, power-ups, and challenging levels with increasing difficulty.",
      developer: "TAD Corporation",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Cadillac: {
      description:
        "The Great American Cross-Country Road Race is a racing game where players participate in a cross-country road race. Players can choose from different cars and compete against computer opponents on various tracks. The game features a first-person perspective and realistic driving mechanics.",
      developer: "Activision",
      platform: "NES",
      genre: "Racing",
    },
    CAESAR: {
      description:
        "Caesar's Palace is a casino simulation game where players can experience various casino games, including slot machines, blackjack, poker, roulette, and more. Players can earn virtual money by playing games and use it to continue gambling. The game features realistic casino graphics and gameplay.",
      developer: "Arcadia Systems",
      platform: "NES",
      genre: "Casino Simulation",
    },
    CALGAMES: {
      description:
        "California Games is a sports simulation game featuring various outdoor sports and activities. Players can choose from games like surfing, skateboarding, BMX biking, and footbag. Each sport has its unique gameplay mechanics and challenges. The game features colorful graphics and a summer sports theme.",
      developer: "Epyx",
      platform: "NES",
      genre: "Sports Simulation",
    },
    caltron: {
      description:
        "Caltron 6-in-1 is a multicart game that includes six different mini-games. Players can choose from games like shooting, puzzle, and platforming. Each game offers unique gameplay mechanics and challenges. The game features simple graphics and classic gameplay reminiscent of early NES games.",
      developer: "Caltron",
      platform: "NES",
      genre: "Multi-Game Compilation",
    },
    CAPAMERI: {
      description:
        "Captain America and the Avengers is a side-scrolling beat 'em up game where players can choose to play as Captain America, Iron Man, Hawkeye, or Vision. Players fight against enemies and bosses to rescue hostages and save the world. The game features cooperative multiplayer gameplay and comic book-inspired graphics.",
      developer: "Data East",
      platform: "NES",
      genre: "Beat 'em Up",
    },
    Capcom30_in_1: {
      description:
        "Capcom 30-in-1 is a multicart game that includes 30 different Capcom games. Players can choose from a variety of genres, including action, platformer, and shooter games. Each game offers unique gameplay mechanics and challenges. The multicart features classic Capcom titles from the NES era.",
      developer: "Capcom",
      platform: "NES",
      genre: "Multi-Game Compilation",
    },
    Capcom_Barcelona_92: {
      description:
        "Capcom's Gold Medal Challenge '92 is a sports simulation game based on the Summer Olympics held in Barcelona. Players can participate in various Olympic events like track and field, swimming, diving, and more. The game features competitive gameplay, realistic sports mechanics, and Olympic-themed graphics.",
      developer: "Capcom",
      platform: "NES",
      genre: "Sports Simulation",
    },
    CAPTAINP: {
      description:
        "Captain Planet and the Planeteers is a side-scrolling action game where players control Captain Planet as he battles against enemies to save the environment. Players can collect power-ups and use elemental abilities to defeat enemies and bosses. The game features environmental themes and cooperative multiplayer gameplay.",
      developer: "Chris Gray Enterprises",
      platform: "NES",
      genre: "Action",
    },
    Captain_Ed: {
      description:
        "Captain Ed is a side-scrolling action game where players control Captain Ed, a superhero battling against enemies. Players can jump, shoot, and use special attacks to defeat enemies and progress through levels. The game features colorful graphics and classic action gameplay.",
      developer: "Mega Soft",
      platform: "NES",
      genre: "Action",
    },
    Captain_Saver: {
      description:
        "Captain Saver is a side-scrolling action game where players control Captain Saver as he battles against enemies to rescue hostages. Players can jump, shoot, and use special attacks to defeat enemies and navigate through levels. The game features challenging platforming sections and classic action gameplay.",
      developer: "Mega Soft",
      platform: "NES",
      genre: "Action",
    },
    Captain_Silver: {
      description:
        "Captain Silver is a side-scrolling action game where players control Captain Silver as he battles against enemies to collect pieces of a map. Players can attack enemies using a sword and navigate through levels filled with traps and obstacles. The game features classic action gameplay and pirate-themed graphics.",
      developer: "Data East",
      platform: "NES",
      genre: "Action",
    },
    Captain_Tsubasa: {
      description:
        "Captain Tsubasa is a soccer simulation game based on the anime and manga series of the same name. Players can choose teams and compete in soccer matches, controlling the players' movements and actions. The game features anime-style graphics, special moves, and realistic soccer gameplay.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Sports (Soccer)",
    },
    Captain_Tsubasa_Vol2Super_Striker: {
      description:
        "Captain Tsubasa Vol. II: Super Striker is a soccer simulation game based on the anime and manga series Captain Tsubasa. Players can choose teams and compete in soccer matches, controlling the players' movements and actions. The game features anime-style graphics, special moves, and realistic soccer gameplay.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Sports (Soccer)",
    },
    CAPTSKYH: {
      description:
        "Captain Skyhawk is a vertical-scrolling shooter game where players control a fighter jet battling against alien invaders. Players can upgrade their weapons and face challenging bosses in various missions. The game features fast-paced gameplay, power-ups, and intense aerial combat.",
      developer: "Rare",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    CART: {
      description:
        "California Raisins: The Grape Escape is a platformer game featuring the California Raisins characters. Players control a California Raisin as he navigates through levels, avoids enemies, and collects musical notes. The game features colorful graphics, classic platforming gameplay, and a music theme.",
      developer: "Radiance Software",
      platform: "NES",
      genre: "Platformer",
    },
    CASINO: {
      description:
        "Casino Kid is a casino simulation game where players can experience various casino games, including poker, blackjack, slot machines, and more. Players can challenge computer opponents in card games and earn money to progress. The game features realistic casino graphics and gameplay.",
      developer: "SOFEL",
      platform: "NES",
      genre: "Casino Simulation",
    },
    Casino_Derby: {
      description:
        "Casino Derby is a casino simulation game where players can experience various casino games, including poker, blackjack, slot machines, and more. Players can challenge computer opponents in card games and earn money to progress. The game features realistic casino graphics and gameplay.",
      developer: "Romstar",
      platform: "NES",
      genre: "Casino Simulation",
    },
    Casino_Kid2: {
      description:
        "Casino Kid II is a casino simulation game where players can experience various casino games, including poker, blackjack, slot machines, and more. Players can challenge computer opponents in card games and earn money to progress. The game features realistic casino graphics and gameplay.",
      developer: "SOFEL",
      platform: "NES",
      genre: "Casino Simulation",
    },
    CASTDECT: {
      description:
        "Castle of Deceit is a platformer game where players control a hero battling against enemies in a castle filled with traps and obstacles. Players can use weapons like swords and shurikens to defeat enemies and navigate through levels. The game features challenging platforming sections and classic action gameplay.",
      developer: "Bunch Games",
      platform: "NES",
      genre: "Platformer",
    },
    CASTDRAG: {
      description:
        "Castlequest is an action-adventure game where players control a character exploring a castle to rescue the kidnapped princess. Players need to solve puzzles, avoid traps, and defeat enemies to progress. The game features top-down gameplay, challenging puzzles, and a mix of action and exploration.",
      developer: "ASCII",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Castelian: {
      description:
        "Castelian is a puzzle game where players control a character navigating through maze-like structures. Players need to strategically destroy blocks to clear pathways and reach the exit. The game features puzzle elements, requiring players to plan their moves and solve challenges to progress.",
      developer: "Bits Studios",
      platform: "NES",
      genre: "Puzzle",
    },
    CASTLE1: {
      description:
        "Castle Quest is an action game where players control a character exploring a castle and battling against enemies. Players can collect power-ups and use weapons to defeat enemies and progress through levels. The game features side-scrolling action and challenging enemies.",
      developer: "Nexoft",
      platform: "NES",
      genre: "Action",
    },
    CASTLE2: {
      description:
        "Castle of Dragon is a side-scrolling action game where players control a knight on a quest to rescue the princess from an evil dragon. Players can use a sword and magic attacks to defeat enemies and navigate through levels. The game features platforming elements and challenging boss battles.",
      developer: "SETA Corporation",
      platform: "NES",
      genre: "Action",
    },
    CASTLE3: {
      description:
        "Castlequest is an action-adventure game where players control a character exploring a castle to rescue the kidnapped princess. Players need to solve puzzles, avoid traps, and defeat enemies to progress. The game features top-down gameplay, challenging puzzles, and a mix of action and exploration.",
      developer: "ASCII",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Castlequest: {
      description:
        "Castlequest is an action-adventure game where players control a character exploring a castle to rescue the kidnapped princess. Players need to solve puzzles, avoid traps, and defeat enemies to progress. The game features top-down gameplay, challenging puzzles, and a mix of action and exploration.",
      developer: "ASCII",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Castle_Excellent: {
      description:
        "Castle Excellent is a puzzle game where players control a character navigating through maze-like structures. Players need to strategically destroy blocks to clear pathways and reach the exit. The game features puzzle elements, requiring players to plan their moves and solve challenges to progress.",
      developer: "ASCII",
      platform: "NES",
      genre: "Puzzle",
    },
    Castle_Quest: {
      description:
        "Castle Quest is an action game where players control a character exploring a castle and battling against enemies. Players can collect power-ups and use weapons to defeat enemies and progress through levels. The game features side-scrolling action and challenging enemies.",
      developer: "Nexoft",
      platform: "NES",
      genre: "Action",
    },
    Cattou_Ninden_Teyandee: {
      description:
        "Cattou Ninden Teyandee is a platformer game based on the anime series Samurai Pizza Cats. Players can choose to play as characters from the series and battle against enemies to save the city. The game features colorful graphics, humorous elements, and classic platforming gameplay.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    CAVEMAN: {
      description:
        "Caveman Games is a sports simulation game featuring prehistoric characters participating in various athletic events. Players can compete in events like fire-making, dino race, mate toss, and clubbing. The game features multiplayer gameplay, allowing players to challenge their friends in prehistoric sports.",
      developer: "Data East",
      platform: "NES",
      genre: "Sports Simulation",
    },
    Cecathlon: {
      description:
        "Cecathlon is a sports simulation game featuring various Olympic events. Players can compete in events like running, swimming, shooting, and more. The game features competitive gameplay, realistic sports mechanics, and Olympic-themed graphics.",
      developer: "Atlus",
      platform: "NES",
      genre: "Sports Simulation",
    },
    Chack_n_Pop: {
      description:
        "Chack'n Pop is a platformer game where players control a character named Chack'n who must clear each level of hearts and enemies to progress. Players can dig through soft blocks and trap enemies to clear pathways. The game features maze-like levels, time limits, and challenging gameplay.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    Challenger: {
      description:
        "Challenger is a vertically scrolling shooter game where players control a spacecraft battling against waves of enemies. Players can collect power-ups and upgrades to enhance their ship's abilities and firepower. The game features various enemy types, challenging boss battles, and fast-paced gameplay.",
      developer: "SNK",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Challenge_of_the_Dragon: {
      description:
        "Challenge of the Dragon is a beat 'em up game where players can choose to play as a male or female character on a quest to rescue the emperor's daughter. Players can fight against enemies using punches, kicks, and special moves. The game features side-scrolling beat 'em up gameplay and cooperative multiplayer.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Beat 'em Up",
    },
    Championship_Bowling: {
      description:
        "Championship Bowling is a bowling simulation game for the NES. Players can choose from different characters and compete in bowling tournaments. The game features realistic bowling mechanics, including aiming, power, and spin control. Players can challenge computer opponents or play against friends.",
      developer: "Romstar",
      platform: "NES",
      genre: "Sports Simulation (Bowling)",
    },
    Championship_Lode_Runner: {
      description:
        "Championship Lode Runner is a puzzle-platformer game where players control a character navigating through maze-like levels. Players need to collect all the gold pieces while avoiding enemies and traps. The game features strategic gameplay, challenging puzzles, and level editor functionality.",
      developer: "Broderbund",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    Championship_Pool: {
      description:
        "Championship Pool is a pool simulation game for the NES. Players can choose from various pool games and compete against computer opponents or friends. The game features realistic pool mechanics, including aiming, shooting, and ball spin. Players can practice their skills or participate in tournaments.",
      developer: "Bitmasters",
      platform: "NES",
      genre: "Sports Simulation (Billiards)",
    },
    chan: {
      description:
        "Chanticleer Hegemony is a strategy game where players control an army of roosters battling against hordes of invading worms. Players need to strategically deploy roosters and defenses to protect their territory and defeat the enemy worms. The game features turn-based gameplay and challenging strategy.",
      developer: "Hacker International",
      platform: "NES",
      genre: "Strategy",
    },
    Chaos_World: {
      description:
        "Chaos World is a role-playing game where players control a hero on a quest to defeat the evil Demon King. Players can recruit allies, explore dungeons, and battle against enemies in turn-based combat. The game features an open world to explore, a variety of monsters, and a classic RPG experience.",
      developer: "Tomy",
      platform: "NES",
      genre: "Role-Playing Game",
    },
    CHBO: {
      description:
        "Chubby Cherub is a side-scrolling action game where players control a cherub navigating through various levels to find hearts and rescue a kidnapped girl. Players can shoot enemies and collect power-ups to enhance their abilities. The game features colorful graphics and classic action gameplay.",
      developer: "Tose",
      platform: "NES",
      genre: "Action",
    },
    Cheetahmen2: {
      description:
        "The Cheetahmen II is an action platformer game where players control anthropomorphic cheetah characters on a quest to save the world from an evil villain. Players can switch between different Cheetahmen characters, each with unique abilities, to battle enemies and navigate through levels. The game features colorful graphics and classic platforming gameplay.",
      developer: "Active Enterprises",
      platform: "NES",
      genre: "Action Platformer",
    },
    Chessmaster: {
      description:
        "Chessmaster is a chess simulation game for the NES. Players can play chess against the computer with various difficulty levels, allowing both beginners and experienced players to enjoy the game. The game features realistic chess mechanics and provides an interactive chess experience for players.",
      developer: "Hi-Tech Expressions",
      platform: "NES",
      genre: "Chess",
    },
    Chester_Field: {
      description:
        "Chester Field: Ankoku Shin e no Chousen is an action-adventure game where players control a character exploring dungeons, solving puzzles, and battling enemies. Players can collect items and weapons to enhance their abilities and progress through the game. The game features top-down gameplay and a mix of action and exploration.",
      developer: "Sofel",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Chibi_Maruko_Chan_Uki_Uki_Shopping: {
      description:
        "Chibi Maruko Chan: Uki Uki Shopping is a shopping simulation game where players control the character Maruko Chan as she goes shopping for various items. Players need to manage their budget and buy the required items while avoiding unnecessary purchases. The game features a shopping theme and cute graphics.",
      developer: "Namco",
      platform: "NES",
      genre: "Simulation",
    },
    Chiisana_Obake_Atchi_Sotchi_Kotchi: {
      description:
        "Chiisana Obake: Atchi Sotchi Kotchi is a puzzle game where players control a character navigating through maze-like levels. Players need to collect keys and reach the exit while avoiding enemies and obstacles. The game features puzzle elements, requiring players to plan their moves and solve challenges to progress.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Puzzle",
    },
    Chiki_Chiki_Machine_Mou_Race: {
      description:
        "Chiki Chiki Machine Mou Race is a racing game where players control a chicken character racing against other animals on various tracks. Players can collect power-ups and use speed boosts to gain an advantage. The game features colorful graphics, humorous characters, and fast-paced racing gameplay.",
      developer: "Konami",
      platform: "NES",
      genre: "Racing",
    },
    Chip_n_Dale_Rescue_Rangers: {
      description:
        "Chip 'n Dale: Rescue Rangers is a platformer game where players can choose to play as Chip or Dale, the iconic chipmunk characters. Players need to rescue their friend Gadget from the evil cat Fat Cat. The game features cooperative multiplayer gameplay, colorful graphics, and classic platforming challenges.",
      developer: "Capcom",
      platform: "NES",
      genre: "Platformer",
    },
    Chip_To_Dale_No_Daisakusen: {
      description:
        "Chip 'n Dale: Rescue Rangers is a platformer game where players can choose to play as Chip or Dale, the iconic chipmunk characters. Players need to rescue their friend Gadget from the evil cat Fat Cat. The game features cooperative multiplayer gameplay, colorful graphics, and classic platforming challenges.",
      developer: "Capcom",
      platform: "NES",
      genre: "Platformer",
    },
    Chip_To_Dale_No_Daisakusen2: {
      description:
        "Chip 'n Dale: Rescue Rangers 2 is a platformer game and the sequel to the original Rescue Rangers game. Players can choose to play as Chip or Dale, the iconic chipmunk characters, and embark on a new adventure to rescue their friend Gadget from the evil cat Fat Cat. The game features cooperative multiplayer gameplay, colorful graphics, and classic platforming challenges.",
      developer: "Capcom",
      platform: "NES",
      genre: "Platformer",
    },
    Chitei_SenkuuBazorudaa: {
      description:
        "Chitei Senkuu Bazorudaa is a shooter game where players control a spaceship battling against waves of enemies. Players can collect power-ups and upgrades to enhance their ship's abilities and firepower. The game features fast-paced gameplay, challenging boss battles, and a variety of enemy types.",
      developer: "Data East",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Chiyo_No_Fuji_No_Ooichou: {
      description:
        "Chiyo no Fuji no Ooichou is a sumo wrestling simulation game where players can participate in sumo tournaments and compete against opponents. Players need to use various sumo techniques to push opponents out of the ring or force them to touch the ground with any part of their body other than the soles of their feet. The game features competitive sumo gameplay and traditional Japanese themes.",
      developer: "KSS",
      platform: "NES",
      genre: "Sports Simulation (Sumo Wrestling)",
    },
    chmpraly: {
      description:
        "Championship Rally is a top-down racing game where players control a rally car and race against opponents on various tracks. Players need to navigate through twists and turns, avoid obstacles, and use nitro boosts strategically to gain an advantage. The game features competitive racing gameplay and a variety of racecourses.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Racing",
    },
    Choplifter: {
      description:
        "Choplifter is a side-scrolling action game where players control a helicopter pilot on a mission to rescue hostages from enemy territory. Players need to evade enemy fire, land carefully to pick up hostages, and return them safely to the home base. The game features challenging rescue missions, enemy vehicles, and strategic gameplay.",
      developer: "Brøderbund",
      platform: "NES",
      genre: "Action",
    },
    ChoujinUltra_Baseball: {
      description:
        "Choujin Ultra Baseball is a baseball simulation game featuring fictional superhuman baseball players. Players can choose teams and compete in baseball matches, controlling the players' movements and actions. The game features anime-style graphics, special moves, and humorous gameplay elements.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Sports Simulation (Baseball)",
    },
    Choujin_Ookami_SenkiWarwolf: {
      description:
        "Choujin Ookami Senki: Warwolf is an action game where players control a werewolf battling against enemies. Players can use a sword and other weapons to defeat enemies and navigate through levels. The game features side-scrolling action, challenging enemies, and a fantasy theme.",
      developer: "Data East",
      platform: "NES",
      genre: "Action",
    },
    Chouwakusei_SenkiMetafight: {
      description:
        "Chouwakusei Senki: Metafight is an action-adventure game where players control a character exploring a post-apocalyptic world. Players need to battle against enemies, collect power-ups, and defeat bosses to progress. The game features side-scrolling action, exploration, and a futuristic theme.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Chronicle_of_the_Radia_War: {
      description:
        "Chronicle of the Radia War is a role-playing game where players control a hero on a quest to rescue a kidnapped princess and defeat an evil sorcerer. Players can recruit allies, explore dungeons, and battle against enemies in turn-based combat. The game features an open world to explore, a variety of monsters, and a classic RPG experience.",
      developer: "ASCII",
      platform: "NES",
      genre: "Role-Playing Game",
    },
    CHUBBY: {
      description:
        "Chubby Cherub is a side-scrolling action game where players control a cherub navigating through various levels to find hearts and rescue a kidnapped girl. Players can shoot enemies and collect power-ups to enhance their abilities. The game features colorful graphics and classic action gameplay.",
      developer: "Tose",
      platform: "NES",
      genre: "Action",
    },
    CHUHAN: {
      description:
        "Chu Han Zheng Ba: The War Between Chu & Han is a strategy game set in ancient China. Players can choose to play as either the Chu or Han forces and engage in tactical battles on a hexagonal grid. Players need to use strategy to outmaneuver opponents and capture their bases. The game features turn-based gameplay, historical themes, and challenging battles.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Strategy",
    },
    Chuugoku_Janshi_Story_Tonpuu: {
      description:
        "Chuugoku Janshi Story: Tonpuu is a mahjong game featuring a story mode where players compete against various mahjong opponents. Players can engage in mahjong matches and progress through the story, interacting with different characters. The game features traditional mahjong gameplay and anime-style graphics.",
      developer: "dB-Soft",
      platform: "NES",
      genre: "Mahjong",
    },
    Chuugoku_Senseijutsu: {
      description:
        "Chuugoku Senseijutsu is a mahjong game featuring traditional Chinese mahjong gameplay. Players can compete against computer opponents or challenge their friends in mahjong matches. The game features classic mahjong rules, tile matching, and strategic gameplay.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Mahjong",
    },
    chuuka: {
      description:
        "Chuuka Taisen is a shoot 'em up game where players control a flying dragon battling against waves of enemies. Players can shoot projectiles and use special attacks to defeat enemies and navigate through levels. The game features fast-paced gameplay, power-ups, and intense aerial combat.",
      developer: "Culturesoft",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    circus: {
      description:
        "Circus Charlie is an action game where players control a circus performer navigating through various circus acts. Players need to jump over obstacles, avoid hazards, and perform stunts to progress through levels. The game features colorful graphics, challenging gameplay, and a circus theme.",
      developer: "Konami",
      platform: "NES",
      genre: "Action",
    },
    Circus_Charlie: {
      description:
        "Circus Charlie is an action game where players control a circus performer navigating through various circus acts. Players need to jump over obstacles, avoid hazards, and perform stunts to progress through levels. The game features colorful graphics, challenging gameplay, and a circus theme.",
      developer: "Konami",
      platform: "NES",
      genre: "Action",
    },
    CITYCON: {
      description:
        "City Connection is a platformer game where players control a car traveling through various cities around the world. Players need to paint every section of the road while avoiding police cars and other obstacles. The game features colorful graphics, fast-paced gameplay, and a unique concept.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Platformer",
    },
    City_Adventure_TouchMystery_of_Triangle: {
      description:
        "City Adventure Touch: Mystery of Triangle is a puzzle-adventure game where players control a character exploring a mysterious city. Players need to solve puzzles, find clues, and interact with the environment to progress through the game. The game features point-and-click gameplay, challenging puzzles, and a mysterious atmosphere.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Puzzle-Adventure",
    },
    CLASHDMN: {
      description:
        "Clash at Demonhead is an action-adventure game where players control a hero on a mission to rescue a scientist and prevent the world from being destroyed by a powerful demon. Players can explore a non-linear world, engage in conversations with NPCs, and battle against enemies using various weapons. The game features exploration, multiple endings, and a mix of action and adventure gameplay.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Classic_Concentration: {
      description:
        "Classic Concentration is a puzzle game based on the television game show of the same name. Players need to match pairs of hidden symbols on a game board within a limited time. The game features different themes, difficulty levels, and memory-based gameplay.",
      developer: "Softie, Inc.",
      platform: "NES",
      genre: "Puzzle",
    },
    Cliffhanger: {
      description:
        "Cliffhanger is an action game where players control the character Cliffhanger, a mountain climber on a mission to rescue his sister from a group of criminals. Players need to navigate through dangerous terrain, avoid obstacles, and defeat enemies to progress. The game features side-scrolling action, challenging levels, and a cinematic presentation.",
      developer: "Spidersoft",
      platform: "NES",
      genre: "Action",
    },
    Clu_Clu_Land: {
      description:
        "Clu Clu Land is an action-puzzle game where players control a character named Bubbles who needs to uncover hidden gold ingots in underwater mazes. Players need to navigate through the mazes, avoid enemies, and uncover the gold while dealing with various obstacles. The game features maze-based gameplay, strategic movement, and colorful graphics.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Puzzle-Action",
    },
    clzlcn: {
      description:
        "Challenge of the Dragon is a beat 'em up game where players can choose to play as a male or female character on a quest to rescue the emperor's daughter. Players can fight against enemies using punches, kicks, and special moves. The game features side-scrolling beat 'em up gameplay and cooperative multiplayer.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Beat 'em Up",
    },
    COBRA: {
      description:
        "Cobra Command is a rail shooter game where players control a helicopter and engage in aerial battles against enemy forces. Players need to aim and shoot at enemies while avoiding incoming fire and obstacles. The game features fast-paced gameplay, cinematic sequences, and intense aerial combat.",
      developer: "Data East",
      platform: "NES",
      genre: "Rail Shooter",
    },
    COBRATRI: {
      description:
        "Cobra Triangle is a vehicular combat game where players control an armed speedboat and participate in various missions and challenges. Players need to navigate through water-based levels, defeat enemies, and complete objectives such as rescuing stranded swimmers or collecting items. The game features fast-paced gameplay, power-ups, and a variety of challenges.",
      developer: "Rare",
      platform: "NES",
      genre: "Vehicular Combat",
    },
    Cobra_Command: {
      description:
        "Cobra Command is a rail shooter game where players control a helicopter and engage in aerial battles against enemy forces. Players need to aim and shoot at enemies while avoiding incoming fire and obstacles. The game features fast-paced gameplay, cinematic sequences, and intense aerial combat.",
      developer: "Data East",
      platform: "NES",
      genre: "Rail Shooter",
    },
    Cocoron: {
      description:
        "Cocoron is a platformer game where players can create a custom character by selecting body parts and abilities. Players then control their custom character in platforming levels, battling enemies and bosses. The game features a unique character creation system, colorful graphics, and classic platforming gameplay.",
      developer: "K2 LLC",
      platform: "NES",
      genre: "Platformer",
    },
    Color_A_Dinosaur: {
      description:
        "Color a Dinosaur is a coloring book game where players can select different colors and paint a variety of dinosaur-themed pictures. Players can use their creativity to color the dinosaurs in different shades and patterns. The game features a simple interface and a wide range of colors to choose from.",
      developer: "FarSight Studios",
      platform: "NES",
      genre: "Educational",
    },
    Colour2001_Streetfighter_II: {
      description:
        "Color 2001 Street Fighter II is a fighting game where players can select characters from the Street Fighter II series and engage in one-on-one battles. Players need to use their fighting skills and special moves to defeat opponents and win matches. The game features colorful graphics, diverse characters, and classic fighting gameplay.",
      developer: "Hummer Team",
      platform: "NES",
      genre: "Fighting",
    },
    ColumbusOugon_No_Yoake: {
      description:
        "Columbus: Ougon no Yoake is a strategy game where players control Christopher Columbus on his journey to discover the New World. Players need to manage resources, recruit crew members, and make strategic decisions to navigate the seas and explore new lands. The game features historical themes, exploration, and strategic gameplay.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    "Commando (U)": {
      description:
        "Commando is a vertically scrolling shooter game where players control a soldier battling against waves of enemies. Players need to shoot enemies, dodge bullets, and collect power-ups to enhance their firepower. The game features fast-paced gameplay, challenging enemies, and a variety of weapons.",
      developer: "Capcom",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    commando: {
      description:
        "Commando is a vertically scrolling shooter game where players control a soldier battling against waves of enemies. Players need to shoot enemies, dodge bullets, and collect power-ups to enhance their firepower. The game features fast-paced gameplay, challenging enemies, and a variety of weapons.",
      developer: "Capcom",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    CONAN: {
      description:
        "Conan: The Mysteries of Time is a side-scrolling action game where players control Conan, a warrior on a quest to save a kidnapped princess and defeat an evil sorcerer. Players need to battle against enemies, avoid traps, and navigate through levels. The game features platforming action, challenging enemies, and a fantasy theme.",
      developer: "Data East",
      platform: "NES",
      genre: "Action",
    },
    CONCRYST: {
      description:
        "Conquest of the Crystal Palace is an action platformer game where players control a young prince on a quest to rescue his dog and defeat an evil demon. Players can jump, attack, and use magical abilities to battle enemies and progress through levels. The game features colorful graphics, challenging gameplay, and a fantasy theme.",
      developer: "Quest",
      platform: "NES",
      genre: "Action Platformer",
    },
    Conflict: {
      description:
        "Conflict is a war simulation game where players command an army and engage in tactical battles against enemy forces. Players need to use strategy to outmaneuver opponents, deploy troops effectively, and achieve victory on the battlefield. The game features turn-based gameplay, military tactics, and challenging battles.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Strategy",
    },
    "Contra (J)": {
      description:
        "Contra is a run-and-gun action game where players control commandos on a mission to stop an evil alien force. Players need to navigate through side-scrolling levels, defeat enemies, and avoid obstacles and hazards. The game features cooperative multiplayer gameplay, powerful weapons, and intense action.",
      developer: "Konami",
      platform: "NES",
      genre: "Run-and-Gun",
    },
    CONTRA: {
      description:
        "Contra is a run-and-gun action game where players control commandos on a mission to stop an evil alien force. Players need to navigate through side-scrolling levels, defeat enemies, and avoid obstacles and hazards. The game features cooperative multiplayer gameplay, powerful weapons, and intense action.",
      developer: "Konami",
      platform: "NES",
      genre: "Run-and-Gun",
    },
    ContraForce: {
      description:
        "Contra Force is a run-and-gun action game and a spin-off of the original Contra series. Players can choose from different characters, each with unique abilities, and battle against enemies in side-scrolling levels. Players need to shoot enemies, avoid traps, and defeat bosses to progress. The game features cooperative multiplayer gameplay and classic Contra action.",
      developer: "Konami",
      platform: "NES",
      genre: "Run-and-Gun",
    },
    contras: {
      description:
        "Contra Spirits is a run-and-gun action game and part of the Contra series. Players control commandos battling against enemies in side-scrolling levels. Players need to navigate through various environments, defeat enemies, and avoid obstacles and traps. The game features cooperative multiplayer gameplay, powerful weapons, and intense action.",
      developer: "Konami",
      platform: "NES",
      genre: "Run-and-Gun",
    },
    Cool_World: {
      description:
        "Cool World is an action-adventure game based on the movie of the same name. Players control a character navigating through various levels inspired by the film. Players need to defeat enemies, avoid obstacles, and solve puzzles to progress. The game features platforming action, colorful graphics, and a unique world inspired by the movie.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Cosmic_Epsilon: {
      description:
        "Cosmic Epsilon is a space-themed shoot 'em up game where players control a spaceship battling against waves of enemy ships. Players can shoot projectiles and use power-ups to enhance their firepower. The game features fast-paced gameplay, challenging enemies, and intense space battles.",
      developer: "Home Data",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Cosmic_Wars: {
      description:
        "Cosmic Wars is a strategy game where players command spaceships in tactical battles. Players need to deploy their fleet, navigate through space, and engage in battles against enemy forces. The game features turn-based gameplay, strategic decisions, and space-themed battles.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    Cosmo_Genesis: {
      description:
        "Cosmo Genesis is a shoot 'em up game where players control a spaceship battling against waves of enemy ships. Players can shoot projectiles and use power-ups to enhance their firepower. The game features fast-paced gameplay, challenging enemies, and intense space battles.",
      developer: "Telenet Japan",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Cosmo_PoliceGalivan: {
      description:
        "Cosmo Police Galivan is a platformer game where players control a space police officer on a mission to rescue hostages from alien invaders. Players can run, jump, and use various weapons to defeat enemies and navigate through levels. The game features side-scrolling action, colorful graphics, and classic platforming gameplay.",
      developer: "Nichibutsu",
      platform: "NES",
      genre: "Platformer",
    },
    Crackout: {
      description:
        "Crackout is a breakout-style arcade game where players control a paddle and ball to break bricks and clear levels. Players need to bounce the ball off the paddle to hit bricks and eliminate them from the screen. The game features different types of bricks, power-ups, and challenging level designs.",
      developer: "Konami",
      platform: "NES",
      genre: "Arcade",
    },
    CRASHBOY: {
      description:
        "CRASH BOY is a puzzle game where players need to clear the screen of colored blocks by matching them in groups of three or more. Players can swap adjacent blocks to create matches and clear them from the board. The game features colorful graphics, various block types, and challenging puzzles.",
      developer: "Bits Laboratory",
      platform: "NES",
      genre: "Puzzle",
    },
    Crayon_Shin_Chan: {
      description:
        "Crayon Shin-Chan is an action game based on the popular anime and manga series. Players control the character Shin-Chan and navigate through levels, defeating enemies and avoiding hazards. The game features side-scrolling action, colorful graphics, and characters from the Shin-Chan series.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    Crazy_Climber: {
      description:
        "Crazy Climber is an arcade action game where players control a climber scaling a building filled with hazards. Players need to avoid obstacles, open windows, and dodge falling objects while climbing to the top. The game features challenging gameplay, precise controls, and a vertical scrolling perspective.",
      developer: "Nihon Bussan",
      platform: "NES",
      genre: "Arcade",
    },
    Crisis_Force: {
      description:
        "Crisis Force is a shoot 'em up game where players control aircraft battling against enemy forces. Players can shoot projectiles, use power-ups, and engage in intense aerial combat. The game features fast-paced gameplay, challenging enemies, and a variety of weapons.",
      developer: "Konami",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Cross_Fire: {
      description:
        "Cross Fire is a puzzle game where players need to clear the screen of colored blocks by matching them in groups of three or more. Players can swap adjacent blocks to create matches and clear them from the board. The game features colorful graphics, various block types, and challenging puzzles.",
      developer: "Nihon Bussan",
      platform: "NES",
      genre: "Puzzle",
    },
    CRYSTALI: {
      description:
        "CRYSTALI is a puzzle game where players need to connect matching crystals to clear them from the screen. Players can swap adjacent crystals to create matches and eliminate them. The game features colorful graphics, various crystal types, and challenging puzzles.",
      developer: "Asmik",
      platform: "NES",
      genre: "Puzzle",
    },
    CRYSTLCV: {
      description:
        "CRYSTALIS is an action RPG where players control a character on a quest to save the world from a post-apocalyptic threat. Players can explore the world, battle enemies, and level up their abilities. The game features an open world, a variety of weapons and items, and a compelling story.",
      developer: "SNK",
      platform: "NES",
      genre: "Action RPG",
    },
    CYBERBAL: {
      description:
        "Cyberball is a futuristic sports game where players control robotic football teams. Players need to pass, run, and score touchdowns while avoiding opponents and obstacles. The game features fast-paced gameplay, strategic team management, and a unique sci-fi sports concept.",
      developer: "Atari Games",
      platform: "NES",
      genre: "Sports",
    },
    CYBRNOID: {
      description:
        "Cybernoid: The Fighting Machine is a shoot 'em up game where players control a spaceship battling against waves of enemy ships and ground-based enemies. Players can shoot projectiles and collect power-ups to enhance their firepower. The game features fast-paced gameplay, challenging enemies, and intense sci-fi battles.",
      developer: "Hewson Consultants",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Cycle_RaceRoad_Man: {
      description:
        "Cycle Race: Road Man is a cycling simulation game where players compete in road cycling races. Players need to pedal, steer, and manage their stamina to stay ahead of the competition. The game features realistic cycling mechanics, various race tracks, and competitive AI opponents.",
      developer: "K Amusement Leasing",
      platform: "NES",
      genre: "Cycling Simulation",
    },
    d4cjqrdz: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    Dai2_JiSuper_Robot_Taisen: {
      description:
        "Dai-2-Ji Super Robot Taisen is a tactical RPG featuring mechs from various anime series. Players command a team of mechs in grid-based battles against enemy forces. Players need to use strategy, deploy units effectively, and exploit enemy weaknesses to achieve victory. The game features turn-based tactics, anime-inspired mechs, and a variety of playable characters.",
      developer: "Banpresto",
      platform: "NES",
      genre: "Tactical RPG",
    },
    DaikaijuuDeburas: {
      description:
        "Daikaijuu Deburas is an action game where players control a giant monster on a rampage through a city. Players can destroy buildings, vehicles, and other structures while avoiding military attacks. The game features destructible environments, chaotic gameplay, and a monster movie-inspired theme.",
      developer: "Tomy",
      platform: "NES",
      genre: "Action",
    },
    Daiku_No_Gen_San: {
      description:
        "Daiku no Gen-san is a platformer game where players control a carpenter navigating through levels filled with obstacles and enemies. Players can jump, climb ladders, and use various tools to defeat enemies and progress. The game features side-scrolling action, challenging platforming, and a lighthearted theme.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Platformer",
    },
    Daiku_No_Gen_San2Akage_No_Dan_No_Gyakushuu: {
      description:
        "Daiku no Gen-san 2: Akage no Dan no Gyakushuu is a platformer game and the sequel to the original Daiku no Gen-san. Players control a carpenter navigating through levels, battling enemies and avoiding hazards. Players can use various tools and abilities to progress. The game features side-scrolling action, challenging platforming, and a lighthearted theme.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Platformer",
    },
    DaimeiroMeikyuu_No_Tatsujin: {
      description:
        "Daimeiro Meikyuu no Tatsujin is a dungeon-crawling RPG where players explore mazes, battle monsters, and search for treasures. Players control a party of characters and navigate through grid-based dungeons. The game features turn-based combat, random encounters, and a variety of items and equipment.",
      developer: "NHK",
      platform: "NES",
      genre: "Dungeon Crawler",
    },
    Daisenryaku: {
      description:
        "Daisenryaku is a war simulation game where players command an army and engage in tactical battles against enemy forces. Players need to use strategy to outmaneuver opponents, deploy troops effectively, and achieve victory on the battlefield. The game features turn-based gameplay, military tactics, and challenging battles.",
      developer: "SystemSoft",
      platform: "NES",
      genre: "Strategy",
    },
    DaivaImperial_of_Nirsartia: {
      description:
        "Daiva: Imperial of Nirsartia is a role-playing game where players embark on a quest in a fantasy world. Players control a character, explore various locations, battle enemies, and complete quests. The game features turn-based combat, a diverse cast of characters, and a detailed fantasy world.",
      developer: "Microcabin",
      platform: "NES",
      genre: "RPG",
    },
    Dai_Koukai_Jidai: {
      description:
        "Dai Koukai Jidai is a strategy game where players lead a fleet of ships in naval battles. Players need to manage their fleet, navigate the seas, and engage in battles against enemy ships. The game features turn-based gameplay, strategic decisions, and naval warfare.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    Dance_Aerobics: {
      description:
        "Dance Aerobics is a rhythm game where players follow on-screen prompts to perform dance exercises. Players need to match the movements shown on the screen and keep up with the rhythm to earn points. The game features various dance routines, music tracks, and fitness-oriented gameplay.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Rhythm Fitness",
    },
    Danny_Sullivans_Indy_Heat: {
      description:
        "Danny Sullivan's Indy Heat is a top-down racing game featuring IndyCar racing. Players can compete in high-speed races on various tracks, striving to win races and championships. The game features fast-paced gameplay, realistic racing mechanics, and competitive AI opponents.",
      developer: "Leland Corporation",
      platform: "NES",
      genre: "Racing",
    },
    DARKMAN: {
      description:
        "Darkman is an action platformer game based on the movie of the same name. Players control Darkman, a vigilante seeking revenge, as he battles enemies and overcomes obstacles. The game features side-scrolling action, varied levels, and a storyline inspired by the film.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action Platformer",
    },
    DARKWING: {
      description:
        "Darkwing Duck is a platformer game based on the popular animated TV series. Players control Darkwing Duck, a superhero, as he battles villains and saves the city. The game features side-scrolling action, colorful graphics, and characters from the Darkwing Duck universe.",
      developer: "Capcom",
      platform: "NES",
      genre: "Platformer",
    },
    Dark_Lord: {
      description:
        "Dark Lord is a role-playing game where players embark on a quest in a fantasy world. Players control a character, explore various locations, battle enemies, and complete quests. The game features turn-based combat, a diverse cast of characters, and a detailed fantasy world.",
      developer: "UPL",
      platform: "NES",
      genre: "RPG",
    },
    Dash_Galaxy_in_the_Alien_Asylum: {
      description:
        "Dash Galaxy in the Alien Asylum is an action-adventure game where players control Dash Galaxy on a mission to rescue hostages in an alien-infested space station. Players need to navigate through maze-like levels, avoid traps, and outsmart enemies to complete the rescue mission. The game features top-down perspective, puzzle-solving, and platforming challenges.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Dash_Yarou: {
      description:
        "Dash Yarou is a racing game where players compete in high-speed races on various tracks. Players can choose from different vehicles and strive to win races and championships. The game features fast-paced gameplay, realistic racing mechanics, and competitive AI opponents.",
      developer: "Data East",
      platform: "NES",
      genre: "Racing",
    },
    Datach_Battle_Rush: {
      description:
        "Datach: Battle Rush is a competitive sports game where players can participate in a variety of track and field events. Players need to perform well in different sports challenges, such as running, jumping, and throwing, to earn points and win the competition. The game features button-mashing gameplay, colorful graphics, and multiple sports events.",
      developer: "Bandai",
      platform: "NES",
      genre: "Sports",
    },
    Datach_Crayon_Shin_Chan: {
      description:
        "Datach: Crayon Shin-Chan is a quiz game featuring questions from the popular anime and manga series. Players need to answer trivia questions correctly to progress and win the game. The game features quiz-based gameplay, colorful graphics, and characters from the Shin-Chan series.",
      developer: "Bandai",
      platform: "NES",
      genre: "Quiz",
    },
    Datach_Dragon_Ball_ZGekitou_Tenkaichi_Budou_Kai: {
      description:
        "Datach: Dragon Ball Z - Gekitou Tenkaichi Budou Kai is a fighting game based on the Dragon Ball Z anime and manga series. Players can choose their favorite characters and engage in one-on-one battles. The game features special moves, combos, and iconic characters from the Dragon Ball Z universe.",
      developer: "Bandai",
      platform: "NES",
      genre: "Fighting",
    },
    Datach_Gundam_Wars: {
      description:
        "Datach: Gundam Wars is a strategy game where players command mechs and engage in tactical battles against enemy forces. Players need to use strategy to outmaneuver opponents, deploy units effectively, and achieve victory on the battlefield. The game features turn-based gameplay, anime-inspired mechs, and a variety of playable characters.",
      developer: "Bandai",
      platform: "NES",
      genre: "Strategy",
    },
    Datach_J_LeagueSuper_Top_Players: {
      description:
        "Datach: J-League Super Top Players is a soccer simulation game featuring teams and players from the J-League. Players can compete in matches, manage their team, and strive to win championships. The game features realistic soccer mechanics, team management options, and competitive AI opponents.",
      developer: "Bandai",
      platform: "NES",
      genre: "Soccer Simulation",
    },
    Datach_Ultraman_Kurabu: {
      description:
        "Datach: Ultraman Club is a quiz game featuring questions from the Ultraman anime series. Players need to answer trivia questions correctly to progress and win the game. The game features quiz-based gameplay, colorful graphics, and characters from the Ultraman series.",
      developer: "Bandai",
      platform: "NES",
      genre: "Quiz",
    },
    Datsugoku: {
      description:
        "Datsugoku is an action game where players control a character trying to escape from prison. Players need to navigate through levels, avoid guards, and overcome obstacles to reach freedom. The game features side-scrolling action, stealth elements, and challenging escape scenarios.",
      developer: "Irem",
      platform: "NES",
      genre: "Action",
    },
    Days_of_Thunder: {
      description:
        "Days of Thunder is a racing game based on the movie of the same name. Players can compete in high-speed stock car races on various tracks. Players need to master the art of drafting, pit stops, and aggressive driving to win races and championships. The game features realistic racing mechanics, competitive AI opponents, and a thrilling atmosphere.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Racing",
    },
    Day_Dreamin_Davey: {
      description:
        "Day Dreamin' Davey is a platformer game where players control a young boy named Davey who embarks on imaginative adventures. Players need to navigate through dreamlike levels, defeat enemies, and overcome obstacles. The game features side-scrolling action, colorful graphics, and a whimsical theme.",
      developer: "Sculptured Software",
      platform: "NES",
      genre: "Platformer",
    },
    db: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    DBZ3: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    dbz4in1: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    dbz5cn: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    DD1: {
      description:
        "DD1 is a shoot 'em up game where players control a spaceship battling against waves of enemy ships. Players can shoot projectiles and use power-ups to enhance their firepower. The game features fast-paced gameplay, challenging enemies, and intense space battles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    dd4: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    DDRIBBLE: {
      description:
        "DDRIBBLE is a basketball simulation game where players can participate in one-on-one basketball matches. Players need to dribble, shoot, and defend against opponents to score points and win the game. The game features realistic basketball mechanics, player animations, and competitive AI opponents.",
      developer: "Unknown",
      platform: "NES",
      genre: "Basketball Simulation",
    },
    ddz: {
      description:
        "Apologies, but I couldn't find any information about this game. It appears to be a non-standard or unreleased title.",
    },
    Deadfox: {
      description:
        "Dead Fox is an action game where players control a character navigating through levels filled with enemies and obstacles. Players can shoot projectiles, dodge attacks, and collect power-ups to enhance their abilities. The game features side-scrolling action, challenging enemies, and fast-paced gameplay.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    DEADLYTO: {
      description:
        "Deadly Towers is an action-adventure game where players control a character exploring a tower filled with monsters and traps. Players need to battle enemies, solve puzzles, and find items to progress through the tower. The game features top-down perspective, maze-like levels, and a variety of enemies.",
      developer: "Lenar",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Deathbots: {
      description:
        "Deathbots is an action game where players control a robot battling against waves of enemy robots. Players can shoot projectiles, dodge attacks, and collect power-ups to enhance their abilities. The game features top-down perspective, fast-paced gameplay, and intense robot battles.",
      developer: "Activision",
      platform: "NES",
      genre: "Action",
    },
    Deblock: {
      description:
        "Deblock is a puzzle game where players need to clear the screen of colored blocks by matching them in groups of three or more. Players can swap adjacent blocks to create matches and clear them from the board. The game features colorful graphics, various block types, and challenging puzzles.",
      developer: "Sony Imagesoft",
      platform: "NES",
      genre: "Puzzle",
    },
    Deep_Dungeon3Yuushi_E_No_Tabi: {
      description:
        "Deep Dungeon III: Yuushi e no Tabi is a dungeon-crawling RPG where players explore mazes, battle monsters, and search for treasures. Players control a party of characters and navigate through grid-based dungeons. The game features turn-based combat, random encounters, and a variety of items and equipment.",
      developer: "Pony Canyon",
      platform: "NES",
      genre: "Dungeon Crawler",
    },
    Deep_Dungeon_4Kuro_No_Youjutsushi: {
      description:
        "Deep Dungeon IV: Kuro no Youjutsushi is a dungeon-crawling RPG where players explore mazes, battle monsters, and search for treasures. Players control a party of characters and navigate through grid-based dungeons. The game features turn-based combat, random encounters, and a variety of items and equipment.",
      developer: "Pony Canyon",
      platform: "NES",
      genre: "Dungeon Crawler",
    },
    DEFENDER: {
      description:
        "Defender is a shoot 'em up game where players control a spaceship defending humanoids from alien invaders. Players can shoot projectiles, deploy smart bombs, and rescue humanoids to earn points. The game features side-scrolling action, fast-paced gameplay, and challenging enemy waves.",
      developer: "Williams Electronics",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Defenders_of_Dynatron_City: {
      description:
        "Defenders of Dynatron City is an action game where players control superhero characters defending the city from villains. Players can switch between characters, battle enemies, and overcome obstacles to save the city. The game features side-scrolling action, colorful graphics, and cooperative gameplay.",
      developer: "Lucasfilm Games",
      platform: "NES",
      genre: "Action",
    },
    Defender_of_the_Crown: {
      description:
        "Defender of the Crown is a strategy game where players control a nobleman leading armies in medieval battles. Players need to manage their resources, recruit troops, and engage in tactical battles to conquer territories. The game features turn-based gameplay, strategic decisions, and a medieval setting.",
      developer: "Cinemaware",
      platform: "NES",
      genre: "Strategy",
    },
    Deja_Vu: {
      description:
        "Deja Vu is a point-and-click adventure game where players assume the role of a detective investigating a mysterious case. Players need to explore various locations, collect items, and solve puzzles to unravel the story. The game features a noir-inspired atmosphere, intricate puzzles, and a gripping narrative.",
      developer: "ICOM Simulations",
      platform: "NES",
      genre: "Point-and-Click Adventure",
    },
    DengekiBig_Bang: {
      description:
        "Dengeki Big Bang! is an action game where players control a character navigating through levels filled with enemies and obstacles. Players can shoot projectiles, dodge attacks, and collect power-ups to enhance their abilities. The game features side-scrolling action, colorful graphics, and challenging gameplay.",
      developer: "Kadokawa",
      platform: "NES",
      genre: "Action",
    },
    Densetsu_No_KishiElrond: {
      description:
        "Densetsu no Kishi: Elrond is an action RPG where players control a knight on a quest to save the kingdom from evil forces. Players can explore the world, battle enemies, and level up their abilities. The game features action combat, a variety of weapons and items, and a fantasy-themed world.",
      developer: "A-Wave",
      platform: "NES",
      genre: "Action RPG",
    },
    Derby_Stallion: {
      description:
        "Derby Stallion is a horse racing simulation game where players can participate in horse races, manage their stable, and breed horses. Players need to train their horses, choose jockeys, and make strategic decisions to win races and championships. The game features realistic horse racing mechanics, betting options, and a variety of horse breeds.",
      developer: "ASCII",
      platform: "NES",
      genre: "Horse Racing Simulation",
    },
    Derby_StallionZenkokuban: {
      description:
        "Derby Stallion: Zenkokuban is an expanded version of the original Derby Stallion game. It offers more horses, tracks, and features for players to enjoy. Players can participate in horse races, manage their stable, and breed horses. The game features realistic horse racing mechanics, betting options, and a variety of horse breeds.",
      developer: "ASCII",
      platform: "NES",
      genre: "Horse Racing Simulation",
    },
    Desert_Commander: {
      description:
        "Desert Commander is a strategy game where players command military units in desert warfare. Players need to use strategy to outmaneuver opponents, deploy troops effectively, and achieve victory on the battlefield. The game features turn-based gameplay, military tactics, and challenging battles.",
      developer: "Kemco",
      platform: "NES",
      genre: "Strategy",
    },
    Destination_Earthstar: {
      description:
        "Destination Earthstar is a space-themed shoot 'em up game where players control a spaceship battling against waves of enemy ships. Players can shoot projectiles, use power-ups, and engage in intense space combat. The game features fast-paced gameplay, challenging enemies, and sci-fi battles.",
      developer: "Acclaim Entertainment",
      platform: "NES",
      genre: "Shoot 'em Up",
    },
    Destiny_of_an_Emperor: {
      description:
        "Destiny of an Emperor is a strategy RPG based on the historical novel 'Romance of the Three Kingdoms.' Players command a party of characters in grid-based battles and engage in tactical warfare. Players need to recruit allies, level up characters, and use strategy to win battles. The game features turn-based combat, a compelling story, and characters from the Three Kingdoms era.",
      developer: "Capcom",
      platform: "NES",
      genre: "Strategy RPG",
    },
    Destiny_of_an_Emperor2: {
      description:
        "Destiny of an Emperor II is the sequel to the original game and continues the story of the Three Kingdoms era. Players command a party of characters in grid-based battles and engage in tactical warfare. Players need to recruit allies, level up characters, and use strategy to win battles. The game features turn-based combat, a compelling story, and characters from the Three Kingdoms era.",
      developer: "Capcom",
      platform: "NES",
      genre: "Strategy RPG",
    },
    "Devil World": {
      description:
        "Devil World is a maze-based action game where players control a character navigating through levels filled with enemies and obstacles. Players need to collect items, avoid enemies, and solve puzzles to progress. The game features top-down perspective, maze-like levels, and a variety of challenges.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action",
    },
    Devil_Man: {
      description:
        "Devil Man is an action platformer game where players control Devil Man on a mission to rescue his girlfriend. Players need to navigate through levels, defeat enemies, and overcome obstacles to complete the rescue mission. The game features side-scrolling action, colorful graphics, and challenging gameplay.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action Platformer",
    },
    Devil_World: {
      description:
        "Devil World is a maze-based action game where players control a character navigating through levels filled with enemies and obstacles. Players need to collect items, avoid enemies, and solve puzzles to progress. The game features top-down perspective, maze-like levels, and a variety of challenges.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action",
    },
    "Dezaemon (English v1_1)": {
      description:
        "Dezaemon is a game creation tool that allows players to create their own shoot 'em up games. Players can design levels, enemies, and weapons to craft unique gameplay experiences. The game features a user-friendly interface, customization options, and the ability to share created games with others.",
      developer: "Athena",
      platform: "NES",
      genre: "Game Creation",
    },
    Dezaemon: {
      description:
        "Dezaemon is a game creation tool that allows players to create their own shoot 'em up games. Players can design levels, enemies, and weapons to craft unique gameplay experiences. The game features a user-friendly interface, customization options, and the ability to share created games with others.",
      developer: "Athena",
      platform: "NES",
      genre: "Game Creation",
    },
    DICKTRA: {
      description:
        "Dick Tracy is an action game based on the comic strip character. Players control Dick Tracy as he battles against criminals and solves cases. The game features side-scrolling action, various weapons, and a storyline inspired by the comic series.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Action",
    },
    dicktracy: {
      description:
        "Dick Tracy is an action game based on the comic strip character. Players control Dick Tracy as he battles against criminals and solves cases. The game features side-scrolling action, various weapons, and a storyline inspired by the comic series.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Action",
    },
    Die_Hard: {
      description:
        "Die Hard is an action game based on the movie of the same name. Players control John McClane, the protagonist, as he battles terrorists and saves hostages in a building. The game features top-down perspective, shooting action, and a storyline inspired by the film.",
      developer: "Pack-In-Video",
      platform: "NES",
      genre: "Action",
    },
    DiggerThe_Legend_of_the_Lost_City: {
      description:
        "Digger T. Rock: The Legend of the Lost City is an action-adventure game where players control Digger T. Rock on a quest to find treasure in a lost city. Players need to navigate through maze-like levels, avoid traps, and solve puzzles to progress. The game features side-scrolling action, exploration, and platforming challenges.",
      developer: "Rare",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Digital_Devil_MonogatariMegami_Tensei: {
      description:
        "Digital Devil Story: Megami Tensei is a role-playing game where players control a character exploring dungeons, battling demons, and collecting allies. Players can negotiate with demons, fuse them to create powerful allies, and engage in turn-based battles. The game features first-person dungeon exploration, demon fusion mechanics, and a dark fantasy storyline.",
      developer: "Atlus",
      platform: "NES",
      genre: "RPG",
    },
    Digital_Devil_MonogatariMegami_Tensei2: {
      description:
        "Digital Devil Story: Megami Tensei II is the sequel to the original game and continues the story of the protagonist battling demons and exploring a post-apocalyptic world. Players can negotiate with demons, fuse them to create powerful allies, and engage in turn-based battles. The game features first-person dungeon exploration, demon fusion mechanics, and a dark fantasy storyline.",
      developer: "Atlus",
      platform: "NES",
      genre: "RPG",
    },
    Dig_Dug: {
      description:
        "Dig Dug is an arcade action game where players control Dig Dug, a character digging underground tunnels and eliminating monsters. Players can inflate enemies until they burst or drop rocks on them to defeat them. The game features underground exploration, puzzle-solving, and classic arcade gameplay.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    Dig_Dug2: {
      description:
        "Dig Dug II: Trouble in Paradise is the sequel to the original Dig Dug game. In this game, players control Dig Dug as he digs tunnels to sink islands and defeat enemies. Players need to strategically collapse islands to eliminate foes and progress through the levels. The game features island-sinking gameplay, puzzle-solving, and arcade action.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    Dirty_Harry: {
      description:
        "Dirty Harry is an action game based on the movie character. Players control Harry Callahan as he battles criminals and solves cases. The game features side-scrolling action, various weapons, and a storyline inspired by the film series.",
      developer: "Gray Matter Inc.",
      platform: "NES",
      genre: "Action",
    },
    Dizzy_The_Adventurer: {
      description:
        "Dizzy: The Adventurer is a platformer game featuring the character Dizzy, an egg-shaped hero. Players need to navigate through levels, collect items, and solve puzzles to progress. The game features side-scrolling action, challenging platforming, and a lighthearted theme.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Platformer",
    },
    DMNSWRD: {
      description:
        "D.M.N.S.W.R.D. is a puzzle game where players need to guide a character through maze-like levels, avoiding obstacles and enemies. Players can push blocks, trigger switches, and plan their moves to solve puzzles and reach the exit. The game features top-down perspective, puzzle-solving challenges, and a variety of obstacles.",
      developer: "Tonkin House",
      platform: "NES",
      genre: "Puzzle",
    },
    Doctor_PCXue_Si_Dian_Nao: {
      description:
        "Doctor PC: Xue Si Dian Nao is a puzzle game where players need to guide a character through maze-like levels, avoiding obstacles and enemies. Players can push blocks, trigger switches, and plan their moves to solve puzzles and reach the exit. The game features top-down perspective, puzzle-solving challenges, and a variety of obstacles.",
      developer: "Sachen",
      platform: "NES",
      genre: "Puzzle",
    },
    Doki_Doki_Yuuenchi: {
      description:
        "Doki Doki Yuuenchi is a board game simulation where players can participate in various carnival activities. Players can play minigames, win prizes, and enjoy the carnival atmosphere. The game features colorful graphics, multiplayer options, and a variety of carnival games.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Board Game Simulation",
    },
    Dokuganryuu_Masamune: {
      description:
        "Dokuganryuu Masamune is a strategy game where players lead a samurai army in battles against rival warlords. Players need to use strategy to outmaneuver opponents, deploy troops effectively, and achieve victory on the battlefield. The game features turn-based gameplay, historical battles, and military tactics.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    Donald_Duck: {
      description:
        "Donald Duck is a platformer game featuring the popular Disney character Donald Duck. Players control Donald as he navigates through levels, defeats enemies, and overcomes obstacles. The game features side-scrolling action, colorful graphics, and characters from the Disney universe.",
      developer: "Bimboosoft",
      platform: "NES",
      genre: "Platformer",
    },
    Donald_Land: {
      description:
        "Donald Land is a platformer game featuring the popular Disney character Donald Duck. Players control Donald as he navigates through levels, defeats enemies, and overcomes obstacles. The game features side-scrolling action, colorful graphics, and characters from the Disney universe.",
      developer: "Data East",
      platform: "NES",
      genre: "Platformer",
    },
    Donkey_Kong: {
      description:
        "Donkey Kong is an arcade classic and one of Nintendo's most iconic games. Players control Mario as he tries to rescue his girlfriend from the giant ape Donkey Kong. Players need to climb platforms, avoid obstacles, and reach the top to save the day. The game features platforming action, challenging levels, and memorable characters.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    Donkey_Kong3: {
      description:
        "Donkey Kong 3 is the third installment in the Donkey Kong series. In this game, players control Stanley the Bugman, who must fend off Donkey Kong and an army of bees. Players use bug spray to repel the bees and defeat Donkey Kong. The game features shooting action, arcade gameplay, and challenging levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Shooter",
    },
    Donkey_Kong_Classics: {
      description:
        "Donkey Kong Classics is a compilation cartridge that includes the original Donkey Kong and its sequel, Donkey Kong Jr. Players can experience both classic games in one package. Donkey Kong features Mario's quest to rescue his girlfriend from Donkey Kong, while Donkey Kong Jr. features Junior's mission to free his father from Mario's captivity. Both games feature platforming action and challenging levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    Donkey_Kong_Jr: {
      description:
        "Donkey Kong Jr. is the sequel to the original Donkey Kong game. In this game, players control Donkey Kong's son, Junior, who must rescue his father from Mario's captivity. Players need to climb vines, avoid enemies, and solve puzzles to progress through the levels. The game features platforming action, colorful graphics, and challenging gameplay.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    Don_Doko_Don: {
      description:
        "Don Doko Don is a platformer game where players control dwarves armed with hammers, battling against enemies and monsters. Players need to defeat foes by hitting them with hammers and collect items to progress through the levels. The game features colorful graphics, cute characters, and challenging platforming.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    Don_Doko_Don2: {
      description:
        "Don Doko Don 2 is the sequel to the original game and continues the adventures of the dwarves armed with hammers. Players control the dwarves as they battle enemies, solve puzzles, and collect items. The game features side-scrolling action, cute characters, and challenging platforming.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    Door_Door: {
      description:
        "Door Door is a puzzle-platformer game where players control a character named Chun as he traps monsters behind doors. Players need to lure monsters to specific positions and then close doors to trap them. The game features puzzle-solving gameplay, arcade elements, and challenging levels.",
      developer: "Enix",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    Doraemo: {
      description:
        "Doraemon is an action-adventure game featuring the popular anime character Doraemon. Players control Doraemon as he explores various levels, battles enemies, and solves puzzles. The game features side-scrolling action, colorful graphics, and characters from the Doraemon series.",
      developer: "Epoch",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Doraemon: {
      description:
        "Doraemon is an action-adventure game featuring the popular anime character Doraemon. Players control Doraemon as he explores various levels, battles enemies, and solves puzzles. The game features side-scrolling action, colorful graphics, and characters from the Doraemon series.",
      developer: "Epoch",
      platform: "NES",
      genre: "Action-Adventure",
    },
    DoraemonGigazombie_No_Gyakushuu: {
      description:
        "Doraemon: Gigazombie no Gyakushuu is an action-adventure game featuring the popular anime character Doraemon. Players need to explore levels, battle enemies, and solve puzzles to progress through the game. The game features side-scrolling action, colorful graphics, and characters from the Doraemon series.",
      developer: "Epoch",
      platform: "NES",
      genre: "Action-Adventure",
    },
    DoraemonThe_Revenge_of_Giga_Zombie: {
      description:
        "Doraemon: The Revenge of Giga Zombie is an action-adventure game featuring the popular anime character Doraemon. Players need to explore levels, battle enemies, and solve puzzles to progress through the game. The game features side-scrolling action, colorful graphics, and characters from the Doraemon series.",
      developer: "Epoch",
      platform: "NES",
      genre: "Action-Adventure",
    },
    doubledragon3: {
      description:
        "Double Dragon III: The Sacred Stones is a beat 'em up game and the third installment in the Double Dragon series. Players can choose between different characters and embark on a quest to defeat enemies and rescue a kidnapped friend. The game features side-scrolling beat 'em up gameplay, cooperative multiplayer, and challenging combat.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Beat 'em Up",
    },
    Double_Dare: {
      description:
        "Double Dare is a video game adaptation of the Nickelodeon game show Double Dare. Players participate in a trivia and stunt-based competition, answering questions and completing messy physical challenges.",
      developer: "Rare",
      platform: "NES",
      genre: "Trivia, Game Show",
    },
    Double_Moon_Densetsu: {
      description:
        "Double Moon Densetsu is a role-playing video game set in a fantasy world. Players embark on a quest, battling monsters and exploring dungeons to save the kingdom from an evil sorcerer.",
      developer: "Unknown",
      platform: "NES",
      genre: "Role-playing",
    },
    Double_Strike: {
      description:
        "Double Strike is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemy aircraft and bosses. The game features power-ups and intense shooting action.",
      developer: "American Video Entertainment",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Dough_Boy: {
      description:
        "Dough Boy is a puzzle-platformer video game. Players control a character made of dough, navigating through levels filled with obstacles and enemies. The game focuses on creative problem-solving.",
      developer: "Sachen",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    DowntownNekketsu_Koushin_KyokuSoreyuke_Daiundoukai: {
      description:
        "Downtown Nekketsu Koushin Kyoku: Soreyuke Daiundoukai is a beat 'em up video game. It is part of the Kunio-kun series. Players control Kunio or one of his friends, fighting through the streets and participating in various sports events.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Beat 'em up, Sports",
    },
    DQ1C: {
      description:
        "Dragon Quest is a role-playing video game. Players create a hero character and embark on a quest to defeat an evil dragon and save the kingdom. The game features turn-based battles and exploration.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    DQ2C: {
      description:
        "Dragon Quest II is a role-playing video game, serving as the second installment in the Dragon Quest series. Players control a group of heroes on a quest to defeat an evil sorcerer. The game features turn-based battles and a vast world to explore.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dq4: {
      description:
        "Dragon Quest IV: Chapters of the Chosen is a role-playing video game. It is the fourth installment in the Dragon Quest series. The game follows the journey of multiple protagonists, each with their own story, as they work together to defeat a great evil.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    DQ4C: {
      description:
        "Dragon Quest IV: Chapters of the Chosen is a role-playing video game. It is the fourth installment in the Dragon Quest series. The game follows the journey of multiple protagonists, each with their own story, as they work together to defeat a great evil.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dracs_Night_Out: {
      description:
        "Drac's Night Out is an action puzzle game. Players control Dracula, guiding him through a maze to reach his coffin while avoiding enemies and traps. The game features maze-solving and strategy elements.",
      developer: "Microsmiths",
      platform: "NES",
      genre: "Action, Puzzle",
    },
    dragonkiller: {
      description:
        "Dragon Killer is an action-adventure game. Players control a hero on a quest to defeat dragons and other mythical creatures. The game combines action combat with exploration and puzzle-solving.",
      developer: "Paragon Programming",
      platform: "NES",
      genre: "Action-adventure",
    },
    dragonkiller2: {
      description:
        "Dragon Killer II is the sequel to the action-adventure game Dragon Killer. Players once again embark on a quest to defeat dragons and other mythical creatures. The game features improved graphics and gameplay mechanics.",
      developer: "Paragon Programming",
      platform: "NES",
      genre: "Action-adventure",
    },
    DragonlanceDragons_of_Flame: {
      description:
        "Dragonlance: Dragons of Flame is a role-playing video game based on the Dragonlance fantasy book series. Players control a group of heroes, battling enemies and solving puzzles to advance the story. The game features turn-based combat and a fantasy narrative.",
      developer: "U.S. Gold",
      platform: "NES",
      genre: "Role-playing",
    },
    dragon_ball: {
      description:
        "Dragon Ball: Shenlong no Nazo is a role-playing video game based on the Dragon Ball manga and anime series. Players control Goku, the main character, as he embarks on a quest to collect the Dragon Balls and save the world. The game features turn-based battles and exploration.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Ball2Daimaou_Fukkatsu: {
      description:
        "Dragon Ball: Daimaou Fukkatsu is a role-playing video game based on the Dragon Ball manga and anime series. Players control Goku and his friends, exploring the world, battling enemies, and collecting items. The game features turn-based battles and a quest to defeat the evil Daimaou.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Ball3Gokuu_Den: {
      description:
        "Dragon Ball: Gokuu Den is a role-playing video game based on the Dragon Ball manga and anime series. Players control Goku, participating in battles and completing quests. The game features turn-based combat and a storyline following the events of the Dragon Ball series.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_BallShen_Ron_No_Nazo: {
      description:
        "Dragon Ball: Shenron no Nazo is a role-playing video game based on the Dragon Ball manga and anime series. Players control Goku, exploring the world, battling enemies, and collecting Dragon Balls. The game features turn-based battles and a quest to defeat the Red Ribbon Army.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Ball_Z2Gekishin_Freeza: {
      description:
        "Dragon Ball Z: Gekishin Freeza!! is a role-playing video game based on the Dragon Ball Z manga and anime series. Players control characters from the series, engaging in battles and leveling up their abilities. The game features turn-based combat and a storyline following the events of the Namek Saga.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Ball_Z3Ressen_Jinzou_Ningen: {
      description:
        "Dragon Ball Z: Ressen Jinzou Ningen is a role-playing video game based on the Dragon Ball Z manga and anime series. Players control characters from the series, battling enemies and progressing through the storyline. The game features turn-based battles and a quest to defeat the androids created by Dr. Gero.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Ball_ZKyoushuu_Saiya_Jin: {
      description:
        "Dragon Ball Z: Kyoushuu! Saiya Jin is a role-playing video game based on the Dragon Ball Z manga and anime series. Players control characters from the series, engaging in battles and exploring the world. The game features turn-based combat and a storyline following the events of the Saiyan Saga.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Ball_Z_GaidenSaiya_Jin_Zetsumetsu_Keikaku: {
      description:
        "Dragon Ball Z Gaiden: Saiya Jin Zetsumetsu Keikaku is a role-playing video game based on the Dragon Ball Z manga and anime series. Players control characters from the series, battling enemies and progressing through the storyline. The game features turn-based battles and a quest to defeat the evil Saiyan Turles.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Buster: {
      description:
        "Dragon Buster is an action-adventure game. Players control a warrior, navigating through dungeons and battling monsters to rescue a princess. The game features side-scrolling action and exploration.",
      developer: "Namco",
      platform: "NES",
      genre: "Action-adventure",
    },
    Dragon_Buster2Yami_No_Fuuin: {
      description:
        "Dragon Buster II: Yami no Fuuin is the sequel to the action-adventure game Dragon Buster. Players once again control a warrior, battling monsters and exploring dungeons. The game features side-scrolling action and a new quest.",
      developer: "Namco",
      platform: "NES",
      genre: "Action-adventure",
    },
    Dragon_Fighter: {
      description:
        "Dragon Fighter is a side-scrolling action game. Players control a martial artist, battling against enemies and bosses in various stages. The game features fast-paced combat and platforming.",
      developer: "Natsume",
      platform: "NES",
      genre: "Action",
    },
    Dragon_Ninja: {
      description:
        "Dragon Ninja is a side-scrolling beat 'em up game. Players control a ninja, fighting against hordes of enemies in different locations. The game features fast-paced combat and cooperative gameplay for two players.",
      developer: "Taito",
      platform: "NES",
      genre: "Beat 'em up",
    },
    Dragon_Power: {
      description:
        "Dragon Power is an action-adventure game based on the Dragon Ball anime series. Players control Goku, battling enemies and exploring the world in search of the Dragon Balls. The game features side-scrolling action and platforming.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action-adventure",
    },
    Dragon_Quest2: {
      description:
        "Dragon Quest II: Luminaries of the Legendary Line is a role-playing video game and the second installment in the Dragon Quest series. Players control a group of heroes, embarking on a quest to defeat an evil sorcerer. The game features turn-based battles and a larger world to explore.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Quest3: {
      description:
        "Dragon Quest III: The Seeds of Salvation is a role-playing video game and the third installment in the Dragon Quest series. Players control a customizable hero, recruiting allies and battling enemies to save the world. The game features turn-based battles and a vast, open world.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_ScrollYomigaerishi_Maryuu: {
      description:
        "Dragon Scroll: Yomigaerishi Maryuu is a role-playing video game. Players control a hero, battling monsters and exploring dungeons to save the kingdom from an evil dragon. The game features turn-based battles and a fantasy narrative.",
      developer: "Nihon Bussan",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Slayer_4Drasle_Family: {
      description:
        "Dragon Slayer IV: Drasle Family is an action role-playing game. Players control a hero, exploring a fantasy world, battling monsters, and completing quests. The game features real-time combat and a rich, immersive storyline.",
      developer: "Epoch",
      platform: "NES",
      genre: "Action RPG",
    },
    Dragon_SpiritAratanaru_Densetsu: {
      description:
        "Dragon Spirit: Aratanaru Densetsu is a vertically scrolling shoot 'em up game. Players control a dragon, battling against waves of enemies and powerful bosses. The game features power-ups and intense shooting action.",
      developer: "Namco",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Dragon_Unit: {
      description:
        "Dragon Unit is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and bosses. The game features power-ups and intense shooting action.",
      developer: "Unknown",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Dragon_Warrior: {
      description:
        "Dragon Warrior, also known as Dragon Quest, is a role-playing video game and the first installment in the Dragon Quest series. Players control a hero, embarking on a quest to defeat an evil dragon and save the kingdom. The game features turn-based battles and a fantasy world to explore.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Warrior3: {
      description:
        "Dragon Warrior III, also known as Dragon Quest III, is a role-playing video game and the third installment in the Dragon Quest series. Players control a hero, recruiting allies and battling enemies to save the world from an ancient evil. The game features turn-based battles and an expansive world to explore.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragon_Wars: {
      description:
        "Dragon Wars is a role-playing video game set in a post-apocalyptic world. Players create a party of characters, exploring the wasteland, battling mutants, and completing quests. The game features turn-based battles and a complex, branching narrative.",
      developer: "Interplay Productions",
      platform: "NES",
      genre: "Role-playing",
    },
    Dragow2: {
      description:
        "Dragow2: Yami no Fukuin is a role-playing video game. Players control a hero, battling monsters and exploring dungeons to save the kingdom from an ancient evil. The game features turn-based battles and a fantasy storyline.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Role-playing",
    },
    Dream_Master: {
      description:
        "Dream Master is a platformer video game. Players control a character, navigating through dreamlike worlds and solving puzzles. The game features side-scrolling platforming and imaginative level design.",
      developer: "KID",
      platform: "NES",
      genre: "Platformer",
    },
    DRGLAIR: {
      description:
        "Dragon Lair is an action-adventure game. Players control a hero, battling enemies and exploring dungeons to rescue a princess. The game features side-scrolling action and maze-like level design.",
      developer: "Milton Bradley",
      platform: "NES",
      genre: "Action-adventure",
    },
    Drop_Zone: {
      description:
        "Drop Zone is a shoot 'em up game. Players control a spaceship, battling against waves of enemy aircraft. The game features vertical scrolling and intense shooting action.",
      developer: "Arena Graphics",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Dr_Chaos: {
      description:
        "Dr. Chaos is an action-adventure game. Players control a hero, exploring a haunted mansion and battling supernatural creatures to rescue a kidnapped girl. The game features side-scrolling action and atmospheric horror elements.",
      developer: "Marionette",
      platform: "NES",
      genre: "Action-adventure",
    },
    Dr_Jekyll_and_Mr_Hyde: {
      description:
        "Dr. Jekyll and Mr. Hyde is an action-adventure game based on the classic novel. Players control Dr. Jekyll, navigating the streets of London and avoiding obstacles. The game features side-scrolling action and a unique morality system where Dr. Jekyll can transform into Mr. Hyde.",
      developer: "Advance Communication",
      platform: "NES",
      genre: "Action-adventure",
    },
    Dr_Mario: {
      description:
        "Dr. Mario is a puzzle video game. Players control Dr. Mario, eliminating viruses by matching colored capsules. The game features falling block puzzle mechanics and fast-paced gameplay.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Puzzle",
    },
    dtales1: {
      description:
        "Dai 2 Ji Super Robot Taisen is a tactical role-playing game. Players control a team of mechs, engaging in grid-based battles against enemies. The game features a crossover storyline with various mecha anime series.",
      developer: "Banpresto",
      platform: "NES",
      genre: "Tactical RPG",
    },
    dtales2: {
      description:
        "Dai 2 Ji Super Robot Taisen is a tactical role-playing game. Players control a team of mechs, engaging in grid-based battles against enemies. The game features a crossover storyline with various mecha anime series.",
      developer: "Banpresto",
      platform: "NES",
      genre: "Tactical RPG",
    },
    "Duck (UNL)": {
      description:
        "Duck is a platformer video game. Players control a duck, navigating through levels filled with obstacles and enemies. The game features side-scrolling platforming and colorful graphics.",
      developer: "Unknown",
      platform: "NES",
      genre: "Platformer",
    },
    duck: {
      description:
        "Duck is a platformer video game. Players control a duck, navigating through levels filled with obstacles and enemies. The game features side-scrolling platforming and colorful graphics.",
      developer: "Unknown",
      platform: "NES",
      genre: "Platformer",
    },
    Duck_Hunt: {
      description:
        "Duck Hunt is a light gun shooter game. Players use the NES Zapper to shoot ducks in various hunting scenes. The game features simple yet challenging gameplay and is often bundled with the NES console.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Light Gun Shooter",
    },
    Duck_Tales2: {
      description:
        "DuckTales 2 is a platformer video game based on the Disney animated TV series DuckTales. Players control Scrooge McDuck, exploring different levels and collecting treasures. The game features side-scrolling platforming and colorful graphics.",
      developer: "Capcom",
      platform: "NES",
      genre: "Platformer",
    },
    Dudes_With_Attitude: {
      description:
        "Dudes with Attitude is a puzzle game. Players control a character, navigating mazes and collecting items while avoiding enemies. The game features tile-based puzzle mechanics and challenging level design.",
      developer: "American Video Entertainment",
      platform: "NES",
      genre: "Puzzle",
    },
    Dungeon_and_MagicSwords_of_Element: {
      description:
        "Dungeon & Magic: Swords of Element is a role-playing video game. Players control a hero, battling monsters and exploring dungeons to save the kingdom from an evil sorcerer. The game features turn-based battles and a fantasy narrative.",
      developer: "Asmik",
      platform: "NES",
      genre: "Role-playing",
    },
    Dungeon_Kid: {
      description:
        "Dungeon Kid is a puzzle-platformer game. Players control a character, navigating through dungeons and solving puzzles to progress. The game features side-scrolling platforming and challenging level design.",
      developer: "Kemco",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    Dynablaster: {
      description:
        "Dynablaster is an action puzzle game. Players navigate maze-like levels, placing bombs to defeat enemies and open pathways. The game features grid-based gameplay and multiplayer support for competitive bomb blasting.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Puzzle",
    },
    Dynamite_Batman: {
      description:
        "Dynamite Batman is a beat 'em up game. Players control a superhero, battling against waves of enemies in side-scrolling stages. The game features fast-paced combat and cooperative gameplay for two players.",
      developer: "Data East",
      platform: "NES",
      genre: "Beat 'em up",
    },
    Dynamite_Bowl: {
      description:
        "Dynamite Bowl is a bowling simulation game. Players control a bowler, aiming to score strikes and spares in various bowling alleys. The game features realistic bowling mechanics and multiplayer support for up to four players.",
      developer: "C.A.P.S.",
      platform: "NES",
      genre: "Sports, Bowling",
    },
    DYNOWAR: {
      description:
        "DYNOWARZ: Destruction of Spondylus is an action platformer game. Players control a warrior riding a dinosaur, battling against robotic enemies in prehistoric environments. The game features side-scrolling action and dinosaur-mounted combat.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action, Platformer",
    },
    Earth_Bound: {
      description:
        "Earth Bound is a role-playing video game. Players control a group of characters, battling against aliens and robots to save the world. The game features turn-based battles, psychic abilities, and a post-apocalyptic setting.",
      developer: "Pax Softnica",
      platform: "NES",
      genre: "Role-playing",
    },
    Edu: {
      description:
        "Edu is an educational game. Players engage in various learning activities, including math, language, and logic puzzles. The game is designed to enhance educational skills and knowledge.",
      developer: "Carrozzeria",
      platform: "NES",
      genre: "Educational",
    },
    EGG: {
      description:
        "EGG: Elemental Gimmick Gear is an action-adventure game. Players control a character, exploring dungeons and battling enemies using a powerful mechanical suit. The game features real-time combat and puzzle-solving.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action-adventure",
    },
    EggerlandMeikyuu_No_Fukkatsu: {
      description:
        "Eggerland: Meikyuu no Fukkatsu is a puzzle-platformer game. Players control a character, navigating through maze-like levels and solving puzzles to progress. The game features tile-based puzzle mechanics and challenging level design.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    Egypt: {
      description:
        "Egypt is a puzzle-platformer game. Players control a character, navigating through ancient Egyptian-themed levels and solving puzzles. The game features side-scrolling platforming and historical elements.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    Eigo_Asobi: {
      description:
        "Eigo Asobi is an educational game focused on teaching English vocabulary and grammar. Players engage in language-based activities and challenges to improve their English skills.",
      developer: "Now Production",
      platform: "NES",
      genre: "Educational",
    },
    elevator: {
      description:
        "Elevator Action is an action platformer game. Players control a spy, navigating through a building filled with enemies. The goal is to collect secret documents and reach the exit while avoiding detection and enemy attacks. The game features side-scrolling action and stealth gameplay.",
      developer: "Taito",
      platform: "NES",
      genre: "Action, Platformer",
    },
    Elevator_Action: {
      description:
        "Elevator Action is an action platformer game. Players control a spy, navigating through a building filled with enemies. The goal is to collect secret documents and reach the exit while avoiding detection and enemy attacks. The game features side-scrolling action and stealth gameplay.",
      developer: "Taito",
      platform: "NES",
      genre: "Action, Platformer",
    },
    Eliminator_Boat_Duel: {
      description:
        "Eliminator Boat Duel is a powerboat racing game. Players compete in high-speed races, avoiding obstacles and collecting power-ups. The game features top-down racing and multiplayer support for two players.",
      developer: "Sculptured Software",
      platform: "NES",
      genre: "Racing",
    },
    ELITE: {
      description:
        "Elite is a space trading and combat simulator game. Players control a spaceship, exploring a vast universe, trading goods, and engaging in space battles. The game features open-world exploration, trading mechanics, and real-time space combat.",
      developer: "David Braben and Ian Bell",
      platform: "NES",
      genre: "Space simulation, Trading, Combat",
    },
    Elysion: {
      description:
        "Elysion is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses. The game features power-ups and intense shooting action.",
      developer: "Sakata SAS",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Emo_Yan_No_10_Bai_Pro_Yakyuu: {
      description:
        "Emoyan no 10 Bai Pro Yakyuu is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Marubeni",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    EMPIREST: {
      description:
        "Empire City: 1931 is a vertically scrolling shoot 'em up game. Players control a detective, battling against waves of enemies and powerful bosses in a noir-inspired city setting. The game features power-ups and intense shooting action.",
      developer: "ASCII",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Empire_Strikes_Back: {
      description:
        "The Empire Strikes Back is an action platformer game based on the Star Wars film. Players control Luke Skywalker, navigating through Hoth, Dagobah, and Cloud City, battling enemies and confronting Darth Vader. The game features side-scrolling action and a storyline following the events of the movie.",
      developer: "Lucasfilm Games",
      platform: "NES",
      genre: "Action, Platformer",
    },
    Erika_To_Satoru_No_Yume_Bouken: {
      description:
        "Erika to Satoru no Yume Bouken is an adventure game. Players control a character, making choices and solving puzzles to progress through the story. The game features a branching narrative and multiple endings.",
      developer: "Namco",
      platform: "NES",
      genre: "Adventure",
    },
    Ernarc_No_Zaihou: {
      description:
        "Ernarc no Zaihou is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses. The game features power-ups and intense shooting action.",
      developer: "Tokuma Shoten",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Ernie_and_The_Muppets_Take_It_All_Off: {
      description:
        "Ernie and the Muppets Take It All Off is a platformer game featuring the Muppet characters. Players control Ernie, navigating through levels filled with obstacles and enemies. The game features side-scrolling platforming and colorful graphics.",
      developer: "Hi Tech Expressions",
      platform: "NES",
      genre: "Platformer",
    },
    Esper_Bouken_Tai: {
      description:
        "Esper Bouken Tai is an action-platformer game. Players control a character, navigating through levels and battling enemies using psychic abilities. The game features side-scrolling action and platforming.",
      developer: "Konami",
      platform: "NES",
      genre: "Action, Platformer",
    },
    Esper_Dream2Aratanaru_Tatakai: {
      description:
        "Esper Dream 2: Aratanaru Tatakai is an action-platformer game. Players control a character, navigating through levels and battling enemies using psychic abilities. The game features side-scrolling action and colorful graphics.",
      developer: "Konami",
      platform: "NES",
      genre: "Action, Platformer",
    },
    Evert_and_Lendl_Top_Players_Tennis: {
      description:
        "Evert and Lendl Top Players' Tennis is a tennis simulation game. Players can choose to play as Chris Evert or Ivan Lendl, competing in tennis matches against computer-controlled opponents. The game features realistic tennis mechanics and multiplayer support for two players.",
      developer: "Asmik",
      platform: "NES",
      genre: "Sports, Tennis",
    },
    Excitebike: {
      description:
        "Excitebike is a motocross racing game. Players control a motorcyclist, navigating through tracks filled with jumps and obstacles. The game features side-scrolling racing and a track editor mode for creating custom tracks.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Racing",
    },
    Exciting_Boxing: {
      description:
        "Exciting Boxing is a boxing simulation game. Players control a boxer, engaging in matches against opponents and striving to become the champion. The game features boxing mechanics, strategy, and multiplayer support for two players.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports, Boxing",
    },
    Exciting_RallyWorld_Rally_Championship: {
      description:
        "Exciting Rally: World Rally Championship is a rally racing game. Players compete in high-speed races, navigating through challenging tracks and avoiding obstacles. The game features top-down racing and multiplayer support for two players.",
      developer: "Elf",
      platform: "NES",
      genre: "Racing",
    },
    Exed_Exes: {
      description:
        "Exed Exes is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses. The game features power-ups and intense shooting action.",
      developer: "Capcom",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Exodus: {
      description:
        "Exodus is an action-platformer game. Players control a character, navigating through levels filled with enemies and obstacles. The game features side-scrolling action and challenging level design.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Action, Platformer",
    },
    eyes: {
      description:
        "Eyes is a maze chase game. Players control a character, navigating through mazes and avoiding enemies. The goal is to collect all the dots in the maze while being pursued by colorful monsters. The game features top-down maze gameplay and challenging enemy AI.",
      developer: "Namco",
      platform: "NES",
      genre: "Maze Chase",
    },
    F117a_Stealth_Fighter: {
      description:
        "F-117A Stealth Fighter is a flight simulation game. Players control an F-117A Nighthawk stealth fighter, engaging in combat missions and bombing targets in enemy territory. The game features realistic flight mechanics and aerial combat.",
      developer: "MicroProse",
      platform: "NES",
      genre: "Flight simulation",
    },
    F1_Circus: {
      description:
        "F1 Circus is a Formula One racing game. Players compete in high-speed races, driving Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and realistic Formula One mechanics.",
      developer: "Nichibutsu",
      platform: "NES",
      genre: "Racing",
    },
    F1_Race: {
      description:
        "F-1 Race is a Formula One racing game. Players compete in high-speed races, driving Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to four players.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Racing",
    },
    F1_Sensation: {
      description:
        "F-1 Sensation is a Formula One racing game. Players compete in high-speed races, driving Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to four players.",
      developer: "Konami",
      platform: "NES",
      genre: "Racing",
    },
    F2047: {
      description:
        "F-2047 is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses. The game features power-ups and intense shooting action.",
      developer: "Sachen",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    F2052: {
      description:
        "F-2052 is a vertically scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses. The game features power-ups and intense shooting action.",
      developer: "Sachen",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Famicom_Disk_System_BIOS_ROM: {
      description:
        "Famicom Disk System BIOS ROM is the basic input/output system firmware for the Famicom Disk System, a peripheral for the Nintendo Famicom console. It provides essential functions for loading and running games stored on floppy disks.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "BIOS",
    },
    Famicom_Igo_Nyuumon: {
      description:
        "Famicom Igo Nyuumon is a traditional Japanese board game simulation. Players engage in the ancient game of Go, placing stones on a grid to capture territory and opponents' stones. The game features Go gameplay and AI opponents of varying skill levels.",
      developer: "Home Data",
      platform: "Famicom Disk System",
      genre: "Board game",
    },
    Famicom_Jump2Saikyou_No_7_Nin: {
      description:
        "Famicom Jump II: Saikyou no 7 Nin is a role-playing video game. Players control a team of characters, battling against enemies and exploring dungeons. The game features turn-based battles and a crossover storyline with various manga and anime series.",
      developer: "Toei Animation",
      platform: "Famicom Disk System",
      genre: "Role-playing",
    },
    Famicom_JumpEiyuu_Retsuden: {
      description:
        "Famicom Jump: Eiyuu Retsuden is a role-playing video game. Players control a team of characters, battling against enemies and exploring dungeons. The game features turn-based battles and a crossover storyline with various manga and anime series.",
      developer: "Toei Animation",
      platform: "Famicom Disk System",
      genre: "Role-playing",
    },
    Famicom_Meijin_Sen: {
      description:
        "Famicom Meijin Sen is a game show simulation game. Players participate in various mini-games and challenges to earn points and become the ultimate game master. The game features diverse mini-games and multiplayer support for up to four players.",
      developer: "Tose",
      platform: "Famicom Disk System",
      genre: "Game show, Mini-games",
    },
    Famicom_ShougiRyuuousen: {
      description:
        "Famicom Shougi: Ryuuousen is a traditional Japanese chess simulation. Players engage in Shogi matches, strategically moving pieces to checkmate the opponent's king. The game features Shogi gameplay and AI opponents of varying skill levels.",
      developer: "Pony Canyon",
      platform: "Famicom Disk System",
      genre: "Board game",
    },
    Famicom_Top_Management: {
      description:
        "Famicom Top Management is a business simulation game. Players take on the role of a manager, overseeing various aspects of a company, including finance, marketing, and production. The game features business management mechanics and strategic decision-making.",
      developer: "Home Data",
      platform: "Famicom Disk System",
      genre: "Business simulation",
    },
    Famicom_Wars: {
      description:
        "Famicom Wars is a turn-based strategy game. Players command an army, strategically moving units and engaging in battles to defeat the enemy forces. The game features grid-based gameplay and multiplayer support for competitive strategy.",
      developer: "Intelligent Systems",
      platform: "Famicom Disk System",
      genre: "Turn-based strategy",
    },
    Famicom_Yakyuu_Ban: {
      description:
        "Famicom Yakyuu: Ban is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Pony Canyon",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_BASIC: {
      description:
        "Family BASIC is a programming tool for the Famicom console. It allows users to write and run BASIC programs on the Famicom system, enabling simple game and application development. The software features a BASIC interpreter and educational programming tutorials.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Programming",
    },
    Family_Block: {
      description:
        "Family Block is a puzzle game. Players arrange falling blocks to create complete lines, which then disappear, earning points. The game features falling block puzzle mechanics and challenging gameplay.",
      developer: "Taito",
      platform: "Famicom Disk System",
      genre: "Puzzle",
    },
    Family_Boxing: {
      description:
        "Family Boxing is a boxing simulation game. Players control a boxer, engaging in matches against opponents and striving to become the champion. The game features boxing mechanics, strategy, and multiplayer support for two players.",
      developer: "Kemco",
      platform: "Famicom Disk System",
      genre: "Sports, Boxing",
    },
    Family_Circuit: {
      description:
        "Family Circuit is a racing game. Players compete in high-speed races, driving various vehicles on tracks with different themes. The game features top-down racing and competitive multiplayer gameplay for up to four players.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Racing",
    },
    Family_Circuit_91: {
      description:
        "Family Circuit '91 is a racing game. Players compete in high-speed races, driving various vehicles on tracks with different themes. The game features top-down racing and competitive multiplayer gameplay for up to four players.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Racing",
    },
    Family_Computer_Othello: {
      description:
        "Family Computer Othello is a digital adaptation of the classic board game Othello. Players take turns placing discs on the board, attempting to flip the opponent's discs to their color. The game features Othello gameplay and AI opponents of varying skill levels.",
      developer: "HAL Laboratory",
      platform: "Famicom Disk System",
      genre: "Board game",
    },
    Family_Computer_Typing_Tutor2: {
      description:
        "Family Computer Typing Tutor II is an educational typing game. Players practice typing skills by completing various typing exercises and challenges. The game features typing mechanics and educational content to improve typing speed and accuracy.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Educational, Typing",
    },
    Family_Feud: {
      description:
        "Family Feud is a video game adaptation of the popular TV game show. Players compete in a quiz-like game, answering survey questions to earn points. The game features quiz show mechanics and multiplayer support for two players.",
      developer: "Softie",
      platform: "Famicom Disk System",
      genre: "Game show",
    },
    Family_Jockey: {
      description:
        "Family Jockey is a horse racing simulation game. Players bet on horses and watch races, hoping to win virtual currency. The game features horse racing simulation and betting mechanics.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Horse racing",
    },
    Family_Mahjong: {
      description:
        "Family Mahjong is a digital adaptation of the classic tile-matching game Mahjong. Players match pairs of tiles to clear the board, following the rules of Mahjong solitaire. The game features Mahjong gameplay and various tile sets.",
      developer: "I'Max",
      platform: "Famicom Disk System",
      genre: "Board game, Mahjong",
    },
    Family_Mahjong2Shanghai_E_No_Michi: {
      description:
        "Family Mahjong II: Shanghai e no Michi is a digital adaptation of the classic tile-matching game Mahjong. Players match pairs of tiles to clear the board, following the rules of Mahjong solitaire. The game features Mahjong gameplay and various tile sets.",
      developer: "I'Max",
      platform: "Famicom Disk System",
      genre: "Board game, Mahjong",
    },
    Family_Pinball: {
      description:
        "Family Pinball is a pinball simulation game. Players control flippers to launch a ball and score points by hitting targets and completing objectives on the pinball table. The game features pinball mechanics and various themed tables.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Pinball",
    },
    Family_Quiz: {
      description:
        "Family Quiz: 4-nin wa Rival is a quiz game. Players answer trivia questions in various categories, competing against computer-controlled opponents. The game features quiz show mechanics and multiplayer support for up to four players.",
      developer: "Saurus",
      platform: "Famicom Disk System",
      genre: "Quiz",
    },
    Family_School: {
      description:
        "Family School is an educational game. Players engage in learning activities and challenges to improve their knowledge in subjects such as math, science, and language. The game is designed to enhance educational skills and features diverse educational content.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Educational",
    },
    Family_StadiumPro_Yakyuu: {
      description:
        "Family Stadium: Pro Yakyuu is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_87Pro_Yakyuu: {
      description:
        "Family Stadium '87: Pro Yakyuu is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_88Pro_Yakyuu: {
      description:
        "Family Stadium '88: Pro Yakyuu is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_89Kaimakuban: {
      description:
        "Family Stadium '89: Kaimaku Ban is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_90: {
      description:
        "Family Stadium '90 is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_91: {
      description:
        "Family Stadium '91 is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_92: {
      description:
        "Family Stadium '92 is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_93: {
      description:
        "Family Stadium '93 is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Stadium_94: {
      description:
        "Family Stadium '94 is a baseball simulation game. Players manage a baseball team, making strategic decisions and controlling players during matches. The game features team management, realistic baseball mechanics, and multiplayer support.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Baseball",
    },
    Family_Tennis: {
      description:
        "Family Tennis is a tennis simulation game. Players can choose to play as one of eight characters, competing in tennis matches against computer-controlled opponents or another player. The game features realistic tennis mechanics and multiplayer support for two players.",
      developer: "Namco",
      platform: "Famicom Disk System",
      genre: "Sports, Tennis",
    },
    Family_TrainerAerobics_Studio: {
      description:
        "Family Trainer: Aerobics Studio is an exercise game. Players follow aerobic exercises and routines guided by an instructor on screen. The game features fitness activities and multiplayer support for two players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Fitness, Exercise",
    },
    Family_TrainerAthletic_World: {
      description:
        "Family Trainer: Athletic World is a fitness game. Players engage in various athletic activities and challenges, such as running, jumping, and swimming, using the Power Pad peripheral. The game features fitness activities and multiplayer support for two players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Fitness, Exercise",
    },
    Family_TrainerDai_Undoukai: {
      description:
        "Family Trainer: Dai Undoukai is a sports game. Players compete in a sports festival, participating in events like running, swimming, and cycling, using the Power Pad peripheral. The game features sports activities and multiplayer support for two players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Sports, Exercise",
    },
    Family_TrainerFuuun_Takeshi_Jou2: {
      description:
        "Family Trainer: Fuuun Takeshi Jou 2 is a game show simulation game. Players participate in various mini-games and challenges inspired by the Takeshi's Castle TV show. The game features diverse mini-games and multiplayer support for up to four players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Game show, Mini-games",
    },
    Family_TrainerJogging_Race: {
      description:
        "Family Trainer: Jogging Race is a fitness game. Players engage in jogging and running exercises, competing against computer-controlled opponents or another player. The game features jogging activities and multiplayer support for two players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Fitness, Exercise",
    },
    Family_TrainerManhattan_Police: {
      description:
        "Family Trainer: Manhattan Police is an action game. Players take on the role of a police officer, patrolling the streets, solving crimes, and apprehending criminals. The game features action and detective gameplay.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Action, Detective",
    },
    Family_TrainerMeiro_Dai_Sakusen: {
      description:
        "Family Trainer: Meiro Dai Sakusen is a puzzle game. Players navigate through mazes, solving puzzles and challenges to progress. The game features maze puzzles and diverse gameplay mechanics.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Puzzle, Maze",
    },
    Family_TrainerRairai_Kyonshiizu: {
      description:
        "Family Trainer: Rairai Kyonshiizu is a game show simulation game. Players participate in various mini-games and challenges, facing off against computer-controlled opponents. The game features diverse mini-games and multiplayer support for up to four players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Game show, Mini-games",
    },
    Family_TrainerRunning_Stadium: {
      description:
        "Family Trainer: Running Stadium is a sports game. Players compete in running races and athletic challenges, using the Power Pad peripheral. The game features running activities and multiplayer support for two players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Sports, Exercise",
    },
    Family_TrainerTotsugeki_Fuuun_Takeshi_Jou: {
      description:
        "Family Trainer: Totsugeki! Fuuun Takeshi Jou is a game show simulation game. Players participate in various mini-games and challenges inspired by the Takeshi's Castle TV show. The game features diverse mini-games and multiplayer support for up to four players.",
      developer: "Bandai",
      platform: "Famicom Disk System",
      genre: "Game show, Mini-games",
    },
    Fantastic_Adventures_of_Dizzy: {
      description:
        "Fantastic Adventures of Dizzy is a platformer game. Players control Dizzy, an egg-shaped character, navigating through levels filled with puzzles and enemies. The game features side-scrolling platforming, puzzle-solving, and colorful graphics.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Platformer",
    },
    Fantasy_Zone: {
      description:
        "Fantasy Zone is a side-scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses in colorful and whimsical environments. The game features power-ups and intense shooting action.",
      developer: "Sega",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Fantasy_Zone2The_Teardrop_of_Opa_Opa: {
      description:
        "Fantasy Zone II: The Teardrop of Opa-Opa is a side-scrolling shoot 'em up game. Players control Opa-Opa, a sentient spaceship, battling against enemies and powerful bosses in vibrant and surreal worlds. The game features power-ups and colorful shooting action.",
      developer: "Sega",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    FANTZONE: {
      description:
        "Fantasy Zone is a side-scrolling shoot 'em up game. Players control a spaceship, battling against waves of enemies and powerful bosses in colorful and whimsical environments. The game features power-ups and intense shooting action.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Faria: {
      description:
        "Faria: A World of Mystery and Danger! is an action RPG. Players control a hero named Fray, embarking on a quest to rescue his wife and save the land of Faria from evil forces. The game features top-down exploration, turn-based battles, and a rich fantasy world.",
      developer: "Game Arts",
      platform: "NES",
      genre: "Action RPG",
    },
    Fatal_Fury2: {
      description:
        "Fatal Fury 2 is a fighting game. Players select one of eight characters, each with unique fighting styles and special moves, and compete in one-on-one battles. The game features competitive fighting gameplay and colorful graphics.",
      developer: "SNK",
      platform: "NES",
      genre: "Fighting",
    },
    Faxanadu: {
      description:
        "Faxanadu is an action RPG. Players assume the role of an unnamed hero, embarking on a quest to save the World Tree and the town of Eolis from a mysterious evil. The game features side-scrolling exploration, platforming, and role-playing elements.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action RPG",
    },
    FCTE019: {
      description:
        "Famicom Tantei Club Part II: Ushiro ni Tatsu Shoujo - Zenpen is a visual novel adventure game. Players investigate a murder mystery, gathering clues and interacting with characters to unravel the story. The game features detective gameplay and a compelling narrative.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Visual novel, Adventure",
    },
    fctw001: {
      description:
        "Famicom Tantei Club Part II: Ushiro ni Tatsu Shoujo - Kouhen is a visual novel adventure game. Players investigate a murder mystery, gathering clues and interacting with characters to unravel the story. The game features detective gameplay and a compelling narrative.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Visual novel, Adventure",
    },
    fctw002: {
      description:
        "Famicom Tantei Club Part II: Ushiro ni Tatsu Shoujo - Zenpen is a visual novel adventure game. Players investigate a murder mystery, gathering clues and interacting with characters to unravel the story. The game features detective gameplay and a compelling narrative.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Visual novel, Adventure",
    },
    fctw005: {
      description:
        "Famicom Tantei Club Part II: Ushiro ni Tatsu Shoujo - Kouhen is a visual novel adventure game. Players investigate a murder mystery, gathering clues and interacting with characters to unravel the story. The game features detective gameplay and a compelling narrative.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Visual novel, Adventure",
    },
    FC_GenjinFreakthoropus_Computerus: {
      description:
        "Famicom Bunko: Hajimari no Mori is an adventure game. Players assume the role of a young girl named Saki, exploring a mysterious forest and interacting with various characters. The game features adventure gameplay and a captivating storyline.",
      developer: "Nintendo",
      platform: "Famicom Disk System",
      genre: "Adventure",
    },
    FELIX: {
      description:
        "Felix the Cat is a platformer game. Players control Felix, a cat with magical powers, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and colorful graphics.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Platformer",
    },
    Ferrari_Grand_Prix_Challenge: {
      description:
        "Ferrari Grand Prix Challenge is a Formula One racing game. Players compete in high-speed races, driving Ferrari Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to two players.",
      developer: "System 3",
      platform: "NES",
      genre: "Racing",
    },
    Ferrari_Grand_Prix_Challenge_: {
      description:
        "Ferrari Grand Prix Challenge is a Formula One racing game. Players compete in high-speed races, driving Ferrari Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to two players.",
      developer: "System 3",
      platform: "NES",
      genre: "Racing",
    },
    FESTER: {
      description:
        "Fester's Quest is an action-adventure game. Players control Uncle Fester, a character from The Addams Family, as he embarks on a quest to rescue his family from aliens. The game features top-down exploration, shooting mechanics, and a variety of weapons.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    FF1C: {
      description:
        "Final Fantasy is a role-playing game. Players control a group of heroes on a quest to save the world from an evil force. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    FF1E: {
      description:
        "Final Fantasy is a role-playing game. Players control a group of heroes on a quest to save the world from an evil force. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    FF2C: {
      description:
        "Final Fantasy II is a role-playing game. Players control a group of heroes on a quest to save the world from an evil empire. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    ff2e: {
      description:
        "Final Fantasy II is a role-playing game. Players control a group of heroes on a quest to save the world from an evil empire. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    Field_Combat: {
      description:
        "Field Combat is a shooter game. Players control a soldier, moving through various landscapes and battling against enemy forces. The game features top-down shooting, a variety of weapons, and intense combat action.",
      developer: "Tose",
      platform: "NES",
      genre: "Shooter",
    },
    Fighting_Golf: {
      description:
        "Fighting Golf is a sports simulation game. Players compete in a round of golf, aiming to complete the course with the fewest strokes. The game features golf simulation mechanics and competitive multiplayer gameplay for up to four players.",
      developer: "SNK",
      platform: "NES",
      genre: "Sports, Golf",
    },
    Fighting_Road: {
      description:
        "Fighting Road is a wrestling game. Players control a wrestler, competing in matches and tournaments against various opponents. The game features wrestling mechanics and a variety of moves to perform.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Wrestling",
    },
    finalf3: {
      description:
        "Final Fantasy III is a role-playing game. Players control a group of heroes on a quest to save the world from an ancient evil. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    FINALFAN: {
      description:
        "Final Fantasy is a role-playing game. Players control a group of heroes on a quest to save the world from an evil force. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    Final_Fantasy: {
      description:
        "Final Fantasy is a role-playing game. Players control a group of heroes on a quest to save the world from an evil force. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    Final_Fantasy2: {
      description:
        "Final Fantasy II is a role-playing game. Players control a group of heroes on a quest to save the world from an evil empire. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    Final_Fantasy3: {
      description:
        "Final Fantasy III is a role-playing game. Players control a group of heroes on a quest to save the world from an ancient evil. The game features turn-based battles, exploration, and a deep storyline with memorable characters.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    Final_Fantasy_1_and_2: {
      description:
        "Final Fantasy I & II: Dawn of Souls is a compilation of the first two Final Fantasy games. Players embark on epic quests in both games, battling monsters and exploring vast worlds. The compilation features turn-based battles, exploration, and deep storylines.",
      developer: "Square",
      platform: "NES",
      genre: "Role-playing",
    },
    Final_Lap: {
      description:
        "Final Lap is a racing game. Players compete in high-speed races, driving various Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to eight players.",
      developer: "Namco",
      platform: "NES",
      genre: "Racing",
    },
    Final_Mission: {
      description:
        "Final Mission is a shooter game. Players control a spaceship, battling against waves of enemies and powerful bosses in space environments. The game features side-scrolling shooting action and a variety of power-ups.",
      developer: "Natsume",
      platform: "NES",
      genre: "Shooter",
    },
    "Fire Emblem Gaiden": {
      description:
        "Fire Emblem Gaiden is a tactical RPG. Players command a group of heroes, navigating grid-based maps and engaging in turn-based battles against enemy forces. The game features tactical gameplay, character customization, and a fantasy storyline.",
      developer: "Intelligent Systems",
      platform: "NES",
      genre: "Tactical RPG",
    },
    firepheonix: {
      description:
        "Fire Hawk is a shooter game. Players control a helicopter, battling against waves of enemy aircraft and ground forces. The game features top-down shooting action, power-ups, and intense combat.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Shooter",
    },
    Fire_Emblem: {
      description:
        "Fire Emblem is a tactical RPG. Players command a group of heroes, navigating grid-based maps and engaging in turn-based battles against enemy forces. The game features tactical gameplay, character customization, and a fantasy storyline.",
      developer: "Intelligent Systems",
      platform: "NES",
      genre: "Tactical RPG",
    },
    Fire_Emblem_Gaiden: {
      description:
        "Fire Emblem Gaiden is a tactical RPG. Players command a group of heroes, navigating grid-based maps and engaging in turn-based battles against enemy forces. The game features tactical gameplay, character customization, and a fantasy storyline.",
      developer: "Intelligent Systems",
      platform: "NES",
      genre: "Tactical RPG",
    },
    Fire_Hawk: {
      description:
        "Fire Hawk is a shooter game. Players control a helicopter, battling against waves of enemy aircraft and ground forces. The game features top-down shooting action, power-ups, and intense combat.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Shooter",
    },
    Fire_n_Ice: {
      description:
        "Fire 'n Ice, also known as Solomon's Key 2, is a puzzle platformer game. Players control a wizard named Dana, creating and destroying ice blocks to navigate through levels and solve puzzles. The game features puzzle-solving, platforming, and challenging level designs.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    fishboy: {
      description:
        "Fisher-Price: Firehouse Rescue is an educational game designed for children. Players engage in learning activities and challenges, improving their cognitive and problem-solving skills. The game features educational content and child-friendly gameplay.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Educational",
    },
    Fisher_PriceFirehouse_Rescue: {
      description:
        "Fisher-Price: Firehouse Rescue is an educational game designed for children. Players engage in learning activities and challenges, improving their cognitive and problem-solving skills. The game features educational content and child-friendly gameplay.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Educational",
    },
    Fisher_PriceI_Can_Remember: {
      description:
        "Fisher-Price: I Can Remember is an educational game designed for children. Players enhance their memory skills by matching pairs of cards featuring various images. The game features memory-enhancing activities and child-friendly gameplay.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Educational, Memory",
    },
    Fisher_PricePerfect_Fit: {
      description:
        "Fisher-Price: Perfect Fit is an educational game designed for children. Players solve shape and pattern recognition puzzles, enhancing their cognitive and problem-solving skills. The game features educational content and child-friendly gameplay.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Educational, Puzzle",
    },
    Fist_of_the_North_Star: {
      description:
        "Fist of the North Star is a beat 'em up game. Players control Kenshiro, a martial artist with deadly techniques, as he battles against hordes of enemies in a post-apocalyptic world. The game features side-scrolling beat 'em up gameplay and powerful special moves.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Beat 'em up",
    },
    Flappy: {
      description:
        "Flappy is an action game. Players control a bird, guiding it through a series of pipes without colliding with them. The game features simple one-button controls and challenging gameplay, requiring precise timing to navigate the bird safely.",
      developer: "dB-Soft",
      platform: "NES",
      genre: "Action",
    },
    Fleet_Commander: {
      description:
        "Fleet Commander is a strategy game. Players command a fleet of spaceships, engaging in tactical battles against enemy forces. The game features turn-based strategy, ship customization, and space combat.",
      developer: "Capcom",
      platform: "NES",
      genre: "Strategy",
    },
    Flight_of_the_Intruder: {
      description:
        "Flight of the Intruder is a flight simulation game. Players pilot an A-6 Intruder aircraft, participating in missions during the Vietnam War. The game features flight simulation mechanics, aerial combat, and mission-based gameplay.",
      developer: "Imagineering",
      platform: "NES",
      genre: "Flight simulation",
    },
    Flintstones2_The_Surprise_at_Dinosaur_Peak: {
      description:
        "The Flintstones: The Surprise at Dinosaur Peak! is a platformer game. Players control Fred Flintstone, navigating through prehistoric landscapes, battling enemies, and rescuing Pebbles and Bamm-Bamm. The game features side-scrolling platforming and colorful graphics.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    FlintstonesThe_Rescue_of_Dino_and_Hoppy: {
      description:
        "The Flintstones: The Rescue of Dino & Hoppy is a platformer game. Players control Fred Flintstone, exploring various levels, battling enemies, and rescuing Dino and Hoppy. The game features side-scrolling platforming and colorful graphics.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    Flipull: {
      description:
        "Flipull is a puzzle game. Players manipulate tiles on a grid, matching and clearing them to progress through levels. The game features puzzle-solving mechanics, challenging puzzles, and colorful graphics.",
      developer: "Taito",
      platform: "NES",
      genre: "Puzzle",
    },
    flower: {
      description:
        "Flower is a puzzle game. Players control a flower petal, guiding it through lush environments, collecting other flower petals, and blooming flowers. The game features serene gameplay, relaxing music, and beautiful visuals.",
      developer: "Thatgamecompany",
      platform:
        "PlayStation 3, PlayStation 4, PlayStation Vita, iOS, Microsoft Windows",
      genre: "Puzzle",
    },
    fly: {
      description:
        "Fly! Fly! Chibimaru-chan is a shooter game. Players control Chibimaru, a tiny character, navigating through levels and battling against various enemies. The game features side-scrolling shooting action and colorful graphics.",
      developer: "Tose",
      platform: "NES",
      genre: "Shooter",
    },
    flyinggod: {
      description:
        "Flying Warriors is an action RPG. Players control a group of warriors, battling against evil forces and powerful enemies. The game features side-scrolling action, beat 'em up gameplay, and RPG elements such as character upgrades and abilities.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action RPG",
    },
    flyingwolf: {
      description:
        "Flying Dragon: The Secret Scroll is a beat 'em up game. Players control Ryuhi, a martial artist, as he battles against enemies and bosses in side-scrolling levels. The game features beat 'em up gameplay and special moves.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Beat 'em up",
    },
    Flying_DragonThe_Secret_Scroll: {
      description:
        "Flying Dragon: The Secret Scroll is a beat 'em up game. Players control Ryuhi, a martial artist, as he battles against enemies and bosses in side-scrolling levels. The game features beat 'em up gameplay and special moves.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Beat 'em up",
    },
    Flying_Hero: {
      description:
        "Flying Hero is a shoot 'em up game. Players control a flying character, battling against waves of enemies in side-scrolling levels. The game features shooting action, power-ups, and challenging gameplay.",
      developer: "Nexa",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Flying_Warriors: {
      description:
        "Flying Warriors is an action RPG. Players control a group of warriors, battling against evil forces and powerful enemies. The game features side-scrolling action, beat 'em up gameplay, and RPG elements such as character upgrades and abilities.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action RPG",
    },
    FN4023: {
      description:
        "Cobra Triangle is a racing and shooting game. Players control a vehicle, competing in various water-based challenges and battling against enemies. The game features racing, shooting action, and diverse challenges.",
      developer: "Rare",
      platform: "NES",
      genre: "Racing, Shooter",
    },
    Formation_Z: {
      description:
        "Formation Z is a shooter game. Players control a flying character, battling against enemies and bosses in side-scrolling levels. The game features shooting action, power-ups, and challenging gameplay.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Formula1Sensation: {
      description:
        "Formula 1 Sensation is a racing game. Players compete in high-speed races, driving Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to two players.",
      developer: "Konami",
      platform: "NES",
      genre: "Racing",
    },
    Formula_One_Built_To_Win: {
      description:
        "Formula One: Built to Win is a Formula One racing game. Players compete in high-speed races, driving Formula One cars on tracks inspired by real-world circuits. The game features top-down racing and competitive multiplayer gameplay for up to two players.",
      developer: "Sculptured Software",
      platform: "NES",
      genre: "Racing",
    },
    Foton: {
      description:
        "Foton: The Ultimate Game on Planet Earth is a puzzle game. Players control a character, navigating through maze-like levels and avoiding obstacles to reach the exit. The game features puzzle-solving mechanics, challenging levels, and colorful graphics.",
      developer: "Bit2",
      platform: "NES",
      genre: "Puzzle",
    },
    FotonThe_Ultimate_Game_on_Planet_Earth: {
      description:
        "Foton: The Ultimate Game on Planet Earth is a puzzle game. Players control a character, navigating through maze-like levels and avoiding obstacles to reach the exit. The game features puzzle-solving mechanics, challenging levels, and colorful graphics.",
      developer: "Bit2",
      platform: "NES",
      genre: "Puzzle",
    },
    FRANKENS: {
      description:
        "Frankenstein: The Monster Returns is a platformer game. Players control Frankenstein's monster, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and challenging gameplay.",
      developer: "Bandai",
      platform: "NES",
      genre: "Platformer",
    },
    Freedom_Force: {
      description:
        "Freedom Force is an action-adventure game. Players control a character, exploring various levels, battling enemies, and solving puzzles. The game features side-scrolling action, platforming, and diverse challenges.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    freefall: {
      description:
        "Free Fall is a puzzle game. Players control a character, falling through a vertical maze filled with obstacles. The game features falling mechanics, avoiding obstacles, and challenging levels.",
      developer: "Micronics",
      platform: "NES",
      genre: "Puzzle",
    },
    FRIDAY13: {
      description:
        "Friday the 13th is a survival horror game. Players control one of six camp counselors, exploring Camp Crystal Lake and trying to survive while being pursued by Jason Voorhees. The game features exploration, horror elements, and challenging gameplay.",
      developer: "Atlus",
      platform: "NES",
      genre: "Survival horror",
    },
    Fun_House: {
      description:
        "Fun House is a puzzle action game. Players control a character, navigating through levels filled with traps and obstacles. The game features puzzle-solving mechanics, action gameplay, and challenging levels.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Puzzle, Action",
    },
    Fuzzical_Fighter: {
      description:
        "Fuzzical Fighter is a puzzle action game. Players control a character, navigating through levels filled with enemies and obstacles. The game features puzzle-solving mechanics, action gameplay, and challenging levels.",
      developer: "Pixel",
      platform: "NES",
      genre: "Puzzle, Action",
    },
    F_15_City_War: {
      description:
        "F-15 City War is a shooter game. Players control an aircraft, battling against waves of enemy planes and ground targets. The game features top-down shooting action, a variety of weapons, and intense combat.",
      developer: "American Video Entertainment",
      platform: "NES",
      genre: "Shooter",
    },
    F_15_Strike_Eagle: {
      description:
        "F-15 Strike Eagle is a flight simulation game. Players pilot an F-15 fighter jet, engaging in missions against enemy forces. The game features flight simulation mechanics, aerial combat, and mission-based gameplay.",
      developer: "MicroProse",
      platform: "NES",
      genre: "Flight simulation",
    },
    Galaga: {
      description:
        "Galaga is a classic shoot 'em up game. Players control a spaceship, battling against waves of alien enemies in space. The game features top-down shooting action, challenging enemy patterns, and addictive gameplay.",
      developer: "Namco",
      platform: "Arcade, NES",
      genre: "Shoot 'em up",
    },
    Galaxian: {
      description:
        "Galaxian is a fixed shooter arcade game. Players control a spaceship, defending against waves of alien enemies. The game features fixed shooting mechanics, colorful visuals, and challenging gameplay.",
      developer: "Namco",
      platform: "Arcade, Atari 2600, NES",
      genre: "Fixed shooter",
    },
    Galaxy_5000: {
      description:
        "Galaxy 5000 is a racing game. Players compete in high-speed races on futuristic tracks, avoiding obstacles and rivals. The game features top-down racing, power-ups, and competitive gameplay.",
      developer: "Activision",
      platform: "NES",
      genre: "Racing",
    },
    Galg: {
      description:
        "Galg is a shoot 'em up game. Players control a spaceship, battling against waves of enemy ships and bosses. The game features top-down shooting action, power-ups, and challenging levels.",
      developer: "Home Data",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Gambler_Jiko_Chuushin_Ha2: {
      description:
        "Gambler Jiko Chuushin Ha: Mahjong Game 2 is a mahjong game. Players engage in traditional Japanese mahjong matches, competing against computer opponents. The game features mahjong gameplay and various skill levels.",
      developer: "Seta",
      platform: "NES",
      genre: "Mahjong",
    },
    Gambler_Jiko_Chuushin_HaMaajan_Game: {
      description:
        "Gambler Jiko Chuushin Ha: Mahjong Game is a mahjong game. Players engage in traditional Japanese mahjong matches, competing against computer opponents. The game features mahjong gameplay and various skill levels.",
      developer: "Seta",
      platform: "NES",
      genre: "Mahjong",
    },
    Game_Party: {
      description:
        "Game Party is a collection of mini-games. Players can enjoy various mini-games such as darts, table hockey, and trivia quizzes. The game features a party atmosphere and multiplayer options.",
      developer: "Epyx",
      platform: "NES",
      genre: "Party, Mini-games",
    },
    Ganbare_Goemon2: {
      description:
        "Ganbare Goemon 2 is an action-adventure game. Players control Goemon, a heroic ninja, as he embarks on a quest to rescue his friends and defeat enemies. The game features side-scrolling action, exploration, and colorful graphics.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    Ganbare_Goemon_Gaiden2Tenka_No_Zaihou: {
      description:
        "Ganbare Goemon Gaiden 2: Tenka no Zaihou is a role-playing game. Players control characters from the Goemon series, battling enemies and exploring dungeons. The game features turn-based battles, RPG elements, and a vibrant world.",
      developer: "Konami",
      platform: "NES",
      genre: "Role-playing",
    },
    Ganbare_Goemon_GaidenKieta_Kogane_Kiseru: {
      description:
        "Ganbare Goemon Gaiden: Kieta Kogane Kiseru is a role-playing game. Players control characters from the Goemon series, battling enemies and exploring dungeons. The game features turn-based battles, RPG elements, and a vibrant world.",
      developer: "Konami",
      platform: "NES",
      genre: "Role-playing",
    },
    Ganbare_Penant_Race: {
      description:
        "Ganbare! Penant Race! is a sports game. Players compete in baseball matches, controlling teams and aiming for victory. The game features baseball gameplay, team management, and competitive matches.",
      developer: "Namco",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    GarfieldA_Week_of_Garfield: {
      description:
        "Garfield: A Week of Garfield is a puzzle-platformer game. Players control Garfield, navigating through levels, solving puzzles, and collecting items. The game features platforming, puzzle-solving, and comic strip-inspired visuals.",
      developer: "Kemco",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    GARG: {
      description:
        "G.I. Joe: A Real American Hero is a run and gun game. Players control G.I. Joe characters, battling against the forces of Cobra. The game features side-scrolling shooting action, various characters, and intense battles.",
      developer: "KID",
      platform: "NES",
      genre: "Run and gun",
    },
    Garou_Densetsu2: {
      description:
        "Garou Densetsu 2: Aratanaru Tatakai is a fighting game. Players select fighters and engage in one-on-one battles, using special moves and combos to defeat opponents. The game features competitive fighting gameplay and a diverse cast of characters.",
      developer: "Takara",
      platform: "NES",
      genre: "Fighting",
    },
    Garou_Densetsu_Special: {
      description:
        "Garou Densetsu Special is a fighting game. Players select fighters and engage in one-on-one battles, using special moves and combos to defeat opponents. The game features competitive fighting gameplay and a diverse cast of characters.",
      developer: "Takara",
      platform: "NES",
      genre: "Fighting",
    },
    GAUNTLET: {
      description:
        "Gauntlet is a dungeon crawl action-adventure game. Players choose from different characters and explore maze-like dungeons, battling enemies and collecting treasure. The game features cooperative multiplayer, hack-and-slash gameplay, and fantasy elements.",
      developer: "Tengen",
      platform: "Arcade, NES",
      genre: "Action-adventure, Dungeon crawl",
    },
    GAUNTLT2: {
      description:
        "Gauntlet II is a dungeon crawl action-adventure game. Players choose from different characters and explore maze-like dungeons, battling enemies and collecting treasure. The game features cooperative multiplayer, hack-and-slash gameplay, and fantasy elements.",
      developer: "Atari Games",
      platform: "Arcade, NES",
      genre: "Action-adventure, Dungeon crawl",
    },
    GBUSTER2: {
      description:
        "Ghostbusters II is an action-adventure game. Players control the Ghostbusters, exploring locations and capturing ghosts. The game features top-down exploration, ghost-catching mechanics, and elements from the movie.",
      developer: "Imagineering",
      platform: "NES",
      genre: "Action-adventure",
    },
    gbzscn: {
      description:
        "Godzilla 2: War of the Monsters is a strategy game. Players control military forces, defending cities from giant monsters like Godzilla. The game features strategy gameplay, city management, and battles against iconic monsters.",
      developer: "Toho",
      platform: "NES",
      genre: "Strategy",
    },
    Gegege_No_Kitarou2Youkaigundan_No_Chousen: {
      description:
        "Gegege no Kitarou 2: Youkai Gundan no Chousen is an action-adventure game. Players control Kitarou, a ghost boy, as he battles against various monsters and spirits. The game features side-scrolling action, exploration, and supernatural elements.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action-adventure",
    },
    Gegege_No_KitarouYoukai_Daimakyou: {
      description:
        "Gegege no Kitarou: Youkai Daimakyou is an action-adventure game. Players control Kitarou, a ghost boy, as he battles against various monsters and spirits. The game features side-scrolling action, exploration, and supernatural elements.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action-adventure",
    },
    Geimos: {
      description:
        "Geimos is a shooter game. Players control a spaceship, battling against waves of enemies and bosses. The game features top-down shooting action, power-ups, and challenging levels.",
      developer: "Micronics",
      platform: "NES",
      genre: "Shooter",
    },
    Gekitotsu_Yon_Ku_Battle: {
      description:
        "Gekitotsu!! Yon Ku Battle is a strategy game. Players command armies in tactical battles, using different units and strategies to defeat opponents. The game features turn-based battles, unit management, and strategic gameplay.",
      developer: "C-Lab",
      platform: "NES",
      genre: "Strategy",
    },
    Gekitou_Pro_WrestlingToukon_Densetsu: {
      description:
        "Gekitou! Pro Wrestling Toukon Densetsu is a wrestling game. Players control wrestlers, engaging in one-on-one matches and using various moves and techniques to defeat opponents. The game features wrestling gameplay, different characters, and intense bouts.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Wrestling",
    },
    Gekitou_Stadium: {
      description:
        "Gekitou Stadium!! is a sports game. Players compete in baseball matches, controlling teams and aiming for victory. The game features baseball gameplay, team management, and competitive matches.",
      developer: "Namco",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Genpei_Toumaden: {
      description:
        "Genpei Toumaden is a platformer game. Players control a character, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and challenging gameplay.",
      developer: "Namco",
      platform: "NES",
      genre: "Platformer",
    },
    GENSAN2: {
      description:
        "Genpei Toumaden: Kannoni is a platformer game. Players control a character, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and challenging gameplay.",
      developer: "Namco",
      platform: "NES",
      genre: "Platformer",
    },
    Gen_Ke_Le_Zhuan: {
      description:
        "Gen Ke Le Zhuan is an action-adventure game. Players control a character, exploring various levels, battling enemies, and solving puzzles. The game features side-scrolling action, platforming, and diverse challenges.",
      developer: "Sachen",
      platform: "NES",
      genre: "Action-adventure",
    },
    George_Foremans_KO_Boxing: {
      description:
        "George Foreman's KO Boxing is a boxing game. Players control a boxer, engaging in matches and using different punches and defensive moves to defeat opponents. The game features boxing gameplay, different opponents, and strategy.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Sports, Boxing",
    },
    Getsufuu_Maden: {
      description:
        "Getsufuu Maden is an action-adventure game. Players control a character, exploring various levels, battling enemies, and solving puzzles. The game features side-scrolling action, platforming, and diverse challenges.",
      developer: "Pony Canyon",
      platform: "NES",
      genre: "Action-adventure",
    },
    Ghostbusters: {
      description:
        "Ghostbusters is an action-adventure game. Players control the Ghostbusters, exploring locations and capturing ghosts. The game features top-down exploration, ghost-catching mechanics, and elements from the movie.",
      developer: "David Crane",
      platform: "NES",
      genre: "Action-adventure",
    },
    Ghoul_School: {
      description:
        "Ghoul School is a platformer game. Players control a character, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and challenging gameplay.",
      developer: "Imagineering",
      platform: "NES",
      genre: "Platformer",
    },
    GHSTLION: {
      description:
        "Ghoul School is a platformer game. Players control a character, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and challenging gameplay.",
      developer: "Imagineering",
      platform: "NES",
      genre: "Platformer",
    },
    giabbit: {
      description:
        "G.I. Rabbit is a platformer game. Players control a character, navigating through levels filled with enemies and obstacles. The game features side-scrolling platforming, power-ups, and challenging gameplay.",
      developer: "HiCom",
      platform: "NES",
      genre: "Platformer",
    },
    GIJOE: {
      description:
        "G.I. Joe is a run and gun game. Players control G.I. Joe characters, battling against the forces of Cobra. The game features side-scrolling shooting action, various characters, and intense battles.",
      developer: "KID",
      platform: "NES",
      genre: "Run and gun",
    },
    GIJOEAF: {
      description:
        "G.I. Joe: The Atlantis Factor is a run and gun game. Players control G.I. Joe characters, battling against the forces of Cobra. The game features side-scrolling shooting action, various characters, and intense battles.",
      developer: "KID",
      platform: "NES",
      genre: "Run and gun",
    },
    GILLIGAN: {
      description:
        "Gilligan's Island is an action-adventure game. Players control characters from the TV show, solving puzzles and exploring the island. The game features top-down exploration, puzzle-solving, and humor from the show.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Action-adventure",
    },
    Gimmi_a_BreakShijou_Saidai_Quiz_Ou_Ketteisen: {
      description:
        "Gimmi a Break: Shijou Saidai no Quiz Ou Ketteisen is a quiz game. Players answer trivia questions in various categories, testing their knowledge. The game features quiz gameplay, multiple-choice questions, and a variety of topics.",
      developer: "Taito",
      platform: "NES",
      genre: "Trivia, Quiz",
    },
    Gimmi_a_BreakShijou_Saikyou_No_Quiz_Ou_Ketteisen2: {
      description:
        "Gimmi a Break: Shijou Saikyou no Quiz Ou Ketteisen 2 is a quiz game. Players answer trivia questions in various categories, testing their knowledge. The game features quiz gameplay, multiple-choice questions, and a variety of topics.",
      developer: "Taito",
      platform: "NES",
      genre: "Trivia, Quiz",
    },
    Ginga_Eiyuu_Densetsu: {
      description:
        "Ginga Eiyuu Densetsu is a strategy game. Players command space fleets, engaging in tactical battles and managing resources. The game features turn-based strategy, space battles, and elements from the anime series.",
      developer: "Tokuma Soft",
      platform: "NES",
      genre: "Strategy",
    },
    Ginga_No_Sannin: {
      description:
        "Ginga no Sannin is an action-adventure game. Players control characters, exploring levels, battling enemies, and solving puzzles. The game features side-scrolling action, platforming, and diverse challenges.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    GK_47in1: {
      description:
        "GK 47-in-1 is a multicart compilation. It includes various games from different genres, offering players a diverse gaming experience. The compilation features different games, genres, and gameplay styles.",
      developer: "Various",
      platform: "NES",
      genre: "Various",
    },
    GNG: {
      description:
        "Ghosts 'n Goblins is a platformer game. Players control a knight, battling against monsters and demons to rescue the princess. The game features side-scrolling platforming, challenging enemies, and intense difficulty.",
      developer: "Capcom",
      platform: "Arcade, NES",
      genre: "Platformer",
    },
    "Goal Two": {
      description:
        "Goal! Two is a soccer game. Players control teams, competing in soccer matches and aiming for victory. The game features soccer gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    "Goal(J)": {
      description:
        "Goal! is a soccer game. Players control teams, competing in soccer matches and aiming for victory. The game features soccer gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Goal: {
      description:
        "Goal! is a soccer game. Players control teams, competing in soccer matches and aiming for victory. The game features soccer gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Goal_Two: {
      description:
        "Goal! Two is a soccer game. Players control teams, competing in soccer matches and aiming for victory. The game features soccer gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    GODZILA1: {
      description:
        "Godzilla: Monster of Monsters! is a strategy game. Players control military forces, defending cities from giant monsters like Godzilla. The game features strategy gameplay, city management, and battles against iconic monsters.",
      developer: "Toho",
      platform: "NES",
      genre: "Strategy",
    },
    Godzilla: {
      description:
        "Godzilla is a strategy game. Players control military forces, defending cities from giant monsters like Godzilla. The game features strategy gameplay, city management, and battles against iconic monsters.",
      developer: "Toho",
      platform: "NES",
      genre: "Strategy",
    },
    Godzilla2War_of_the_Monsters: {
      description:
        "Godzilla 2: War of the Monsters is a strategy game. Players control military forces, defending cities from giant monsters like Godzilla. The game features strategy gameplay, city management, and battles against iconic monsters.",
      developer: "Toho",
      platform: "NES",
      genre: "Strategy",
    },
    God_SlayerHaruka_Tenkuu_No_Sonata: {
      description:
        "God Slayer: Haruka Tenkuu no Sonata is an action-adventure game. Players control a character, battling enemies and exploring a fantasy world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Marvelous",
      platform: "NES",
      genre: "Action-adventure",
    },
    GOEMON: {
      description:
        "Ganbare Goemon! Karakuri Douchuu is an action-adventure game. Players control Goemon, a heroic ninja, as he embarks on a quest to rescue his friends and defeat enemies. The game features side-scrolling action, exploration, and colorful graphics.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    GOEMON2: {
      description:
        "Ganbare Goemon 2: Kiteretsu Shougun Magginesu is an action-adventure game. Players control Goemon, a heroic ninja, as he embarks on a quest to rescue his friends and defeat enemies. The game features side-scrolling action, exploration, and colorful graphics.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    Golby_No_Pipeline_Daisakusen: {
      description:
        "Golby no Pipeline Daisakusen is a puzzle game. Players connect pipes to create a pipeline, guiding a liquid to the goal. The game features puzzle-solving, pipe connection mechanics, and challenging levels.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Puzzle",
    },
    Gold_Medal_Challenge_92: {
      description:
        "Gold Medal Challenge '92 is a sports game. Players compete in various Olympic events, testing their skills in different sports disciplines. The game features sports gameplay, multiple events, and competitive challenges.",
      developer: "Data East",
      platform: "NES",
      genre: "Sports, Olympics",
    },
    golf: {
      description:
        "Golf is a sports simulation game. Players compete in golf matches, aiming to complete courses with the fewest strokes possible. The game features golf gameplay, different courses, and realistic mechanics.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Sports, Golf",
    },
    Golf_92_The: {
      description:
        "The Golf '92 is a sports simulation game. Players compete in golf matches, aiming to complete courses with the fewest strokes possible. The game features golf gameplay, different courses, and realistic mechanics.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Sports, Golf",
    },
    Golf_Grand_Slam: {
      description:
        "Golf Grand Slam is a sports simulation game. Players compete in golf matches, aiming to complete courses with the fewest strokes possible. The game features golf gameplay, different courses, and realistic mechanics.",
      developer: "Atlus",
      platform: "NES",
      genre: "Sports, Golf",
    },
    Golf_Ko_Open: {
      description:
        "Golf: Ko Open is a sports simulation game. Players compete in golf matches, aiming to complete courses with the fewest strokes possible. The game features golf gameplay, different courses, and realistic mechanics.",
      developer: "Irem",
      platform: "NES",
      genre: "Sports, Golf",
    },
    Golf_KurabuBirdy_Rush: {
      description:
        "Golf Kurabu: Birdy Rush is a sports simulation game. Players compete in golf matches, aiming to complete courses with the fewest strokes possible. The game features golf gameplay, different courses, and realistic mechanics.",
      developer: "Data East",
      platform: "NES",
      genre: "Sports, Golf",
    },
    Golgo13Kamigami_No_Tasogare: {
      description:
        "Golgo 13: Kamigami no Tasogare is an action-adventure game. Players control Golgo 13, a professional assassin, as he embarks on missions and battles enemies. The game features side-scrolling action, shooting mechanics, and espionage.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Action-adventure",
    },
    Golgo13The_Riddle_of_Icarus: {
      description:
        "Golgo 13: The Riddle of Icarus is an action-adventure game. Players control Golgo 13, a professional assassin, as he embarks on missions and battles enemies. The game features side-scrolling action, shooting mechanics, and espionage.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Action-adventure",
    },
    Gomoku_Narabe: {
      description:
        "Gomoku Narabe is a board game. Players compete in the traditional board game Gomoku, placing stones on a grid and aiming to create a row of five stones. The game features board game mechanics, strategic gameplay, and multiplayer options.",
      developer: "Home Data",
      platform: "NES",
      genre: "Board game",
    },
    GOONIE1: {
      description:
        "The Goonies is an action-adventure game. Players control characters from the movie, exploring levels, solving puzzles, and battling enemies. The game features side-scrolling action, platforming, and elements from the film.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    GOONIES2: {
      description:
        "The Goonies II is an action-adventure game. Players control characters from the movie, exploring levels, solving puzzles, and battling enemies. The game features side-scrolling action, platforming, and elements from the film.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    Goonies_The: {
      description:
        "The Goonies is an action-adventure game. Players control characters from the movie, exploring levels, solving puzzles, and battling enemies. The game features side-scrolling action, platforming, and elements from the film.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    Gorilla_Man: {
      description:
        "Gorilla Man is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Panesian Taiwan",
      platform: "NES",
      genre: "Puzzle",
    },
    GOTCHA: {
      description:
        "Gotcha! The Sport! is a sports game. Players compete in paintball matches, aiming to tag opponents and achieve victory. The game features paintball gameplay, team-based matches, and competitive action.",
      developer: "Atlus",
      platform: "NES",
      genre: "Sports, Paintball",
    },
    gowest: {
      description:
        "Go West is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Sachen",
      platform: "NES",
      genre: "Puzzle",
    },
    Gradius: {
      description:
        "Gradius is a shooter game. Players control a spaceship, battling against waves of enemies and bosses. The game features side-scrolling shooting action, power-ups, and challenging levels.",
      developer: "Konami",
      platform: "Arcade, NES",
      genre: "Shooter",
    },
    Gradius2: {
      description:
        "Gradius II is a shooter game. Players control a spaceship, battling against waves of enemies and bosses. The game features side-scrolling shooting action, power-ups, and challenging levels.",
      developer: "Konami",
      platform: "Arcade, NES",
      genre: "Shooter",
    },
    Grand_Master: {
      description:
        "Grand Master is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Puzzle",
    },
    Great_Battle_Cyber: {
      description:
        "The Great Battle Cyber is an action game. Players control characters from various anime series, battling enemies and bosses in side-scrolling levels. The game features action gameplay, different characters, and crossover elements.",
      developer: "Banpresto",
      platform: "SNES",
      genre: "Action",
    },
    Great_Boxing_Rush_Up: {
      description:
        "Great Boxing Rush Up is a boxing game. Players control a boxer, engaging in matches and using different punches and defensive moves to defeat opponents. The game features boxing gameplay, different opponents, and strategy.",
      developer: "Nihon Corp.",
      platform: "NES",
      genre: "Sports, Boxing",
    },
    Great_Deal: {
      description:
        "Great Deal is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Puzzle",
    },
    Great_Tank: {
      description:
        "Great Tank is a shooter game. Players control a tank, battling against waves of enemies and bosses. The game features top-down shooting action, power-ups, and challenging levels.",
      developer: "NTVIC",
      platform: "NES",
      genre: "Shooter",
    },
    Great_Waldo_Search: {
      description:
        "The Great Waldo Search is a puzzle game. Players search for the character Waldo in detailed, colorful scenes. The game features hidden object gameplay, diverse locations, and a variety of challenges.",
      developer: "Radiance Software",
      platform: "NES",
      genre: "Puzzle",
    },
    Greg_Normans_Golf_Power: {
      description:
        "Greg Norman's Golf Power is a golf simulation game. Players compete in golf matches, aiming to complete courses with the fewest strokes possible. The game features golf gameplay, different courses, and realistic mechanics.",
      developer: "Gremlin Interactive",
      platform: "NES",
      genre: "Sports, Golf",
    },
    GREMLIN2: {
      description:
        "Gremlins 2: The New Batch is an action-adventure game. Players control Gizmo, a friendly Mogwai, as he battles against enemies and tries to stop the Gremlins' mischief. The game features side-scrolling action, platforming, and elements from the film.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    Gremlin2Shinshu_Tanjou: {
      description:
        "Gremlins 2: Shinshu Tanjou is an action-adventure game. Players control Gizmo, a friendly Mogwai, as he battles against enemies and tries to stop the Gremlins' mischief. The game features side-scrolling action, platforming, and elements from the film.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    Guardian_Legend: {
      description:
        "The Guardian Legend is an action-adventure game. Players control a character, exploring levels, battling enemies, and solving puzzles. The game features top-down exploration, shooting mechanics, and diverse challenges.",
      developer: "Compile",
      platform: "NES",
      genre: "Action-adventure",
    },
    GUARDL_J: {
      description:
        "The Guardian Legend is an action-adventure game. Players control a character, exploring levels, battling enemies, and solving puzzles. The game features top-down exploration, shooting mechanics, and diverse challenges.",
      developer: "Compile",
      platform: "NES",
      genre: "Action-adventure",
    },
    GUERILLA: {
      description:
        "Guerilla War is a run and gun game. Players control soldiers, battling against enemies and bosses in side-scrolling levels. The game features shooting action, cooperative gameplay, and intense battles.",
      developer: "SNK",
      platform: "Arcade, NES",
      genre: "Run and gun",
    },
    Gumshoe: {
      description:
        "Gumshoe is a shooter game. Players control a detective, navigating through levels, avoiding obstacles, and shooting enemies. The game features side-scrolling shooting action, platforming, and challenging levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Shooter",
    },
    GUNNAC: {
      description:
        "GUN.NAC is a shooter game. Players control a spaceship, battling against waves of enemies and bosses. The game features side-scrolling shooting action, power-ups, and challenging levels.",
      developer: "Compile",
      platform: "NES",
      genre: "Shooter",
    },
    Gun_Dec: {
      description:
        "Gun-Dec is a shooter game. Players control a character, battling enemies in side-scrolling levels. The game features shooting action, various weapons, and challenging gameplay.",
      developer: "Tonkin House",
      platform: "NES",
      genre: "Shooter",
    },
    Gun_Hed: {
      description:
        "Gun Hed is a shooter game. Players control a spaceship, battling against waves of enemies and bosses. The game features top-down shooting action, power-ups, and challenging levels.",
      developer: "Compile",
      platform: "NES",
      genre: "Shooter",
    },
    Gun_Sight: {
      description:
        "Gun Sight is a shooter game. Players control a character, battling enemies in side-scrolling levels. The game features shooting action, various weapons, and challenging gameplay.",
      developer: "Axela",
      platform: "NES",
      genre: "Shooter",
    },
    Gyrodine: {
      description:
        "Gyrodine is a shooter game. Players control a helicopter, battling against waves of enemies and bosses. The game features top-down shooting action, power-ups, and challenging levels.",
      developer: "Crux",
      platform: "Arcade, NES",
      genre: "Shooter",
    },
    Gyromite: {
      description:
        "Gyromite is a puzzle-platformer game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, cooperative gameplay, and challenging levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Puzzle, Platformer",
    },
    GYRUS: {
      description:
        "Gyruss is a shooter game. Players control a spaceship, battling against waves of enemies and bosses. The game features tube-based shooting action, power-ups, and challenging levels.",
      developer: "Konami",
      platform: "Arcade, NES",
      genre: "Shooter",
    },
    Haja_No_Fuuin: {
      description:
        "Haja no Fuuin is an action-adventure game. Players control a character, battling enemies and exploring levels in a fantasy world. The game features side-scrolling action, platforming, and diverse challenges.",
      developer: "Kemco",
      platform: "NES",
      genre: "Action-adventure",
    },
    HammerinHARRY: {
      description:
        "Hammerin' Harry is a platformer game. Players control Harry, a carpenter, battling enemies and bosses in side-scrolling levels. The game features platforming action, hammer attacks, and diverse challenges.",
      developer: "Irem",
      platform: "NES",
      genre: "Platformer",
    },
    Hanafuda_Yuukyou_DenNagarebana_Oryuu: {
      description:
        "Hanafuda Yuukyou Den: Nagarebana Oryuu is a card game. Players compete in Hanafuda, a traditional Japanese card game, aiming to create specific card combinations. The game features card game mechanics, strategy, and multiplayer options.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Card game",
    },
    Hana_No_Star_Kaidou: {
      description:
        "Hana no Star Kaidou is a role-playing game. Players control characters, embarking on a journey, battling enemies, and completing quests. The game features turn-based combat, exploration, and a fantasy storyline.",
      developer: "KSS",
      platform: "NES",
      genre: "Role-playing",
    },
    Hanjuku_Hero: {
      description:
        "Hanjuku Hero is a strategy game. Players command armies, engaging in tactical battles and managing resources. The game features turn-based strategy, battles, and elements from the Hanjuku Hero series.",
      developer: "Square",
      platform: "NES",
      genre: "Strategy",
    },
    Harlem_Globetrotters: {
      description:
        "Harlem Globetrotters is a sports game. Players control the Harlem Globetrotters basketball team, competing in matches and showcasing their skills. The game features basketball gameplay, team management, and competitive matches.",
      developer: "Bethesda Softworks",
      platform: "NES",
      genre: "Sports, Basketball",
    },
    Hatris: {
      description:
        "Hatris is a puzzle game. Players stack falling hats to create complete lines and clear them from the screen. The game features puzzle-solving, stacking mechanics, and challenging levels.",
      developer: "Bitera",
      platform: "NES",
      genre: "Puzzle",
    },
    Hayachi_Super_Igo: {
      description:
        "Hayachi Super Igo is a board game. Players compete in the traditional board game Igo, aiming to control the board and capture opponent's stones. The game features board game mechanics, strategic gameplay, and multiplayer options.",
      developer: "NCS",
      platform: "NES",
      genre: "Board game",
    },
    heavenhell: {
      description:
        "Heaven & Hell is a strategy game. Players control characters, managing resources, building armies, and engaging in tactical battles. The game features turn-based strategy, resource management, and fantasy elements.",
      developer: "NCS",
      platform: "NES",
      genre: "Strategy",
    },
    HEAVYBAR: {
      description:
        "Heavy Barrel is a run and gun game. Players control soldiers, battling against enemies and bosses in top-down levels. The game features shooting action, cooperative gameplay, and intense battles.",
      developer: "Data East",
      platform: "Arcade, NES",
      genre: "Run and gun",
    },
    Heavy_Barrel: {
      description:
        "Heavy Barrel is a run and gun game. Players control soldiers, battling against enemies and bosses in top-down levels. The game features shooting action, cooperative gameplay, and intense battles.",
      developer: "Data East",
      platform: "Arcade, NES",
      genre: "Run and gun",
    },
    Heavy_Shreddin: {
      description:
        "Heavy Shreddin' is a sports game. Players control a snowboarder, performing tricks and avoiding obstacles in downhill races. The game features snowboarding gameplay, trick mechanics, and competitive challenges.",
      developer: "Imagineering",
      platform: "NES",
      genre: "Sports, Snowboarding",
    },
    Hector_87: {
      description:
        "Hector '87 is a shooter game. Players control a character, battling enemies in side-scrolling levels. The game features shooting action, various weapons, and challenging gameplay.",
      developer: "M.N.M Software",
      platform: "NES",
      genre: "Shooter",
    },
    Heisei_Tensai_Bakabon: {
      description:
        "Heisei Tensai Bakabon is an action game. Players control characters from the anime series, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and elements from the show.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    Hello_Kitty_No_Ohanabatake: {
      description:
        "Hello Kitty no Ohanabatake is a simulation game. Players grow flowers, take care of a garden, and participate in flower competitions. The game features gardening simulation, flower breeding, and colorful graphics.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Simulation",
    },
    Hello_Kitty_World: {
      description:
        "Hello Kitty World is a puzzle game. Players guide Hello Kitty through levels, solving puzzles and collecting items. The game features puzzle-solving, platforming, and elements from the Hello Kitty franchise.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Puzzle",
    },
    Hercules_No_Eikou2Titan_No_Metsubou: {
      description:
        "Hercules no Eikou II: Titan no Metsubou is an action-adventure game. Players control Hercules, battling enemies and embarking on quests in a mythological world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Data East",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hercules_No_EikouToujin_Makyouden: {
      description:
        "Hercules no Eikou: Toujin Makyou Den is an action-adventure game. Players control Hercules, battling enemies and embarking on quests in a mythological world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Data East",
      platform: "NES",
      genre: "Action-adventure",
    },
    HEROS: {
      description:
        "HERO'S is an action game. Players control a character, battling enemies in side-scrolling levels. The game features action gameplay, various weapons, and challenging levels.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    Hero_Quest: {
      description:
        "Hero Quest is a strategy game. Players control characters, exploring dungeons, battling monsters, and completing quests. The game features turn-based strategy, role-playing elements, and a fantasy world.",
      developer: "FCI",
      platform: "NES",
      genre: "Strategy",
    },
    Higemaru_MakaijimaNanatsu_No_Shima_Daibouken: {
      description:
        "Higemaru Makaijima: Nanatsu no Shima Daibouken is an action game. Players control a character, battling enemies and navigating through levels. The game features action gameplay, diverse enemies, and challenging levels.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action",
    },
    Highway_Star: {
      description:
        "Highway Star is a racing game. Players control a car, racing on highways, avoiding traffic, and collecting items. The game features racing gameplay, traffic challenges, and competitive races.",
      developer: "Nexa Corporation",
      platform: "NES",
      genre: "Racing",
    },
    HIK7in1: {
      description:
        "HIK 7-in-1 is a multi-game cartridge containing seven different games. The games feature various genres and gameplay styles, providing players with a diverse gaming experience.",
      developer: "Various developers",
      platform: "NES",
      genre: "Various",
    },
    Hirake_Ponkikki: {
      description:
        "Hirake! Ponkikki is an educational game. Players engage in various activities and mini-games, promoting learning and creativity. The game features educational challenges, creativity, and colorful graphics.",
      developer: "Kemco",
      platform: "NES",
      genre: "Educational, Mini-games",
    },
    hiryuk3: {
      description:
        "Hiryu no Ken III: 5 Nin no Ryuu Senshi is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    Hiryuu_No_Ken2Dragon_No_Tsubasa: {
      description:
        "Hiryuu no Ken II: Dragon no Tsubasa is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    Hiryuu_No_Ken35_Nin_No_Ryuu_Senshi: {
      description:
        "Hiryuu no Ken: 3 Nin no Ryuu Senshi is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    Hiryuu_No_KenOugi_No_Shu: {
      description:
        "Hiryuu no Ken: Ougi no Shou is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    Hiryuu_No_Ken_SpecialFighting_Wars: {
      description:
        "Hiryuu no Ken Special: Fighting Wars is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    Hissatsu_Doujou_Yaburi: {
      description:
        "Hissatsu Doujou Yaburi is a fighting game. Players control characters, engaging in martial arts battles and using different moves and techniques to defeat opponents. The game features fighting gameplay, diverse characters, and competitive matches.",
      developer: "SNK",
      platform: "NES",
      genre: "Fighting",
    },
    Hissatsu_Shigoto_Nin: {
      description:
        "Hissatsu Shigoto Nin is a strategy game. Players control workers, managing resources and constructing buildings to complete tasks. The game features strategy gameplay, resource management, and diverse challenges.",
      developer: "Yonezawa PR21",
      platform: "NES",
      genre: "Strategy",
    },
    Hitler_No_FukkatsuTop_Secret: {
      description:
        "Hitler no Fukkatsu: Top Secret is an action-adventure game. Players control a character, battling enemies and exploring levels in a World War II setting. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Hacker International",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hi_No_ToriHouou_HenGaou_No_Bouken: {
      description:
        "Hi no Tori: Houou Hen - Gaou no Bouken is an action-adventure game. Players control a character, battling enemies and exploring levels in a fantasy world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-adventure",
    },
    HOCKEY: {
      description:
        "Hockey is a sports game. Players control hockey teams, competing in matches and aiming to score goals. The game features hockey gameplay, team management, and competitive matches.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Sports, Hockey",
    },
    Hogans_Alley: {
      description:
        "Hogan's Alley is a shooting game. Players control a character, aiming and shooting at targets in various scenarios. The game features shooting gameplay, target practice, and quick reflex challenges.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Shooter",
    },
    Hokuto_No_Ken: {
      description:
        "Hokuto no Ken is an action game. Players control Kenshiro, a martial artist, battling enemies in side-scrolling levels. The game features action gameplay, different moves, and challenging levels.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action",
    },
    Hokuto_No_Ken2Seikimatsu_Kyuuseishu_Densetsu: {
      description:
        "Hokuto no Ken 2: Seikimatsu Kyuuseishu Densetsu is an action game. Players control Kenshiro, a martial artist, battling enemies in side-scrolling levels. The game features action gameplay, different moves, and challenging levels.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action",
    },
    Hokuto_No_Ken3Shinseiki_Souzou_Seiken_Restuden: {
      description:
        "Hokuto no Ken 3: Shinseiki Souzou Seiken Restuden is an action game. Players control Kenshiro, a martial artist, battling enemies in side-scrolling levels. The game features action gameplay, different moves, and challenging levels.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action",
    },
    Hokuto_No_Ken_4Shichisei_HakendenHokuto_Shinken_No_Kan: {
      description:
        "Hokuto no Ken 4: Shichisei Hakenden: Hokuto Shinken no Kanata e is an action game. Players control Kenshiro, a martial artist, battling enemies in side-scrolling levels. The game features action gameplay, different moves, and challenging levels.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action",
    },
    Hollywood_Squares: {
      description:
        "Hollywood Squares is a quiz game. Players participate in a quiz show, answering questions and making strategic moves to win. The game features quiz questions, celebrity interactions, and a game show atmosphere.",
      developer: "Rare",
      platform: "NES",
      genre: "Quiz",
    },
    Holy_Diver: {
      description:
        "Holy Diver is a platformer game. Players control a character, navigating through levels, battling enemies, and avoiding obstacles. The game features platforming action, diverse enemies, and challenging levels.",
      developer: "Irem",
      platform: "NES",
      genre: "Platformer",
    },
    Homalon1: {
      description:
        "Homalones 1 is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Home Data",
      platform: "NES",
      genre: "Puzzle",
    },
    HOMALON2: {
      description:
        "Homalones 2 is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Home Data",
      platform: "NES",
      genre: "Puzzle",
    },
    Home_Run_NighterPenant_League: {
      description:
        "Home Run Nighter: Pennant League! is a baseball game. Players control baseball teams, competing in matches and aiming to score runs. The game features baseball gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Home_Run_Nighter_90The_Penant_League: {
      description:
        "Home Run Nighter: '90 - The Pennant League! is a baseball game. Players control baseball teams, competing in matches and aiming to score runs. The game features baseball gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    honmei: {
      description:
        "Honmei is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Puzzle",
    },
    Honoo_No_ToukyuujiDodge_Danpei: {
      description:
        "Honoo no Toukyuuji: Dodge Danpei is a sports game. Players control characters, engaging in dodgeball matches and using different throws and tactics to win. The game features dodgeball gameplay, diverse teams, and competitive matches.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Sports, Dodgeball",
    },
    Honoo_No_ToukyuujiDodge_Danpei2: {
      description:
        "Honoo no Toukyuuji: Dodge Danpei 2 is a sports game. Players control characters, engaging in dodgeball matches and using different throws and tactics to win. The game features dodgeball gameplay, diverse teams, and competitive matches.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Sports, Dodgeball",
    },
    Hook: {
      description:
        "Hook is a platformer game. Players control Peter Pan, navigating through levels, battling enemies, and avoiding obstacles. The game features platforming action, diverse enemies, and challenging levels.",
      developer: "Sony Imagesoft",
      platform: "NES",
      genre: "Platformer",
    },
    Hoops: {
      description:
        "Hoops is a basketball game. Players control basketball teams, competing in matches and aiming to score points. The game features basketball gameplay, team management, and competitive matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Basketball",
    },
    Hoshi_No_KirbyYume_No_Izumi_No_Monogatari: {
      description:
        "Hoshi no Kirby: Yume no Izumi no Monogatari is an action-adventure game. Players control Kirby, battling enemies and exploring levels in a fantasy world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hoshi_Wo_Miruhito: {
      description:
        "Hoshi wo Miru Hito is a role-playing game. Players control characters, embarking on a journey, battling enemies, and completing quests. The game features turn-based combat, exploration, and a fantasy storyline.",
      developer: "Another",
      platform: "NES",
      genre: "Role-playing",
    },
    HostagesThe_Embassy_Mission: {
      description:
        "Hostages: The Embassy Mission is a strategy game. Players control a SWAT team, infiltrating an embassy to rescue hostages and eliminate terrorists. The game features strategic gameplay, planning, and intense missions.",
      developer: "Infogrames",
      platform: "NES",
      genre: "Strategy",
    },
    HOT09: {
      description:
        "HOT 09 is a sports game. Players control soccer teams, competing in matches and aiming to score goals. The game features soccer gameplay, team management, and competitive matches.",
      developer: "Bit Managers",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Hototogisu: {
      description:
        "Hototogisu is a simulation game. Players manage a sumo wrestling stable, training wrestlers and competing in sumo tournaments. The game features simulation gameplay, wrestler management, and strategic decisions.",
      developer: "KSS",
      platform: "NES",
      genre: "Simulation, Sumo wrestling",
    },
    Hottaaman_No_Chitei_Tanken: {
      description:
        "Hottaaman no Chitei Tanken is an action-adventure game. Players control a character, battling enemies and exploring levels in a jungle setting. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    Houma_Ga_Toki: {
      description:
        "Houma ga Toki is an action-adventure game. Players control a character, battling enemies and exploring levels in historical Japan. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Irem",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hudoumyouou_Den: {
      description:
        "Hudoumyouou Den is a role-playing game. Players control characters, embarking on a journey, battling enemies, and completing quests. The game features turn-based combat, exploration, and a fantasy storyline.",
      developer: "Kemco",
      platform: "NES",
      genre: "Role-playing",
    },
    Hudsons_Adventure_Island: {
      description:
        "Hudson's Adventure Island is a platformer game. Players control Master Higgins, navigating through levels, collecting items, and battling enemies. The game features platforming action, diverse enemies, and challenging levels.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Platformer",
    },
    Hudson_Hawk: {
      description:
        "Hudson Hawk is an action-adventure game. Players control Hudson Hawk, navigating through levels, battling enemies, and completing missions. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hunt_For_Red_October: {
      description:
        "The Hunt for Red October is a strategy game. Players control submarines, engaging in naval battles and strategic maneuvers. The game features submarine warfare, strategy, and intense naval combat.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Strategy, Naval combat",
    },
    Hushigina_Bloby: {
      description:
        "Hushigina Bloby is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Puzzle",
    },
    Hushigi_No_Umi_No_Nadia: {
      description:
        "Hushigi no Umi no Nadia is an action-adventure game. Players control Nadia, battling enemies and exploring levels in a maritime setting. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Namco",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hyakkiyakou: {
      description:
        "Hyakki Yakou is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Yonezawa PR21",
      platform: "NES",
      genre: "Puzzle",
    },
    Hyaku_No_Sekai_No_MonogatariThe_Tales_on_a_Watery_Wildern: {
      description:
        "Hyaku no Sekai no Monogatari: The Tales on a Watery Wilderness is an action-adventure game. Players control a character, battling enemies and exploring levels in a fantasy world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Random House",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hydlide3: {
      description:
        "Hydlide 3: Yami Kara no Houmonsha is an action role-playing game. Players control characters, battling enemies and exploring dungeons in a fantasy world. The game features action RPG gameplay, exploration, and a fantasy storyline.",
      developer: "T&E Soft",
      platform: "NES",
      genre: "Action RPG",
    },
    Hydlide_Special: {
      description:
        "Hydlide Special is an action-adventure game. Players control a character, battling enemies and exploring levels in a fantasy world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "T&E Soft",
      platform: "NES",
      genre: "Action-adventure",
    },
    Hyokkori_Hyoutan_JimaNazo_No_Kaizokusen: {
      description:
        "Hyokkori Hyoutan Jima: Nazo no Kaizokusen is an action game. Players control a character, battling enemies and navigating through levels in a pirate setting. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    Hyper_Olympic: {
      description:
        "Hyper Olympic is a sports game. Players compete in Olympic events, showcasing their skills in various sports. The game features sports gameplay, competitive events, and multiple challenges.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports, Olympic events",
    },
    Hyper_Sports: {
      description:
        "Hyper Sports is a sports game. Players compete in different sporting events, showcasing their skills in various disciplines. The game features sports gameplay, competitive events, and multiple challenges.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports, Various sports",
    },
    icehokey: {
      description:
        "Ice Hockey is a sports game. Players control hockey teams, competing in matches and aiming to score goals. The game features hockey gameplay, team management, and competitive matches.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Sports, Hockey",
    },
    Ice_Climber: {
      description:
        "Ice Climber is a platformer game. Players control characters, navigating through icy mountains, breaking blocks, and avoiding enemies. The game features platforming action, cooperative gameplay, and challenging levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    Ide_Yousuke_Meijin_No_Jissen_Maajan: {
      description:
        "Ide Yousuke Meijin no Jissen Mahjong is a mahjong game. Players engage in mahjong matches, using strategic moves and calculations to win. The game features mahjong gameplay, different rulesets, and competitive matches.",
      developer: "Home Data",
      platform: "NES",
      genre: "Mahjong",
    },
    Ide_Yousuke_Meijin_No_Jissen_Maajan2: {
      description:
        "Ide Yousuke Meijin no Jissen Mahjong II is a mahjong game. Players engage in mahjong matches, using strategic moves and calculations to win. The game features mahjong gameplay, different rulesets, and competitive matches.",
      developer: "Home Data",
      platform: "NES",
      genre: "Mahjong",
    },
    Idol_Hakkenden: {
      description:
        "Idol Hakkenden is an adventure game. Players control a character, navigating through a story, making decisions, and interacting with other characters. The game features visual novel-style gameplay, a branching narrative, and multiple endings.",
      developer: "IDP",
      platform: "NES",
      genre: "Adventure, Visual novel",
    },
    Idol_Shisen_Mahjong: {
      description:
        "Idol Shisen Mahjong is a mahjong game. Players engage in mahjong matches, using strategic moves and calculations to win. The game features mahjong gameplay, different rulesets, and competitive matches.",
      developer: "Home Data",
      platform: "NES",
      genre: "Mahjong",
    },
    Igo_Meikan: {
      description:
        "Igo Meikan is a board game. Players engage in Igo matches, strategizing and placing stones on a board to dominate territory. The game features Igo gameplay, different rulesets, and competitive matches.",
      developer: "Hect",
      platform: "NES",
      genre: "Board game, Igo",
    },
    Igo_Shinan: {
      description:
        "Igo Shinan is a board game. Players engage in Igo matches, strategizing and placing stones on a board to dominate territory. The game features Igo gameplay, different rulesets, and competitive matches.",
      developer: "Hect",
      platform: "NES",
      genre: "Board game, Igo",
    },
    Igo_Shinan_91: {
      description:
        "Igo Shinan '91 is a board game. Players engage in Igo matches, strategizing and placing stones on a board to dominate territory. The game features Igo gameplay, different rulesets, and competitive matches.",
      developer: "Hect",
      platform: "NES",
      genre: "Board game, Igo",
    },
    Igo_Shinan_93: {
      description:
        "Igo Shinan '93 is a board game. Players engage in Igo matches, strategizing and placing stones on a board to dominate territory. The game features Igo gameplay, different rulesets, and competitive matches.",
      developer: "Hect",
      platform: "NES",
      genre: "Board game, Igo",
    },
    Igo_Shinan_94: {
      description:
        "Igo Shinan '94 is a board game. Players engage in Igo matches, strategizing and placing stones on a board to dominate territory. The game features Igo gameplay, different rulesets, and competitive matches.",
      developer: "Hect",
      platform: "NES",
      genre: "Board game, Igo",
    },
    "Ikari (J)": {
      description:
        "Ikari is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "SNK",
      platform: "NES",
      genre: "Puzzle",
    },
    IKARI: {
      description:
        "Ikari is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "SNK",
      platform: "NES",
      genre: "Puzzle",
    },
    IKARI2: {
      description:
        "Ikari II: Dogosoken is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "SNK",
      platform: "NES",
      genre: "Action",
    },
    IKARI3: {
      description:
        "Ikari III: The Rescue is an action game. Players control characters, battling enemies in side-scrolling levels. The game features action gameplay, diverse characters, and challenging levels.",
      developer: "SNK",
      platform: "NES",
      genre: "Action",
    },
    Ike_Ike_Nekketsu_Hockey_BuSubette_Koronde_Dairantou: {
      description:
        "Ike! Ike! Nekketsu Hockey Bu: Subette Koronde Dairantou is a sports game. Players control hockey teams, competing in matches and aiming to score goals. The game features hockey gameplay, team management, and competitive matches.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Sports, Hockey",
    },
    Ikinari_Musician: {
      description:
        "Ikinari! Musician is a music game. Players control a musician, playing instruments and creating music. The game features music gameplay, rhythm challenges, and creative music composition.",
      developer: "Enix",
      platform: "NES",
      genre: "Music, Rhythm",
    },
    Ikki: {
      description:
        "Ikki is an action game. Players control characters, battling enemies in top-down levels. The game features action gameplay, diverse enemies, and challenging levels.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action",
    },
    Image_Fight: {
      description:
        "Image Fight is a scrolling shooter game. Players control spacecraft, battling enemies in vertically scrolling levels. The game features shooting gameplay, diverse enemies, and challenging levels.",
      developer: "Irem",
      platform: "NES",
      genre: "Shooter",
    },
    "Immortal,_The": {
      description:
        "The Immortal is an action-adventure game. Players control a wizard, navigating through levels, battling enemies, and solving puzzles. The game features isometric action, exploration, and challenging puzzles.",
      developer: "Sandcastle",
      platform: "NES",
      genre: "Action-adventure",
    },
    Impossible_Mission2: {
      description:
        "Impossible Mission II is an action-adventure game. Players control a secret agent, infiltrating an enemy base, avoiding traps, and solving puzzles. The game features side-scrolling action, exploration, and challenging puzzles.",
      developer: "Epyx",
      platform: "NES",
      genre: "Action-adventure",
    },
    Incredible_Crash_Dummies: {
      description:
        "The Incredible Crash Dummies is an action game. Players control crash test dummies, navigating through levels, avoiding hazards, and completing challenges. The game features platforming action, creative obstacles, and diverse levels.",
      developer: "LJN",
      platform: "NES",
      genre: "Action",
    },
    Indiana_Jones_and_the_Last_Crusade: {
      description:
        "Indiana Jones and the Last Crusade is an action-adventure game. Players control Indiana Jones, navigating through levels, battling enemies, and solving puzzles. The game features action gameplay, exploration, and challenging puzzles.",
      developer: "Software Creations",
      platform: "NES",
      genre: "Action-adventure",
    },
    Indiana_Jones_and_the_Temple_of_Doom: {
      description:
        "Indiana Jones and the Temple of Doom is an action-adventure game. Players control Indiana Jones, navigating through levels, battling enemies, and solving puzzles. The game features action gameplay, exploration, and challenging puzzles.",
      developer: "Nesoft",
      platform: "NES",
      genre: "Action-adventure",
    },
    Indora_No_Hikari: {
      description:
        "Indora no Hikari is an action-adventure game. Players control a character, battling enemies and exploring levels in a fantasy world. The game features side-scrolling action, exploration, and diverse challenges.",
      developer: "Koei",
      platform: "NES",
      genre: "Action-adventure",
    },
    Infiltrator: {
      description:
        "Infiltrator is a action-adventure game. Players control a spy, infiltrating enemy bases, avoiding security, and completing missions. The game features stealth action, exploration, and diverse challenges.",
      developer: "Chris Gray Enterprises",
      platform: "NES",
      genre: "Action-adventure",
    },
    Insector_X: {
      description:
        "Insector X is a scrolling shooter game. Players control insect-shaped spacecraft, battling enemies in horizontally scrolling levels. The game features shooting gameplay, diverse enemies, and challenging levels.",
      developer: "Hot-B",
      platform: "NES",
      genre: "Shooter",
    },
    InternationalCricket: {
      description:
        "International Cricket is a sports game. Players control cricket teams, competing in matches and aiming to score runs. The game features cricket gameplay, team management, and competitive matches.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Sports, Cricket",
    },
    IronswordWizards_and_Warriors2: {
      description:
        "Ironsword: Wizards & Warriors II is an action-adventure game. Players control a knight, battling enemies, navigating through levels, and solving puzzles. The game features action gameplay, exploration, and challenging puzzles.",
      developer: "Zippo Games",
      platform: "NES",
      genre: "Action-adventure",
    },
    IRONTANK: {
      description:
        "Irontank is a scrolling shooter game. Players control a tank, battling enemies in vertically scrolling levels. The game features shooting gameplay, diverse enemies, and challenging levels.",
      developer: "Sachen",
      platform: "NES",
      genre: "Shooter",
    },
    Isaki_Shugoro_No_Keiba_Hisshougaku: {
      description:
        "Isaki Shuugorou no Keiba Hisshougaku is a horse racing simulation game. Players participate in horse races, placing bets, managing their horses, and aiming to win races. The game features horse racing simulation, betting, and strategic decisions.",
      developer: "K Amusement Leasing",
      platform: "NES",
      genre: "Simulation, Horse racing",
    },
    island1: {
      description:
        "Island 1 is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Lakendary",
      platform: "NES",
      genre: "Puzzle",
    },
    island2: {
      description:
        "Island 2 is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Lakendary",
      platform: "NES",
      genre: "Puzzle",
    },
    island3: {
      description:
        "Island 3 is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Lakendary",
      platform: "NES",
      genre: "Puzzle",
    },
    island4: {
      description:
        "Island 4 is a puzzle game. Players control a character, navigating through levels, solving puzzles, and avoiding enemies. The game features puzzle-solving, maze navigation, and challenging levels.",
      developer: "Lakendary",
      platform: "NES",
      genre: "Puzzle",
    },
    ISOWARR: {
      description:
        "Isolated Warrior is a scrolling shooter game. Players control a character, battling enemies in horizontally scrolling levels. The game features shooting gameplay, diverse enemies, and challenging levels.",
      developer: "KID",
      platform: "NES",
      genre: "Shooter",
    },
    Itadaki_StreetWatashi_No_Mise_Ni_Yottette: {
      description:
        "Itadaki Street: Watashi no Mise ni Yottette is a board game simulation. Players compete in a board game, buying properties, investing, and aiming to accumulate wealth. The game features board game gameplay, strategy, and economic simulation.",
      developer: "Tose",
      platform: "NES",
      genre: "Board game, Simulation",
    },
    I_Love_Softball: {
      description:
        "I Love Softball is a sports game. Players control softball teams, competing in matches and aiming to score runs. The game features softball gameplay, team management, and competitive matches.",
      developer: "ASCII",
      platform: "NES",
      genre: "Sports, Softball",
    },
    jackal: {
      description:
        "Jackal is a run and gun game. Players control military vehicles, battling enemies and rescuing prisoners of war. The game features top-down action, cooperative gameplay, and intense battles.",
      developer: "Konami",
      platform: "NES",
      genre: "Run and gun",
    },
    Jackie_Chan: {
      description:
        "Jackie Chan's Action Kung Fu is a platformer game. Players control Jackie Chan, battling enemies and navigating through levels. The game features platforming action, martial arts moves, and challenging levels.",
      developer: "Now Production",
      platform: "NES",
      genre: "Platformer, Martial arts",
    },
    JACKY: {
      description: "Not Available",
      developer: "VREELAND",
      platform: "NES",
      genre: "Action",
    },
    Jack_Nicklaus_Greatest_18_Holes_of_Championship_Golf: {
      description:
        "Golf simulation game featuring Jack Nicklaus, one of the greatest golfers.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports, Golf",
    },
    jafy: {
      description: "Not Available",
      developer: "WAVE",
      platform: "NES",
      genre: "Not Available",
    },
    JajamaruNinpou_Chou: {
      description:
        "Action game where players control a ninja battling enemies.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action",
    },
    Jajamaru_GekimadenMaboroshi_No_Kinmajou: {
      description:
        "Action game featuring the character Jajamaru. Players navigate through levels defeating enemies.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action",
    },
    Jajamaru_No_Daibouken: {
      description:
        "Action-adventure game where players control Jajamaru on a quest to rescue a kidnapped princess.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Jambo_Ozaki_No_Hole_in_One_Professional: {
      description: "Golf simulation game featuring professional golfer Ozaki.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Sports, Golf",
    },
    James_Bond_Jr: {
      description:
        "Action game based on the animated TV series. Players control James Bond Jr. on various missions.",
      developer: "Eurocom",
      platform: "NES",
      genre: "Action",
    },
    Jangou: {
      description: "Traditional Japanese card game simulation.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Card, Tabletop",
    },
    Jarinko_Chie: {
      description:
        "Based on the manga and anime series Jarinko Chie. Players control Chie and solve puzzles.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Puzzle, Adventure",
    },
    JAWS: {
      description:
        "Action-adventure game based on the movie Jaws. Players hunt down the great white shark.",
      developer: "Westone Bit Entertainment",
      platform: "NES",
      genre: "Action, Adventure",
    },
    jdsecj: {
      description: "Not Available",
      developer: "UNKNWON",
      platform: "NES",
      genre: "Not Available",
    },
    Jeopardy: {
      description:
        "Game show simulation where players answer trivia questions.",
      developer: "GameTek",
      platform: "NES",
      genre: "Trivia, Game Show",
    },
    Jeopardy25th_Anniversary_Edition: {
      description:
        "Trivia game based on the TV show Jeopardy! with questions from the show's 25-year history.",
      developer: "RARE",
      platform: "NES",
      genre: "Trivia, Game Show",
    },
    Jeopardy_Junior_Edition: {
      description:
        "Trivia game designed for younger players. Features age-appropriate questions.",
      developer: "RARE",
      platform: "NES",
      genre: "Trivia, Game Show",
    },
    Jesus: {
      description: "Religious-themed game based on the life of Jesus Christ.",
      developer: "Nexa",
      platform: "NES",
      genre: "Religious, Adventure",
    },
    JETSON: {
      description:
        "Action-adventure game based on the animated TV series The Jetsons. Players control George Jetson.",
      developer: "Taito",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Jewelry: {
      description:
        "Puzzle game where players match colored jewels to score points.",
      developer: "Hect",
      platform: "NES",
      genre: "Puzzle",
    },
    Jigoku_Gokuraku_Maru: {
      description:
        "Action game where players control a ninja battling enemies.",
      developer: "Konami",
      platform: "NES",
      genre: "Action",
    },
    Jikuu_YuudenDebias: {
      description:
        "Action game where players control a character fighting through various levels.",
      developer: "Kadokawa",
      platform: "NES",
      genre: "Action",
    },
    JJTobidase_Daisakusen_Part2: {
      description:
        "Platformer game featuring a character navigating through levels and avoiding obstacles.",
      developer: "Tokuma Shoten",
      platform: "NES",
      genre: "Platformer",
    },
    JJZS: {
      description:
        "Action game where players control a character fighting enemies.",
      developer: "Konami",
      platform: "NES",
      genre: "Action",
    },
    Joe_and_Mac: {
      description:
        "Platformer game where players control cavemen on a quest to rescue cavewomen.",
      developer: "Data East",
      platform: "NES",
      genre: "Platformer",
    },
    John_Elways_Quarterback: {
      description:
        "Football simulation game featuring NFL player John Elway. Players control a football team.",
      developer: "Tradewest",
      platform: "NES",
      genre: "Sports, Football",
    },
    Jongbou: {
      description: "Traditional Mahjong game simulation.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Card, Tabletop",
    },
    Jordan_Vs_BirdOne_On_One: {
      description:
        "Basketball simulation game featuring Michael Jordan and Larry Bird. Players compete in one-on-one matches.",
      developer: "Atlus",
      platform: "NES",
      genre: "Sports, Basketball",
    },
    Joshua: {
      description: "Biblical-themed game based on the story of Joshua.",
      developer: "Commin",
      platform: "NES",
      genre: "Religious, Adventure",
    },
    JOURNEY: {
      description:
        "Adventure game where players control a character on a quest to find the mystical Sphere of Light.",
      developer: "Data East",
      platform: "NES",
      genre: "Adventure",
    },
    Joust: {
      description:
        "Action game where players control a knight riding an ostrich and defeat enemy knights.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Action",
    },
    Jovei_Quest: {
      description:
        "Role-playing game where players control a party of characters on a quest to save the world.",
      developer: "Epoch",
      platform: "NES",
      genre: "RPG",
    },
    Jovial_Race: {
      description:
        "Racing game where players control a car and compete in various tracks.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Racing",
    },
    Joy_Mech_Fight: {
      description:
        "Fighting game featuring robots. Players control robots in battles against other robots.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Fighting",
    },
    jsoccer: {
      description:
        "Soccer simulation game. Players control a soccer team and compete in matches.",
      developer: "KID",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Juju_Densetsu: {
      description:
        "Action-adventure game where players control a character exploring dungeons and battling monsters.",
      developer: "Chunsoft",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Jumpin_KidJappu_To_Mame_No_Ki_Monogatari: {
      description:
        "Platformer game where players control a character jumping through levels.",
      developer: "Irem",
      platform: "NES",
      genre: "Platformer",
    },
    Jungle_Book: {
      description:
        "Action game based on Disney's The Jungle Book movie. Players control Mowgli in various jungle-themed levels.",
      developer: "Virgin Interactive",
      platform: "NES",
      genre: "Action",
    },
    JURASSIC: {
      description:
        "Action-adventure game where players control a dinosaur exploring prehistoric environments.",
      developer: "ICOM Simulations",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Just_Breed: {
      description:
        "Role-playing game where players control a party of characters on a quest to defeat an evil emperor.",
      developer: "Enix",
      platform: "NES",
      genre: "RPG",
    },
    J_League_Fighting_SoccerThe_King_of_Ace_Strikers: {
      description:
        "Soccer simulation game featuring the J-League teams. Players control soccer players in matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    J_League_Winning_Goal: {
      description:
        "Soccer simulation game featuring the J-League teams. Players control soccer players in matches.",
      developer: "Data East",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    KABUKIQF: {
      description:
        "Fighting game featuring kabuki theater characters. Players engage in battles against opponents.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Fighting",
    },
    Kabushiki_Doujou: {
      description:
        "Simulation game where players manage a stock trading company.",
      developer: "Yonezawa PR21",
      platform: "NES",
      genre: "Simulation",
    },
    Kaettekita_Gunjin_Shougi: {
      description: "Traditional Japanese chess (shogi) game simulation.",
      developer: "Data East",
      platform: "NES",
      genre: "Board, Tabletop",
    },
    Kage: {
      description:
        "Action game where players control a ninja battling enemies.",
      developer: "Natsume",
      platform: "NES",
      genre: "Action",
    },
    Kagerou_Densetsu: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    Kage_No_Densetsu: {
      description:
        "Action game where players control a ninja battling enemies.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    Kaguya_Hime_Densetsu: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Victor Interactive Software",
      platform: "NES",
      genre: "Action",
    },
    Kaijuu_Monogatari: {
      description:
        "Role-playing game where players control a character on a quest to save a fantasy world.",
      developer: "Namco",
      platform: "NES",
      genre: "RPG",
    },
    Kaiketsu_Yanchamaru: {
      description:
        "Action-adventure game where players control a ninja battling enemies.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kaiketsu_Yanchamaru2Karakuri_Land: {
      description:
        "Action-adventure game where players control a ninja exploring levels and battling enemies.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kaiketsu_Yanchamaru3Taiketsu_Zouringen: {
      description:
        "Action-adventure game featuring the character Yanchamaru. Players battle enemies in various levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kakefu_Kun_No_Jump_TengokuSpeed_Jigoku: {
      description:
        "Platformer game where players control a character jumping through levels.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    Kamen_No_NinjaHanamaru: {
      description:
        "Action-adventure game where players control a ninja battling enemies.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kamen_Rider_Kurabu: {
      description:
        "Adventure game where players control a character exploring various environments.",
      developer: "Bandai",
      platform: "NES",
      genre: "Adventure",
    },
    Kamen_Rider_SDGuranshukkaa_No_Yabou: {
      description:
        "Action-adventure game featuring the character Kamen Rider. Players battle enemies in side-scrolling levels.",
      developer: "Yutaka",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kame_No_OngaeshiUrashima_Densetsu: {
      description:
        "Adventure game based on the Japanese folk tale of Urashima Taro. Players control the protagonist on a quest.",
      developer: "Konami",
      platform: "NES",
      genre: "Adventure",
    },
    kamikaze: {
      description:
        "Action game where players control a plane and engage in aerial combat.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    kamikaze2: {
      description:
        "Action game where players control a plane and engage in aerial combat.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    kamikaze3: {
      description:
        "Action game where players control a plane and engage in aerial combat.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    Karakuri_KendoudenMusashi_Road: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Takara",
      platform: "NES",
      genre: "Action",
    },
    Karateka: {
      description:
        "Fighting game where players control a karate master fighting enemies to rescue a princess.",
      developer: "Broderbund",
      platform: "NES",
      genre: "Fighting",
    },
    Karate_Champ: {
      description:
        "Fighting game where players engage in karate matches against opponents.",
      developer: "Data East",
      platform: "NES",
      genre: "Fighting",
    },
    KARATKID: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    KARNOV: {
      description:
        "Action game where players control a character battling enemies in side-scrolling levels.",
      developer: "Data East",
      platform: "NES",
      genre: "Action",
    },
    kati: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Katteni_Shirokuma: {
      description:
        "Puzzle game where players move blocks to solve puzzles and progress through levels.",
      developer: "Konami",
      platform: "NES",
      genre: "Puzzle",
    },
    Kawa_No_Nushi_Tsuri: {
      description:
        "Fishing simulation game where players catch various fish species in different locations.",
      developer: "Pax Softnica",
      platform: "NES",
      genre: "Sports, Fishing",
    },
    Keroppi_and_Keroleens_Splash_Bomb: {
      description:
        "Action game where players control characters throwing water balloons at enemies.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Action",
    },
    Keroppi_To_Keroriinu_No_Splash_Bomb: {
      description:
        "Action game featuring characters throwing water balloons at enemies.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Action",
    },
    Kero_Kero_Keroppis_Big_Adventure: {
      description:
        "Action game where players control characters navigating through levels and avoiding obstacles.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Action",
    },
    Kero_Kero_Keroppis_Great_Big_Adventure2: {
      description:
        "Action game where players control characters navigating through levels and avoiding obstacles.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Action",
    },
    Kero_Kero_Keroppi_No_Daibouken: {
      description:
        "Action-adventure game featuring the character Keroppi. Players explore levels and battle enemies.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kero_Kero_Keroppi_No_Daibouken2: {
      description:
        "Action-adventure game featuring the character Keroppi. Players explore levels and battle enemies.",
      developer: "Character Soft",
      platform: "NES",
      genre: "Action, Adventure",
    },
    kevtris_Reset_Based_Four_in_One: {
      description: "Compilation cartridge featuring four different games.",
      developer: "Caltron",
      platform: "NES",
      genre: "Various",
    },
    Kickle_Cubicle: {
      description:
        "Puzzle game where players control a character freezing enemies and solving puzzles.",
      developer: "Irem",
      platform: "NES",
      genre: "Puzzle",
    },
    KICKMAST: {
      description:
        "Soccer simulation game. Players control a soccer team and compete in matches.",
      developer: "Taito",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Kick_Off: {
      description:
        "Soccer simulation game. Players control a soccer team and compete in matches.",
      developer: "Anco Software",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    KIDICAR: {
      description:
        "Racing game where players control toy cars and race on various tracks.",
      developer: "Loriciel",
      platform: "NES",
      genre: "Racing",
    },
    Kid_Klown: {
      description:
        "Platformer game where players control Kid Klown through levels and avoid obstacles.",
      developer: "Kemco",
      platform: "NES",
      genre: "Platformer",
    },
    Kid_Kool: {
      description:
        "Platformer game where players control Kid Kool through levels and avoid obstacles.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Platformer",
    },
    Kid_NikiRadical_Ninja: {
      description:
        "Platformer game where players control Kid Niki and defeat enemies.",
      developer: "Data East",
      platform: "NES",
      genre: "Platformer",
    },
    "King of Kings": {
      description: "Biblical-themed game based on the life of Jesus Christ.",
      developer: "Wisdom Tree",
      platform: "NES",
      genre: "Religious, Adventure",
    },
    kingkong2: {
      description:
        "Platformer game where players control King Kong and navigate through levels.",
      developer: "Konami",
      platform: "NES",
      genre: "Platformer",
    },
    kingring: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    KINGSKNI: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Atlus",
      platform: "NES",
      genre: "Action",
    },
    Kings_Knight: {
      description:
        "Action-adventure game featuring four characters on a quest to save a princess.",
      developer: "Square",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kings_Quest_V: {
      description:
        "Adventure game where players control a character on a quest to rescue a kidnapped royal family.",
      developer: "Sierra On-Line",
      platform: "NES",
      genre: "Adventure",
    },
    King_Kong2Ikari_No_Megaton_Punch: {
      description:
        "Platformer game where players control King Kong and navigate through levels.",
      developer: "Konami",
      platform: "NES",
      genre: "Platformer",
    },
    King_Neptunes_Adventure: {
      description:
        "Action-adventure game where players control a character exploring underwater environments.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Action, Adventure",
    },
    King_of_Kings: {
      description: "Biblical-themed game based on the life of Jesus Christ.",
      developer: "Wisdom Tree",
      platform: "NES",
      genre: "Religious, Adventure",
    },
    kiniku: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    KinnikumanMuscle_Tag_Match: {
      description:
        "Wrestling game featuring characters from the Kinnikuman anime and manga series.",
      developer: "Bandai",
      platform: "NES",
      genre: "Sports, Wrestling",
    },
    KIRBYADV: {
      description:
        "Action-adventure game featuring the character Kirby. Players explore levels and battle enemies.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kiteretsu_Daihyakka: {
      description:
        "Adventure game based on the Kiteretsu Daihyakka anime and manga series. Players solve puzzles and progress through the story.",
      developer: "Tomy",
      platform: "NES",
      genre: "Adventure",
    },
    Kiwi_Kraze: {
      description:
        "Action game where players control a character exploring levels and defeating enemies.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    Klash_Ball: {
      description:
        "Sports game where players control a character in futuristic sports matches.",
      developer: "SOFEL",
      platform: "NES",
      genre: "Sports, Futuristic",
    },
    Klax: {
      description:
        "Puzzle game where players align colored tiles to score points.",
      developer: "Tengen",
      platform: "NES",
      genre: "Puzzle",
    },
    Knight_Rider: {
      description:
        "Action-adventure game based on the TV series Knight Rider. Players control KITT, the high-tech car.",
      developer: "Pack-In-Video",
      platform: "NES",
      genre: "Action, Adventure",
    },
    kof96: {
      description:
        "Fighting game featuring characters from the King of Fighters series.",
      developer: "SNK",
      platform: "NES",
      genre: "Fighting",
    },
    KONAMI: {
      description: "Compilation cartridge featuring multiple Konami games.",
      developer: "Konami",
      platform: "NES",
      genre: "Various",
    },
    Konami2: {
      description: "Compilation cartridge featuring multiple Konami games.",
      developer: "Konami",
      platform: "NES",
      genre: "Various",
    },
    Konamic_Sports_in_Seoul: {
      description:
        "Sports simulation game featuring events from the 1988 Seoul Olympics.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports, Olympics",
    },
    konamisp: {
      description: "Compilation cartridge featuring multiple Konami games.",
      developer: "Konami",
      platform: "NES",
      genre: "Various",
    },
    Konami_Hyper_Soccer: {
      description:
        "Soccer simulation game featuring international teams. Players compete in soccer matches.",
      developer: "Konami",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Koushien: {
      description:
        "Baseball simulation game featuring high school baseball teams. Players compete in baseball matches.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Krazy_Kreatures: {
      description: "Puzzle game where players match creatures to score points.",
      developer: "American Video Entertainment",
      platform: "NES",
      genre: "Puzzle",
    },
    Krion_Conquest: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Action",
    },
    Krustys_Fun_House: {
      description:
        "Puzzle game where players guide mice to eliminate pests in a fun house.",
      developer: "Fox Williams",
      platform: "NES",
      genre: "Puzzle",
    },
    Kujaku_Ou: {
      description:
        "Action-adventure game based on the manga and anime series Peacock King. Players control a character exploring levels and battling enemies.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kujaku_Ou2: {
      description:
        "Action-adventure game based on the manga and anime series Peacock King. Players control a character exploring levels and battling enemies.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kung_Fu_Heroes: {
      description:
        "Action game where players control characters battling enemies in various levels.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action",
    },
    Kunio_Kun_No_Jidaigekidayo_Zenin_Shuugou: {
      description:
        "Action-adventure game where players control Kunio on a quest to save his girlfriend.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Kunio_Kun_No_Nekketsu_Soccer_League: {
      description:
        "Soccer simulation game featuring characters from the Kunio-kun series. Players control soccer players in matches.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    Kurogane_Hiroshi_No_Yosou_Daisuki_Keiba_Densetsu: {
      description:
        "Horse racing simulation game where players bet on horse races and manage their stable.",
      developer: "ASCII",
      platform: "NES",
      genre: "Sports, Horse Racing",
    },
    Kyonshiizu2: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "Zemina",
      platform: "NES",
      genre: "Action",
    },
    Kyoro_Chan_Land: {
      description:
        "Platformer game where players control Kyorochan through levels and avoid obstacles.",
      developer: "Konami",
      platform: "NES",
      genre: "Platformer",
    },
    Kyouryuu_Sentai_Juranger: {
      description:
        "Action game based on the Super Sentai series Kyoryu Sentai Zyuranger. Players control characters battling enemies.",
      developer: "Angel",
      platform: "NES",
      genre: "Action",
    },
    Kyuukyoku_Harikiri_Koushien: {
      description:
        "Baseball simulation game featuring high school baseball teams. Players compete in baseball matches.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Kyuukyoku_Harikiri_Stadium3: {
      description:
        "Baseball simulation game featuring high school baseball teams. Players compete in baseball matches.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Kyuukyoku_Harikiri_StadiumHeisei_Gannen_Ban: {
      description:
        "Baseball simulation game featuring high school baseball teams. Players compete in baseball matches.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Kyuukyoku_Harikiri_Stadium_88: {
      description:
        "Baseball simulation game featuring high school baseball teams. Players compete in baseball matches.",
      developer: "Taito",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Kyuukyoku_Tiger: {
      description:
        "Shoot 'em up game where players control a ship and defeat enemies.",
      developer: "Taito",
      platform: "NES",
      genre: "Shooter",
    },
    Labyrinth: {
      description:
        "Action game where players control a character navigating through maze-like levels.",
      developer: "Acclaim Entertainment",
      platform: "NES",
      genre: "Action",
    },
    Last_Action_Hero: {
      description:
        "Action game based on the movie Last Action Hero. Players control the protagonist in various action-packed levels.",
      developer: "Psygnosis",
      platform: "NES",
      genre: "Action",
    },
    Last_Armageddon: {
      description:
        "Role-playing game where players control a party of characters on a quest to save the world.",
      developer: "Kemco",
      platform: "NES",
      genre: "RPG",
    },
    Last_Ninja: {
      description:
        "Action-adventure game where players control a ninja on a quest to defeat enemies and bosses.",
      developer: "Activision",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Last_Starfighter: {
      description:
        "Shoot 'em up game where players control a spaceship and engage in space battles.",
      developer: "LJN",
      platform: "NES",
      genre: "Shooter",
    },
    Law_of_the_West: {
      description:
        "Action game where players assume the role of a sheriff and engage in duels and decision-making.",
      developer: "Accolade",
      platform: "NES",
      genre: "Action",
    },
    Layla: {
      description:
        "Action-adventure game where players control a character on a quest to rescue a kidnapped girl.",
      developer: "Hect",
      platform: "NES",
      genre: "Action, Adventure",
    },
    LEE: {
      description:
        "Fighting game where players control a martial artist battling enemies.",
      developer: "SNK",
      platform: "NES",
      genre: "Fighting",
    },
    Legends_of_the_Diamond: {
      description:
        "Baseball simulation game featuring MLB teams and players. Players compete in baseball matches.",
      developer: "Bandai",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Legend_of_Kage: {
      description:
        "Action game where players control a ninja battling enemies in various environments.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    Legend_of_Prince_Valiant: {
      description:
        "Action-adventure game based on the Prince Valiant comic strip. Players control Prince Valiant on a quest to rescue his bride.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Lemmings: {
      description:
        "Puzzle game where players guide lemmings to safety by assigning them specific tasks.",
      developer: "Psygnosis",
      platform: "NES",
      genre: "Puzzle",
    },
    LEmpereur: {
      description:
        "Historical strategy game set in ancient China. Players control warlords and engage in battles and diplomacy.",
      developer: "KOEI",
      platform: "NES",
      genre: "Strategy",
    },
    les_chevaliers_du_zodiaque: {
      description:
        "Action game based on the Saint Seiya anime and manga series. Players control characters battling enemies.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action",
    },
    Lethal_Weapon: {
      description:
        "Action game based on the movie Lethal Weapon. Players control the protagonists in various action sequences.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action",
    },
    LGNDWNGS: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    LIFEFORC: {
      description:
        "Shoot 'em up game and a spin-off of the Gradius series. Players control a ship and defeat enemies.",
      developer: "Konami",
      platform: "NES",
      genre: "Shooter",
    },
    Linus_Spaceheads_Cosmic_Crusade: {
      description:
        "Action-adventure game where players control a character on a quest to save his planet.",
      developer: "Codemasters",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Lion_King: {
      description:
        "Platformer game based on the Disney animated movie The Lion King. Players control Simba through levels.",
      developer: "Virgin Interactive",
      platform: "NES",
      genre: "Platformer",
    },
    Lipple_Island: {
      description:
        "Puzzle game where players guide creatures to eliminate pests in an island setting.",
      developer: "SNK",
      platform: "NES",
      genre: "Puzzle",
    },
    LITLNEMO: {
      description:
        "Platformer game based on the Little Nemo comic strip. Players control Nemo on a quest to rescue Princess Camille.",
      developer: "Capcom",
      platform: "NES",
      genre: "Platformer",
    },
    Litmer: {
      description:
        "Puzzle game where players guide fireflies through mazes to light up the path.",
      developer: "IREM",
      platform: "NES",
      genre: "Puzzle",
    },
    Little_Magic: {
      description:
        "Action-adventure game where players control a character exploring levels and battling enemies.",
      developer: "SNK",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Little_MermaidOu_Hime: {
      description:
        "Action game based on the Disney animated movie The Little Mermaid. Players control Ariel on a quest to rescue Prince Eric.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action",
    },
    Little_Ninja_Brothers: {
      description:
        "Action-adventure game where players control ninja characters battling enemies in various environments.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Little_Samson: {
      description:
        "Action-adventure game where players control characters with different abilities on a quest to save the kingdom.",
      developer: "Taito",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Live_in_Power_BowlTM_Network: {
      description:
        "Bowling simulation game where players control characters and compete in bowling matches.",
      developer: "SETA Corporation",
      platform: "NES",
      genre: "Sports, Bowling",
    },
    Li_Ma_Shin_Dian: {
      description:
        "Action game where players control characters battling enemies.",
      developer: "Gamtec",
      platform: "NES",
      genre: "Action",
    },
    Lode_Runner: {
      description:
        "Puzzle game where players collect gold and avoid enemies in maze-like levels.",
      developer: "Broderbund",
      platform: "NES",
      genre: "Puzzle",
    },
    LOLO2: {
      description:
        "Puzzle game where players move blocks to solve puzzles and progress through levels.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Puzzle",
    },
    LOLO3: {
      description:
        "Puzzle game where players move blocks to solve puzzles and progress through levels.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Puzzle",
    },
    LONERNGR: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Loopz: {
      description:
        "Puzzle game where players connect tiles to create loops and clear them from the screen.",
      developer: "Mindscape",
      platform: "NES",
      genre: "Puzzle",
    },
    Lord_of_King: {
      description:
        "Strategy game where players control characters and battle enemies in turn-based combat.",
      developer: "Jaleco Entertainment",
      platform: "NES",
      genre: "Strategy",
    },
    Lost_Word_of_Jenny: {
      description:
        "Educational game where players learn and practice spelling and vocabulary.",
      developer: "Seta",
      platform: "NES",
      genre: "Educational",
    },
    Lot_Lot: {
      description:
        "Puzzle game where players match colors and shapes to progress through levels.",
      developer: "Idea-Tek",
      platform: "NES",
      genre: "Puzzle",
    },
    LOWGMAN: {
      description:
        "Action game where players control a character battling enemies.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Action",
    },
    Lunar_Ball: {
      description:
        "Sports simulation game featuring lunar soccer matches. Players control teams in soccer matches on the moon.",
      developer: "Victor Interactive Software",
      platform: "NES",
      genre: "Sports, Soccer",
    },
    lunar_pool: {
      description:
        "Sports simulation game featuring pool matches set on the moon. Players compete in pool games.",
      developer: "Compile",
      platform: "NES",
      genre: "Sports, Billiards",
    },
    Lupin_SanseiPandora_No_Isan: {
      description:
        "Action-adventure game based on the Lupin III anime series. Players control Lupin and his companions on a quest to recover a treasure.",
      developer: "Namco",
      platform: "NES",
      genre: "Action, Adventure",
    },
    lyxz: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Maajan: {
      description:
        "Mahjong simulation game where players play mahjong matches against computer opponents.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Board Game, Mahjong",
    },
    Maajan_KurabuNagatachou: {
      description:
        "Mahjong simulation game where players play mahjong matches against computer opponents.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Board Game, Mahjong",
    },
    Maajan_RPG_Dora_Dora_Dora: {
      description:
        "Mahjong simulation game with RPG elements. Players battle opponents in mahjong matches to progress through the story.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Board Game, Mahjong, RPG",
    },
    Maajan_Taikai: {
      description:
        "Mahjong tournament game where players compete in mahjong matches against computer opponents.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Board Game, Mahjong",
    },
    Maajan_Taisen: {
      description:
        "Mahjong tournament game where players compete in mahjong matches against computer opponents.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Board Game, Mahjong",
    },
    macross: {
      description:
        "Shoot 'em up game based on the Macross anime series. Players control transforming mechs and engage in space battles.",
      developer: "Namco",
      platform: "NES",
      genre: "Shooter",
    },
    MAFATCON: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    MagicalMathematics: {
      description:
        "Educational game where players learn math concepts and solve math problems.",
      developer: "Sachen",
      platform: "NES",
      genre: "Educational",
    },
    Magical_Doropie: {
      description:
        "Action-adventure game where players control a character exploring levels and battling enemies.",
      developer: "Vic Tokai",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Magical_Taruruuto_Kun2Mahou_Daibouken: {
      description:
        "Action-adventure game based on the Magical Taruruuto-kun anime and manga series. Players control a character exploring levels and battling enemies.",
      developer: "K Amusement Leasing Co.",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Magical_Taruruuto_KunFantastic_World: {
      description:
        "Action-adventure game based on the Magical Taruruuto-kun anime and manga series. Players control a character exploring levels and battling enemies.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action, Adventure",
    },
    MAGICIAN: {
      description:
        "Action-adventure game where players control a magician exploring levels and battling enemies.",
      developer: "Taxan",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Magicofs: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Magic_Block: {
      description:
        "Puzzle game where players clear blocks from the screen by connecting them in squares of the same color.",
      developer: "DSG",
      platform: "NES",
      genre: "Puzzle",
    },
    Magic_Candle: {
      description:
        "Role-playing game where players control a party of characters on a quest to save a kingdom.",
      developer: "Mindcraft",
      platform: "NES",
      genre: "RPG",
    },
    Magic_Darts: {
      description:
        "Darts simulation game where players compete in darts matches.",
      developer: "Romstar",
      platform: "NES",
      genre: "Sports, Darts",
    },
    Magmax: {
      description:
        "Shoot 'em up game where players control a spaceship and defeat enemies.",
      developer: "Nihon Bussan",
      platform: "NES",
      genre: "Shooter",
    },
    Magnum_Kikiippatsu: {
      description:
        "Puzzle game where players guide a bomb to the exit by placing arrows to direct its path.",
      developer: "Nichibutsu",
      platform: "NES",
      genre: "Puzzle",
    },
    Maharaja: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Mahjang_Companion: {
      description:
        "Mahjong simulation game where players play mahjong matches against computer opponents.",
      developer: "Hacker International",
      platform: "NES",
      genre: "Board Game, Mahjong",
    },
    Mahou_No_Princess_Minky_Momo: {
      description:
        "Action-adventure game based on the Magical Princess Minky Momo anime and manga series. Players control a character exploring levels and battling enemies.",
      developer: "Victor Interactive Software",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Maison_Ikkoku: {
      description:
        "Adventure game based on the Maison Ikkoku anime and manga series. Players solve puzzles and progress through the story.",
      developer: "SNK",
      platform: "NES",
      genre: "Adventure",
    },
    MajaventureMaajan_Senki: {
      description:
        "Mahjong simulation game where players play mahjong matches against computer opponents.",
      developer: "American Video Entertainment",
      platform: "NES",
      genre: "Board Game, Mahjong",
    },
    Major_League: {
      description:
        "Baseball simulation game featuring MLB teams and players. Players compete in baseball matches.",
      developer: "LJN",
      platform: "NES",
      genre: "Sports, Baseball",
    },
    Majou_Densetsu2Daimashikyou_Galious: {
      description:
        "Action-adventure game where players control a character exploring levels and battling enemies.",
      developer: "Konami",
      platform: "NES",
      genre: "Action, Adventure",
    },
    Makaimura: {
      description:
        "Action game where players control a knight battling enemies and bosses.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action",
    },
    Maniac_Mansion: {
      description:
        "Graphic adventure game where players control a group of characters exploring a mysterious mansion.",
      developer: "Jaleco Entertainment",
      platform: "NES",
      genre: "Adventure",
    },
    mao: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Mappy: {
      description:
        "Action game where players control a mouse named Mappy who must retrieve stolen goods from cats in a mansion.",
      developer: "Namco",
      platform: "NES",
      genre: "Action",
    },
    Mappy_Kids: {
      description:
        "Platformer game where players control a mouse character navigating levels and avoiding enemies.",
      developer: "Namco",
      platform: "NES",
      genre: "Platformer",
    },
    Mappy_Land: {
      description:
        "Platformer game featuring Mappy, a mouse character, navigating levels and avoiding enemies.",
      developer: "Namco",
      platform: "NES",
      genre: "Platformer",
    },
    Marble_Madness: {
      description:
        "Puzzle game where players guide a marble through obstacle courses to reach the goal.",
      developer: "Atari Games",
      platform: "NES",
      genre: "Puzzle",
    },
    MARIO: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    Mario16: {
      description:
        "Platformer game featuring Mario, the iconic Nintendo character, navigating levels and defeating enemies.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    MARIO2: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    MARIO3: {
      description: "Not Available",
      developer: "UNKNOWN",
      platform: "NES",
      genre: "Not Available",
    },
    MARIOBRO: {
      description:
        "Platformer game featuring Mario and Luigi, the iconic Nintendo characters, navigating levels and defeating enemies.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },

    MARIOMIS: {
      description:
        "Platform game featuring Mario, the iconic video game character from Nintendo.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    Mario_Bros: {
      description:
        "Arcade-style platform game featuring Mario and Luigi, the iconic video game characters from Nintendo.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Arcade",
    },
    Mario_Diapers: {
      description:
        "Educational game designed for young children featuring Mario, the iconic video game character from Nintendo.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Educational",
    },
    Mario_Different_Levels: {
      description:
        "Platform game featuring Mario, the iconic video game character from Nintendo, with different levels and challenges.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Platformer",
    },
    Mario_Open_Golf: {
      description:
        "Golf simulation game featuring Mario, the iconic video game character from Nintendo.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Sports",
    },
    Mario_Sonic_Bros: {
      description:
        "Crossover game featuring Mario and Sonic, two iconic video game characters from Nintendo and Sega, in a platformer adventure.",
      developer: "Nintendo, Sega",
      platform: "NES",
      genre: "Platformer",
    },
    Mari_Street_Fighter3_Turbo: {
      description:
        "Crossover fighting game featuring characters from the Mario series and Street Fighter series.",
      developer: "Nintendo, Capcom",
      platform: "NES",
      genre: "Fighting",
    },
    Marusa_No_Onna: {
      description:
        "Adventure game featuring a female protagonist navigating various challenges.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Mashou: {
      description:
        "Action-adventure game where players embark on a magical journey, battling enemies and solving puzzles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Mastrchu: {
      description:
        "Puzzle game where players arrange falling blocks to create complete lines and clear the screen.",
      developer: "Unknown",
      platform: "NES",
      genre: "Puzzle",
    },
    Masuzoe_YouichiAsamade_Famicon: {
      description: "Unknown game featuring the character Masuzoe Youichi.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    MATENDOJ: {
      description:
        "Educational game designed to teach mathematics concepts to players.",
      developer: "Unknown",
      platform: "NES",
      genre: "Educational",
    },
    Matendouji: {
      description:
        "Puzzle game where players solve mathematical puzzles and challenges.",
      developer: "Unknown",
      platform: "NES",
      genre: "Puzzle",
    },
    Matsumoto_Tooru_No_Kabushiki_Hisshougaku: {
      description: "Unknown game featuring the character Matsumoto Tooru.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Matsumoto_Tooru_No_Kabushiki_Hisshougaku2: {
      description: "Unknown game featuring the character Matsumoto Tooru.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    maxi15au: {
      description: "Unknown game with the title 'maxi15au'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Max_WarriorWakusei_Kaigenrei: {
      description:
        "Action game set in a futuristic world where players control a character battling enemies.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    MCKIDS: {
      description:
        "Platform game featuring characters from McDonald's, where players navigate various levels and challenges.",
      developer: "Virgin Games",
      platform: "NES",
      genre: "Platformer",
    },
    mdsym: {
      description: "Unknown game with the title 'mdsym'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    MECHANIZ: {
      description:
        "Puzzle game where players manipulate mechanisms and solve puzzles to progress.",
      developer: "Unknown",
      platform: "NES",
      genre: "Puzzle",
    },
    MEGAMAN: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    Megaman1: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    MEGAMAN2: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    MEGAMAN3: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    Megaman4: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    MEGAMAN5: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    MEGAMAN6: {
      description:
        "Action-platformer game featuring Mega Man, a robotic hero battling evil forces.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action-Platformer",
    },
    Meijiishin: {
      description: "Unknown game with the title 'Meijiishin'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Meikyuujima: {
      description:
        "Adventure game set in a labyrinthine island where players navigate challenges and puzzles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Meimon_Dai_San_Yakyuu_Bu: {
      description: "Sports simulation game focusing on high school baseball.",
      developer: "Unknown",
      platform: "NES",
      genre: "Sports",
    },
    Meimon_Tako_Nishi_Ouendan: {
      description:
        "Adventure game featuring a group of cheerleaders supporting their team.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Meitantei_Holmes: {
      description:
        "Adventure game featuring Sherlock Holmes, the famous detective, solving mysteries and puzzles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Meitantei_HolmesKiri_No_London_Satsujin_Jiken: {
      description:
        "Adventure game featuring Sherlock Holmes, the famous detective, solving mysteries and puzzles set in London.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Melvilles_Flame: {
      description: "Unknown game with the title 'Melville's Flame'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Menace_Beach: {
      description:
        "Action game where players navigate challenges and obstacles to progress through levels.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    METALGER: {
      description:
        "Action game where players control a character battling enemies and bosses.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    METALGR2: {
      description:
        "Action game where players control a character battling enemies and bosses.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    Metal_Fighter: {
      description:
        "Fighting game where players control metal fighters in intense battles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Fighting",
    },
    Metal_Gear: {
      description:
        "Action-adventure game where players control a soldier on a mission to defeat enemies and sabotage plans.",
      developer: "Konami",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Metal_Max: {
      description:
        "Role-playing game set in a post-apocalyptic world where players embark on quests and battles.",
      developer: "Data East",
      platform: "NES",
      genre: "RPG",
    },
    Metal_Slader_Glory: {
      description:
        "Adventure game set in a futuristic world with interactive storytelling and choices.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Adventure",
    },
    Metal_Storm: {
      description:
        "Action game where players control a powerful mech navigating challenges and enemies.",
      developer: "Irem",
      platform: "NES",
      genre: "Action",
    },
    METLSTRM: {
      description:
        "Action game where players control a futuristic spaceship battling enemies and bosses.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    METROID: {
      description:
        "Action-adventure game where players control Samus Aran, a bounty hunter exploring alien worlds and battling foes.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Metro_Cross: {
      description:
        "Arcade-style action game where players navigate maze-like levels to collect flags while avoiding obstacles.",
      developer: "Namco",
      platform: "NES",
      genre: "Arcade",
    },
    Mezase_Pachi_ProPachio_Kun: {
      description:
        "Pachinko simulation game where players experience the popular Japanese gambling game.",
      developer: "Coconuts Japan",
      platform: "NES",
      genre: "Simulation",
    },
    Mezase_Top_ProGreen_Ni_Kakeru_Yume: {
      description:
        "Unknown game with the title 'Mezase Top Pro: Green ni Kakeru Yume'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    mf2: {
      description: "Unknown game with the title 'mf2'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Michael_Andrettis_World_Grand_Prix: {
      description:
        "Racing simulation game featuring real-world racing circuits and vehicles.",
      developer: "American Sammy",
      platform: "NES",
      genre: "Racing",
    },
    Mickeys_Adventures_in_Numberland: {
      description:
        "Educational game designed to teach mathematical concepts to young players.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Educational",
    },
    Mickeys_Safari_in_Letterland: {
      description:
        "Educational game designed to teach spelling and language skills to young players.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Educational",
    },
    Mickey_Mouse3Yume_Fuusen: {
      description:
        "Adventure game featuring Mickey Mouse in a dreamlike world, solving puzzles and challenges.",
      developer: "Kemco",
      platform: "NES",
      genre: "Adventure",
    },
    Middle_School_English_II: {
      description:
        "Educational game designed to teach English language skills to students.",
      developer: "Unknown",
      platform: "NES",
      genre: "Educational",
    },
    Mighty_Bomb_Jack: {
      description:
        "Platformer game where players control Jack, navigating levels and avoiding enemies.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Platformer",
    },
    Mighty_Final_Fight: {
      description:
        "Beat 'em up game featuring characters from the Final Fight series, battling enemies in side-scrolling levels.",
      developer: "Capcom",
      platform: "NES",
      genre: "Beat 'em up",
    },
    Might_and_Magic: {
      description:
        "Role-playing game set in a fantasy world, where players embark on quests, battle monsters, and explore dungeons.",
      developer: "New World Computing",
      platform: "NES",
      genre: "RPG",
    },
    mikey: {
      description:
        "Action game where players control Mikey, navigating levels to rescue his friends and avoid enemies.",
      developer: "Konami",
      platform: "NES",
      genre: "Action",
    },
    Millipede: {
      description:
        "Arcade-style shooter game where players control a ship, shooting at insects and avoiding their attacks.",
      developer: "Atari",
      platform: "NES",
      genre: "Shooter",
    },
    Milons_Secret_Castle: {
      description:
        "Platformer game where players control Milo, navigating levels and solving puzzles to progress.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Platformer",
    },
    MindBlowerPak: {
      description:
        "Collection of various mini-games and challenges designed to test players' skills and reflexes.",
      developer: "Unknown",
      platform: "NES",
      genre: "Mini-Games",
    },
    Mindseeker: {
      description:
        "Puzzle game where players connect similar symbols and clear the board to progress.",
      developer: "Intelligent Systems",
      platform: "NES",
      genre: "Puzzle",
    },
    Minelvaton_Saga: {
      description:
        "Role-playing game where players control a group of heroes, battling enemies and embarking on quests.",
      developer: "Taito",
      platform: "NES",
      genre: "RPG",
    },
    Mini_Putt: {
      description:
        "Miniature golf simulation game where players aim to complete courses with the fewest strokes.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Sports",
    },
    Minna_No_Taabou_No_Nakayoshi_Daisakusen: {
      description:
        "Board game compilation featuring various mini-games and challenges for players to enjoy.",
      developer: "Unknown",
      platform: "NES",
      genre: "Board Games",
    },
    Miracle_Piano_Teaching_System: {
      description:
        "Educational game designed to teach piano skills and music theory to players.",
      developer: "Unknown",
      platform: "NES",
      genre: "Educational",
    },
    Miracle_Ropits_Adventure_in2100: {
      description:
        "Unknown game with the title 'Miracle Ropit's Adventure in 2100'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Mirai_SenseiLios: {
      description:
        "Educational game designed to teach various subjects to young players.",
      developer: "Unknown",
      platform: "NES",
      genre: "Educational",
    },
    Mirai_Shinwa_Jarvas: {
      description:
        "Role-playing game set in a fantasy world, where players control characters on quests and adventures.",
      developer: "Unknown",
      platform: "NES",
      genre: "RPG",
    },
    MISSIMPO: {
      description:
        "Simulation game where players manage and operate a missile production facility.",
      developer: "Unknown",
      platform: "NES",
      genre: "Simulation",
    },
    MISSION: {
      description:
        "Action game where players control a character on a mission, battling enemies and completing objectives.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    Mississippi_Satsujin_Jiken: {
      description:
        "Adventure game set in a murder mystery scenario, where players investigate and solve crimes.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Mito_Koumon: {
      description:
        "Adventure game featuring the character Mito Koumon, navigating challenges and puzzles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Mito_Koumon2: {
      description:
        "Adventure game featuring the character Mito Koumon, navigating challenges and puzzles.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Mitsume_Ga_Tooru: {
      description:
        "Action game where players control a character battling enemies and bosses.",
      developer: "Natsume",
      platform: "NES",
      genre: "Action",
    },
    Mizushima_Shinji_No_Daikoushien: {
      description: "Sports simulation game focusing on high school baseball.",
      developer: "K Amusement Leasing",
      platform: "NES",
      genre: "Sports",
    },
    Moai_Kun: {
      description:
        "Puzzle-platformer game where players control Moai, navigating levels and solving puzzles.",
      developer: "Konami",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    Mobile_Suit_Z_GundamHot_Scramble: {
      description:
        "Action game featuring mobile suits from the Gundam anime series, battling enemies in space.",
      developer: "Yutaka",
      platform: "NES",
      genre: "Action",
    },
    Moero_Pro_Tennis: {
      description:
        "Sports simulation game focusing on professional tennis matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports",
    },
    Moero_Pro_Yakyuu: {
      description:
        "Sports simulation game focusing on professional baseball matches.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports",
    },
    Moero_Pro_Yakyuu_88_Ketteihen: {
      description:
        "Sports simulation game focusing on professional baseball matches in 1988.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports",
    },
    Moeru_Oniisan: {
      description:
        "Adventure game featuring the character Moeru Oniisan, navigating challenges and puzzles.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Adventure",
    },
    Moe_Pro_90Kandouhen: {
      description:
        "Sports simulation game focusing on various sports events in 1990.",
      developer: "K Amusement Leasing",
      platform: "NES",
      genre: "Sports",
    },
    Moe_Pro_Saikyouhen: {
      description: "Sports simulation game focusing on various sports events.",
      developer: "K Amusement Leasing",
      platform: "NES",
      genre: "Sports",
    },
    Momotarou_Densetsu: {
      description:
        "Board game simulation featuring characters from Japanese folklore, where players embark on quests.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Board Game",
    },
    Momotarou_Densetsu_Gaiden: {
      description:
        "Board game simulation featuring characters from Japanese folklore, where players embark on quests.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Board Game",
    },
    Momotarou_Dentetsu: {
      description:
        "Board game simulation featuring characters from Japanese folklore, where players embark on quests.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Board Game",
    },
    Money_Game: {
      description:
        "Board game simulation where players engage in business and financial activities.",
      developer: "Unknown",
      platform: "NES",
      genre: "Board Game",
    },
    Money_Game2Kabuto_Chou_No_Kiseki: {
      description:
        "Board game simulation where players engage in business and financial activities.",
      developer: "Unknown",
      platform: "NES",
      genre: "Board Game",
    },
    Monopoly: {
      description:
        "Board game simulation where players buy, sell, and trade properties to become the wealthiest player.",
      developer: "Parker Brothers",
      platform: "NES",
      genre: "Board Game",
    },
    MONSTERP: {
      description:
        "Action game where players control a character battling monsters and enemies.",
      developer: "Unknown",
      platform: "NES",
      genre: "Action",
    },
    monsterschool: {
      description: "Unknown game with the title 'monsterschool'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Monster_Maker7_Tsu_No_Hihou: {
      description:
        "Role-playing game where players create and customize their monsters, engaging in battles and quests.",
      developer: "SAS Sakata",
      platform: "NES",
      genre: "RPG",
    },
    Monster_Party: {
      description:
        "Action game featuring a young boy transformed into a monster, battling bizarre enemies and bosses.",
      developer: "Human Entertainment",
      platform: "NES",
      genre: "Action",
    },
    Monster_Truck_Rally: {
      description:
        "Racing game featuring monster trucks, where players compete in off-road races.",
      developer: "Realtime Associates",
      platform: "NES",
      genre: "Racing",
    },
    Moon_Crystal: {
      description:
        "Action game where players control a character exploring alien worlds and battling enemies.",
      developer: "Hector",
      platform: "NES",
      genre: "Action",
    },
    Moon_Ranger: {
      description:
        "Action game where players control a character exploring the moon and battling enemies.",
      developer: "Odyssey Software",
      platform: "NES",
      genre: "Action",
    },
    Morita_Shougi: {
      description:
        "Shogi simulation game, a traditional Japanese chess variant, where players compete against AI or human opponents.",
      developer: "Random House",
      platform: "NES",
      genre: "Board Game",
    },
    Mortal_Kombat3: {
      description:
        "Fighting game featuring characters from the Mortal Kombat series, battling opponents in one-on-one combat.",
      developer: "Midway",
      platform: "NES",
      genre: "Fighting",
    },
    Mortal_Kombat3Special_56_Peoples: {
      description:
        "Fighting game featuring characters from the Mortal Kombat series, battling opponents in one-on-one combat.",
      developer: "Midway",
      platform: "NES",
      genre: "Fighting",
    },
    Mortal_Kombat_IV: {
      description:
        "Fighting game featuring characters from the Mortal Kombat series, battling opponents in one-on-one combat.",
      developer: "Midway",
      platform: "NES",
      genre: "Fighting",
    },
    Mortal_Kombat_Trilogy: {
      description:
        "Fighting game featuring characters from the Mortal Kombat series, battling opponents in one-on-one combat.",
      developer: "Midway",
      platform: "NES",
      genre: "Fighting",
    },
    mortal_kombat_v1996_turbo_30_peoples: {
      description:
        "Fighting game featuring characters from the Mortal Kombat series, battling opponents in one-on-one combat.",
      developer: "Midway",
      platform: "NES",
      genre: "Fighting",
    },
    moshenfashicn: {
      description: "Unknown game with the title 'moshenfashicn'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Mother: {
      description:
        "Role-playing game where players control a young boy on a quest to save the world from an alien threat.",
      developer: "Nintendo",
      platform: "NES",
      genre: "RPG",
    },
    Motocross_Champion: {
      description:
        "Motocross racing game where players compete in off-road motorcycle races.",
      developer: "Japanese Communication",
      platform: "NES",
      genre: "Racing",
    },
    motor: {
      description:
        "Racing game where players compete in motorcycle races, navigating tracks and avoiding obstacles.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Racing",
    },
    MOTORCTY: {
      description:
        "Racing game where players control motorcycles, competing in races and avoiding obstacles.",
      developer: "Source Research & Development",
      platform: "NES",
      genre: "Racing",
    },
    Mottomo_Abunai_Deka: {
      description:
        "Adventure game where players take on the role of a detective, solving crimes and mysteries.",
      developer: "Unknown",
      platform: "NES",
      genre: "Adventure",
    },
    Mouryou_Senki_Madara: {
      description:
        "Role-playing game where players control a hero on a quest to save the world from evil forces.",
      developer: "Konami",
      platform: "NES",
      genre: "RPG",
    },
    MRIOTMCH: {
      description: "Unknown game with the title 'MRIOTMCH'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Mr_Gimmick: {
      description:
        "Platformer game where players control a character named Mr. Gimmick, navigating levels and solving puzzles.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Platformer",
    },
    MSPACMAN: {
      description:
        "Arcade-style maze game where players control Pac-Man, eating pellets and avoiding ghosts.",
      developer: "Namco",
      platform: "NES",
      genre: "Arcade",
    },
    MULE: {
      description:
        "Strategy game where players compete in resource management and land development on an alien planet.",
      developer: "Intelligent Systems",
      platform: "NES",
      genre: "Strategy",
    },
    Muppet_AdventureChaos_at_the_Carnival: {
      description:
        "Adventure game featuring the Muppets, where players navigate challenges and puzzles.",
      developer: "Hi Tech Expressions",
      platform: "NES",
      genre: "Adventure",
    },
    Murder_Club: {
      description:
        "Adventure game where players investigate and solve murder mysteries.",
      developer: "Data East",
      platform: "NES",
      genre: "Adventure",
    },
    Musashi_No_Bouken: {
      description:
        "Action-adventure game where players control a character on a quest, battling enemies and bosses.",
      developer: "Sigma Enterprises",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Musashi_No_Ken: {
      description:
        "Action game where players control a swordsman battling enemies and bosses.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    Mutant_Virus_The: {
      description:
        "Puzzle game where players control a character navigating maze-like levels to collect items and avoid enemies.",
      developer: "Rocket Science Productions",
      platform: "NES",
      genre: "Puzzle",
    },
    mxdcn: {
      description: "Unknown game with the title 'mxdcn'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Mystery_Quest: {
      description:
        "Adventure game where players solve puzzles and mysteries to progress through the story.",
      developer: "Taxan",
      platform: "NES",
      genre: "Adventure",
    },
    My_Life_My_LoveBoku_No_YumeWatashi_No_Negai: {
      description:
        "Adventure game where players navigate challenges and make choices in a romantic story.",
      developer: "Intec",
      platform: "NES",
      genre: "Adventure",
    },
    m_tank: {
      description:
        "Action game where players control a tank, navigating levels and battling enemies.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action",
    },
    NadiaSecret_of_the_Blue_Water: {
      description:
        "Adventure game based on the anime series 'Nadia: The Secret of Blue Water', where players embark on quests.",
      developer: "Namco",
      platform: "NES",
      genre: "Adventure",
    },
    Nagagutsu_Wo_Haita_Neko: {
      description:
        "Action game based on the fairy tale 'Puss in Boots', where players control the titular cat on a quest.",
      developer: "Toei Animation",
      platform: "NES",
      genre: "Action",
    },
    Naitou_9_DanShougi_Hiden: {
      description:
        "Shogi simulation game, a traditional Japanese chess variant, where players compete against AI opponents.",
      developer: "Hect",
      platform: "NES",
      genre: "Board Game",
    },
    Nakajima_Satoru_F_1_Hero: {
      description:
        "Racing simulation game featuring Formula 1 racing, where players compete in championship races.",
      developer: "Varie",
      platform: "NES",
      genre: "Racing",
    },
    Nakajima_Satoru_F_1_Hero2: {
      description:
        "Racing simulation game featuring Formula 1 racing, where players compete in championship races.",
      developer: "Varie",
      platform: "NES",
      genre: "Racing",
    },
    Nakayoshi_To_Issho: {
      description:
        "Board game simulation featuring various mini-games and challenges for players to enjoy.",
      developer: "Culture Brain",
      platform: "NES",
      genre: "Board Games",
    },
    Namcot_Maajan3Maajan_Tengoku: {
      description:
        "Mahjong simulation game featuring various modes and challenges for players to enjoy.",
      developer: "Namco",
      platform: "NES",
      genre: "Mahjong",
    },
    Namco_Classic: {
      description:
        "Compilation of classic Namco arcade games, including Pac-Man, Dig Dug, and Galaga.",
      developer: "Namco",
      platform: "NES",
      genre: "Arcade",
    },
    Namco_Classic2: {
      description:
        "Compilation of classic Namco arcade games, including Pac-Man, Dig Dug, and Galaga.",
      developer: "Namco",
      platform: "NES",
      genre: "Arcade",
    },
    Nangoku_ShireiSpy_Vs_Spy: {
      description:
        "Action game based on the comic 'Spy vs. Spy', where players control spies setting traps for each other.",
      developer: "Kemco",
      platform: "NES",
      genre: "Action",
    },
    Napoleon_Senki: {
      description:
        "Strategy game where players control Napoleon Bonaparte and engage in battles and military campaigns.",
      developer: "Imagineer",
      platform: "NES",
      genre: "Strategy",
    },
    NARC: {
      description:
        "Action game where players control law enforcement officers, battling criminals and drug dealers.",
      developer: "Rare",
      platform: "NES",
      genre: "Action",
    },
    Navy_Blue: {
      description:
        "Action game where players control a character battling enemies in side-scrolling levels.",
      developer: "K Amusement Leasing",
      platform: "NES",
      genre: "Action",
    },
    Nekketsu_KouhaKunio_Kun: {
      description:
        "Action game where players control a high school student, battling enemies and gangs in side-scrolling levels.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Action",
    },
    Nekketsu_KoukouDodgeball_Bu: {
      description:
        "Sports game where players control a dodgeball team, competing in matches against rival teams.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Sports",
    },
    Nekketsu_Koukou_Dodgeball_BuSoccer_Hen: {
      description:
        "Sports game where players control a soccer team, competing in matches against rival teams.",
      developer: "Technos Japan",
      platform: "NES",
      genre: "Sports",
    },
    NemoPajama_Hero: {
      description:
        "Action game where players control a young boy exploring a dream world, battling enemies and bosses.",
      developer: "Capcom",
      platform: "NES",
      genre: "Action",
    },
    NESA_Audio_PlayerLittle_Nemo_Sample: {
      description:
        "Unknown game with the title 'NESA Audio Player (Little Nemo Sample)'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    NESA_Audio_PlayerTimes_of_Lore: {
      description:
        "Unknown game with the title 'NESA Audio Player (Times of Lore)'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    New_Zealand_Story: {
      description:
        "Platformer game where players control a kiwi bird, navigating levels and rescuing other birds.",
      developer: "Taito",
      platform: "NES",
      genre: "Platformer",
    },
    NFL_Football: {
      description:
        "Sports simulation game featuring American football, where players compete in matches and championships.",
      developer: "Atlus",
      platform: "NES",
      genre: "Sports",
    },
    NGHTMARE: {
      description:
        "Action game where players control a character exploring a haunted mansion, battling ghosts and monsters.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action",
    },
    Nigel_Mansells_World_Championship_Challenge: {
      description:
        "Racing simulation game featuring Formula 1 racing, where players compete in championship races.",
      developer: "Gremlin Graphics",
      platform: "NES",
      genre: "Racing",
    },
    Nightshade: {
      description:
        "Action-adventure game where players control a superhero, battling enemies and solving puzzles.",
      developer: "Beam Software",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Night_Arrow: {
      description:
        "Action game where players control a character battling enemies and avoiding obstacles in side-scrolling levels.",
      developer: "Taito",
      platform: "NES",
      genre: "Action",
    },
    "Ninja Crusaders": {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "AGCI",
      platform: "NES",
      genre: "Action",
    },
    ninja2: {
      description: "Unknown game with the title 'ninja2'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    ninjacat: {
      description: "Unknown game with the title 'ninjacat'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    ninjadog: {
      description: "Unknown game with the title 'ninjadog'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    NinjaGa1: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action",
    },
    NINJAGA2: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action",
    },
    Ninjaga3: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action",
    },
    Ninjara_Hoi: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Sunsoft",
      platform: "NES",
      genre: "Action",
    },
    Ninja_Cop_Saizou: {
      description:
        "Action game where players control a ninja cop, battling enemies and bosses in side-scrolling levels.",
      developer: "UPL",
      platform: "NES",
      genre: "Action",
    },
    Ninja_Crusaders: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "AGCI",
      platform: "NES",
      genre: "Action",
    },
    Ninja_Hattori_Kun: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action",
    },
    Ninja_JajamaruGinga_Daisakusen: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action",
    },
    Ninja_Jajamaru_Kun: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action",
    },
    Ninja_Kun: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action",
    },
    Ninja_KunAshura_No_Shou: {
      description:
        "Action game where players control a ninja, battling enemies and bosses in side-scrolling levels.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Action",
    },
    Ninja_Ryuukenden: {
      description:
        "Action-adventure game where players control a ninja on a quest, battling enemies and bosses.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Ninja_Ryuukenden1: {
      description:
        "Action-adventure game where players control a ninja on a quest, battling enemies and bosses.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Ninja_Ryuukenden2: {
      description:
        "Action-adventure game where players control a ninja on a quest, battling enemies and bosses.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Ninja_Ryuukenden3: {
      description:
        "Action-adventure game where players control a ninja on a quest, battling enemies and bosses.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Action-Adventure",
    },
    Nishimura_Kyoutarou_MysteryBlue_Train_Satsujin_Jiken: {
      description:
        "Adventure game where players investigate and solve a murder mystery on a train.",
      developer: "Data East",
      platform: "NES",
      genre: "Adventure",
    },
    Nishimura_Kyoutarou_MysterySuper_Express_Satsujin_Jiken: {
      description:
        "Adventure game where players investigate and solve a murder mystery on a super express train.",
      developer: "Data East",
      platform: "NES",
      genre: "Adventure",
    },
    Noahs_Ark: {
      description:
        "Action game where players control Noah, gathering animals and resources to build the ark before the flood.",
      developer: "Source Research & Development",
      platform: "NES",
      genre: "Action",
    },
    Nobunaga_no_YabouBushou_Fuuun_Roku: {
      description:
        "Strategy game where players control warlords in feudal Japan, engaging in battles and political maneuvers.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    Nobunaga_No_YabouSengoku_Gunyuuden: {
      description:
        "Strategy game where players control warlords in feudal Japan, engaging in battles and political maneuvers.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    Nobunaga_no_YabouZenkoku_Ban: {
      description:
        "Strategy game where players control warlords in feudal Japan, engaging in battles and political maneuvers.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    NORTHST2: {
      description:
        "Strategy game where players control and manage a transportation company, building railroads and transporting goods.",
      developer: "Koei",
      platform: "NES",
      genre: "Strategy",
    },
    North_and_South: {
      description:
        "Strategy game where players control armies in the American Civil War, engaging in battles and capturing territories.",
      developer: "Infogrames",
      platform: "NES",
      genre: "Strategy",
    },
    Nuts_and_Milk: {
      description:
        "Puzzle-platformer game where players control a character navigating levels to collect nuts and reach the exit.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Puzzle-Platformer",
    },
    NWC: {
      description: "Unknown game with the title 'NWC'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Obotchama_Kun: {
      description:
        "Adventure game based on the manga and anime series 'Obotchama-kun', where players navigate challenges and puzzles.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Adventure",
    },
    Oeka_KidsAnpanman_No_Hiragana_Daisuki: {
      description:
        "Educational game teaching hiragana characters featuring the character Anpanman.",
      developer: "Bandai",
      platform: "NES",
      genre: "Educational",
    },
    Oeka_KidsAnpanman_To_Oekaki_Shiyou: {
      description:
        "Educational game allowing players to draw and color pictures featuring the character Anpanman.",
      developer: "Bandai",
      platform: "NES",
      genre: "Educational",
    },
    OFFROAD: {
      description:
        "Racing game where players control off-road vehicles, navigating tracks and competing in races.",
      developer: "Upstar",
      platform: "NES",
      genre: "Racing",
    },
    Ohootsuku_Ni_KiyuHokkaidou_Rensa_Satsujin: {
      description:
        "Adventure game where players investigate and solve a murder mystery in Hokkaido, Japan.",
      developer: "Tokuma Shoten",
      platform: "NES",
      genre: "Adventure",
    },
    Oishinbo: {
      description:
        "Educational game teaching about Japanese cuisine and ingredients.",
      developer: "Shueisha",
      platform: "NES",
      genre: "Educational",
    },
    oly: {
      description: "Unknown game with the title 'oly'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    OLYMPUS: {
      description: "Unknown game with the title 'OLYMPUS'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Olympus_No_Tatakai: {
      description:
        "Role-playing game where players control a group of heroes on a quest to save the world.",
      developer: "Kemco",
      platform: "NES",
      genre: "RPG",
    },
    Onyanko_Town: {
      description:
        "Simulation game where players manage and operate a town populated by anthropomorphic cats.",
      developer: "ASCII",
      platform: "NES",
      genre: "Simulation",
    },
    Operation_Secret_Storm: {
      description:
        "Action game where players control a special agent, battling enemies and completing missions.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Action",
    },
    OPERWOLF: {
      description:
        "Action game where players control a special forces operative, battling enemies and completing missions.",
      developer: "Data East",
      platform: "NES",
      genre: "Action",
    },
    Orb3D: {
      description:
        "Puzzle game where players control a ball, navigating maze-like levels to collect items and reach the exit.",
      developer: "Hi Tech Expressions",
      platform: "NES",
      genre: "Puzzle",
    },
    Osomatsu_Kun: {
      description:
        "Adventure game based on the manga and anime series 'Osomatsu-kun', where players navigate challenges and puzzles.",
      developer: "Atlus",
      platform: "NES",
      genre: "Adventure",
    },
    Otaku_No_SeizaAn_Adventure_in_the_Otaku_Galaxy: {
      description:
        "Adventure game set in the world of anime and manga fandom, where players navigate challenges and puzzles.",
      developer: "Kadokawa",
      platform: "NES",
      genre: "Adventure",
    },
    otoko: {
      description: "Unknown game with the title 'otoko'.",
      developer: "Unknown",
      platform: "NES",
      genre: "Unknown",
    },
    Outlanders: {
      description:
        "Adventure game where players control a group of heroes on a quest to save the world.",
      developer: "NCS",
      platform: "NES",
      genre: "Adventure",
    },
    Overlord: {
      description:
        "Strategy game where players control an evil Overlord, building dungeons and commanding minions to conquer the world.",
      developer: "Virgin Interactive",
      platform: "NES",
      genre: "Strategy",
    },
    Over_Horizon: {
      description:
        "Shoot 'em up game where players control a spaceship, battling enemies and bosses in side-scrolling levels.",
      developer: "Hot-B",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    Pachinko_Daisakusen1: {
      description:
        "Pachinko simulation game where players enjoy the popular Japanese gambling game.",
      developer: "Coconuts Japan",
      platform: "NES",
      genre: "Pachinko",
    },
    Pachinko_Daisakusen2: {
      description:
        "Pachinko simulation game where players enjoy the popular Japanese gambling game.",
      developer: "Coconuts Japan",
      platform: "NES",
      genre: "Pachinko",
    },
    Pachio_Kun2: {
      description:
        "Pachinko simulation game featuring various machines and challenges for players to enjoy.",
      developer: "Coconuts Japan",
      platform: "NES",
      genre: "Pachinko",
    },
    Pachio_Kun3: {
      description:
        "Pachinko simulation game featuring various machines and challenges for players to enjoy.",
      developer: "Coconuts Japan",
      platform: "NES",
      genre: "Pachinko",
    },
    Pachio_Kun_4: {
      description:
        "Pachinko simulation game featuring various machines and challenges for players to enjoy.",
      developer: "Coconuts Japan",
      platform: "NES",
      genre: "Pachinko",
    },
    Pachi_Com: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Pachi_Slot_Adventure3: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Pacmania: {
      description:
        "Classic arcade game where players control Pac-Man through mazes, avoiding ghosts and eating dots.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Arcade",
    },
    Pac_Land: {
      description:
        "Classic arcade game where players guide Pac-Man through levels, avoiding enemies and collecting items.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Arcade",
    },
    Pac_Man: {
      description:
        "Iconic arcade game where players control Pac-Man, navigating mazes, eating dots, and avoiding ghosts.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Arcade",
    },
    Palamedes: {
      description:
        "Classic puzzle video game where players match colored blocks to clear the screen.",
      developer: "Hot B",
      platform: "NES",
      genre: "Puzzle",
    },
    Palamedes2_Star_Twinkles: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    pal_I: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Panic_Restaurant: {
      description:
        "Action video game where players control a chef fighting food enemies in a restaurant setting.",
      developer: "Taito",
      platform: "TurboGrafx-16",
      genre: "Action",
    },
    Paperboy: {
      description:
        "Classic arcade game where players control a paperboy delivering newspapers while avoiding obstacles.",
      developer: "Atari Games",
      platform: "Arcade",
      genre: "Arcade",
    },
    Paperboy2: {
      description:
        "Sequel to the classic Paperboy arcade game, featuring similar gameplay with new levels and challenges.",
      developer: "Atari Games",
      platform: "Arcade",
      genre: "Arcade",
    },
    Papillion: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Parallel_World: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Parasol_Henbee: {
      description:
        "Platform video game featuring a bee character navigating levels with a parasol, collecting items and avoiding enemies.",
      developer: "Varie",
      platform: "NES",
      genre: "Platform",
    },
    parasol_stars: {
      description:
        "Platform video game where players control a character shooting enemies with a parasol, featuring colorful graphics and cute characters.",
      developer: "Taito",
      platform: "Arcade",
      genre: "Platform",
    },
    Paris_Dakar_Rally_Special: {
      description:
        "Racing video game based on the Paris-Dakar Rally, featuring off-road racing and various vehicles.",
      developer: "Unknown",
      platform: "Arcade",
      genre: "Racing",
    },
    Parman: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Parman2: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Parodius: {
      description:
        "Shoot 'em up video game and parody of the Gradius series, featuring humorous characters and levels.",
      developer: "Konami",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    Parutena_No_KagamiHikari_Shinwa: {
      description:
        "Action-adventure video game where players control the character Pit, battling enemies and bosses in various levels.",
      developer: "Nintendo",
      platform: "NES",
      genre: "Action-adventure",
    },
    pc: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Peek_A_Boo_Poker: {
      description: "Adult-themed video poker game with a peek-a-boo twist.",
      developer: "Panesian",
      platform: "NES",
      genre: "Adult/Card",
    },
    Peepar_Time: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Penguin_Kun_Wars: {
      description:
        "Action video game where players control a penguin, shooting snowballs at enemies and avoiding hazards.",
      developer: "ASCII Corporation",
      platform: "NES",
      genre: "Action",
    },
    Perfect_Bowling: {
      description:
        "Bowling simulation video game with realistic physics and gameplay, allowing players to experience bowling on a computer or console.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    Pesterminator: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    PETERPAN: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    PHANTOM: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    PHEONIX: {
      description:
        "Classic shoot 'em up video game where players control a spaceship, shooting enemies and avoiding their attacks.",
      developer: "Amstar Electronics",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    Pictionary: {
      description:
        "Video game adaptation of the popular board game Pictionary, where players draw and guess words to advance in the game.",
      developer: "Software Creations",
      platform: "NES",
      genre: "Party",
    },
    PIKACHU: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Pinball: {
      description:
        "Classic pinball simulation game with various tables and challenges, featuring realistic ball physics and sound effects.",
      developer: "Nintendo R&D1",
      platform: "NES",
      genre: "Pinball",
    },
    Pinball_Quest: {
      description:
        "Pinball video game with RPG elements, where players embark on a quest, defeat enemies, and complete missions using pinball mechanics.",
      developer: "Sculptured Software",
      platform: "NES",
      genre: "Pinball/RPG",
    },
    Pinbot: {
      description:
        "Pinball video game featuring a futuristic theme and challenging gameplay, where players manipulate flippers to score points and complete objectives.",
      developer: "Rare",
      platform: "NES",
      genre: "Pinball",
    },
    pino: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Pipe_Dream: {
      description:
        "Puzzle video game where players connect pipes to create a continuous flow for a substance, solving plumbing challenges within a time limit.",
      developer: "The Assembly Line",
      platform: "Various",
      genre: "Puzzle",
    },
    Pirates: {
      description:
        "Action-adventure video game where players take on the role of a pirate, exploring islands, battling enemies, and searching for treasures.",
      developer: "Ultra Games",
      platform: "NES",
      genre: "Action-adventure",
    },
    Pizza_Pop: {
      description:
        "Puzzle video game where players match ingredients to create pizzas, featuring challenging levels and various pizza recipes.",
      developer: "Arcadia Systems",
      platform: "NES",
      genre: "Puzzle",
    },
    Plasma_Ball: {
      description:
        "Puzzle video game where players manipulate plasma balls, solving puzzles by directing the balls to their targets and avoiding obstacles.",
      developer: "Use Corporation",
      platform: "NES",
      genre: "Puzzle",
    },
    PLATOON: {
      description:
        "Action video game based on the film Platoon, where players control a soldier navigating the Vietnam War, battling enemies and completing missions.",
      developer: "Ocean Software",
      platform: "NES",
      genre: "Action",
    },
    Pocahontas: {
      description:
        "Action-adventure video game based on the Disney film Pocahontas, where players control the titular character, exploring the forest and interacting with animals.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Pocket_Zaurus_Ouken_No_Nazo: {
      description:
        "Action-adventure video game where players control a dinosaur exploring various levels, solving puzzles, and battling enemies.",
      developer: "Tecmo",
      platform: "NES",
      genre: "Action-adventure",
    },
    Poker3_5in1: {
      description:
        "Collection of five different poker games in one cartridge, allowing players to enjoy various poker variants on their gaming console.",
      developer: "Sachen",
      platform: "NES",
      genre: "Card",
    },
    Pooyan: {
      description:
        "Arcade shooter game where players control a pig, shooting arrows at wolves and other enemies to protect their piglets.",
      developer: "Konami",
      platform: "Arcade",
      genre: "Shooter",
    },
    POPERSIA: {
      description: "Description not available.",
      developer: "Developer not available.",
      platform: "Platform not available.",
      genre: "Genre not available.",
    },
    Popeye: {
      description:
        "Arcade game where players control Popeye, collecting hearts and avoiding enemies to rescue Olive Oyl from Bluto.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Arcade",
    },
    POW: {
      description:
        "Run and gun video game where players control a soldier, fighting through enemy-infested levels and defeating bosses.",
      developer: "SNK",
      platform: "Arcade",
      genre: "Run and gun",
    },
    PowerRangers2: {
      description:
        "Action-adventure video game based on the Power Rangers franchise, featuring characters from the series and various missions to complete.",
      developer: "Bandai",
      platform: "NES",
      genre: "Action-adventure",
    },
    Power_Blade: {
      description:
        "Action-adventure video game where players control a cyborg exploring levels, fighting enemies, and collecting power-ups to save the world from an evil organization.",
      developer: "Natsume",
      platform: "NES",
      genre: "Action-adventure",
    },
    Power_Blade2: {
      description:
        "Sequel to Power Blade, featuring similar gameplay with new levels, enemies, and challenges.",
      developer: "Taito",
      platform: "NES",
      genre: "Action-adventure",
    },
    Power_Blazer: {
      description:
        "Action-adventure video game where players control a character exploring dungeons, fighting enemies, and solving puzzles to rescue hostages.",
      developer: "Kemco",
      platform: "NES",
      genre: "Action-adventure",
    },
    Power_Punch2: {
      description:
        "Boxing video game where players control a boxer, competing in matches against various opponents and striving to become the boxing champion.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    Power_Soccer: {
      description:
        "Soccer video game featuring fast-paced arcade-style gameplay, allowing players to control teams and compete in exciting matches.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    Pradikus_Conflict: {
      description:
        "Action-adventure video game where players control a character battling aliens and exploring levels to save the world from an alien invasion.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Predator: {
      description:
        "Run and gun video game based on the film Predator, where players control a soldier battling enemies in a jungle setting, inspired by the movie.",
      developer: "Pack-In-Video",
      platform: "NES",
      genre: "Run and gun",
    },
    President_No_Sentaku: {
      description:
        "Simulation video game where players take on the role of a political candidate, managing campaigns, making decisions, and striving to become the president.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Probotector: {
      description:
        "Run and gun video game where players control a soldier, battling robotic enemies and bosses to save the world from a mechanical army.",
      developer: "Konami",
      platform: "NES",
      genre: "Run and gun",
    },
    Probotector2Return_of_the_Evil_Forces: {
      description:
        "Sequel to Probotector, featuring similar gameplay with new levels, enemies, and challenges.",
      developer: "Konami",
      platform: "NES",
      genre: "Run and gun",
    },
    Project_Q: {
      description:
        "Puzzle video game where players manipulate blocks and shapes to complete puzzles, featuring challenging levels and creative solutions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    PROWREST: {
      description:
        "Wrestling video game where players control wrestlers, competing in matches against opponents and aiming to win championship titles.",
      developer: "SOFEL",
      platform: "NES",
      genre: "Sports/Wrestling",
    },
    Pro_Sport_Hockey: {
      description:
        "Ice hockey video game where players control teams, compete in matches, and aim to score goals while defending their own net.",
      developer: "Jaleco",
      platform: "NES",
      genre: "Sports/Hockey",
    },
    Pro_Yakyuu_Satsujin_Jiken: {
      description:
        "Adventure video game where players investigate a murder mystery involving a baseball team, solving puzzles and gathering clues to unravel the case.",
      developer: "Data East",
      platform: "NES",
      genre: "Adventure",
    },
    Punch_Out: {
      description:
        "Boxing simulation video game where players control a boxer, Little Mac, climbing the ranks and facing opponents in boxing matches, including iconic fighters like Mike Tyson.",
      developer: "Nintendo R&D3",
      platform: "NES",
      genre: "Sports/Boxing",
    },
    PUNISHER: {
      description:
        "Beat 'em up video game based on the Marvel Comics character The Punisher, where players control the titular character, battling criminals and bosses to achieve justice.",
      developer: "Capcom",
      platform: "Arcade",
      genre: "Beat 'em up",
    },
    Puss_BootsPeros_Great_Adventure: {
      description:
        "Action-adventure video game where players control the character Puss in Boots, exploring levels, solving puzzles, and battling enemies.",
      developer: "Shouei System",
      platform: "NES",
      genre: "Action-adventure",
    },
    Puyo_Puyo: {
      description:
        "Puzzle video game where players match colored blobs called Puyos, creating chains and combos to clear the screen and defeat opponents in competitive matches.",
      developer: "Compile",
      platform: "Various",
      genre: "Puzzle",
    },
    Puzslot: {
      description:
        "Slot machine simulation video game where players experience the thrill of playing slot machines, featuring various themes and gameplay mechanics.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation/Gambling",
    },
    Puzzle: {
      description:
        "Puzzle video game where players solve a variety of puzzles, including logic puzzles, mazes, and pattern recognition challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Puzznic: {
      description:
        "Puzzle video game where players match and clear blocks by pushing them, solving puzzles and preventing the screen from filling up with blocks.",
      developer: "Taito",
      platform: "Various",
      genre: "Puzzle",
    },
    Pyokotan_No_Dai_Meiro: {
      description:
        "Puzzle video game where players manipulate colored tiles to create specific patterns and solve puzzles, testing their logic and spatial reasoning skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Pyramid: {
      description:
        "Puzzle video game where players navigate a pyramid, solving puzzles and avoiding traps to uncover hidden treasures.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Puzzle",
    },
    Qix: {
      description:
        "Puzzle video game where players claim territory on the screen by drawing boxes, avoiding enemies and obstacles while completing levels.",
      developer: "Taito",
      platform: "Various",
      genre: "Puzzle",
    },
    qtaro: {
      description:
        "Puzzle video game where players guide a character through mazes, avoiding traps and enemies, and collecting items to progress through the levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Quarter_Back_Scramble: {
      description:
        "Football simulation video game where players control a quarterback, passing and running to score touchdowns and win games.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Football",
    },
    Quarth: {
      description:
        "Puzzle video game where players manipulate falling blocks to create square shapes, preventing the screen from filling up and clearing blocks to advance.",
      developer: "Konami",
      platform: "Various",
      genre: "Puzzle",
    },
    Quattro_Adventure: {
      description:
        "Compilation of four different action and puzzle games, offering a variety of gameplay experiences in one cartridge.",
      developer: "Codemasters",
      platform: "Various",
      genre: "Action/Puzzle",
    },
    Quattro_Arcade: {
      description:
        "Compilation of four different arcade-style games, providing a diverse gaming experience with various challenges and gameplay mechanics.",
      developer: "Codemasters",
      platform: "Various",
      genre: "Arcade",
    },
    Quattro_Sports: {
      description:
        "Compilation of four different sports-themed games, allowing players to compete in basketball, soccer, tennis, and golf.",
      developer: "Codemasters",
      platform: "Various",
      genre: "Sports",
    },
    Quest_of_Ki: {
      description:
        "Action-adventure video game where players control the character Ki, navigating levels, solving puzzles, and battling enemies to rescue a kidnapped queen.",
      developer: "Namco",
      platform: "NES",
      genre: "Action-adventure",
    },
    Quinty: {
      description:
        "Puzzle video game where players guide the character Quinty, arranging blocks to create pathways and solve puzzles, testing their spatial reasoning skills.",
      developer: "Namco",
      platform: "NES",
      genre: "Puzzle",
    },
    Q_bert: {
      description:
        "Arcade puzzle game where players control Q*bert, hopping on a pyramid of cubes, changing their colors, and avoiding enemies to clear levels.",
      developer: "Gottlieb",
      platform: "Arcade",
      genre: "Puzzle",
    },
    RACEAMER: {
      description:
        "Racing video game where players compete in high-speed races, maneuvering through tracks, avoiding obstacles, and striving to reach the finish line first.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Racer_Mini_Yon_KuJapan_Cup: {
      description:
        "Racing video game where players compete in mini car races, navigating tracks and corners to win races and progress through championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Race_America: {
      description:
        "Racing video game where players compete in cross-country races, navigating challenging terrains and obstacles to reach the finish line first.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Rackets_and_Rivals: {
      description:
        "Tennis simulation video game where players control tennis players, serving, volleying, and smashing their way to victory in tennis matches.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Tennis",
    },
    Racket_Attack: {
      description:
        "Tennis simulation video game where players control tennis players, competing in matches and tournaments, aiming to become tennis champions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Tennis",
    },
    RADGRAV: {
      description:
        "Platformer video game where players control a character equipped with a gravity-defying device, exploring levels, avoiding traps, and solving puzzles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Radia_SenkiReimei_Hen: {
      description:
        "Role-playing video game where players embark on a quest, battling enemies, leveling up characters, and unraveling the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "RPG",
    },
    RADIOACT: {
      description:
        "Arcade puzzle game where players manipulate radioactive isotopes, arranging them to form molecules and clear levels, testing their puzzle-solving skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Rad_Racer2: {
      description:
        "Racing video game where players compete in high-speed races, maneuvering through tracks, avoiding obstacles, and striving to reach the finish line first.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Rad_Racket_Deluxe_Tennis2: {
      description:
        "Tennis simulation video game where players control tennis players, serving, volleying, and smashing their way to victory in tennis matches.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Tennis",
    },
    Raf_World: {
      description:
        "Platformer video game where players control a character, jumping and running through levels, avoiding enemies, and collecting items to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Raid2020: {
      description:
        "Action-adventure video game where players control a character, battling enemies, completing missions, and striving to bring justice to a post-apocalyptic world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Raid_on_Bungeling_Bay: {
      description:
        "Shoot 'em up video game where players control a helicopter, bombing enemy factories and defending their own base from attacks, designed by Will Wright.",
      developer: "Broderbund",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    rainbowisland: {
      description:
        "Platformer video game where players control characters, using rainbows to trap and defeat enemies, navigating levels, and reaching the top of the Rainbow Tower.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Rainbow_IslandsThe_Story_of_Bubble_Bobble2: {
      description:
        "Platformer video game and sequel to Bubble Bobble, where players control characters, trapping enemies in rainbows, collecting power-ups, and progressing through levels.",
      developer: "Taito",
      platform: "Various",
      genre: "Platformer",
    },
    Rainbow_Silkroad: {
      description:
        "Platformer video game where players control a character, jumping and running through levels, avoiding enemies, and collecting items to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Rally_Bike: {
      description:
        "Racing video game where players compete in motorcycle races, navigating tracks and obstacles to reach the finish line first, known as Dash Yarou in Japan.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Rambo: {
      description:
        "Run and gun video game based on the film Rambo: First Blood Part II, where players control John Rambo, battling enemies and completing missions.",
      developer: "Pack-In-Video",
      platform: "NES",
      genre: "Run and gun",
    },
    Rampart: {
      description:
        "Strategy shoot 'em up video game where players build and defend castles, strategically placing walls and cannons to fend off enemy attacks and conquer territories.",
      developer: "Atari Games",
      platform: "Various",
      genre: "Strategy/Shoot 'em up",
    },
    Rasaaru_Ishii_No_Childs_Quest: {
      description:
        "Role-playing video game where players control characters, battling enemies, leveling up, and progressing through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "RPG",
    },
    RBI1: {
      description:
        "Baseball simulation video game where players control baseball teams, pitching, batting, and fielding in games, aiming to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Baseball",
    },
    RBI2: {
      description:
        "Baseball simulation video game, sequel to RBI Baseball, where players control baseball teams, pitching, batting, and fielding in games, aiming to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Baseball",
    },
    RBI3: {
      description:
        "Baseball simulation video game, part of the RBI Baseball series, where players control baseball teams, pitching, batting, and fielding in games, aiming to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Baseball",
    },
    RCPROAM: {
      description:
        "Racing video game where players control radio-controlled cars, competing in races and navigating tracks to reach the finish line.",
      developer: "Rare",
      platform: "NES",
      genre: "Racing",
    },
    RCPROAM2: {
      description:
        "Racing video game and sequel to R.C. Pro-Am, where players control radio-controlled cars, racing against opponents, and upgrading their vehicles.",
      developer: "Rare",
      platform: "NES",
      genre: "Racing",
    },
    RC_Pro_Am: {
      description:
        "Racing video game where players control radio-controlled cars, competing in races, collecting power-ups, and striving to win championships.",
      developer: "Rare",
      platform: "NES",
      genre: "Racing",
    },
    RECCA: {
      description:
        "Shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and facing challenging bosses in intense battles.",
      developer: "Enix",
      platform: "NES",
      genre: "Shoot 'em up",
    },
    red: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    redshadow: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Red_Ariimaa2: {
      description:
        "Chess-like strategy board game where players move pieces with different abilities on the board, aiming to defeat the opponent's king.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy/Board game",
    },
    Reigen_Doushi: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Remote_Control: {
      description:
        "Puzzle video game where players manipulate objects and mechanisms remotely, solving puzzles and challenges to advance through the game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    RENEGADE: {
      description:
        "Beat 'em up video game where players control a character, fighting against waves of enemies, using martial arts and weapons to defeat opponents.",
      developer: "Technos Japan",
      platform: "Various",
      genre: "Beat 'em up",
    },
    RENSTIMP: {
      description:
        "Racing video game where players compete in high-speed races, maneuvering through tracks, avoiding obstacles, and striving to reach the finish line first.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    "result.txt": {
      description:
        "Text file used for storing game results, scores, or other data related to video games.",
      developer: "Unknown",
      platform: "Various",
      genre: "Utility",
    },
    Riki_Kunio: {
      description:
        "Beat 'em up video game featuring Kunio-kun, where players control a character, fighting against enemies in urban environments, using martial arts and weapons.",
      developer: "Technos Japan",
      platform: "Various",
      genre: "Beat 'em up",
    },
    RINGKING: {
      description:
        "Boxing simulation video game where players control boxers, jabbing, hooking, and dodging in matches to defeat opponents and become boxing champions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Boxing",
    },
    River_City_Brawl: {
      description:
        "Fighting video game featuring Kunio-kun characters, where players engage in brawls, using punches, kicks, and special moves to defeat opponents.",
      developer: "Technos Japan",
      platform: "Various",
      genre: "Fighting",
    },
    RoadBlasters: {
      description:
        "Vehicular combat racing game where players drive armed cars, shooting enemies and avoiding obstacles to reach the finish line.",
      developer: "Atari Games",
      platform: "Various",
      genre: "Vehicular combat/Racing",
    },
    RoadRunr: {
      description:
        "Endless runner video game where players control characters, running through levels, avoiding obstacles, and collecting items to achieve high scores.",
      developer: "Unknown",
      platform: "Various",
      genre: "Endless runner",
    },
    ROADSTRICKET: {
      description:
        "Racing video game where players compete in high-speed races, maneuvering through tracks, avoiding obstacles, and striving to reach the finish line first.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Road_Fighter: {
      description:
        "Racing video game where players control cars, navigating tracks, avoiding other vehicles, and collecting items to enhance their cars' abilities.",
      developer: "Konami",
      platform: "Various",
      genre: "Racing",
    },
    ROBNHOOD: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Robocco_Wars: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Robocop1: {
      description:
        "Run and gun video game based on the film RoboCop, where players control the cyborg RoboCop, battling criminals and completing missions.",
      developer: "Data East",
      platform: "Various",
      genre: "Run and gun",
    },
    Robocop2: {
      description:
        "Run and gun video game based on the film RoboCop 2, where players control the cyborg RoboCop, battling criminals and completing missions.",
      developer: "Data East",
      platform: "Various",
      genre: "Run and gun",
    },
    Robocop3: {
      description:
        "Run and gun video game based on the film RoboCop 3, where players control the cyborg RoboCop, battling criminals and completing missions.",
      developer: "Probe Software",
      platform: "Various",
      genre: "Run and gun",
    },
    Robocop_Vs_The_Terminator: {
      description:
        "Run and gun video game featuring a crossover between the RoboCop and Terminator franchises, where players battle enemies from both universes.",
      developer: "Interplay Entertainment",
      platform: "Various",
      genre: "Run and gun",
    },
    Robodemons: {
      description:
        "Action platformer video game where players control a character, jumping and shooting enemies, progressing through levels, and defeating bosses.",
      developer: "Color Dreams",
      platform: "NES",
      genre: "Action platformer",
    },
    Robo_Warrior: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Hudson Soft",
      platform: "NES",
      genre: "Action-adventure",
    },
    rock5pr8: {
      description:
        "Text-based simulation game where players manage a rock band, making decisions related to music, tours, and promotions to achieve success.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    rocket: {
      description:
        "Shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and facing challenging bosses in space battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    ROCKETER: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Rockman: {
      description:
        "Action platformer video game where players control the character Rockman, also known as Mega Man, using his arm cannon to shoot enemies and defeat bosses.",
      developer: "Capcom",
      platform: "Various",
      genre: "Action platformer",
    },
    Rockman_6Shijou_Saidai_no_Tatakai: {
      description:
        "Action platformer video game, part of the Mega Man series, where players control Rockman, battling robot masters and collecting their powers.",
      developer: "Capcom",
      platform: "Various",
      genre: "Action platformer",
    },
    Rockman_7: {
      description:
        "Action platformer video game, part of the Mega Man series, where players control Rockman, battling robot masters and collecting their powers.",
      developer: "Capcom",
      platform: "Various",
      genre: "Action platformer",
    },
    ROCKNKAT: {
      description:
        "Action platformer video game where players control a character, jumping and running through levels, avoiding enemies, and collecting items to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Rock_Ball: {
      description:
        "Puzzle video game where players control a paddle, hitting a ball to break bricks and clear levels, similar to the classic game Breakout.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Rod_Land: {
      description:
        "Platformer arcade game where players control characters, trapping enemies in rainbows, collecting power-ups, and progressing through levels.",
      developer: "Jaleco",
      platform: "Various",
      genre: "Platformer",
    },
    ROGERRAB: {
      description:
        "Racing video game where players control radio-controlled cars, competing in races and navigating tracks to reach the finish line.",
      developer: "Rare",
      platform: "NES",
      genre: "Racing",
    },
    Roger_Clemens_MVP_Baseball: {
      description:
        "Baseball simulation video game where players control baseball teams, pitching, batting, and fielding in games, aiming to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Baseball",
    },
    Rokudenashi_Blues: {
      description:
        "Beat 'em up video game featuring Kunio-kun, where players control a character, fighting against enemies in urban environments, using martial arts and weapons.",
      developer: "Technos Japan",
      platform: "Various",
      genre: "Beat 'em up",
    },
    rolegame: {
      description:
        "Text-based role-playing game where players navigate a story through textual descriptions, making choices that affect the outcome of the game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Text-based RPG",
    },
    ROLLER: {
      description:
        "Simulation game where players control a roller coaster, designing tracks, managing the ride's intensity, and ensuring the safety and enjoyment of virtual visitors.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Rollerball: {
      description:
        "Pinball video game simulation where players control flippers, hitting a metal ball to score points, trigger events, and achieve high scores.",
      developer: "HAL Laboratory",
      platform: "NES",
      genre: "Pinball",
    },
    Rollerblade_Racer: {
      description:
        "Racing video game where players control characters on rollerblades, competing in races, performing tricks, and avoiding obstacles to reach the finish line.",
      developer: "Konami",
      platform: "NES",
      genre: "Racing",
    },
    Rollergames: {
      description:
        "Action platformer video game where players control characters, jumping and fighting enemies, progressing through levels, and defeating bosses.",
      developer: "Konami",
      platform: "NES",
      genre: "Action platformer",
    },
    Rolling_Thunder: {
      description:
        "Run and gun video game where players control a secret agent, shooting enemies, avoiding obstacles, and completing missions in a covert operation.",
      developer: "Namco",
      platform: "Various",
      genre: "Run and gun",
    },
    ROMANCE: {
      description:
        "Board game simulation where players navigate relationships, make decisions related to love, and pursue romantic partners, aiming for a successful romance.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Romance_of_the_Three_Kingdoms2: {
      description:
        "Strategy simulation game set in ancient China, where players control kingdoms, manage resources, engage in diplomacy, and lead armies in battles.",
      developer: "Koei",
      platform: "Various",
      genre: "Strategy",
    },
    Romancia: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Roundball_2_on_2_Challenge: {
      description:
        "Basketball simulation video game where players control teams, passing, shooting, and defending in fast-paced matches, aiming to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Basketball",
    },
    Route_16_Turbo: {
      description:
        "Top-down racing video game where players drive cars, navigating a city to collect items, avoiding traffic, and reaching specific locations within a time limit.",
      developer: "SunSoft",
      platform: "NES",
      genre: "Racing",
    },
    Royal_Blood: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    RPG_Jinsei_Game: {
      description:
        "Role-playing board game simulation where players make life decisions, such as career, relationships, and investments, aiming to achieve success.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation/Board game",
    },
    ruledriv: {
      description:
        "Puzzle video game where players manipulate blocks, creating paths and clearing obstacles to guide a rolling sphere to the exit.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Rumble_And_Frenzy: {
      description:
        "Fighting video game where players control characters, engaging in brawls, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Rushn_Attack: {
      description:
        "Run and gun video game where players control a soldier, shooting enemies and avoiding obstacles while progressing through side-scrolling levels.",
      developer: "Konami",
      platform: "Various",
      genre: "Run and gun",
    },
    RXDGBLUS: {
      description:
        "Board game simulation where players compete in the real estate market, buying, selling, and managing properties to accumulate wealth.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation/Board game",
    },
    RXHPUS: {
      description:
        "Board game simulation where players compete in the real estate market, buying, selling, and managing properties to accumulate wealth.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation/Board game",
    },
    RYGAR: {
      description:
        "Action platformer video game where players control Rygar, a warrior armed with a yo-yo-like weapon, battling enemies and exploring side-scrolling levels.",
      developer: "Tecmo",
      platform: "Various",
      genre: "Action platformer",
    },
    s700in1: {
      description:
        "Compilation cartridge containing multiple games from various genres, providing players with a diverse gaming experience in a single package.",
      developer: "Various",
      platform: "Various",
      genre: "Various",
    },
    sag32_1: {
      description:
        "Compilation cartridge containing multiple games from various genres, providing players with a diverse gaming experience in a single package.",
      developer: "Various",
      platform: "Various",
      genre: "Various",
    },
    Saint_SeiyaOugon_Densetsu: {
      description:
        "Fighting video game featuring characters from the Saint Seiya anime/manga series, where players engage in battles, using special moves and combos to defeat opponents.",
      developer: "Bandai",
      platform: "Various",
      genre: "Fighting",
    },
    Saint_SeiyaOugon_Densetsu_Kanketsu_Hen: {
      description:
        "Fighting video game featuring characters from the Saint Seiya anime/manga series, where players engage in battles, using special moves and combos to defeat opponents.",
      developer: "Bandai",
      platform: "Various",
      genre: "Fighting",
    },
    saiyuki: {
      description:
        "Action platformer video game based on the Saiyuki anime/manga series, where players control characters, battling enemies and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    saiyuki2: {
      description:
        "Action platformer video game based on the Saiyuki anime/manga series, where players control characters, battling enemies and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Saiyuuki_World2Tenjoukai_No_Mashou: {
      description:
        "Action platformer video game based on the Saiyuki anime/manga series, where players control characters, battling enemies and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Sakigake_Otoko_Juku: {
      description:
        "Beat 'em up video game based on the Sakigake!! Otoko Juku anime/manga series, where players control characters, fighting against enemies in side-scrolling levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    Salad_No_Kuni_No_Tomato_Hime: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    salama: {
      description:
        "Board game simulation where players manage restaurants, serving customers, preparing dishes, and managing resources to create a successful eatery.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation/Board game",
    },
    Salamander: {
      description:
        "Shoot 'em up video game and spin-off of the Gradius series, where players control a spaceship, shooting enemies, avoiding obstacles, and facing challenging bosses.",
      developer: "Konami",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    samurai: {
      description:
        "Action-adventure video game set in feudal Japan, where players control a samurai, battling enemies, exploring the environment, and completing quests.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Sanada_Juu_Yuushi: {
      description:
        "Action-adventure video game based on the Sanada Ten Braves folklore, where players control characters, battling enemies and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    sango4: {
      description:
        "Strategy simulation game set in ancient China, where players control factions, manage resources, engage in diplomacy, and lead armies in battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Sangokushi: {
      description:
        "Strategy simulation game set in ancient China, where players control factions, manage resources, engage in diplomacy, and lead armies in battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Sangokushi2: {
      description:
        "Strategy simulation game set in ancient China, where players control factions, manage resources, engage in diplomacy, and lead armies in battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Sangokushi2_Hanou_No_Tairiku: {
      description:
        "Strategy simulation game set in ancient China, where players control factions, manage resources, engage in diplomacy, and lead armies in battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Sangokushi_Chuuken_No_Hasha: {
      description:
        "Strategy simulation game set in ancient China, where players control factions, manage resources, engage in diplomacy, and lead armies in battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Sango_Fighter: {
      description:
        "Fighting game set in ancient China, where players control characters, engaging in battles using martial arts and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Sanma_No_Mei_Tantei: {
      description:
        "Adventure game where players solve mysteries and puzzles as a detective, exploring environments, gathering clues, and unraveling the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Adventure",
    },
    Sanrio_Carnival: {
      description:
        "Mini-game collection featuring Sanrio characters, where players engage in various activities, puzzles, and challenges set in the Sanrio universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Mini-game compilation",
    },
    Sanrio_Carnival2: {
      description:
        "Mini-game collection featuring Sanrio characters, where players engage in various activities, puzzles, and challenges set in the Sanrio universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Mini-game compilation",
    },
    Sanrio_CupPon_Pon_Volley: {
      description:
        "Sports video game featuring Sanrio characters playing volleyball, where players control teams, serve, spike, and block in matches to win.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports/Volleyball",
    },
    Sansaara_Naaga: {
      description:
        "Action-adventure video game where players control a character, exploring levels, solving puzzles, and battling enemies to progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Sansuu2_NenKeisan_Game: {
      description:
        "Educational game focused on arithmetic, where players solve mathematical problems, equations, and puzzles to enhance their numerical skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Sansuu3_NenKeisan_Game: {
      description:
        "Educational game focused on arithmetic, where players solve mathematical problems, equations, and puzzles to enhance their numerical skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Sansuu_1_NenKeisan_Game: {
      description:
        "Educational game focused on arithmetic, where players solve mathematical problems, equations, and puzzles to enhance their numerical skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Sansuu_4_NenKeisan_Game: {
      description:
        "Educational game focused on arithmetic, where players solve mathematical problems, equations, and puzzles to enhance their numerical skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Sansuu_5_and_6_NenKeisan_Game: {
      description:
        "Educational game focused on arithmetic, where players solve mathematical problems, equations, and puzzles to enhance their numerical skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Sansuu_Asobi: {
      description:
        "Educational game focused on arithmetic, where players solve mathematical problems, equations, and puzzles to enhance their numerical skills.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Satomi_Hakkenden: {
      description:
        "Role-playing video game based on the Satomi Hakkenden novel, where players control characters, battle enemies, and progress through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Satsui_No_Kaisou: {
      description:
        "Fighting game where players control characters, engaging in battles, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    SCAT: {
      description:
        "Run and gun video game where players control characters, shooting enemies, avoiding obstacles, and completing missions in side-scrolling levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Run and gun",
    },
    SCON: {
      description:
        "Puzzle video game where players manipulate blocks and objects, solving puzzles and challenges to clear levels and advance in the game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    scontra: {
      description:
        "Run and gun video game where players control characters, shooting enemies, avoiding obstacles, and completing missions in side-scrolling levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Run and gun",
    },
    sdclub: {
      description:
        "Educational game focused on social studies and geography, where players explore countries, cultures, and historical events to enhance their knowledge.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    sdhero: {
      description:
        "Action platformer video game where players control a hero character, battling enemies and navigating through various levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    SDNKYKG2: {
      description:
        "Action platformer video game featuring characters from the Donkey Kong series, where players control Donkey Kong or Diddy Kong, overcoming obstacles and enemies in levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    SD_Battle_OozumouHeisei_Hero_Basho: {
      description:
        "Wrestling video game featuring SD (super deformed) characters, where players engage in wrestling matches, performing moves and combos to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Wrestling",
    },
    SD_GundamGachapon_Senshi2Capsule_Senki: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD_GundamGachapon_Senshi3Eiyuu_Senki: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD_GundamGachapon_Senshi_4New_Type_Story: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD_GundamGachapon_Senshi_5Battle_of_Universal_Century: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD_Gundam_GaidenKnight_Gundam_Monogatari: {
      description:
        "Action role-playing game featuring SD (super deformed) Gundam characters, where players control characters, battle enemies, and progress through the story in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action RPG",
    },
    SD_Gundam_GaidenKnight_Gundam_Monogatari2: {
      description:
        "Action role-playing game featuring SD (super deformed) Gundam characters, where players control characters, battle enemies, and progress through the story in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action RPG",
    },
    SD_Gundam_GaidenKnight_Gundam_Monogatari3Densetsu_No: {
      description:
        "Action role-playing game featuring SD (super deformed) Gundam characters, where players control characters, battle enemies, and progress through the story in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action RPG",
    },
    SD_HeroSoukessenTaose_Aku_No_Gundan: {
      description:
        "Fighting game featuring SD (super deformed) characters from various franchises, where players engage in battles using punches, kicks, and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    SD_KeijiBlader: {
      description:
        "Action platformer video game featuring SD (super deformed) characters, where players control a character, battle enemies, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    SD_Sengoku_Bushou_Retsuden: {
      description:
        "Strategy simulation game featuring SD (super deformed) characters from the Sengoku period, where players control factions, manage resources, and engage in battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD街头快打: {
      description:
        "Fighting game featuring SD (super deformed) characters from Street Fighter, where players engage in battles using martial arts and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    SD高达外传: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD高达外传2: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD高达外传3: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    SD高达战士3: {
      description:
        "Strategy simulation game featuring SD (super deformed) Gundam characters, where players manage units, resources, and engage in battles in the Gundam universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    Secret_Scout: {
      description:
        "Action-adventure game where players control a character, solving puzzles, exploring environments, and progressing through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    sectionz: {
      description:
        "Vertical scrolling shooter game where players control a spacecraft, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Seicross: {
      description:
        "Racing video game where players control futuristic motorcycles, racing on tracks, avoiding obstacles, and competing against opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Seifuku_HenShisen_Mahjong: {
      description:
        "Mahjong video game where players match tiles, form combinations, and compete against opponents to win in traditional Mahjong fashion.",
      developer: "Unknown",
      platform: "Various",
      genre: "Mahjong",
    },
    Seikima2Akuma_No_Gyakushuu: {
      description:
        "Action platformer video game featuring characters from the Seikimatsu series, where players control a character, battle enemies, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Seirei_Densetsu_Lickle: {
      description:
        "Action platformer video game where players control a character, navigating through levels, solving puzzles, and battling enemies.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Seirei_Gari: {
      description:
        "Action platformer video game where players control a character, battling enemies, overcoming obstacles, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Sekiryuuou: {
      description:
        "Action platformer video game where players control a character, battling enemies, overcoming obstacles, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Sensha_SenryakuSabaku_No_Kitsune: {
      description:
        "Strategy simulation game where players control tanks, engage in battles, and strategize to defeat enemy forces in desert-themed environments.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    Sesame_Street_ABC123: {
      description:
        "Educational game focused on teaching letters and numbers, where players engage in activities and puzzles related to the alphabet and counting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    Sesame_Street_Countdown: {
      description:
        "Educational game focused on teaching counting and numbers, where players engage in activities and puzzles related to numerical concepts.",
      developer: "Unknown",
      platform: "Various",
      genre: "Educational",
    },
    sf97: {
      description:
        "Fighting game featuring characters from the Street Fighter series, where players engage in battles using martial arts and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    sfzero2: {
      description:
        "Fighting game featuring characters from the Street Fighter series, where players engage in battles using martial arts and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    SG: {
      description:
        "Fighting game featuring martial arts characters, where players engage in battles using punches, kicks, and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    SHADNINJ: {
      description:
        "Action platformer video game where players control a ninja character, battling enemies, avoiding traps, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Shadowgate: {
      description:
        "Adventure puzzle game where players explore a castle, solving puzzles, finding items, and progressing through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Adventure puzzle",
    },
    "shadowgate_(french)": {
      description:
        "Adventure puzzle game where players explore a castle, solving puzzles, finding items, and progressing through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Adventure puzzle",
    },
    Shadow_Brain: {
      description:
        "Strategy simulation game where players control units, engage in battles, and strategize to conquer territories and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    Shadow_Warriors: {
      description:
        "Action platformer video game where players control a ninja character, battling enemies, avoiding traps, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Shaffle_Fight: {
      description:
        "Fighting game where players control characters, engaging in battles, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Shancara: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Shanghai: {
      description:
        "Tile-matching puzzle game where players match pairs of tiles to clear them from the board, aiming to clear all tiles and complete the game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Shanghai2: {
      description:
        "Tile-matching puzzle game where players match pairs of tiles to clear them from the board, aiming to clear all tiles and complete the game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Shen_Huo_Le_Zhuan: {
      description:
        "Action platformer video game where players control a character, battling enemies, overcoming obstacles, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Sherlock_Holmes: {
      description:
        "Adventure mystery game where players control Sherlock Holmes, solve puzzles, investigate crime scenes, and unravel mysteries.",
      developer: "Unknown",
      platform: "Various",
      genre: "Adventure mystery",
    },
    Shikinjou: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    SHINGEN: {
      description:
        "Strategy simulation game where players control factions, manage resources, and engage in battles to conquer territories and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    Shinobi: {
      description:
        "Action platformer video game where players control a ninja character, battling enemies, avoiding traps, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Shin_4_Nin_Uchi_MahjongYakuman_Tengoku: {
      description:
        "Mahjong video game where players match tiles, form combinations, and compete against opponents to win in traditional Mahjong fashion.",
      developer: "Unknown",
      platform: "Various",
      genre: "Mahjong",
    },
    Shin_Moero_Pro_Yakyuu: {
      description:
        "Sports simulation game where players control baseball teams, manage players, and participate in matches to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Shin_Satomi_Hakkenden: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Shisenden: {
      description:
        "Fighting game where players control characters, engaging in battles, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Shockwave: {
      description:
        "Vertical scrolling shooter game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Shooting_Range: {
      description:
        "Light gun shooting game where players aim and shoot at targets, practicing their shooting skills and accuracy.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooting",
    },
    Short_OrderEggsplode: {
      description:
        "Action puzzle game where players control a chef, catching falling ingredients and assembling orders to complete levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action puzzle",
    },
    Shougi_Meikan_92: {
      description:
        "Shogi (Japanese chess) simulation game where players engage in shogi matches, strategizing to checkmate the opponent's king.",
      developer: "Unknown",
      platform: "Various",
      genre: "Board game simulation",
    },
    Shougi_Meikan_93: {
      description:
        "Shogi (Japanese chess) simulation game where players engage in shogi matches, strategizing to checkmate the opponent's king.",
      developer: "Unknown",
      platform: "Various",
      genre: "Board game simulation",
    },
    Shougun: {
      description:
        "Strategy simulation game where players control factions, manage resources, and engage in battles to conquer territories and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    Shoukoushi_Sedi: {
      description:
        "Action platformer video game where players control a character, battling enemies, overcoming obstacles, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Shounen_AshibeNepal_Daibouken_No_Maki: {
      description:
        "Action platformer video game where players control a character, battling enemies, overcoming obstacles, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    SHTRHAND: {
      description:
        "Puzzle video game where players manipulate pipes and create a pipeline to transport liquids from one point to another, solving puzzles to complete levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Shufflepuck_Cafe: {
      description:
        "Air hockey simulation game where players control paddles, hitting a puck back and forth, aiming to score goals and win matches.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    "SIDEPO~1": {
      description:
        "Action platformer video game where players control a character, battling enemies, overcoming obstacles, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    Silent_Assault: {
      description:
        "Shooter video game where players control a tank, engaging in battles, shooting enemies, and strategizing to complete missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Silent_Service: {
      description:
        "Submarine simulation game where players control a submarine, navigating underwater, launching torpedoes, and engaging in naval battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    SILKWORM: {
      description:
        "Shoot 'em up video game where players control helicopters, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Silva_Saga: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Silver_Surfer: {
      description:
        "Shoot 'em up video game where players control Silver Surfer, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    sjl2cn: {
      description:
        "Fighting game featuring characters from the Street Fighter series, where players engage in battles using martial arts and special moves.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    skateboard: {
      description:
        "Skateboarding video game where players control skateboarders, performing tricks, navigating obstacles, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    Skate_or_Die: {
      description:
        "Skateboarding video game where players control skateboarders, performing tricks, navigating obstacles, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    Skate_or_Die2: {
      description:
        "Skateboarding video game where players control skateboarders, performing tricks, navigating obstacles, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    Skull_and_Crossbones: {
      description:
        "Beat 'em up video game where players control characters, battling enemies, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    skydog: {
      description:
        "Vertical scrolling shooter game where players control an aircraft, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    skying: {
      description:
        "Skiing video game where players control skiers, navigating downhill slopes, avoiding obstacles, and completing races.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    SKYSHARK: {
      description:
        "Shoot 'em up video game where players control aircraft, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Sky_Kid: {
      description:
        "Horizontal scrolling shooter game where players control aircraft, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Slalom: {
      description:
        "Skiing video game where players control skiers, navigating downhill slopes, avoiding obstacles, and completing races.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports",
    },
    smallmario: {
      description:
        "Platformer video game where players control Mario, jumping over obstacles, avoiding enemies, and reaching the end of each level.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    SMASHTV: {
      description:
        "Top-down shooter game where players control characters, shooting enemies, avoiding traps, and completing levels in a game show setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Snake_Rattlen_Roll: {
      description:
        "Platformer video game where players control snakes, navigating levels, avoiding obstacles, and reaching the exit to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    SNOOPY: {
      description:
        "Side-scrolling shooter game where players control Snoopy, shooting enemies, avoiding obstacles, and completing levels in the Peanuts universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    SNOWBROS: {
      description:
        "Platformer video game where players control snowmen, shooting enemies with snowballs, defeating foes, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    "Soccer (JU)": {
      description:
        "Soccer simulation game where players control teams, pass, shoot, and score goals to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    SOCCER: {
      description:
        "Soccer simulation game where players control teams, pass, shoot, and score goals to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Soccer_LeagueWinners_Cup: {
      description:
        "Soccer simulation game where players control teams, pass, shoot, and score goals to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Softball_Tengoku: {
      description:
        "Softball simulation game where players control teams, pitch, bat, and field to win matches and championships in a softball league.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    SOF_v1b: {
      description:
        "Action-adventure game where players control a soldier, battling enemies, solving puzzles, and progressing through levels in a war setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Solar_JetmanHunt_for_the_Golden_Warpship: {
      description:
        "Action-adventure game where players control a spaceship, exploring planets, collecting items, and completing missions to find the Golden Warpship.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Solar_Wars_Silent: {
      description:
        "Strategy simulation game where players control spaceships, engage in battles, manage resources, and conquer planets to achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy simulation",
    },
    Solitaire: {
      description:
        "Card game where players arrange cards in specific orders and suits, following rules to complete the game and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Card game",
    },
    Solomons_Key: {
      description:
        "Puzzle-platformer game where players control a character, navigating levels, solving puzzles, and unlocking doors to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle-platformer",
    },
    Solomon_No_Kagi: {
      description:
        "Puzzle-platformer game where players control a character, navigating levels, solving puzzles, and unlocking doors to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle-platformer",
    },
    Solomon_No_Kagi2Coolmin_Tou_Kyuushutsu_Sakusen: {
      description:
        "Puzzle-platformer game where players control a character, navigating levels, solving puzzles, and unlocking doors to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle-platformer",
    },
    SOLSTICE: {
      description:
        "Isometric puzzle-adventure game where players control a character, solving puzzles, exploring environments, and progressing through the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle-adventure",
    },
    "Somari (UNL)": {
      description:
        "Platformer video game where players control Somari, a character similar to Mario, jumping over obstacles, avoiding enemies, and reaching the end of each level.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    somari: {
      description:
        "Platformer video game where players control Somari, a character similar to Mario, jumping over obstacles, avoiding enemies, and reaching the end of each level.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    SONIC6: {
      description:
        "Platformer video game where players control Sonic the Hedgehog, running, jumping, and collecting rings while avoiding enemies and obstacles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Sonic_3D_Blast_5: {
      description:
        "Platformer video game where players control Sonic the Hedgehog, running, jumping, and collecting rings while avoiding enemies and obstacles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    sonson: {
      description:
        "Platformer video game where players control characters, jumping over obstacles, avoiding enemies, and reaching the end of each level.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Son_Son: {
      description:
        "Platformer video game where players control characters, jumping over obstacles, avoiding enemies, and reaching the end of each level.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Soreike_Anpan_ManMinna_De_Hiking_Game: {
      description:
        "Action platformer video game featuring Anpanman characters, where players control characters, battling enemies, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action platformer",
    },
    space: {
      description:
        "Space-themed shoot 'em up video game where players control a spacecraft, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Space_Harrier: {
      description:
        "Third-person rail shooter game where players control a character flying in a fantasy world, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Space_Hunter: {
      description:
        "Side-scrolling shooter game where players control a character, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Space_Invaders: {
      description:
        "Fixed shooter game where players control a ship at the bottom of the screen, shooting aliens descending from above to prevent them from reaching the bottom.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Space_Shadow: {
      description:
        "Vertical scrolling shooter game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Space_Shuttle_Project: {
      description:
        "Space flight simulation game where players control a space shuttle, performing missions, launching, navigating, and landing in a space exploration setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Spartan_X2: {
      description:
        "Beat 'em up video game where players control a character, battling enemies, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    Spelunker: {
      description:
        "Platformer video game where players control a character, exploring caves, avoiding traps, collecting treasures, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Spelunker2Yuusha_E_No_Chousen: {
      description:
        "Platformer video game and sequel to Spelunker where players control a character, exploring caves, avoiding traps, collecting treasures, and progressing through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Spiritual_Warfare: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a religious setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Splatterhouse: {
      description:
        "Beat 'em up video game where players control a character, battling enemies, using punches, kicks, and special moves to defeat opponents in a horror-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    SplatterhouseWanpaku_Graffiti: {
      description:
        "Platformer game and prequel to Splatterhouse where players control a character, battling enemies, avoiding traps, and completing levels in a horror-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Spot: {
      description:
        "Puzzle video game where players control a character, navigating levels, jumping on spots, avoiding enemies, and reaching the exit to progress.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    spyhunter: {
      description:
        "Vehicular combat game where players control a car equipped with weapons, shooting enemies, avoiding obstacles, and completing missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Vehicular combat",
    },
    Spy_Hunter: {
      description:
        "Vehicular combat game where players control a car equipped with weapons, shooting enemies, avoiding obstacles, and completing missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Vehicular combat",
    },
    SQOON: {
      description:
        "Shoot 'em up video game where players control a character swimming underwater, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    squall2: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Square_No_Tom_Sawyer: {
      description:
        "Action-adventure game based on the novel The Adventures of Tom Sawyer, where players control Tom Sawyer, solve puzzles, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    srw2: {
      description:
        "Turn-based strategy game where players control units, engage in tactical battles, and progress through missions in a science fiction setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Stack_Up: {
      description:
        "Puzzle video game where players control a robot, stacking blocks in specific patterns to complete challenges and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    StanleyThe_Search_For_Dr_Livingston: {
      description:
        "Action-adventure game where players control a character, exploring environments, solving puzzles, and progressing through levels in a safari setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    starbaseball: {
      description:
        "Baseball simulation game where players control teams, pitch, bat, and field to win matches and championships in a baseball league.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    starbaseball2: {
      description:
        "Baseball simulation game where players control teams, pitch, bat, and field to win matches and championships in a baseball league.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    starsoldier: {
      description:
        "Vertical scrolling shooter game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    STARTRO2: {
      description:
        "Shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    STARTROP: {
      description:
        "Shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    STARWARS: {
      description:
        "Space-themed shoot 'em up video game based on the Star Wars franchise, where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Star_Gate: {
      description:
        "Shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Star_Luster: {
      description:
        "Space-themed shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Star_TrekThe_Next_Generation: {
      description:
        "Space simulation game based on the Star Trek franchise, where players control a spaceship, explore space, engage in battles, and complete missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Star_Trek_25th_Anniversary: {
      description:
        "Space simulation game based on the Star Trek franchise, where players control a spaceship, explore space, engage in battles, and complete missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Star_Wars: {
      description:
        "Space-themed shoot 'em up video game based on the Star Wars franchise, where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Stealth_ATF: {
      description:
        "Flight simulation game where players control an advanced fighter jet, engaging in dogfights, completing missions, and exploring a variety of environments.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    StedIseki_Wakusei_No_Yabou: {
      description:
        "Turn-based strategy game where players control units, engage in tactical battles, and progress through missions in a science fiction setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    StedStarfield_of_Memorable_Relics: {
      description:
        "Turn-based strategy game where players control units, engage in tactical battles, and progress through missions in a science fiction setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    STINGER: {
      description:
        "Shoot 'em up video game where players control a character, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Street_Cop: {
      description:
        "Beat 'em up video game where players control a police officer, battling criminals, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    Street_Fighter2010: {
      description:
        "Action-platformer game where players control a character, battling enemies, using punches, kicks, and special moves to defeat opponents in a futuristic setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    Street_Fighter_VI_12_Peoples: {
      description:
        "Fighting game where players control characters, using punches, kicks, and special moves to defeat opponents in one-on-one battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    STRIDER: {
      description:
        "Action-platformer game where players control a ninja-like character, battling enemies, using a sword, and performing acrobatic moves to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    STRT2010: {
      description:
        "Action-platformer game where players control a character, battling enemies, using punches, kicks, and special moves to defeat opponents in a futuristic setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    Stunt_Kids: {
      description:
        "Platformer video game where players control a character, jumping over obstacles, avoiding enemies, and reaching the end of each level.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Sugoro_QuestDice_No_Senshitachi: {
      description:
        "Board game video game where players roll dice, move characters on a board, and engage in battles to progress and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Board game",
    },
    Sugoro_QuestThe_Quest_of_Dice_Heros: {
      description:
        "Board game video game where players roll dice, move characters on a board, and engage in battles to progress and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Board game",
    },
    SuikodenTenmei_No_Chikai: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    SuiTang: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a historical setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Sukeban_Deka3: {
      description:
        "Beat 'em up video game where players control a character, battling enemies, using punches, kicks, and special moves to defeat opponents.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    Summer_Carnival_92Recca: {
      description:
        "Shoot 'em up video game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a summer carnival setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    sumo: {
      description:
        "Sports simulation game where players control sumo wrestlers, pushing opponents out of the ring or forcing them to touch the ground to win matches.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Sumo_Wrestling: {
      description:
        "Sports simulation game where players control sumo wrestlers, pushing opponents out of the ring or forcing them to touch the ground to win matches.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Sunday_Funday: {
      description:
        "Action-platformer game where players control a character, navigating levels, avoiding enemies, and collecting items to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    SUPERC: {
      description:
        "Racing video game where players control cars, competing in races, avoiding obstacles, and reaching the finish line to win.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    superflying: {
      description:
        "Action game where players control a character, flying in the sky, avoiding obstacles, collecting items, and completing missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Superman: {
      description:
        "Action-adventure game where players control Superman, battling enemies, solving puzzles, and progressing through levels in a superhero-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Superstar_Pro_Wrestling: {
      description:
        "Wrestling simulation game where players control wrestlers, performing moves, pins, and submissions to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Super_Aladdin: {
      description:
        "Platformer video game where players control Aladdin, jumping over obstacles, avoiding enemies, and completing levels in an Arabian Nights setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Arabian: {
      description:
        "Platformer video game where players control a character, jumping over obstacles, avoiding enemies, and completing levels in an Arabian Nights setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Black_Onyx: {
      description:
        "Role-playing video game where players control characters, battle enemies, complete quests, and progress through the story in a fantasy world.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Super_Cars: {
      description:
        "Racing video game where players control cars, competing in races, avoiding obstacles, and reaching the finish line to win.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Super_Castlevania2: {
      description:
        "Action-platformer game where players control a vampire hunter, battling enemies, using a whip, and progressing through levels in a horror-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    Super_Chinese: {
      description:
        "Action game where players control characters, battling enemies, using punches, kicks, and special moves to defeat opponents in one-on-one battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Super_Chinese2Dragon_Kid: {
      description:
        "Action game where players control characters, battling enemies, using punches, kicks, and special moves to defeat opponents in one-on-one battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Super_Chinese3: {
      description:
        "Action game where players control characters, battling enemies, using punches, kicks, and special moves to defeat opponents in one-on-one battles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Super_Contra: {
      description:
        "Run and gun video game where players control characters, shooting enemies, avoiding obstacles, and completing levels in a sci-fi military setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Run and gun",
    },
    Super_Donkey_KongXiang_Jiao_Chuan: {
      description:
        "Platformer video game and spin-off of the Donkey Kong series where players control characters, jumping over obstacles, avoiding enemies, and completing levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Dynamix_Badminton: {
      description:
        "Sports simulation game where players control characters, playing badminton matches, serving, smashing, and scoring points to win.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Super_Glove_Ball: {
      description:
        "Sports simulation game where players control characters, playing baseball matches, pitching, batting, and fielding to win.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Super_Jeopardy: {
      description:
        "Trivia video game based on the Jeopardy! television show, where players answer questions, solve puzzles, and compete to win virtual currency.",
      developer: "Unknown",
      platform: "Various",
      genre: "Trivia",
    },
    Super_Mario_Bros1: {
      description:
        "Platformer video game where players control Mario, jumping over obstacles, avoiding enemies, and completing levels in the Mushroom Kingdom.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_Bros2: {
      description:
        "Platformer video game where players control Mario, Luigi, Toad, or Princess Peach, navigating levels, defeating enemies, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_Bros3: {
      description:
        "Platformer video game where players control Mario or Luigi, navigating levels, defeating enemies, and using power-ups to complete challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_BrosDuck_Hunt: {
      description:
        "Platformer video game where players control Mario, jumping over obstacles, avoiding enemies, and completing levels in the Mushroom Kingdom. Includes a Duck Hunt mini-game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_BrosDuck_Hunt_Track_Meet: {
      description:
        "Platformer video game where players control Mario, jumping over obstacles, avoiding enemies, and completing levels in the Mushroom Kingdom. Includes a Duck Hunt mini-game and a track and field mini-game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_BrosTetris_Nintendo_World_Cup: {
      description:
        "Platformer video game where players control Mario, navigating levels, defeating enemies, and completing challenges. Includes a Tetris mini-game and a Nintendo World Cup soccer mini-game.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_USA: {
      description:
        "Platformer video game where players control Mario, Luigi, Toad, or Princess Peach, navigating levels, defeating enemies, and completing challenges in a dream world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mario_World: {
      description:
        "Platformer video game where players control Mario or Luigi, navigating levels, defeating enemies, and completing challenges in the Dinosaur Land setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Mogura_TatakiPokkun_Moguraa: {
      description:
        "Action game where players control characters, hitting moles with a hammer, avoiding obstacles, and completing challenges in a mole-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Super_Momotarou_Dentetsu: {
      description:
        "Board game video game where players roll dice, move characters on a board, and engage in various events and challenges to progress and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Board game",
    },
    Super_Monkey_Daibouken: {
      description:
        "Platformer video game where players control a monkey, jumping over obstacles, avoiding enemies, and collecting items to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Pinball: {
      description:
        "Pinball video game where players control flippers, launch balls, and score points by hitting targets, completing challenges, and achieving high scores.",
      developer: "Unknown",
      platform: "Various",
      genre: "Pinball",
    },
    Super_Pitfall: {
      description:
        "Platformer video game where players control a character, navigating levels, avoiding traps, and completing challenges in a jungle-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Super_Real_Baseball_88: {
      description:
        "Baseball simulation game where players control teams, pitch, bat, and field to win matches and championships in a baseball league.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Super_Rugby: {
      description:
        "Rugby simulation game where players control teams, pass, tackle, and score points to win matches and championships in a rugby league.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Super_Sky_Kid: {
      description:
        "Vertical scrolling shooter game where players control a character, shooting enemies, avoiding obstacles, and completing levels in a western-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Super_Sprint: {
      description:
        "Racing video game where players control cars, competing in races, avoiding obstacles, and reaching the finish line to win.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Super_Spy_Hunter: {
      description:
        "Vehicular combat game where players control a car equipped with weapons, shooting enemies, avoiding obstacles, and completing missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Vehicular combat",
    },
    Super_Star_Force: {
      description:
        "Vertical scrolling shooter game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a space-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Super_Team_Games: {
      description:
        "Sports simulation game where players control teams, compete in various sports events, and score points to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Super_Turrican: {
      description:
        "Run and gun video game where players control a character, shooting enemies, avoiding obstacles, and completing levels in a sci-fi setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Run and gun",
    },
    Super_XeviousGanpu_No_Nazo: {
      description:
        "Vertical scrolling shooter game where players control a spaceship, shooting enemies, avoiding obstacles, and completing levels in a sci-fi setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Super_X_Wing: {
      description:
        "Space simulation game based on the Star Wars franchise, where players control a spaceship, engage in battles, and complete missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Swamp_Thing: {
      description:
        "Action-platformer game where players control Swamp Thing, battling enemies, using punches, kicks, and special moves to defeat opponents in a swamp-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    swarrior: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    SWATSpecial_Weapons_and_Tactics: {
      description:
        "Action game where players control members of a SWAT team, engaging in missions, rescuing hostages, and defeating criminals in a law enforcement setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Sweet_Home: {
      description:
        "Survival horror role-playing video game where players control a team exploring a haunted mansion, solving puzzles, battling supernatural creatures, and uncovering the story.",
      developer: "Unknown",
      platform: "Various",
      genre: "Survival horror",
    },
    Swords_And_Serpents: {
      description:
        "Role-playing video game where players control characters, battle monsters, complete quests, and progress through dungeons to achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Role-playing",
    },
    Sword_Master: {
      description:
        "Action-platformer game where players control a character, battling enemies, using swords, and performing acrobatic moves to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    T2: {
      description:
        "Light gun shooter game based on the Terminator 2: Judgment Day movie, where players use a light gun to shoot enemies and complete missions.",
      developer: "Unknown",
      platform: "Various",
      genre: "Light gun shooter",
    },
    TabooThe_Sixth_Sense: {
      description:
        "Tarot card reading simulation game where players consult a virtual tarot deck to answer questions and explore their destiny.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Tagin_Dragon: {
      description:
        "Platformer game where players control a character, navigating levels, avoiding enemies, and completing challenges to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Tag_Team_Pro_Wrestling: {
      description:
        "Wrestling video game where players control tag teams, grappling, throwing, and pinning opponents to win matches and championships in the wrestling ring.",
      developer: "Unknown",
      platform: "Various",
      genre: "Wrestling",
    },
    Taito_Basketball: {
      description:
        "Basketball simulation game where players control teams, dribble, shoot, pass, and score points to win matches and championships in basketball leagues.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Taito_Chase_HQ: {
      description:
        "Driving action game where players control a car, chasing and capturing criminals, avoiding obstacles, and completing missions in various city settings.",
      developer: "Unknown",
      platform: "Various",
      genre: "Driving",
    },
    Taito_Front_Line: {
      description:
        "Top-down shooter game where players control a character, shooting enemies, avoiding obstacles, and completing missions in a military-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooter",
    },
    Taito_Grand_PrixEikou_No_License: {
      description:
        "Racing simulation game where players compete in Grand Prix races, drive cars, avoid obstacles, and reach the finish line to win championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Taiyou_No_ShindenAsteka2: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and exploring ancient civilizations in a historical setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Taiyou_No_YuushaFibird: {
      description:
        "Action-platformer game where players control a character, battling enemies, using weapons, and navigating levels to defeat bosses and complete challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    TAKAHAS4: {
      description: "Unknown",
      developer: "Unknown",
      platform: "Various",
      genre: "Unknown",
    },
    Takahashi_Meijin_No_Boukenjima: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Takahashi_Meijin_No_Boukenjima2: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Takahashi_Meijin_No_Boukenjima3: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Takahashi_Meijin_No_Boukenjima_4: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Takahashi_Meijin_No_Bugutte_Honey: {
      description:
        "Puzzle game where players match colors and shapes, clearing blocks and completing challenges in various game modes.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Takeda_Shingen: {
      description:
        "Strategy simulation game where players control armies, command troops, and engage in battles to conquer territories and achieve victory in feudal Japan.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Takeda_Shingen2: {
      description:
        "Strategy simulation game where players control armies, command troops, and engage in battles to conquer territories and achieve victory in feudal Japan.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Takeshi_No_Chousen_Jou: {
      description:
        "Action game where players control a character, avoiding obstacles, solving puzzles, and completing challenges to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Takeshi_No_Sengoku_Fuuunji: {
      description:
        "Action game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a historical setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    TALESPIN: {
      description:
        "Platformer video game where players control characters, navigating levels, defeating enemies, and completing challenges in the TaleSpin animated series setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Tamura_Koushou_No_Maajan_Seminar: {
      description:
        "Mahjong simulation game where players match tiles, form sets, and complete hands to win matches in various mahjong game modes.",
      developer: "Unknown",
      platform: "Various",
      genre: "Mahjong",
    },
    TandC2Thrillas_Surfari: {
      description:
        "Skateboarding video game where players control characters, performing tricks, avoiding obstacles, and completing challenges in various skateboarding locations.",
      developer: "Unknown",
      platform: "Various",
      genre: "Skateboarding",
    },
    TandC_Surf_Design: {
      description:
        "Skateboarding video game where players control characters, performing tricks, avoiding obstacles, and completing challenges in various skateboarding locations.",
      developer: "Unknown",
      platform: "Various",
      genre: "Skateboarding",
    },
    Tanigawa_Kouji_No_Shougi_Shinan2: {
      description:
        "Shogi simulation game where players compete in shogi matches, moving pieces strategically, capturing opponent's pieces, and aiming to checkmate the opponent's king.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shogi",
    },
    Tanigawa_Kouji_No_Shougi_Shinan3: {
      description:
        "Shogi simulation game where players compete in shogi matches, moving pieces strategically, capturing opponent's pieces, and aiming to checkmate the opponent's king.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shogi",
    },
    tanks: {
      description:
        "Tank battle game where players control tanks, shooting enemies, avoiding obstacles, and completing missions in a war-themed setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Tank battle",
    },
    Tantei_Jinguuji_SaburouToki_No_Sugiyukumamani: {
      description:
        "Visual novel adventure game where players control a detective, investigating crimes, solving mysteries, and interacting with characters to uncover the truth.",
      developer: "Unknown",
      platform: "Various",
      genre: "Visual novel",
    },
    Tao: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and progressing through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    TARGET: {
      description:
        "Target shooting game where players use a light gun to shoot targets, aim for accuracy, and achieve high scores in various shooting ranges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shooting",
    },
    Tashiro_Masashi_No_Princess_Ga_Ippai: {
      description:
        "Dating simulation game where players interact with characters, make choices, and build relationships to romance princesses in various storylines.",
      developer: "Unknown",
      platform: "Various",
      genre: "Dating simulation",
    },
    Tatakai_No_Banka: {
      description:
        "Fighting game where players control characters, use punches, kicks, and special moves to defeat opponents, and win matches in various arenas.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Technos_Ice_Hockey: {
      description:
        "Ice hockey simulation game where players control teams, pass, shoot, and score goals to win matches and championships in ice hockey leagues.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Technos_Samurai_Downtown_Special: {
      description:
        "Beat 'em up game where players control characters, fight waves of enemies, use punches, kicks, and weapons to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    Tecmo_Bowl: {
      description:
        "American football simulation game where players control teams, call plays, pass, run, tackle, and score touchdowns to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Tecmo_CupSoccer_Game: {
      description:
        "Soccer simulation game where players control teams, dribble, pass, shoot, and score goals to win matches and championships in soccer leagues.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Tecmo_NBA_Basketball: {
      description:
        "Basketball simulation game where players control NBA teams, dribble, pass, shoot, and score points to win matches and championships in basketball leagues.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Tecmo_Super_Bowl: {
      description:
        "American football simulation game where players control NFL teams, call plays, pass, run, tackle, and score touchdowns to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Tecmo_World_Cup_Soccer: {
      description:
        "Soccer simulation game where players control teams, dribble, pass, shoot, and score goals to win matches and championships in soccer leagues.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Teenage_Mutant_Ninja_Turtles: {
      description:
        "Action game where players control Teenage Mutant Ninja Turtles, battling enemies, using punches, kicks, and special moves to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Teenage_Mutant_Ninja_Turtles_Tournament_Fighters: {
      description:
        "Fighting game where players control Teenage Mutant Ninja Turtles characters, use punches, kicks, and special moves to defeat opponents, and win matches in various arenas.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Tekken2: {
      description:
        "Fighting game where players control characters, use punches, kicks, and special moves to defeat opponents, and win matches in various arenas.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Tenchi_Wo_Kurau: {
      description:
        "Beat 'em up game where players control characters, fight waves of enemies, use punches, kicks, and weapons to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Beat 'em up",
    },
    Tenchi_Wo_Kurau2_Zhuge_Kongming_Den: {
      description:
        "Strategy simulation game where players control armies, command troops, and engage in battles to conquer territories and achieve victory in ancient China.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Tenkaichi_BushiKeru_Naguuru: {
      description:
        "Action game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a historical setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    TENNIS: {
      description:
        "Tennis simulation game where players control players, serve, volley, and hit the ball to win matches and championships in tennis tournaments.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Terao_No_Dosukoi_Oozumou: {
      description:
        "Sumo wrestling simulation game where players control sumo wrestlers, push opponents, throw opponents out of the ring, and win matches in sumo tournaments.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Terra_Cresta: {
      description:
        "Shoot 'em up game where players control a spaceship, shoot enemies, avoid obstacles, and defeat bosses in space-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    Tetrastar_The_Fighter: {
      description:
        "Fighting game where players control characters, use punches, kicks, and special moves to defeat opponents, and win matches in various arenas.",
      developer: "Unknown",
      platform: "Various",
      genre: "Fighting",
    },
    Tetris: {
      description:
        "Puzzle game where players manipulate falling tetrominoes, clear lines, and score points by creating complete lines in the playfield.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    TETRIS2: {
      description:
        "Puzzle game where players manipulate falling tetrominoes, clear lines, and score points by creating complete lines in the playfield.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Tetris2_Bombliss: {
      description:
        "Puzzle game where players manipulate falling tetrominoes, clear lines, and score points by creating complete lines in the playfield.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    TETRISA: {
      description:
        "Puzzle game where players manipulate falling tetrominoes, clear lines, and score points by creating complete lines in the playfield.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Tetris_Flash: {
      description:
        "Puzzle game where players manipulate falling tetrominoes, clear lines, and score points by creating complete lines in the playfield.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Tetsudou_Ou: {
      description:
        "Train simulation game where players manage railways, control trains, schedule routes, and transport passengers and cargo to various destinations.",
      developer: "Unknown",
      platform: "Various",
      genre: "Simulation",
    },
    Tetsuwan_Atom: {
      description:
        "Action-platformer game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a sci-fi setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    TheLionKing: {
      description:
        "Platformer video game where players control characters from The Lion King animated film, navigating levels, defeating enemies, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Thexder: {
      description:
        "Shoot 'em up game where players control a transforming robot, shoot enemies, avoid obstacles, and defeat bosses in sci-fi-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    The_Little_Red_Hood: {
      description:
        "Platformer video game where players control the character Little Red Hood, navigating levels, avoiding enemies, and collecting items in a fairy tale setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    thunder: {
      description:
        "Shoot 'em up game where players control a spaceship, shoot enemies, avoid obstacles, and defeat bosses in space-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    Thunderbirds: {
      description:
        "Action game where players control characters from the Thunderbirds animated series, piloting vehicles, completing missions, and saving the day.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Thundercade: {
      description:
        "Shoot 'em up game where players control a spaceship, shoot enemies, avoid obstacles, and defeat bosses in space-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    ThunderWarrior: {
      description:
        "Action game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Thunder_and_Lightning: {
      description:
        "Shoot 'em up game where players control a spaceship, shoot enemies, avoid obstacles, and defeat bosses in space-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    Tie_Fighter2: {
      description:
        "Space simulation game where players control starfighters, engage in space battles, complete missions, and achieve victory in the Star Wars universe.",
      developer: "Unknown",
      platform: "Various",
      genre: "Space simulation",
    },
    Tiger_Heli: {
      description:
        "Shoot 'em up game where players control a helicopter, shoot enemies, avoid obstacles, and defeat bosses in war-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    TIGRHELI: {
      description:
        "Shoot 'em up game where players control a helicopter, shoot enemies, avoid obstacles, and defeat bosses in war-themed levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Shoot 'em up",
    },
    Tiles_of_Fate: {
      description:
        "Puzzle game where players match tiles, clear blocks, and complete challenges in various game modes to progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    timedoor: {
      description:
        "Action-adventure game where players control a character, solving puzzles, avoiding obstacles, and completing challenges to progress through levels in a time-traveling setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    TIMELORD: {
      description:
        "Action-adventure game where players control a character, solving puzzles, avoiding obstacles, and completing challenges to progress through levels in a time-traveling setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Times_of_Lore: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and completing quests to progress through a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Time_Conquest: {
      description:
        "Strategy simulation game where players control civilizations, manage resources, research technologies, and engage in wars to conquer territories and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    Time_Diver_Avenger: {
      description:
        "Action game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a sci-fi setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Time_Lord: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and completing quests to progress through levels in various time periods.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Time_Zone: {
      description:
        "Text-based adventure game where players read descriptions, input commands, and make choices to solve puzzles and progress through the story in various time periods.",
      developer: "Unknown",
      platform: "Various",
      genre: "Text-based adventure",
    },
    TINYTON2: {
      description:
        "Action-adventure game where players control a character, battling enemies, solving puzzles, and completing challenges to progress through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    TINYTOON: {
      description:
        "Platformer video game where players control Tiny Toon characters, navigating levels, defeating enemies, and completing challenges in the Tiny Toon Adventures animated series setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Tiny_Toon_Adventures: {
      description:
        "Platformer video game where players control Tiny Toon characters, navigating levels, defeating enemies, and completing challenges in the Tiny Toon Adventures animated series setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Tiny_Toon_Adventures2: {
      description:
        "Platformer video game where players control Tiny Toon characters, navigating levels, defeating enemies, and completing challenges in the Tiny Toon Adventures animated series setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Tiny_Toon_Adventures_Cartoon_Workshop: {
      description:
        "Creative game where players design and create their own Tiny Toon Adventures cartoon episodes, featuring characters, settings, and storylines from the animated series.",
      developer: "Unknown",
      platform: "Various",
      genre: "Creative",
    },
    Titan: {
      description:
        "Strategy simulation game where players control civilizations, manage resources, research technologies, and engage in wars to conquer territories and achieve victory.",
      developer: "Unknown",
      platform: "Various",
      genre: "Strategy",
    },
    tjzcn: {
      description: "Unknown",
      developer: "Unknown",
      platform: "Various",
      genre: "Unknown",
    },
    TMNT: {
      description:
        "Action game where players control Teenage Mutant Ninja Turtles, battling enemies, using punches, kicks, and special moves to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    TMNT1: {
      description:
        "Action game where players control Teenage Mutant Ninja Turtles, battling enemies, using punches, kicks, and special moves to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    TMNT2: {
      description:
        "Action game where players control Teenage Mutant Ninja Turtles, battling enemies, using punches, kicks, and special moves to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    TMNT3: {
      description:
        "Action game where players control Teenage Mutant Ninja Turtles, battling enemies, using punches, kicks, and special moves to defeat opponents, and progress through levels.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Toki: {
      description:
        "Platformer video game where players control a character, navigating levels, defeating enemies, and completing challenges to rescue a kidnapped princess in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Toki_No_Tabibito: {
      description:
        "Platformer video game where players control a character, navigating levels, defeating enemies, and completing challenges to rescue a kidnapped princess in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    Tokkyuu_ShireiSolbrain: {
      description:
        "Action-platformer game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in various settings.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    Tokoro_San_No_Mamorumo_Semerumo: {
      description:
        "Puzzle game where players match colors and shapes, clearing blocks and completing challenges in various game modes.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Tom_And_Jerry: {
      description:
        "Action game where players control characters from the Tom and Jerry animated series, navigating levels, avoiding enemies, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Tom_And_Jerry3: {
      description:
        "Action game where players control characters from the Tom and Jerry animated series, navigating levels, avoiding enemies, and completing challenges.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Tom_Sawyer_No_Bouken: {
      description:
        "Action-adventure game where players control Tom Sawyer, battling enemies, solving puzzles, and completing challenges to progress through levels in a historical setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-adventure",
    },
    Tonjan: {
      description:
        "Puzzle game where players match colors and shapes, clearing blocks and completing challenges in various game modes.",
      developer: "Unknown",
      platform: "Various",
      genre: "Puzzle",
    },
    Toobin: {
      description:
        "Racing game where players control characters in inner tubes, navigate rivers, avoid obstacles, and complete challenges in various river settings.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Top_Gun: {
      description:
        "Flight simulation game where players control fighter jets, engage in dogfights, complete missions, and achieve victory in air combat scenarios.",
      developer: "Unknown",
      platform: "Various",
      genre: "Flight simulation",
    },
    Top_Gun2Dual_Fighters: {
      description:
        "Flight simulation game where players control fighter jets, engage in dogfights, complete missions, and achieve victory in air combat scenarios.",
      developer: "Unknown",
      platform: "Various",
      genre: "Flight simulation",
    },
    Top_Rider: {
      description:
        "Racing game where players control motorcycles, race on tracks, avoid obstacles, and compete against opponents to achieve victory in motorcycle races.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Top_Striker: {
      description:
        "Soccer simulation game where players control teams, dribble, pass, shoot, and score goals to win matches and championships in soccer leagues.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },
    Totally_Rad: {
      description:
        "Action-platformer game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action-platformer",
    },
    Total_Recall: {
      description:
        "Action game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a sci-fi setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Totsuzen_Machoman: {
      description:
        "Action game where players control a character, battling enemies, avoiding obstacles, and completing challenges to progress through levels in a fantasy world setting.",
      developer: "Unknown",
      platform: "Various",
      genre: "Action",
    },
    Touch_Down_Fever: {
      description:
        "Football simulation game where players control teams, call plays, pass, run, tackle, and score touchdowns to win matches and championships.",
      developer: "Unknown",
      platform: "Various",
      genre: "Sports simulation",
    },

    Touhou_Kenbunroku: {
      description:
        "Touhou Kenbunroku is a unique visual novel game developed by the Japanese doujin community Team Shanghai Alice. The game is set in the library of Gensokyo in the Touhou universe. Players take on the role of an investigator and interact with various fantasy creatures, solving puzzles within the game.",
      developer: "Team Shanghai Alice",
      platform: "Various",
      genre: "Visual novel",
    },
    Toukaidou_Gojuusan_Tsugi: {
      description:
        "Toukaidou Gojuusan Tsugi is a role-playing game released in 1995, developed by Namco. The game is set in the Edo period of Japan. Players explore different regions, interact with various characters, complete quests, and embark on adventures.",
      developer: "Namco",
      platform: "Various",
      genre: "Role-playing",
    },
    Toukon_Kurabu: {
      description:
        "Toukon Kurabu is a professional wrestling game developed by Eleven and released in 1989. Players can choose different wrestling characters and participate in various matches and tournaments, challenging other professional wrestlers.",
      developer: "Eleven",
      platform: "Various",
      genre: "Sports simulation",
    },
    Toukyou_Pachi_Slot_Adventure: {
      description:
        "Toukyou Pachi-Slot Adventure is a simulation game that simulates playing slot machines in Tokyo's casinos. Developed by Natsume and released in 1996, players can experience the thrill of playing slot machines and try to win rewards.",
      developer: "Natsume",
      platform: "Various",
      genre: "Simulation",
    },
    Toushou_Ramen_Man: {
      description:
        "Toushou Ramen Man is an action game based on the Ramenman manga and anime series. Developed by Malibu Games and released in 1991, players assume the role of Ramenman and battle enemies to protect the city from evil forces.",
      developer: "Malibu Games",
      platform: "Various",
      genre: "Action",
    },
    Tower_of_Druaga: {
      description:
        "Tower of Druaga is a classic maze-based action role-playing game developed by Namco. Players navigate through maze-like levels, battling monsters and collecting treasures. The game is known for its challenging gameplay and intricate level designs.",
      developer: "Namco",
      platform: "Various",
      genre: "Action role-playing",
    },
    TOXCRUS: {
      description: "TOXCRUS is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    To_The_Earth: {
      description:
        "To The Earth is a shooter game where players control a spaceship and defend Earth from alien attacks. Developed by Cirque Verte and released in 1989, players shoot down incoming enemies and protect the planet.",
      developer: "Cirque Verte",
      platform: "Various",
      genre: "Shooter",
    },
    Track_and_Field: {
      description:
        "Track and Field is a sports simulation game where players participate in various Olympic events. Developed by Konami and released in 1983, players compete in events like running, jumping, and throwing to achieve the best scores.",
      developer: "Konami",
      platform: "Various",
      genre: "Sports simulation",
    },
    Track_and_Field2: {
      description:
        "Track and Field 2 is the sequel to the original Track and Field game, featuring more Olympic events and improved gameplay. Developed by Konami and released in 1988, players compete in events like swimming, hurdles, and long jump.",
      developer: "Konami",
      platform: "Various",
      genre: "Sports simulation",
    },
    TransformersConvoy_No_Nazo: {
      description:
        "Transformers: Convoy no Nazo is an action platformer game based on the Transformers franchise. Developed by ISCO and released in 1986, players control Autobot leader Optimus Prime in a quest to rescue kidnapped Autobots and defeat the Decepticons.",
      developer: "ISCO",
      platform: "Nintendo Entertainment System",
      genre: "Action platformer",
    },
    Treasure_Master: {
      description:
        "Treasure Master is a platformer game released for the Nintendo Entertainment System (NES) in 1991. Players control a character named Skooter on a quest to collect treasures in various levels filled with challenges and enemies.",
      developer: "Software Creations",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    Triathron: {
      description:
        "Triathron is a sports simulation game released for the Family Computer (Famicom) in 1989. Players can participate in three different Olympic events: swimming, cycling, and running. The game features competitive gameplay and challenges players to excel in all three sports.",
      developer: "Hect",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Trog: {
      description:
        "Trog is an arcade action game released by Midway in 1990. Players control a dinosaur and navigate mazes while avoiding enemies and collecting eggs. The game features both single-player and multiplayer modes.",
      developer: "Midway",
      platform: "Arcade",
      genre: "Action",
    },
    Trojan: {
      description:
        "Trojan is a side-scrolling action game developed by Capcom and released in arcades in 1986. Players control a hero with a sword and shield, battling enemies in a post-apocalyptic world. The game features challenging gameplay and boss fights.",
      developer: "Capcom",
      platform: "Arcade",
      genre: "Action",
    },
    Trolls_in_Crazyland: {
      description:
        "Trolls in Crazyland is a platformer game released for the Nintendo Entertainment System (NES) in 1993. Players control a character named Trellis, a troll, on a mission to rescue his friends and defeat enemies in various levels.",
      developer: "Sachen",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    Trolls_on_Treasure_Island: {
      description:
        "Trolls on Treasure Island is an action-adventure game released for the Nintendo Entertainment System (NES) in 1993. Players control a troll named Poppy on a quest to save his friends and restore harmony to Treasure Island. The game features platforming and puzzle-solving elements.",
      developer: "Sachen",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    tstd: {
      description: "tstd is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    tstd2c: {
      description: "tstd2c is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    tsubasa2: {
      description:
        "Captain Tsubasa II: Super Striker is a soccer role-playing game released for the Family Computer (Famicom) in 1990. Players control soccer teams and participate in matches, combining strategic gameplay with sports simulation.",
      developer: "Tecmo",
      platform: "Family Computer",
      genre: "Soccer role-playing",
    },
    Tsuppari_Wars: {
      description:
        "Tsuppari Wars is a puzzle game released for the Family Computer (Famicom) in 1991. Players solve puzzles by pushing blocks, avoiding enemies, and reaching the goal. The game features challenging puzzles and reflex-based gameplay.",
      developer: "Tonkin House",
      platform: "Family Computer",
      genre: "Puzzle",
    },
    Tsuri_Kichi_SanpeiBlue_Marlin_Hen: {
      description:
        "Tsuri Kichi Sanpei: Blue Marlin Hen is a fishing game released for the Family Computer (Famicom) in 1986. Players simulate fishing and aim to catch the biggest fish. The game offers a realistic fishing experience and various fishing locations.",
      developer: "Hot-B",
      platform: "Family Computer",
      genre: "Fishing simulation",
    },
    Tsuru_Pika_HagemaruMezase_Tsuru_Seko_No_Akashi: {
      description:
        "Tsuru Pika Hagemaru: Mezase! Tsuru Seko no Akashi is a puzzle game released for the Family Computer (Famicom) in 1991. Players solve puzzles by guiding a character through mazes and avoiding obstacles. The game features maze-solving challenges.",
      developer: "Sun L",
      platform: "Family Computer",
      genre: "Puzzle",
    },
    Twinbee: {
      description:
        "TwinBee is a vertically scrolling shoot 'em up game released by Konami in 1985. Players control cute ships, TwinBee and WinBee, as they shoot enemies and dodge bullets. The game features colorful graphics and cooperative multiplayer gameplay.",
      developer: "Konami",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    twinbee3: {
      description:
        "TwinBee 3: Poko Poko Daimaou is a shoot 'em up game released by Konami in 1987. Players control cute ships, TwinBee and WinBee, as they battle enemies in various levels. The game features cooperative multiplayer gameplay and power-up mechanics.",
      developer: "Konami",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    Twinbee3Poko_Poko_Daimaou: {
      description:
        "TwinBee 3: Poko Poko Daimaou is a shoot 'em up game released by Konami in 1987. Players control cute ships, TwinBee and WinBee, as they battle enemies in various levels. The game features cooperative multiplayer gameplay and power-up mechanics.",
      developer: "Konami",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    TWINCOBR: {
      description:
        "TWIN COBRA is a vertically scrolling shoot 'em up game released by Taito in 1987. Players control helicopters and battle enemies across various landscapes. The game features cooperative multiplayer gameplay and intense shooting action.",
      developer: "Taito",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    Twin_Eagle: {
      description:
        "Twin Eagle is a vertically scrolling shoot 'em up game released by Seta Corporation in 1988. Players control helicopters and battle enemies in intense aerial combat. The game features cooperative multiplayer gameplay and challenging boss fights.",
      developer: "Seta Corporation",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    Twin_EagleRevenge_Joes_Brother: {
      description:
        "Twin Eagle: Revenge Joe's Brother is a vertically scrolling shoot 'em up game released by Seta Corporation in 1988. Players control helicopters and battle enemies in intense aerial combat. The game features cooperative multiplayer gameplay and challenging boss fights.",
      developer: "Seta Corporation",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    UchuusenCosmo_Carrier: {
      description:
        "Uchuusen Cosmo Carrier is a shoot 'em up game released by SNK in 1980. Players control a spaceship and defend against waves of enemy ships. The game features classic shooting gameplay and simple controls.",
      developer: "SNK",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    Uchuu_Keibitai_SDF: {
      description:
        "Uchuu Keibitai SDF is a shoot 'em up game released by Sega in 1980. Players control a spaceship and battle waves of enemy ships and bosses. The game features classic shooting gameplay and challenging levels.",
      developer: "Sega",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    UFO: {
      description:
        "UFO is a fixed shooter arcade game released by Sega in 1978. Players control a turret and shoot down waves of descending UFOs. The game features simple gameplay and classic arcade shooting action.",
      developer: "Sega",
      platform: "Arcade",
      genre: "Fixed shooter",
    },
    Ufouria: {
      description:
        "Ufouria: The Saga is a platformer game released for the Nintendo Entertainment System (NES) in 1991. Players control anthropomorphic characters in a side-scrolling world, solving puzzles and battling enemies. The game features exploration and puzzle-solving gameplay.",
      developer: "Sunsoft",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    ULTIMA3: {
      description:
        "Ultima III: Exodus is a classic role-playing game developed by Origin Systems and released in 1983. Players embark on a quest to defeat the evil Exodus and save the land of Sosaria. The game features turn-based combat, exploration, and character customization.",
      developer: "Origin Systems",
      platform: "Various",
      genre: "Role-playing",
    },
    UltimaKyoufu_No_Exodus: {
      description:
        "Ultima: Kyoufu no Exodus is the Japanese version of Ultima III: Exodus, released for the Family Computer (Famicom) in 1987. Players embark on a quest to defeat the evil Exodus and save the land of Sosaria. The game features turn-based combat and exploration.",
      developer: "Pony Canyon",
      platform: "Family Computer",
      genre: "Role-playing",
    },
    UltimaSeija_E_No_Michi: {
      description:
        "Ultima: Seija e no Michi is the Japanese version of Ultima IV: Quest of the Avatar, released for the Family Computer (Famicom) in 1987. Players embark on a quest to become the Avatar, mastering virtues and saving the land of Britannia.",
      developer: "Pony Canyon",
      platform: "Family Computer",
      genre: "Role-playing",
    },
    Ultimate_Air_Combat: {
      description:
        "Ultimate Air Combat is a flight simulation game released for the Nintendo Entertainment System (NES) in 1991. Players pilot fighter jets and engage in aerial combat missions. The game features various aircraft and challenging dogfights.",
      developer: "Activision",
      platform: "Nintendo Entertainment System",
      genre: "Flight simulation",
    },
    Ultimate_Basketball: {
      description:
        "Ultimate Basketball is a basketball simulation game released for the Nintendo Entertainment System (NES) in 1990. Players can choose from various teams and compete in basketball matches. The game features basketball gameplay with different teams and players.",
      developer: "Aicom",
      platform: "Nintendo Entertainment System",
      genre: "Sports simulation",
    },
    Ultimate_Stuntman: {
      description:
        "Ultimate Stuntman is an action game released for the Nintendo Entertainment System (NES) in 1990. Players control a stuntman performing dangerous stunts in movie sets. The game features platforming levels with obstacles and challenges.",
      developer: "Codemasters",
      platform: "Nintendo Entertainment System",
      genre: "Action",
    },
    Ultraman_Kurabu: {
      description:
        "Ultraman Club: Supokon Fight! is a sports simulation game released for the Family Computer (Famicom) in 1988. Players can participate in various sports events and compete in challenges. The game features sports simulation gameplay.",
      developer: "Tsuburava",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Ultraman_Kurabu2Kaettekita_Ultraman: {
      description:
        "Ultraman Club 2: Kaettekita Ultraman Club is a sports simulation game released for the Family Computer (Famicom) in 1990. Players can participate in sports events and compete against opponents. The game features sports simulation gameplay.",
      developer: "Tsuburava",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Ultraman_Kurabu3: {
      description:
        "Ultraman Club 3: Mata, Tsukuware! Ultra Kyoudai is a sports simulation game released for the Family Computer (Famicom) in 1990. Players can participate in sports events and compete against opponents. The game features sports simulation gameplay.",
      developer: "Tsuburava",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Uncharted_Waters: {
      description:
        "Uncharted Waters is a simulation and strategy game released for various platforms, including the Family Computer (Famicom), in 1991. Players can explore the world, trade goods, and engage in naval battles. The game features historical simulation gameplay set during the Age of Exploration.",
      developer: "Koei",
      platform: "Various",
      genre: "Simulation, Strategy",
    },
    Untouchables: {
      description:
        "The Untouchables is an action game based on the movie of the same name, released for the Nintendo Entertainment System (NES) in 1991. Players control Eliot Ness and his team as they fight against organized crime in Chicago. The game features action-packed levels and boss fights.",
      developer: "Ocean Software",
      platform: "Nintendo Entertainment System",
      genre: "Action",
    },
    Urban_Champion: {
      description:
        "Urban Champion is a fighting game released for the Nintendo Entertainment System (NES) in 1984. Players engage in street fights, trying to knock out their opponents and win the match. The game features simple controls and competitive two-player gameplay.",
      developer: "Nintendo R&D1",
      platform: "Nintendo Entertainment System",
      genre: "Fighting",
    },
    Urusei_YatsuraLum_No_Wedding_Bell: {
      description:
        "Urusei Yatsura: Lum no Wedding Bell is a platformer game released for the Family Computer (Famicom) in 1986. Players control Ataru Moroboshi, the protagonist, as he navigates platforming levels to rescue Lum. The game features platforming challenges and enemy encounters.",
      developer: "Jaleco",
      platform: "Family Computer",
      genre: "Platformer",
    },
    USA_Ice_Hockey_in_FC: {
      description:
        "USA Ice Hockey in FC is a sports simulation game released for the Family Computer (Famicom) in 1988. Players can participate in ice hockey matches and compete against opponents. The game features sports simulation gameplay.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Ushio_To_ToraShinen_No_Daiyou: {
      description:
        "Ushio to Tora: Shinen no Daiyou is an action game based on the manga and anime series Ushio to Tora, released for the Family Computer (Famicom) in 1993. Players control Ushio as he battles enemies and bosses. The game features action gameplay and levels based on the series.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Action",
    },
    US_Championship_VBall: {
      description:
        "U.S. Championship V'Ball is a sports simulation game released for the Nintendo Entertainment System (NES) in 1990. Players can participate in beach volleyball matches and compete in tournaments. The game features volleyball gameplay with various teams.",
      developer: "Technos Japan",
      platform: "Nintendo Entertainment System",
      genre: "Sports simulation",
    },
    Utsurun_Desu: {
      description:
        "Utsurun Desu: Kawauso Hawaii e Iku!!! is an adventure game released for the Family Computer (Famicom) in 1992. Players control a character and navigate through the game's story, making choices and solving puzzles. The game features adventure gameplay and a unique storyline.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Adventure",
    },
    ValisThe_Fantastic_Soldier: {
      description:
        "Valis: The Fantastic Soldier is an action platformer game released for the Family Computer (Famicom) in 1987. Players control a heroine named Yuko Ahso as she battles enemies in side-scrolling levels. The game features platforming and action gameplay.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Action platformer",
    },
    Valkyrie_No_BoukenToki_No_Kagi_Densetsu: {
      description:
        "Valkyrie no Bouken: Toki no Kagi Densetsu is an action-adventure game released for the Family Computer (Famicom) in 1986. Players control a Valkyrie princess on a quest to rescue her kingdom. The game features action-adventure gameplay with platforming elements.",
      developer: "NAMCO",
      platform: "Family Computer",
      genre: "Action-adventure",
    },
    Vegas_Connection: {
      description:
        "Vegas Connection: Casino Kara Ai o Komete is a casino simulation game released for the Family Computer (Famicom) in 1990. Players can experience various casino games, including poker, blackjack, and roulette. The game features casino simulation gameplay.",
      developer: "HAL Laboratory",
      platform: "Family Computer",
      genre: "Casino simulation",
    },
    Venus_Senki: {
      description:
        "Venus Senki is an action role-playing game released for the Family Computer (Famicom) in 1989. Players control a character exploring dungeons, battling enemies, and collecting items. The game features action RPG gameplay with dungeon-crawling elements.",
      developer: "HAL Laboratory",
      platform: "Family Computer",
      genre: "Action RPG",
    },
    Venus_Wars: {
      description:
        "Venus Wars is a strategy game based on the anime and manga series Venus Wars, released for the PC Engine in 1991. Players command units and engage in tactical battles in a futuristic setting. The game features turn-based strategy gameplay.",
      developer: "Unknown",
      platform: "PC Engine",
      genre: "Strategy",
    },
    ViceProject_Doom: {
      description:
        "Vice: Project Doom is an action game released for the Nintendo Entertainment System (NES) in 1991. Players control a character named Quinn Hart, who battles enemies in side-scrolling levels and engages in various gameplay styles, including shooting and driving.",
      developer: "Aicom",
      platform: "Nintendo Entertainment System",
      genre: "Action",
    },
    Vindicators: {
      description:
        "Vindicators is an arcade action game released by Atari Games in 1988. Players control tanks and battle enemies in a top-down perspective. The game features cooperative multiplayer gameplay and power-up mechanics.",
      developer: "Atari Games",
      platform: "Arcade",
      genre: "Action",
    },
    "viper (c)": {
      description: "viper (c) is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    VIPER: {
      description: "VIPER is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    Viva_Las_Vegas: {
      description:
        "Viva Las Vegas is a casino simulation game released for the Nintendo Entertainment System (NES) in 1990. Players can experience various casino games, including poker, blackjack, and slot machines. The game features casino simulation gameplay.",
      developer: "HAL Laboratory",
      platform: "Nintendo Entertainment System",
      genre: "Casino simulation",
    },
    Volguard2: {
      description:
        "Volguard II is a shoot 'em up game released for the Family Computer (Famicom) in 1995. Players control a spaceship and battle waves of enemies in side-scrolling levels. The game features shooting gameplay and power-up mechanics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Shoot 'em up",
    },
    vs_battle_city: {
      description:
        "Vs. Battle City is an arcade game released by Namco in 1985. Players control tanks and battle enemies in a maze-like arena. The game features competitive multiplayer gameplay and strategic tank battles.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Action",
    },
    vs_clu_clu_land: {
      description:
        "Vs. Clu Clu Land is an arcade game released by Nintendo in 1984. Players control Bubbles, a character navigating mazes and uncovering hidden gold bars. The game features puzzle-solving gameplay and maze exploration.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Puzzle",
    },
    vs_dr_mario: {
      description:
        "Vs. Dr. Mario is an arcade puzzle game released by Nintendo in 1990. Players control Dr. Mario, matching colored pills to eliminate viruses in the playfield. The game features puzzle-solving gameplay and competitive multiplayer action.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Puzzle",
    },
    vs_duck_hunt: {
      description:
        "Vs. Duck Hunt is an arcade shooting game released by Nintendo in 1984. Players use a light gun to shoot ducks on the screen. The game features shooting gameplay and target practice.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Shooting",
    },
    VS_Excitebike: {
      description:
        "VS. Excitebike is an arcade motocross racing game released by Nintendo in 1988. Players race motocross bikes on tracks filled with obstacles and ramps. The game features racing gameplay and time trials.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Racing",
    },
    vs_excite_bike: {
      description:
        "Vs. Excitebike is an arcade motocross racing game released by Nintendo in 1988. Players race motocross bikes on tracks filled with obstacles and ramps. The game features racing gameplay and time trials.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Racing",
    },
    vs_golf: {
      description:
        "Vs. Golf is an arcade golf game released by Nintendo in 1984. Players control golfers and aim to complete courses with the fewest strokes. The game features golf gameplay and precision aiming.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Sports simulation",
    },
    vs_hogan_alley: {
      description:
        "Vs. Hogan's Alley is an arcade shooting game released by Nintendo in 1984. Players use a light gun to shoot gangsters and avoid shooting innocent civilians. The game features shooting gameplay and target practice.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Shooting",
    },
    vs_ice_climber: {
      description:
        "Vs. Ice Climber is an arcade platformer game released by Nintendo in 1985. Players control Ice Climbers Popo and Nana, climbing icy mountains and avoiding enemies. The game features platforming gameplay and cooperative multiplayer action.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Platformer",
    },
    vs_mach_rider: {
      description:
        "Vs. Mach Rider is an arcade racing game released by Nintendo in 1985. Players control a motorbike rider, racing through post-apocalyptic landscapes and battling enemies. The game features racing gameplay and combat mechanics.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Racing",
    },
    vs_pinball: {
      description:
        "Vs. Pinball is an arcade pinball game released by Nintendo in 1984. Players control flippers to hit the ball and score points on the pinball table. The game features pinball gameplay and high-score challenges.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Pinball",
    },
    vs_platoon: {
      description:
        "Vs. Platoon is an arcade shooter game based on the movie Platoon, released by Nintendo in 1987. Players control a soldier and battle enemies in a jungle environment. The game features shooting gameplay and action-packed levels.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Shooter",
    },
    vs_rbi_baseball: {
      description:
        "Vs. R.B.I. Baseball is an arcade baseball game released by Namco in 1987. Players control baseball teams and compete in matches. The game features baseball gameplay and competitive multiplayer action.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Sports simulation",
    },
    vs_slalom: {
      description:
        "Vs. Slalom is an arcade skiing game released by Nintendo in 1986. Players control a skier and navigate downhill slopes, avoiding obstacles and gates. The game features skiing gameplay and time trials.",
      developer: "Nintendo",
      platform: "Arcade",

      genre: "Sports simulation",
    },
    vs_soccer: {
      description:
        "Vs. Soccer is an arcade soccer game released by Nintendo in 1985. Players control soccer teams and compete in matches, aiming to score goals and win the game. The game features soccer gameplay and competitive multiplayer action.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Sports simulation",
    },
    VS_Star_Luster: {
      description:
        "Vs. Star Luster is an arcade space simulation game released by Namco in 1985. Players control a starship and engage in space battles against enemy fleets. The game features space combat gameplay and strategic battles.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Space simulation",
    },
    vs_super_mario_bros: {
      description:
        "Vs. Super Mario Bros. is an arcade platformer game released by Nintendo in 1986. Players control Mario or Luigi, navigating platforming levels, avoiding enemies, and rescuing Princess Peach. The game features platforming gameplay and cooperative multiplayer action.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Platformer",
    },
    VS_Tetris: {
      description:
        "Vs. Tetris is an arcade puzzle game released by Nintendo in 1988. Players manipulate falling blocks to complete lines and clear the playfield. The game features puzzle-solving gameplay and competitive multiplayer action.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Puzzle",
    },
    VS_TKO_Boxing: {
      description:
        "Vs. TKO Boxing is an arcade boxing game released by Nintendo in 1984. Players control boxers and compete in matches, aiming to knock out opponents and win bouts. The game features boxing gameplay and competitive multiplayer action.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Sports simulation",
    },
    vs_xevious_romset2: {
      description:
        "Vs. Xevious is an arcade shoot 'em up game released by Namco in 1983. Players control aircraft and battle waves of enemies in both air and ground combat. The game features shoot 'em up gameplay and challenging levels.",
      developer: "Namco",
      platform: "Arcade",
      genre: "Shoot 'em up",
    },
    wa: {
      description: "wa is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    wa2: {
      description: "wa2 is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    Wacky_Races: {
      description:
        "Wacky Races is a racing game released for various platforms, including the Nintendo Entertainment System (NES), in 1992. Players can choose from various characters and vehicles inspired by the classic animated series Wacky Races. The game features racing gameplay with power-ups and obstacles.",
      developer: "Unknown",
      platform: "Various",
      genre: "Racing",
    },
    Wagan_Land: {
      description:
        "Wagan Land is a platformer game released for the Family Computer (Famicom) in 1989. Players control a character named Wagan, navigating platforming levels and avoiding enemies. The game features platforming challenges and colorful graphics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Wagan_Land2: {
      description:
        "Wagan Land 2 is a platformer game released for the Family Computer (Famicom) in 1990. Players control a character named Wagan, navigating platforming levels and avoiding enemies. The game features platforming challenges and colorful graphics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Wagan_Land3: {
      description:
        "Wagan Land 3 is a platformer game released for the Family Computer (Famicom) in 1991. Players control a character named Wagan, navigating platforming levels and avoiding enemies. The game features platforming challenges and colorful graphics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Platformer",
    },
    WaitAndSee: {
      description:
        "Wait and See: Eine Fantastische Reise is an adventure game released for the Nintendo Entertainment System (NES) in 1989. Players control a character exploring a surreal and dreamlike world, solving puzzles and interacting with bizarre characters. The game features adventure gameplay and a unique atmosphere.",
      developer: "ASCII",
      platform: "Nintendo Entertainment System",
      genre: "Adventure",
    },
    Wai_Wai_World: {
      description:
        "Wai Wai World is a platformer game released for the Family Computer (Famicom) in 1988. Players control a character navigating platforming levels filled with enemies and obstacles. The game features crossover elements, with characters from various Konami games appearing as playable characters.",
      developer: "Konami",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Wai_Wai_World2SOS_Paseri_Jou: {
      description:
        "Wai Wai World 2: SOS!! Paseri Jou is a platformer game released for the Family Computer (Famicom) in 1991. Players control a character navigating platforming levels filled with enemies and obstacles. The game features crossover elements, with characters from various Konami games appearing as playable characters.",
      developer: "Konami",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Wai_Xin_Zhan_Shi: {
      description:
        "Wai Xin Zhan Shi is a shoot 'em up game released for the Family Computer (Famicom) in 1989. Players control a spaceship and battle waves of enemies in vertical scrolling levels. The game features shooting gameplay and power-up mechanics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Shoot 'em up",
    },
    Wally_Bear_and_the_No_Gang: {
      description:
        "Wally Bear and the NO! Gang is an educational game released for the Nintendo Entertainment System (NES) in 1992. Players control Wally Bear, who must navigate levels and avoid peer pressure and drugs. The game aims to educate players about the dangers of substance abuse.",
      developer: "American Game Cartridges",
      platform: "Nintendo Entertainment System",
      genre: "Educational",
    },
    Wall_Street_Kid: {
      description:
        "Wall Street Kid is a simulation game released for the Nintendo Entertainment System (NES) in 1990. Players take on the role of a young stockbroker aiming to make a fortune in the stock market. The game features simulation gameplay, investment strategies, and financial management.",
      developer: "SOFEL",
      platform: "Nintendo Entertainment System",
      genre: "Simulation",
    },
    Wang_Di: {
      description:
        "Wang Di is a puzzle game released for the Nintendo Entertainment System (NES) in 1991. Players control a character navigating mazes and collecting hearts while avoiding enemies. The game features puzzle-solving gameplay and maze exploration.",
      developer: "Sachen",
      platform: "Nintendo Entertainment System",
      genre: "Puzzle",
    },
    Wanpaku_Duck_Yume_Bouken: {
      description:
        "Wanpaku Duck Yume Bouken is a platformer game released for the Family Computer (Famicom) in 1990. Players control a character named Wanpaku Duck, navigating platforming levels and avoiding enemies. The game features platforming challenges and colorful graphics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Wanpaku_Kokkun_No_Gurume_World: {
      description:
        "Wanpaku Kokkun no Gourmet World is an action game released for the Family Computer (Famicom) in 1991. Players control a character exploring levels, collecting ingredients, and avoiding enemies. The game features action gameplay and food-themed levels.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Action",
    },
    warehn18: {
      description: "warehn18 is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    Warios_Woods: {
      description:
        "Wario's Woods is a puzzle game released for various platforms, including the Nintendo Entertainment System (NES), in 1994. Players control Toad as he stacks and clears falling pieces in a match-3 style gameplay. The game features puzzle-solving gameplay and competitive multiplayer action.",
      developer: "Nintendo",
      platform: "Various",
      genre: "Puzzle",
    },
    Wario_No_Mori: {
      description:
        "Wario no Mori is a simulation game released for the Nintendo 64DD in 2001. Players manage a village and its inhabitants, making decisions and customizing the town. The game features simulation gameplay and village management.",
      developer: "Nintendo",
      platform: "Nintendo 64DD",
      genre: "Simulation",
    },
    Warpman: {
      description:
        "Warpman is an action game released for the Family Computer (Famicom) in 1985. Players control a character navigating maze-like levels, defeating enemies and collecting items. The game features action gameplay and cooperative multiplayer action.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Action",
    },
    Warrior_of_Argos: {
      description:
        "Warrior of Argos is an action-adventure game released for the Nintendo Entertainment System (NES) in 1989. Players control a warrior exploring dungeons, battling enemies, and collecting items. The game features action-adventure gameplay with RPG elements.",
      developer: "Unknown",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    wataru: {
      description: "wataru is a game with an unknown description.",
      developer: "Unknown",
      platform: "Unknown",
      genre: "Unknown",
    },
    Waynes_World: {
      description:
        "Wayne's World is a platformer game released for various platforms, including the Nintendo Entertainment System (NES), in 1993. Players control Wayne and Garth as they embark on a musical adventure, avoiding enemies and collecting musical notes. The game features platforming gameplay and levels based on the movie and TV show.",
      developer: "Unknown",
      platform: "Various",
      genre: "Platformer",
    },
    WCW_World_Championship_Wrestling: {
      description:
        "WCW: World Championship Wrestling is a wrestling game released for the Nintendo Entertainment System (NES) in 1990. Players can choose from various WCW wrestlers and compete in wrestling matches, aiming to win championships. The game features wrestling gameplay and competitive multiplayer action.",
      developer: "Unknown",
      platform: "Nintendo Entertainment System",
      genre: "Wrestling",
    },
    Werewolf: {
      description:
        "Werewolf: The Last Warrior is an action platformer game released for the Nintendo Entertainment System (NES) in 1990. Players control a warrior who can transform into a werewolf, battling enemies and bosses in side-scrolling levels. The game features platforming and action gameplay.",
      developer: "Data East",
      platform: "Nintendo Entertainment System",
      genre: "Action platformer",
    },
    Western_Kids: {
      description:
        "Western Kids is a platformer game released for the Family Computer (Famicom) in 1993. Players control a character navigating platforming levels, defeating enemies and avoiding obstacles. The game features platforming challenges and colorful graphics.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Wheel_of_Fortune: {
      description:
        "Wheel of Fortune is a game show video game released for various platforms, including the Nintendo Entertainment System (NES), in 1987. Players can participate in the TV game show experience, solving word puzzles and spinning the wheel. The game features gameplay based on the popular TV show.",
      developer: "Rare",
      platform: "Various",
      genre: "Game show",
    },
    Wheel_of_Fortune_Family_Edition: {
      description:
        "Wheel of Fortune: Family Edition is a game show video game released for various platforms, including the Nintendo Entertainment System (NES), in 1990. Players can participate in the TV game show experience with family and friends, solving word puzzles and spinning the wheel. The game features gameplay based on the popular TV show.",
      developer: "Rare",
      platform: "Various",
      genre: "Game show",
    },
    Wheel_of_Fortune_Junior_Edition: {
      description:
        "Wheel of Fortune: Junior Edition is a game show video game released for various platforms, including the Nintendo Entertainment System (NES), in 1989. Players can participate in the TV game show experience designed for younger players, solving word puzzles and spinning the wheel. The game features gameplay based on the popular TV show.",
      developer: "GameTek",
      platform: "Various",
      genre: "Game show",
    },
    Wheel_of_Fortune_Starring_Vanna_White: {
      description:
        "Wheel of Fortune Starring Vanna White is a game show video game released for various platforms, including the Nintendo Entertainment System (NES), in 1992. Players can participate in the TV game show experience hosted by Vanna White, solving word puzzles and spinning the wheel. The game features gameplay based on the popular TV show.",
      developer: "GameTek",
      platform: "Various",
      genre: "Game show",
    },
    Wheres_Waldo: {
      description:
        "Where's Waldo? is a puzzle adventure game released for various platforms, including the Nintendo Entertainment System (NES), in 1991. Players must find Waldo and other characters in detailed illustrations within a time limit. The game features puzzle-solving gameplay and colorful visuals.",
      developer: "Bethesda Softworks",
      platform: "Various",
      genre: "Puzzle",
    },
    Where_in_Time_is_Carmen_Sandiego: {
      description:
        "Where in Time is Carmen Sandiego? is an educational adventure game released for various platforms, including the Nintendo Entertainment System (NES), in 1989. Players take on the role of ACME agents, traveling through time to thwart Carmen Sandiego's plans. The game features educational gameplay, historical information, and puzzle-solving challenges.",
      developer: "Broderbund",
      platform: "Various",
      genre: "Educational",
    },
    White_Lion_Densetsu: {
      description:
        "White Lion Densetsu is a role-playing game released for the Family Computer (Famicom) in 1989. Players control a character on a quest to rescue a princess and save the kingdom. The game features RPG gameplay, exploration, and turn-based battles.",
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Role-playing",
    },
    WhompEm: {
      description:
        "Whomp 'Em, also known as Saiyūki World 2: Tenjōkai no Majin in Japan, is a platformer game released for the Nintendo Entertainment System (NES) in 1991. Players control a Native American warrior named Soaring Eagle, battling enemies and bosses in side-scrolling levels. The game features platforming and action gameplay.",
      developer: "Jaleco",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    Widget: {
      description:
        "Widget is a platformer game released for various platforms, including the Nintendo Entertainment System (NES), in 1992. Players control Widget, a small purple creature, navigating platforming levels and defeating enemies. The game features colorful graphics and platforming challenges.",
      developer: "Graphic Research",
      platform: "Various",
      genre: "Platformer",
    },
    Wild_Gunman: {
      description:
        "Wild Gunman is a light gun shooting game released by Nintendo in 1984. Players use a light gun to draw and shoot at outlaws appearing on the screen. The game features shooting gameplay and target practice.",
      developer: "Nintendo",
      platform: "Arcade",
      genre: "Shooting",
    },
    Willow: {
      description:
        "Willow is an action role-playing game based on the 1988 film of the same name. Players control Willow Ufgood, a young farmer, who embarks on a quest to save a special baby from an evil queen. The game features action RPG gameplay, exploration, and magic.",
      developer: "Capcom",
      platform: "Nintendo Entertainment System",
      genre: "Action RPG",
    },
    Wily_and_Light_No_RockboardThats_Paradise: {
      description:
        "Wily & Right no RockBoard: That's Paradise is a video board game spin-off of the Mega Man series. Players compete on a game board, investing in properties and battling each other. The game features board game mechanics and Mega Man-themed elements.",
      developer: "Capcom",
      platform: "Nintendo Entertainment System",
      genre: "Board game",
    },
    Win_Lose_or_Draw: {
      description:
        "Win, Lose or Draw is a video game adaptation of the television game show where players guess words and phrases based on drawings. Players can participate in drawing challenges and compete in teams. The game features drawing and guessing gameplay.",
      developer: "Rare",
      platform: "Nintendo Entertainment System",
      genre: "Party game",
    },
    Wing_of_Madoola: {
      description:
        "Wing of Madoola, also known as Madoola no Tsubasa: The Wing of Madoola, is an action-adventure game. Players control a character named Rael, battling enemies and exploring levels to rescue a princess. The game features side-scrolling action and platforming.",
      developer: "Konami",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    Winter_Games: {
      description:
        "Winter Games is a sports video game featuring various winter sports events. Players can compete in events such as skiing, ice hockey, and figure skating. The game features sports simulation gameplay and competitive multiplayer action.",
      developer: "Epyx",
      platform: "Various",
      genre: "Sports simulation",
    },
    Wits: {
      description:
        "Wits is a puzzle video game where players solve a variety of puzzles, including math problems, word games, and pattern recognition challenges. The game features puzzle-solving gameplay and brain-teasing challenges.",
      developer: "BAM! Entertainment",
      platform: "Game Boy",
      genre: "Puzzle",
    },
    Wizardry2Legacy_of_Llylgamyn: {
      description:
        "Wizardry II: The Knight of Diamonds is a role-playing game and the second installment in the Wizardry series. Players create a party of adventurers and explore dungeons, battling monsters and solving quests. The game features turn-based combat and dungeon crawling.",
      developer: "Sir-Tech",
      platform: "Various",
      genre: "Role-playing",
    },
    Wizardry3The_Knight_of_Diamonds: {
      description:
        "Wizardry III: The Knight of Diamonds is a role-playing game and the third installment in the Wizardry series. Players continue their adventure, exploring dungeons, battling monsters, and solving quests. The game features turn-based combat and dungeon crawling.",
      developer: "Sir-Tech",
      platform: "Various",
      genre: "Role-playing",
    },
    WizardryProving_Grounds_of_the_Mad_Overlord: {
      description:
        "Wizardry: Proving Grounds of the Mad Overlord is a classic role-playing game and the first installment in the Wizardry series. Players create a party of adventurers and explore a dungeon, battling monsters and collecting treasures. The game features turn-based combat and dungeon crawling.",
      developer: "Sir-Tech",
      platform: "Various",
      genre: "Role-playing",
    },
    Wizards_and_Warriors: {
      description:
        "Wizards & Warriors is a platformer game where players control the knight Kuros on a quest to rescue a kidnapped princess. Players navigate levels, battle enemies, and collect items. The game features platforming challenges and action gameplay.",
      developer: "Rare",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    Wizards_and_Warriors3: {
      description:
        "Wizards & Warriors III: Kuros: Visions of Power is the third installment in the Wizards & Warriors series. Players control the knight Kuros, exploring levels, battling enemies, and collecting items. The game features platforming challenges and action gameplay.",
      developer: "Rare",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    Wolverine: {
      description:
        "Wolverine is an action-adventure game featuring the Marvel Comics character Wolverine. Players control Wolverine, battling enemies and solving puzzles in side-scrolling levels. The game features action-adventure gameplay and superhero-themed elements.",
      developer: "Software Creations",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    Woody_Poko: {
      description:
        "Woody Poko is a platformer game where players control Woody Poko, a character who must rescue his friends from enemies. Players navigate levels, defeat enemies, and collect items. The game features platforming challenges and action gameplay.",
      developer: "Namco",
      platform: "Family Computer",
      genre: "Platformer",
    },
    World_Boxing: {
      description:
        "World Boxing is a boxing sports simulation game. Players can create a boxer and compete in boxing matches, aiming to become the world champion. The game features boxing simulation gameplay and sports challenges.",
      developer: "Marubeni",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    World_Champ: {
      description:
        "World Champ is a boxing sports simulation game. Players can choose a boxer and compete in boxing matches, aiming to become the world champion. The game features boxing simulation gameplay and sports challenges.",
      developer: "Romstar",
      platform: "Nintendo Entertainment System",
      genre: "Sports simulation",
    },
    World_Games: {
      description:
        "World Games is a sports video game featuring non-traditional sports events from around the world. Players can compete in events such as log rolling, cliff diving, and caber toss. The game features sports simulation gameplay and competitive multiplayer action.",
      developer: "Epyx",
      platform: "Various",
      genre: "Sports simulation",
    },
    World_Grand_PrixPole_To_Finish: {
      description:
        "World Grand Prix: Pole to Finish is a Formula 1 racing game. Players can choose a Formula 1 car and compete in races on various tracks. The game features racing simulation gameplay and competitive multiplayer action.",
      developer: "Sega",
      platform: "Sega Master System",
      genre: "Racing",
    },
    World_Heroes2: {
      description:
        "World Heroes 2 is a fighting game and the second installment in the World Heroes series. Players can choose from a roster of historical and fictional characters and compete in one-on-one battles. The game features fighting gameplay and competitive multiplayer action.",
      developer: "ADK",
      platform: "Various",
      genre: "Fighting",
    },
    World_Super_Tennis: {
      description:
        "World Super Tennis is a tennis sports simulation game. Players can choose a tennis player and compete in tennis matches on different surfaces. The game features tennis simulation gameplay and sports challenges.",
      developer: "Clary",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Wrath_of_the_Black_Manta: {
      description:
        "Wrath of the Black Manta is an action-platformer game. Players control a ninja named Black Manta, battling enemies and rescuing children from kidnappers. The game features side-scrolling action and platforming.",
      developer: "AI",
      platform: "Nintendo Entertainment System",
      genre: "Action-platformer",
    },
    wrecking: {
      description:
        "Wrecking Crew is a puzzle-platformer game. Players control Mario, who must demolish walls while avoiding enemies. The game features puzzle-solving and platforming gameplay.",
      developer: "Nintendo R&D1",
      platform: "Nintendo Entertainment System",
      genre: "Puzzle-platformer",
    },
    wrest: {
      description:
        "WrestleMania Challenge is a professional wrestling game featuring WWE (then WWF) superstars. Players can choose their favorite wrestler and compete in wrestling matches, performing moves and trying to win the championship title. The game features wrestling simulation gameplay and competitive multiplayer action.",
      developer: "Rare",
      platform: "Nintendo Entertainment System",
      genre: "Wrestling",
    },
    Wurm: {
      description:
        "Wurm: Journey to the Center of the Earth is an action-adventure game. Players control a character exploring underground caves, battling monsters and solving puzzles. The game features action-adventure gameplay and exploration.",
      developer: "Cyclone System",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    WWF_King_of_the_Ring: {
      description:
        "WWF King of the Ring is a professional wrestling game featuring WWE (then WWF) superstars. Players can choose their favorite wrestler and compete in wrestling matches, performing moves and trying to win the King of the Ring tournament. The game features wrestling simulation gameplay and competitive multiplayer action.",
      developer: "Sculptured Software",
      platform: "Nintendo Entertainment System",
      genre: "Wrestling",
    },
    WWF_Steel_Cage_Challenge: {
      description:
        "WWF Steel Cage Challenge is a professional wrestling game featuring WWE (then WWF) superstars. Players can choose their favorite wrestler and compete in wrestling matches, performing moves and trying to win matches inside a steel cage. The game features wrestling simulation gameplay and competitive multiplayer action.",
      developer: "Sculptured Software",
      platform: "Nintendo Entertainment System",
      genre: "Wrestling",
    },
    WWF_Wrestlemania: {
      description:
        "WWF WrestleMania is a professional wrestling game featuring WWE (then WWF) superstars. Players can choose their favorite wrestler and compete in wrestling matches, performing moves and trying to win matches at WrestleMania. The game features wrestling simulation gameplay and competitive multiplayer action.",
      developer: "Rare",
      platform: "Nintendo Entertainment System",
      genre: "Wrestling",
    },
    WWF_Wrestlemania_Challenge: {
      description:
        "WWF WrestleMania Challenge is a professional wrestling game featuring WWE (then WWF) superstars. Players can choose their favorite wrestler and compete in wrestling matches, performing moves and trying to win championships. The game features wrestling simulation gameplay and competitive multiplayer action.",
      developer: "Rare",
      platform: "Nintendo Entertainment System",
      genre: "Wrestling",
    },
    Xenophobe: {
      description:
        "Xenophobe is a multi-directional shooter game. Players control characters exploring space stations, battling aliens and repairing systems. The game features shooting gameplay and cooperative multiplayer action.",
      developer: "Bally Midway",
      platform: "Various",
      genre: "Shooter",
    },
    Xevious: {
      description:
        "Xevious is a vertically scrolling shooter game. Players control an aircraft, battling enemies and avoiding obstacles in the air and on the ground. The game features shooting gameplay and challenging levels.",
      developer: "Namco",
      platform: "Various",
      genre: "Shooter",
    },
    Xexyz: {
      description:
        "Xexyz is a platformer game where players control a character exploring various levels, battling enemies, and collecting items. The game features platforming challenges, action gameplay, and colorful visuals.",
      developer: "Atlus",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    xmem: {
      description:
        "X-Mem is a puzzle game where players match pairs of symbols to clear the board. The game features puzzle-solving gameplay and memory challenges.",
      developer: "Home Data",
      platform: "Nintendo Entertainment System",
      genre: "Puzzle",
    },
    X_MEN: {
      description:
        "X-Men is an action game featuring the Marvel Comics superhero team X-Men. Players can choose from different X-Men characters and battle enemies using their unique abilities. The game features action gameplay and superhero-themed elements.",
      developer: "Software Creations",
      platform: "Nintendo Entertainment System",
      genre: "Action",
    },
    Yaji_Kita_Chin_Douchuu: {
      description:
        'Yaji Kita Chin Douchuu is an action-adventure game based on the novel "Tokaidochu Hizakurige." Players control the character Yaji, traveling along the Tokaido road, battling enemies, and completing quests. The game features action-adventure gameplay and exploration.',
      developer: "Unknown",
      platform: "Family Computer",
      genre: "Action-adventure",
    },
    Yamamura_Misa_SuspenseKyouto_Hana_No_Misshitsu_Satsujin: {
      description:
        "Yamamura Misa Suspense: Kyouto Hana no Misshitsu Satsujin is an adventure game where players solve a murder mystery in Kyoto. Players investigate the crime scene, gather clues, and interrogate suspects to solve the case. The game features mystery-solving gameplay and visual novel elements.",
      developer: "TOSE",
      platform: "Family Computer",
      genre: "Adventure",
    },
    Yamamura_Misa_SuspenseKyouto_Ryuu_No_Tera_Satsujin: {
      description:
        "Yamamura Misa Suspense: Kyouto Ryuu no Tera Satsujin is an adventure game where players solve a murder mystery at a Kyoto temple. Players investigate the crime scene, gather clues, and interrogate suspects to solve the case. The game features mystery-solving gameplay and visual novel elements.",
      developer: "TOSE",
      platform: "Family Computer",
      genre: "Adventure",
    },
    Yamamura_Misa_SuspenseKyouto_Zaiteku_Satsujin_Jiken: {
      description:
        "Yamamura Misa Suspense: Kyouto Zaiteku Satsujin Jiken is an adventure game where players solve a murder mystery in Kyoto. Players investigate the crime scene, gather clues, and interrogate suspects to solve the case. The game features mystery-solving gameplay and visual novel elements.",
      developer: "TOSE",
      platform: "Family Computer",
      genre: "Adventure",
    },
    YANG: {
      description:
        "YANG is a puzzle game where players navigate mazes, avoiding obstacles and enemies to collect items and reach the exit. The game features maze-solving gameplay and puzzle challenges.",
      developer: "Vic Tokai",
      platform: "Family Computer",
      genre: "Puzzle",
    },
    Yie_Ar_Kung_Fu: {
      description:
        "Yie Ar Kung-Fu is a fighting game where players control a martial artist named Oolong. Players battle a series of opponents, each with unique fighting styles and abilities. The game features fighting gameplay and competitive multiplayer action.",
      developer: "Konami",
      platform: "Various",
      genre: "Fighting",
    },
    ylqxz: {
      description:
        "Yong Zhe Dou E Long: Dragon Quest is an action-adventure game based on the manga and anime series. Players control characters, battling enemies and completing quests in side-scrolling levels. The game features action-adventure gameplay and exploration.",
      developer: "Suzhou Hong Zhuang Yuan Culture Communication Co., Ltd.",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    Yokohama_Renzoku_Satsujin_Jiken: {
      description:
        "Yokohama Renzoku Satsujin Jiken is an adventure game where players solve a series of murder mysteries in Yokohama. Players investigate crime scenes, gather clues, and interrogate suspects to solve the cases. The game features mystery-solving gameplay and visual novel elements.",
      developer: "TOSE",
      platform: "Family Computer",
      genre: "Adventure",
    },
    Yong_Ze_Do_Re_Long_6: {
      description:
        "Yong Zhe Dou E Long 6 is an action-adventure game based on the manga and anime series. Players control characters, battling enemies and completing quests in side-scrolling levels. The game features action-adventure gameplay and exploration.",
      developer: "Suzhou Hong Zhuang Yuan Culture Communication Co., Ltd.",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    YONOID: {
      description:
        "YONOID is a breakout-style game where players control a paddle and bounce a ball to break bricks. The game features brick-breaking gameplay and arcade challenges.",
      developer: "SUNSOFT",
      platform: "Nintendo Entertainment System",
      genre: "Arcade",
    },
    "Yoshi No Tamago": {
      description:
        "Yoshi no Tamago is a puzzle game where players match eggs to clear the board. The game features puzzle-solving gameplay and colorful visuals.",
      developer: "Game Freak",
      platform: "Family Computer",
      genre: "Puzzle",
    },
    Yoshi_Hash_Cookie: {
      description:
        "Yoshi no Cookie: Kuruppon Oven de Cookie is a puzzle game where players match cookies to clear the board. The game features puzzle-solving gameplay and colorful visuals.",
      developer: "Nintendo R&D1",
      platform: "Nintendo Entertainment System",
      genre: "Puzzle",
    },
    Yoshi_No_Cookie: {
      description:
        "Yoshi no Cookie is a puzzle game where players match cookies to clear the board. The game features puzzle-solving gameplay and colorful visuals.",
      developer: "Nintendo R&D1",
      platform: "Family Computer",
      genre: "Puzzle",
    },
    Yoshi_No_Tamago: {
      description:
        "Yoshi no Tamago is a puzzle game where players match eggs to clear the board. The game features puzzle-solving gameplay and colorful visuals.",
      developer: "Game Freak",
      platform: "Family Computer",
      genre: "Puzzle",
    },
    youkai: {
      description:
        "Youkai Douchuuki is an action-adventure game where players control a character battling supernatural creatures. Players navigate levels, defeat enemies, and collect items. The game features action-adventure gameplay and exploration.",
      developer: "TOSE",
      platform: "Family Computer",
      genre: "Action-adventure",
    },
    Youkai_Douchuuki: {
      description:
        "Youkai Douchuuki is an action-adventure game where players control a character battling supernatural creatures. Players navigate levels, defeat enemies, and collect items. The game features action-adventure gameplay and exploration.",
      developer: "Namco",
      platform: "Various",
      genre: "Action-adventure",
    },
    Youkai_Kurabu: {
      description:
        "Youkai Kurabu is an action-adventure game where players control a character battling supernatural creatures. Players navigate levels, defeat enemies, and collect items. The game features action-adventure gameplay and exploration.",
      developer: "KSS",
      platform: "Family Computer",
      genre: "Action-adventure",
    },
    Young_Indiana_Jones_Chronicles: {
      description:
        "Young Indiana Jones Chronicles is an action-adventure game based on the television series. Players control Indiana Jones, exploring levels, battling enemies, and solving puzzles. The game features action-adventure gameplay and exploration.",
      developer: "Jaleco Entertainment",
      platform: "Nintendo Entertainment System",
      genre: "Action-adventure",
    },
    youyou_c: {
      description:
        "Youyou Senki is a role-playing game where players control a party of characters, exploring dungeons, battling monsters, and completing quests. The game features turn-based combat and dungeon crawling.",
      developer: "Data East",
      platform: "Nintendo Entertainment System",
      genre: "Role-playing",
    },
    Ys: {
      description:
        "Ys is an action role-playing game and the first installment in the Ys series. Players control the adventurer Adol Christin, battling enemies and exploring dungeons. The game features action RPG gameplay and real-time combat.",
      developer: "Nihon Falcom",
      platform: "Various",
      genre: "Action RPG",
    },
    Ys2Ancient_Ys_Vanished: {
      description:
        "Ys II: Ancient Ys Vanished – The Final Chapter is an action role-playing game and the second installment in the Ys series. Players continue the adventure of Adol Christin, exploring new locations, battling enemies, and solving quests. The game features action RPG gameplay and real-time combat.",
      developer: "Nihon Falcom",
      platform: "Various",
      genre: "Action RPG",
    },
    Ys3Wanderers_From_Ys: {
      description:
        "Ys III: Wanderers from Ys is an action role-playing game and the third installment in the Ys series. Players control Adol Christin, battling enemies and exploring a new land. The game features action RPG gameplay and side-scrolling combat.",
      developer: "Nihon Falcom",
      platform: "Various",
      genre: "Action RPG",
    },
    Yume_Penguin_Monogatari: {
      description:
        "Yume Penguin Monogatari is a platformer game where players control a penguin named Penta, battling enemies and overcoming obstacles to rescue his girlfriend Penko. The game features platforming challenges and action gameplay.",
      developer: "Konami",
      platform: "Family Computer",
      genre: "Platformer",
    },
    Yuu_Yuu: {
      description:
        "Yuu Yuu Hakusho: Makai no Tobira is a fighting game based on the Yu Yu Hakusho anime and manga series. Players can choose characters from the series and battle in one-on-one fights. The game features fighting gameplay and competitive multiplayer action.",
      developer: "Namco",
      platform: "Family Computer",
      genre: "Fighting",
    },
    ZANAC: {
      description:
        "Zanac is a vertically scrolling shooter game. Players control the AFX-6502 Zanac ship, battling various enemies and bosses. The game features shooting gameplay and challenging levels.",
      developer: "Compile",
      platform: "Various",
      genre: "Shooter",
    },
    Zenbei_Pro_Basketball: {
      description:
        "Zenbei Pro Basketball is a basketball sports simulation game. Players can choose a basketball team and compete in matches against other teams. The game features basketball simulation gameplay and sports challenges.",
      developer: "KID",
      platform: "Family Computer",
      genre: "Sports simulation",
    },
    Zen_Intergalactic_Ninja: {
      description:
        "Zen: Intergalactic Ninja is a platformer game where players control Zen, battling enemies and saving the Earth from evil forces. Players navigate levels, defeat enemies, and collect power-ups. The game features platforming challenges and action gameplay.",
      developer: "Konami",
      platform: "Nintendo Entertainment System",
      genre: "Platformer",
    },
    Zhong_Guo_Xiang_Qi: {
      description:
        "Zhong Guo Xiang Qi is a chess game based on traditional Chinese chess rules. Players can compete against the computer or other players in chess matches. The game features chess gameplay and strategic challenges.",
      developer: "Thin Chen Enterprise",
      platform: "Family Computer",
      genre: "Board game",
    },
    Zippy_Race: {
      description:
        "Zippy Race is a racing game where players control a car, motorcycle, or boat, racing against opponents on various tracks. Players must avoid obstacles and compete for the first place. The game features racing gameplay and competitive multiplayer action.",
      developer: "Irem",
      platform: "Family Computer",
      genre: "Racing",
    },
    Zoids2Zenebasu_No_Gyakushuu: {
      description:
        "Zoids 2: Zenebasu no Gyakushuu is a strategy game based on the Zoids anime and model franchise. Players command Zoids units, engaging in tactical battles against enemy forces. The game features strategy gameplay and Zoids-themed elements.",
      developer: "Tomy",
      platform: "Family Computer",
      genre: "Strategy",
    },
    ZoidsChuuou_Tairiku_No_Tatakai: {
      description:
        "Zoids: Chuuou Tairiku no Tatakai is a strategy game based on the Zoids anime and model franchise. Players command Zoids units, engaging in tactical battles against enemy forces. The game features strategy gameplay and Zoids-themed elements.",
      developer: "Tomy",
      platform: "Family Computer",
      genre: "Strategy",
    },
    ZoidsMokushiroku: {
      description:
        "Zoids: Mokushiroku is a strategy game based on the Zoids anime and model franchise. Players command Zoids units, engaging in tactical battles against enemy forces. The game features strategy gameplay and Zoids-themed elements.",
      developer: "Tomy",
      platform: "Family Computer",
      genre: "Strategy",
    },
    Zombie_Nation: {
      description:
        "Zombie Nation is a shoot 'em up game where players control a floating samurai head, battling enemies and bosses in various levels. Players can shoot projectiles and use special attacks to defeat enemies. The game features shooting gameplay and challenging levels.",
      developer: "KAZe",
      platform: "Nintendo Entertainment System",
      genre: "Shooter",
    },
    七宝奇谋2: {
      description:
        "七宝奇谋2是一款角色扮演游戏，玩家控制角色在游戏世界中冒险，完成任务，战胜敌人。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    三国志2霸王的大陆: {
      description:
        "三国志2霸王的大陆是一款策略游戏，玩家扮演三国时期的角色，参与政治、军事活动，争夺天下霸权。",
      developer: "未知",
      platform: "未知",
      genre: "策略",
    },
    三国志超级版: {
      description:
        "三国志超级版是一款策略游戏，基于三国历史背景，玩家可以扮演三国时期的名将，进行政治斗争和战争。",
      developer: "未知",
      platform: "未知",
      genre: "策略",
    },
    三目童子: {
      description:
        "三目童子是一款角色扮演游戏，玩家扮演主角在幻想世界中冒险，与各种妖怪和敌人战斗。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    中东战争: {
      description:
        "中东战争是一款战争策略游戏，玩家可以扮演各个中东国家的角色，参与战争，争夺领土。",
      developer: "未知",
      platform: "未知",
      genre: "战略",
    },
    五子棋: {
      description:
        "五子棋是一款经典棋类游戏，在棋盘上进行对弈，玩家需要先将五颗棋子连成一线获胜。",
      developer: "未知",
      platform: "未知",
      genre: "棋类",
    },
    任天堂战争: {
      description:
        "任天堂战争是一款动作策略游戏，玩家可以选择不同的任天堂角色进行战斗，使用各种技能和道具。",
      developer: "未知",
      platform: "未知",
      genre: "动作策略",
    },
    任天堂足球: {
      description:
        "任天堂足球是一款足球体育游戏，玩家可以选择不同的任天堂角色组成球队，参加足球比赛。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    "侠客情-荆轲刺秦王": {
      description:
        "侠客情-荆轲刺秦王是一款角色扮演游戏，玩家扮演历史人物荆轲，在游戏中经历各种历史事件，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    假面忍者花丸: {
      description:
        "假面忍者花丸是一款动作冒险游戏，玩家扮演忍者角色花丸，在游戏中进行冒险，战胜敌人。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    兽王记: {
      description:
        "兽王记是一款养成游戏，玩家可以养成各种动物，照顾它们的生活，培养它们的技能。",
      developer: "未知",
      platform: "未知",
      genre: "养成",
    },
    冒险岛2: {
      description:
        "冒险岛2是一款在线多人角色扮演游戏，玩家可以选择不同的职业和角色，进行冒险，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "多人在线角色扮演",
    },
    冒险岛2中文版: {
      description:
        "冒险岛2中文版是冒险岛2的中文版，玩家可以在游戏中使用中文进行交流和游戏。",
      developer: "未知",
      platform: "未知",
      genre: "多人在线角色扮演",
    },
    冒险岛3: {
      description:
        "冒险岛3是冒险岛系列的第三部作品，玩家可以继续在游戏中进行冒险，完成新的任务和挑战。",
      developer: "未知",
      platform: "未知",
      genre: "多人在线角色扮演",
    },
    冒险岛3中文版: {
      description:
        "冒险岛3中文版是冒险岛3的中文版，玩家可以在游戏中使用中文进行交流和游戏。",
      developer: "未知",
      platform: "未知",
      genre: "多人在线角色扮演",
    },
    冒险岛4: {
      description:
        "冒险岛4是冒险岛系列的第四部作品，玩家可以继续在游戏中进行冒险，完成新的任务和挑战。",
      developer: "未知",
      platform: "未知",
      genre: "多人在线角色扮演",
    },
    "动作-坦克大战": {
      description:
        "动作-坦克大战是一款坦克战斗游戏，玩家驾驶坦克进行战斗，摧毁敌人的坦克和设施。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    "动作-飞碟吸坦克": {
      description:
        "动作-飞碟吸坦克是一款动作游戏，玩家控制飞碟吸引坦克，进行战斗和防御。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    "动作-马戏团": {
      description:
        "动作-马戏团是一款马戏团表演游戏，玩家可以扮演马戏团演员，在游戏中进行各种表演和技巧。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    "动作-马戏团无限命版": {
      description:
        "动作-马戏团无限命版是马戏团表演游戏的无限生命版本，玩家可以在游戏中获得无限的生命。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    勇者斗恶龙: {
      description:
        "勇者斗恶龙是一款角色扮演游戏，玩家扮演勇者角色，在游戏中与怪兽战斗，拯救世界。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    勇者斗恶龙1: {
      description:
        "勇者斗恶龙1是勇者斗恶龙系列的第一作，玩家扮演勇者角色，与怪兽战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    勇者斗恶龙2: {
      description:
        "勇者斗恶龙2是勇者斗恶龙系列的第二作，玩家扮演勇者角色，与怪兽战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    勇者斗恶龙3: {
      description:
        "勇者斗恶龙3是勇者斗恶龙系列的第三作，玩家扮演勇者角色，与怪兽战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    双截龙1: {
      description:
        "双截龙1是一款动作游戏，玩家扮演忍者角色，使用各种武器和技能，打败敌人。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    双截龙2: {
      description:
        "双截龙2是双截龙系列的第二作，玩家继续扮演忍者角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    双截龙3: {
      description:
        "双截龙3是双截龙系列的第三作，玩家继续扮演忍者角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    口袋妖怪金: {
      description:
        "口袋妖怪金是口袋妖怪系列的一部分，玩家可以捕捉各种口袋妖怪，进行战斗和培养。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    台球锦标赛: {
      description:
        "台球锦标赛是一款台球体育游戏，玩家可以进行台球比赛，使用球杆将球打入球袋。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    史努比的趣味运动会: {
      description:
        "史努比的趣味运动会是一款体育游戏，玩家可以选择史努比和他的朋友进行各种运动比赛。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    合金装备1: {
      description:
        "合金装备1是合金装备系列的第一作，玩家扮演特工角色，进行战斗和潜入任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    合金装备2: {
      description:
        "合金装备2是合金装备系列的第二作，玩家继续扮演特工角色，进行战斗和潜入任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    唐老鸭梦冒险: {
      description:
        "唐老鸭梦冒险是一款冒险游戏，玩家扮演唐老鸭角色，在梦幻世界中进行冒险。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    圣火徽章外传: {
      description:
        "圣火徽章外传是圣火徽章系列的外传作品，玩家可以体验圣火徽章系列的新故事和角色。",
      developer: "未知",
      platform: "未知",
      genre: "策略角色扮演",
    },
    坦克: {
      description:
        "坦克是一款坦克战斗游戏，玩家驾驶坦克进行战斗，摧毁敌人的坦克和设施。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    坦克大战: {
      description:
        "坦克大战是一款坦克战斗游戏，玩家驾驶坦克进行战斗，摧毁敌人的坦克和设施。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    塞尔达传说2: {
      description:
        "塞尔达传说2是塞尔达传说系列的第二作，玩家扮演勇者角色，进行冒险，解开谜题和战斗敌人。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    塞尔达传说Outlands: {
      description:
        "塞尔达传说Outlands是塞尔达传说系列的外传作品，玩家可以体验新的故事和地图。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    大盗伍佑卫门: {
      description:
        "大盗伍佑卫门是一款动作冒险游戏，玩家扮演大盗伍佑卫门，在游戏中进行冒险，解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    天使之翼1: {
      description:
        "天使之翼1是一款角色扮演游戏，玩家扮演天使角色，在游戏中与恶魔战斗，拯救世界。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    天使之翼2: {
      description:
        "天使之翼2是天使之翼系列的第二作，玩家继续扮演天使角色，在游戏中与恶魔战斗，拯救世界。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    妈妈: {
      description:
        "妈妈是一款模拟游戏，玩家扮演妈妈角色，照顾家庭成员，完成各种家务和任务。",
      developer: "未知",
      platform: "未知",
      genre: "模拟",
    },
    "娱乐-中国象棋(中文)": {
      description:
        "娱乐-中国象棋(中文)是一款中国象棋游戏，玩家可以进行象棋对弈，提高棋艺。",
      developer: "未知",
      platform: "未知",
      genre: "棋类",
    },
    "娱乐-五子棋": {
      description:
        "娱乐-五子棋是一款五子棋游戏，玩家可以进行五子棋对弈，提高棋艺。",
      developer: "未知",
      platform: "未知",
      genre: "棋类",
    },
    宇宙巡航舰1: {
      description:
        "宇宙巡航舰1是一款太空战斗游戏，玩家驾驶宇宙飞船，在太空中进行战斗和探险。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    宇宙巡航舰2: {
      description:
        "宇宙巡航舰2是宇宙巡航舰系列的第二作，玩家继续驾驶宇宙飞船，在太空中进行战斗和探险。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    封神榜: {
      description:
        "封神榜是一款角色扮演游戏，玩家可以扮演封神榜中的角色，在游戏中完成任务和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    小魔法: {
      description:
        "小魔法是一款魔法冒险游戏，玩家扮演小魔法师，在游戏中使用魔法解锁谜题和战胜敌人。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    岳飞传: {
      description:
        "岳飞传是一款历史角色扮演游戏，玩家扮演岳飞，参与历史事件，完成任务和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    希特勒复活: {
      description:
        "希特勒复活是一款历史战争游戏，玩家可以选择不同的历史角色，参与第二次世界大战。",
      developer: "未知",
      platform: "未知",
      genre: "战争",
    },
    希魔复活: {
      description:
        "希魔复活是一款冒险战斗游戏，玩家扮演勇者角色，与魔物战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    幽游白书爆斗暗黑武术会: {
      description:
        "幽游白书爆斗暗黑武术会是一款格斗游戏，玩家可以选择幽游白书中的角色进行对战。",
      developer: "未知",
      platform: "未知",
      genre: "格斗",
    },
    彩虹小子: {
      description:
        "彩虹小子是一款冒险游戏，玩家扮演彩虹小子，在彩虹世界中进行冒险，解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    影子传说: {
      description:
        "影子传说是一款动作冒险游戏，玩家扮演忍者角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者猫: {
      description:
        "忍者猫是一款动作冒险游戏，玩家扮演忍者猫角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者神龟1: {
      description:
        "忍者神龟1是忍者神龟系列的第一作，玩家可以选择忍者神龟角色进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者神龟2: {
      description:
        "忍者神龟2是忍者神龟系列的第二作，玩家可以选择忍者神龟角色进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者神龟3: {
      description:
        "忍者神龟3是忍者神龟系列的第三作，玩家可以选择忍者神龟角色进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者龙剑传1: {
      description:
        "忍者龙剑传1是忍者龙剑传系列的第一作，玩家扮演忍者角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者龙剑传2: {
      description:
        "忍者龙剑传2是忍者龙剑传系列的第二作，玩家继续扮演忍者角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者龙剑传3: {
      description:
        "忍者龙剑传3是忍者龙剑传系列的第三作，玩家继续扮演忍者角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    忍者龙牙: {
      description:
        "忍者龙牙是一款动作游戏，玩家扮演忍者角色，使用各种武器和技能，打败敌人。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    怪鸭历险记2: {
      description:
        "怪鸭历险记2是怪鸭历险记系列的第二作，玩家继续扮演怪鸭角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    恶魔之剑: {
      description:
        "恶魔之剑是一款动作角色扮演游戏，玩家扮演勇者角色，在游戏中与恶魔战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作角色扮演",
    },
    恶魔城1: {
      description:
        "恶魔城1是恶魔城系列的第一作，玩家扮演勇者角色，与恶魔战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    恶魔城2: {
      description:
        "恶魔城2是恶魔城系列的第二作，玩家继续扮演勇者角色，在游戏中与恶魔战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    恶魔城3: {
      description:
        "恶魔城3是恶魔城系列的第三作，玩家继续扮演勇者角色，在游戏中与恶魔战斗，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    成龙之龙: {
      description:
        "成龙之龙是一款动作游戏，玩家扮演成龙角色，进行各种战斗和动作。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    战国武将烈传: {
      description:
        "战国武将烈传是一款策略角色扮演游戏，玩家扮演战国武将，进行战斗和策略。",
      developer: "未知",
      platform: "未知",
      genre: "策略角色扮演",
    },
    摩登原始人1: {
      description:
        "摩登原始人1是摩登原始人系列的第一作，玩家扮演原始人角色，在游戏中进行冒险和生存。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    摩登原始人2: {
      description:
        "摩登原始人2是摩登原始人系列的第二作，玩家继续扮演原始人角色，在游戏中进行冒险和生存。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    新人类: {
      description:
        "新人类是一款科幻冒险游戏，玩家扮演新人类角色，在游戏中进行科学实验和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "科幻冒险",
    },
    "星之卡比(美版)": {
      description:
        "星之卡比(美版)是星之卡比系列的美国版游戏，玩家可以体验星之卡比系列的经典玩法和角色。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    星际战士中文版: {
      description:
        "星际战士中文版是星际战士系列的中文版游戏，玩家可以体验星际战士系列的经典玩法和角色。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    暗杀希特勒: {
      description:
        "暗杀希特勒是一款历史战争游戏，玩家扮演特工角色，进行暗杀任务，参与第二次世界大战。",
      developer: "未知",
      platform: "未知",
      genre: "战争",
    },
    最终幻想2: {
      description:
        "最终幻想2是最终幻想系列的第二作，玩家扮演勇者角色，进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    最终幻想3: {
      description:
        "最终幻想3是最终幻想系列的第三作，玩家扮演勇者角色，进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    杀虫大战: {
      description:
        "杀虫大战是一款射击游戏，玩家扮演守卫角色，使用各种武器击败入侵的虫子。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    条码版战斗机器人: {
      description:
        "条码版战斗机器人是一款射击游戏，玩家扮演机器人角色，在游戏中进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    松鼠大作战1: {
      description:
        "松鼠大作战1是松鼠大作战系列的第一作，玩家扮演松鼠角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    松鼠大作战2: {
      description:
        "松鼠大作战2是松鼠大作战系列的第二作，玩家继续扮演松鼠角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    松鼠大战: {
      description:
        "松鼠大战是松鼠大作战系列的游戏，玩家扮演松鼠角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    松鼠大战2: {
      description:
        "松鼠大战2是松鼠大作战系列的游戏，玩家扮演松鼠角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    柯拉米2: {
      description:
        "柯拉米2是柯拉米系列的第二作，玩家扮演柯拉米角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    柯拉米世界1: {
      description:
        "柯拉米世界1是柯拉米世界系列的第一作，玩家扮演柯拉米角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    柯拉米世界2: {
      description:
        "柯拉米世界2是柯拉米世界系列的第二作，玩家继续扮演柯拉米角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    柯纳米世界1: {
      description:
        "柯纳米世界1是柯纳米世界系列的第一作，玩家扮演柯纳米角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    柯纳米世界2: {
      description:
        "柯纳米世界2是柯纳米世界系列的第二作，玩家继续扮演柯纳米角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    桃太郎電鉄: {
      description:
        "桃太郎電鉄是一款经营策略游戏，玩家扮演角色，在游戏中进行经营和发展。",
      developer: "未知",
      platform: "未知",
      genre: "经营策略",
    },
    水上魂斗罗: {
      description:
        "水上魂斗罗是魂斗罗系列的水上版本，玩家可以在水上进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    沙罗曼蛇: {
      description:
        "沙罗曼蛇是一款射击游戏，玩家扮演飞行角色，在游戏中进行射击和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    沙罗曼蛇1: {
      description:
        "沙罗曼蛇1是沙罗曼蛇系列的第一作，玩家扮演飞行角色，在游戏中进行射击和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    沙罗曼蛇3: {
      description:
        "沙罗曼蛇3是沙罗曼蛇系列的第三作，玩家扮演飞行角色，在游戏中进行射击和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    法拉利赛车: {
      description:
        "法拉利赛车是一款赛车游戏，玩家可以驾驶法拉利赛车，在赛道上进行比赛。",
      developer: "未知",
      platform: "未知",
      genre: "赛车",
    },
    泡泡龙2: {
      description:
        "泡泡龙2是泡泡龙系列的第二作，玩家可以射击泡泡，完成任务和解锁关卡。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    泰坦尼克号: {
      description:
        "泰坦尼克号是一款冒险游戏，玩家扮演角色，在泰坦尼克号上进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    洛克人1: {
      description:
        "洛克人1是洛克人系列的第一作，玩家扮演机器人角色，进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    洛克人2: {
      description:
        "洛克人2是洛克人系列的第二作，玩家继续扮演机器人角色，进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    洛克人3: {
      description:
        "洛克人3是洛克人系列的第三作，玩家继续扮演机器人角色，进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    洛克人4: {
      description:
        "洛克人4是洛克人系列的第四作，玩家继续扮演机器人角色，进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    洛克人5: {
      description:
        "洛克人5是洛克人系列的第五作，玩家继续扮演机器人角色，进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    洛克人6: {
      description:
        "洛克人6是洛克人系列的第六作，玩家继续扮演机器人角色，进行战斗和射击。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    涡轮赛车: {
      description: "涡轮赛车是一款赛车游戏，玩家可以选择不同的赛车，参加比赛。",
      developer: "未知",
      platform: "未知",
      genre: "赛车",
    },
    激龟忍者传1: {
      description:
        "激龟忍者传1是激龟忍者传系列的第一作，玩家扮演忍者角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    激龟忍者传2: {
      description:
        "激龟忍者传2是激龟忍者传系列的第二作，玩家继续扮演忍者角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    激龟忍者传3: {
      description:
        "激龟忍者传3是激龟忍者传系列的第三作，玩家继续扮演忍者角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    火之鸟凤凰篇: {
      description:
        "火之鸟凤凰篇是一款动作角色扮演游戏，玩家扮演勇者角色，进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作角色扮演",
    },
    炸弹人: {
      description:
        "炸弹人是一款经典游戏，玩家扮演角色，放置炸弹炸毁障碍物，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    炸弹人2: {
      description:
        "炸弹人2是炸弹人系列的第二作，玩家继续扮演角色，放置炸弹炸毁障碍物，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    炸弹人无敌版: {
      description:
        "炸弹人无敌版是炸弹人系列的加强版游戏，玩家继续扮演角色，放置炸弹炸毁障碍物，完成任务。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    烈火: {
      description: "烈火是一款动作游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    热血新记录: {
      description:
        "热血新记录是一款角色扮演游戏，玩家扮演角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    热血时代剧: {
      description:
        "热血时代剧是一款战斗游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "战斗",
    },
    热血曲棍球: {
      description:
        "热血曲棍球是一款曲棍球游戏，玩家扮演球员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    热血格斗传说: {
      description:
        "热血格斗传说是一款格斗游戏，玩家扮演角色，在游戏中进行战斗和格斗。",
      developer: "未知",
      platform: "未知",
      genre: "格斗",
    },
    热血物语: {
      description:
        "热血物语是一款冒险游戏，玩家扮演角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    热血篮球: {
      description:
        "热血篮球是一款篮球游戏，玩家扮演球员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    热血行进曲: {
      description:
        "热血行进曲是一款音乐游戏，玩家扮演音乐家角色，在游戏中进行音乐创作和演奏。",
      developer: "未知",
      platform: "未知",
      genre: "音乐",
    },
    热血足球3: {
      description:
        "热血足球3是热血足球系列的第三作，玩家扮演足球队员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    热血足球联盟: {
      description:
        "热血足球联盟是一款足球游戏，玩家扮演足球队员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    热血高校: {
      description:
        "热血高校是一款角色扮演游戏，玩家扮演学生角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    热血高校足球: {
      description:
        "热血高校足球是一款足球游戏，玩家扮演足球队员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    燃烧战车1: {
      description:
        "燃烧战车1是燃烧战车系列的第一作，玩家扮演战车角色，在游戏中进行战斗和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "竞速",
    },
    燃烧战车2: {
      description:
        "燃烧战车2是燃烧战车系列的第二作，玩家继续扮演战车角色，在游戏中进行战斗和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "竞速",
    },
    爆笑三国: {
      description:
        "爆笑三国是一款策略游戏，玩家扮演角色，在游戏中进行策略和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "策略",
    },
    特库摩世界杯足球赛: {
      description:
        "特库摩世界杯足球赛是一款足球游戏，玩家扮演足球队员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    特救指令: {
      description:
        "特救指令是一款动作游戏，玩家扮演特工角色，在游戏中进行战斗和救援。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    猫和老鼠: {
      description:
        "猫和老鼠是一款动作游戏，玩家可以选择扮演猫或老鼠，在游戏中进行追逐和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    玛里奥: {
      description:
        "玛里奥是超级玛丽系列的第一作，玩家扮演玛里奥角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    玛里奥2: {
      description:
        "玛里奥2是超级玛丽系列的第二作，玩家继续扮演玛里奥角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    玛里奥3: {
      description:
        "玛里奥3是超级玛丽系列的第三作，玩家继续扮演玛里奥角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    生化危机: {
      description:
        "生化危机是一款恐怖生存游戏，玩家扮演角色，在游戏中进行生存和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "恐怖生存",
    },
    皇室血裔: {
      description:
        "皇室血裔是一款动作角色扮演游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作角色扮演",
    },
    皮诺曹的复苏: {
      description:
        "皮诺曹的复苏是一款冒险游戏，玩家扮演角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    空中魂斗罗1: {
      description:
        "空中魂斗罗1是魂斗罗系列的第一作，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    空中魂斗罗2: {
      description:
        "空中魂斗罗2是魂斗罗系列的第二作，玩家继续扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    空中魂斗罗l: {
      description:
        "空中魂斗罗l是魂斗罗系列的版本，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    篮球: {
      description:
        "篮球是一款篮球游戏，玩家扮演篮球队员，在球场上进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    终极篮球: {
      description:
        "终极篮球是一款篮球游戏，玩家扮演篮球队员，在球场上进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    维纳斯战记: {
      description:
        "维纳斯战记是一款角色扮演游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    绿色兵团: {
      description:
        "绿色兵团是一款战略游戏，玩家扮演指挥官角色，指挥绿色兵团进行战斗和策略。",
      developer: "未知",
      platform: "未知",
      genre: "战略",
    },
    绿野仙踪: {
      description:
        "绿野仙踪是一款角色扮演游戏，玩家扮演角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    网球: {
      description:
        "网球是一款体育游戏，玩家扮演网球选手，在球场上进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    网球中文版: {
      description:
        "网球中文版是一款体育游戏，玩家扮演网球选手，在球场上进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    美国上尉: {
      description:
        "美国上尉是一款战斗游戏，玩家扮演美国上尉，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "战斗",
    },
    脱狱: {
      description:
        "脱狱是一款动作游戏，玩家扮演囚犯角色，在游戏中进行逃脱和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    花式撞球: {
      description:
        "花式撞球是一款桌球游戏，玩家扮演球员，在游戏中进行撞球和比赛。",
      developer: "未知",
      platform: "未知",
      genre: "桌球",
    },
    荆柯新传: {
      description:
        "荆柯新传是一款角色扮演游戏，玩家扮演角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    荒野大镖客: {
      description:
        "荒野大镖客是一款动作冒险游戏，玩家扮演角色，在游戏中进行冒险和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    菲力士猫: {
      description:
        "菲力士猫是一款冒险游戏，玩家扮演菲力士猫角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    虹岛: {
      description:
        "虹岛是一款冒险游戏，玩家扮演角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    蜘蛛侠: {
      description:
        "蜘蛛侠是一款动作冒险游戏，玩家扮演蜘蛛侠角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    蝙蝠侠1: {
      description:
        "蝙蝠侠1是蝙蝠侠系列的第一作，玩家扮演蝙蝠侠角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    蝙蝠侠2: {
      description:
        "蝙蝠侠2是蝙蝠侠系列的第二作，玩家继续扮演蝙蝠侠角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作冒险",
    },
    西游记: {
      description:
        "西游记是一款动作角色扮演游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作角色扮演",
    },
    赛尔达传说1: {
      description:
        "赛尔达传说1是赛尔达传说系列的第一作，玩家扮演勇者角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    "赛车-坦克飞机": {
      description:
        "赛车-坦克飞机是一款赛车游戏，玩家扮演赛车手，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "竞速",
    },
    赤影战士: {
      description:
        "赤影战士是一款动作游戏，玩家扮演忍者角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    赤色要塞: {
      description:
        "赤色要塞是一款射击游戏，玩家扮演战斗机驾驶员，在游戏中进行空战和射击。",
      developer: "未知",
      platform: "未知",
      genre: "射击",
    },
    超级玛丽1: {
      description:
        "超级玛丽1是超级玛丽系列的第一作，玩家扮演玛丽角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    超级玛丽2: {
      description:
        "超级玛丽2是超级玛丽系列的第二作，玩家继续扮演玛丽角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    超级玛丽3: {
      description:
        "超级玛丽3是超级玛丽系列的第三作，玩家继续扮演玛丽角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    超级玛莉1: {
      description:
        "超级玛莉1是超级玛莉系列的第一作，玩家扮演玛莉角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    超级玛莉2: {
      description:
        "超级玛莉2是超级玛莉系列的第二作，玩家继续扮演玛莉角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    超级玛莉3: {
      description:
        "超级玛莉3是超级玛莉系列的第三作，玩家继续扮演玛莉角色，进行冒险和救公主。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    超级赛车: {
      description:
        "超级赛车是一款赛车游戏，玩家扮演赛车手，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "竞速",
    },
    趣味方块完美汉化版: {
      description:
        "趣味方块完美汉化版是一款益智游戏，玩家需要操作方块，完成拼图和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "益智",
    },
    足球联赛冠军杯: {
      description:
        "足球联赛冠军杯是一款足球游戏，玩家扮演足球队员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    踢王: {
      description:
        "踢王是一款体育游戏，玩家扮演足球队员，在游戏中进行比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "体育",
    },
    迷宫组曲: {
      description:
        "迷宫组曲是一款冒险游戏，玩家扮演角色，在迷宫中进行探险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    铁甲威龙: {
      description:
        "铁甲威龙是一款动作游戏，玩家扮演机甲角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    铁甲威龙2: {
      description:
        "铁甲威龙2是铁甲威龙系列的第二作，玩家继续扮演机甲角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    铁道王: {
      description:
        "铁道王是一款模拟经营游戏，玩家扮演铁路公司经理，在游戏中进行铁路建设和经营。",
      developer: "未知",
      platform: "未知",
      genre: "模拟经营",
    },
    阴谋行星: {
      description:
        "阴谋行星是一款角色扮演游戏，玩家扮演角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    隋唐演义: {
      description:
        "隋唐演义是一款策略角色扮演游戏，玩家扮演角色，在游戏中进行策略和战斗。",
      developer: "未知",
      platform: "未知",
      genre: "策略角色扮演",
    },
    雪人兄弟: {
      description:
        "雪人兄弟是一款冒险游戏，玩家扮演雪人角色，在游戏中进行冒险和解锁谜题。",
      developer: "未知",
      platform: "未知",
      genre: "冒险",
    },
    革命英雄: {
      description:
        "革命英雄是一款动作角色扮演游戏，玩家扮演英雄角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作角色扮演",
    },
    顶尖经营者: {
      description:
        "顶尖经营者是一款模拟经营游戏，玩家扮演企业经理，在游戏中进行经营和发展。",
      developer: "未知",
      platform: "未知",
      genre: "模拟经营",
    },
    飞龙子拳: {
      description:
        "飞龙子拳是一款动作游戏，玩家扮演拳击手角色，在游戏中进行拳击比赛和竞技。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    马戏团: {
      description:
        "马戏团是一款模拟经营游戏，玩家扮演马戏团经理，在游戏中进行马戏团表演和经营。",
      developer: "未知",
      platform: "未知",
      genre: "模拟经营",
    },
    魂斗罗1: {
      description:
        "魂斗罗1是魂斗罗系列的第一作，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    魂斗罗2: {
      description:
        "魂斗罗2是魂斗罗系列的第二作，玩家继续扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    魂斗罗3: {
      description:
        "魂斗罗3是魂斗罗系列的第三作，玩家继续扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
    魔神英雄传: {
      description:
        "魔神英雄传是一款角色扮演游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "角色扮演",
    },
    魔鬼总动员: {
      description:
        "魔鬼总动员是一款动作游戏，玩家扮演角色，在游戏中进行战斗和冒险。",
      developer: "未知",
      platform: "未知",
      genre: "动作",
    },
  },
};

export default config;

// # Getting the game information based on the user's input
// def get_game_info(game_name):
//     game_name = game_name.strip().replace(" ", "_")
//     if game_name in games_data["games"]:
//         return games_data["games"][game_name]
//     else:
//         return None

// # Example usage
// user_input = "Batman"
// game_info = get_game_info(user_input)
// if game_info:
//     print(f"Information about {user_input}:")
//     print(f"Description: {game_info['description']}")
//     print(f"Developer: {game_info['developer']}")
//     print(f"Platform: {game_info['platform']}")
//     print(f"Genre: {game_info['genre']}")
// else:
//     print(f"Game '{user_input}' not found in the database.")
