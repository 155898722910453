import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./PrivacyPolicy.css";

export default class PrivacyPolicy extends Component {
  state = {
    homeApplicationIdJson: {},
    postApplicationIdJson: {},
  };

  /**
   * 初期化
   */
  componentDidMount() {
    this.setState({
      postApplicationIdJson: {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": `https://fc-game.github.io/#/about`,
        },
        headline: `Family Computer Games`,
        description: `FC Game Privacy Policy`,
        publisher: {
          "@type": "Person",
          name: "Family Computer Games",
        },
        author: {
          url: "https://github.com/MADAOU",
          "@type": "Person",
          name: "Seiriryu",
        },
      },
      breadcrumbListApplicationIdJson: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              name: "home",
              "@id": "https://fc-game.github.io",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              name: `PrivacyPolicy`,
              "@id": `https://fc-game.github.io/#/about`,
            },
          },
        ],
      },
    });
  }

  render() {
    return (
      <>
        <Header />
        <div className="list-page">
          {/* description */}
          <meta name="description" content={`Family Computer Games`} />
          <meta name="og:description" content={`Family Computer Games`} />
          <meta name="twitter:description" content={`Family Computer Games`} />
          {/* Open Graph / Facebook */}
          <meta content="en" property="og:locale" />
          <meta content="Family Computer Games" property="og:site_name" />
          <meta content="https://fc-game.github.io" property="og:url" />
          <meta content="website" property="og:type" />
          {/* Twitter */}
          <meta content="@Seiriryu" name="twitter:site" />
          <meta content="@Seiriryu" name="twitter:creator" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="https://fc-game.github.io" name="twitter:url" />
          <meta content="https://fc-game.github.io" name="twitter:domain" />
          <meta content="fc,fcgames,game,FamilyComputerGames" name="keywords" />
          {/* JSON+Id */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.homeApplicationIdJson)}
          </script>
          {/* 内容 */}
          <div>
            <h1 className="main-title">Privacy Policy for Family Computer Games</h1>
            <p>If you require any more information or have any questions about our privacy policy, please feel free to contact us.</p>
            <h2 className="sub-title">
              Collecting and Using Your Personal Data
            </h2>
            <h3 className="sub-sub-title">Personal Data</h3>
            <p>
              This website will not collect your personal information,The user's
              personal information is not used in this site <br />
              and not provide user information to third parties.
            </p>
            <p>
              This website uses Google Analytics as an analysis tool, and Google
              Analytics has the possibility of automatically acquiring user
              information.
              <br />
              Please refer to the privacy policy of Google Analytics for
              information acquired, the purpose of use, and the provision to the
              third party.
            </p>
            <h3 className="sub-sub-title">Tracking Technologies and Cookies</h3>
            <p>
              We're not use Cookies and similar tracking technologies on the
              website.
            </p>
            <h2 className="sub-title">Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us.
              <br />
              *Please refer to the footer of this website for contact
              information
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
