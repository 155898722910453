import Raven from "raven-js";

/**
 * 是否有Error
 * @param {*} error
 * @param {*} errorInfo
 */
export const handleError = (error, errorInfo) => {
  console.error(error);
  Raven.captureException(error, { extra: errorInfo });
};

/**
 * 加载ROM
 * @param {*} path
 * @param {*} callback
 * @param {*} handleProgress
 * @returns
 */
export function loadBinary(path, callback, handleProgress) {
  let req = new XMLHttpRequest();
  req.open("GET", `${process.env.REACT_APP_ROM_DOMAIN}${path}`);
  req.overrideMimeType("text/plain; charset=x-user-defined");
  req.onload = function () {
    if (req.status === 200) {
      if (req.responseText.match(/^<!doctype html>/i)) {
        // Got HTML back, so it is probably falling back to index.html due to 404
        return callback(new Error("Page Not Found!!"));
      }

      callback(null, req.responseText);
    } else if (req.status === 0) {
      // Aborted, so ignore error
    } else {
      callback(new Error(req.statusText));
    }
  };
  req.onerror = function () {
    callback(new Error(req.statusText));
  };
  req.onprogress = handleProgress;
  req.send();
  return req;
}