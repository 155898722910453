import Raven from "raven-js";
import React, { Component } from "react";
import { NES, Controller } from "jsnes";
import FrameTimer from "./FrameTimer";
import GamepadController from "./GamepadController";
import KeyboardController from "./KeyboardController";
import Screen from "./Screen";
import Speakers from "./Speakers";
import "./Emulator.css";

/*
 * Runs the emulator.
 * The only UI is a canvas element. It assumes it is a singleton in various ways
 * (binds to window, keyboard, speakers, etc).
 */
export default class Emulator extends Component {
  /**
   * 初期化
   */
  componentDidMount() {
    // Initial layout
    this.fitInParent();

    //声音设置
    this.speakers = new Speakers({
      onBufferUnderrun: (actualSize, desiredSize) => {
        if (this.props.paused) {
          return;
        }
        // Skip a video frame so audio remains consistent. This happens for
        // a variety of reasons:
        // - Frame rate is not quite 60fps, so sometimes buffer empties
        // - Page is not visible, so requestAnimationFrame doesn't get fired.
        //   In this case emulator still runs at full speed, but timing is
        //   done by audio instead of requestAnimationFrame.
        // - System can't run emulator at full speed. In this case it'll stop
        //    firing requestAnimationFrame.
        // console.log(
        //   "Buffer underrun, running another frame to try and catch up"
        // );

        this.frameTimer.generateFrame();
        // desiredSize will be 2048, and the NES produces 1468 samples on each
        // frame so we might need a second frame to be run. Give up after that
        // though -- the system is not catching up
        if (this.speakers.buffer.size() < desiredSize) {
          // console.log("Still buffer underrun, running a second frame");
          this.frameTimer.generateFrame();
        }
      },
    });

    //NES Package加载
    this.nes = new NES({
      onFrame: this.screen.setBuffer,
      onAudioSample: this.speakers.writeSample,
      sampleRate: this.speakers.getSampleRate(),
    });

    // For debugging. (["nes"] instead of .nes to avoid VS Code type errors.)
    window["nes"] = this.nes;

    this.frameTimer = new FrameTimer({
      onGenerateFrame: Raven.wrap(this.nes.frame),
      onWriteFrame: Raven.wrap(this.screen.writeBuffer),
    });

    // Set up gamepad and keyboard
    this.gamepadController = new GamepadController({
      onButtonDown: this.nes.buttonDown,
      onButtonUp: this.nes.buttonUp,
    });

    //游戏控制加载
    this.gamepadController.loadGamepadConfig();
    this.gamepadPolling = this.gamepadController.startPolling();

    //游戏键盘控制器
    this.keyboardController = new KeyboardController({
      onButtonDown: this.gamepadController.disableIfGamepadEnabled(
        this.nes.buttonDown
      ),
      onButtonUp: this.gamepadController.disableIfGamepadEnabled(
        this.nes.buttonUp
      ),
    });

    // Load keys from localStorage (if they exist)
    this.keyboardController.loadKeys();
    document.addEventListener("keydown", this.keyboardController.handleKeyDown);
    document.addEventListener("keyup", this.keyboardController.handleKeyUp);
    document.addEventListener(
      "keypress",
      this.keyboardController.handleKeyPress
    );
    //load ROM
    this.nes.loadROM(this.props.romData);
    this.start();
  }

  /**
   * 卸载
   */
  componentWillUnmount() {
    this.stop();
    // Unbind keyboard
    document.removeEventListener(
      "keydown",
      this.keyboardController.handleKeyDown
    );
    document.removeEventListener("keyup", this.keyboardController.handleKeyUp);
    document.removeEventListener(
      "keypress",
      this.keyboardController.handleKeyPress
    );
    // Stop gamepad
    this.gamepadPolling.stop();
    window["nes"] = undefined;
  }

  /**
   * 更新的生命周期
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.paused !== prevProps.paused) {
      if (this.props.paused) {
        this.stop();
      } else {
        this.start();
      }
    }
  }

  /**
   * Start
   */
  start = () => {
    this.frameTimer.start();
    this.speakers.start();
    this.fpsInterval = setInterval(() => {
      // console.log(`FPS: ${this.nes.getFPS()}`);
    }, 1000);
  };

  /**
   * Stop
   */
  stop = () => {
    this.frameTimer.stop();
    this.speakers.stop();
    clearInterval(this.fpsInterval);
  };

  /*
   * Fill parent element with screen. Typically called if parent element changes size.
   */
  fitInParent() {
    this.screen.fitInParent();
  }

  /**
   * 按钮按下
   * @param {*} buttonId
   * @returns
   */
  controlBtnDownHandle = (buttonId) => {
    return (e) => {
      this.nes.buttonDown(1, buttonId);
      e.preventDefault();
    };
  };

  //按钮抬起
  controlBtnUpHandle = (buttonId) => {
    return (e) => {
      this.nes.buttonUp(1, buttonId);
      e.preventDefault();
    };
  };

  /**
   * 渲染
   */
  render() {
    return (
      <>
        <Screen
          ref={(screen) => {
            this.screen = screen;
          }}
          onGenerateFrame={() => {
            this.nes.frame();
          }}
          onMouseDown={(x, y) => {
            this.nes.zapperMove(x, y);
            this.nes.zapperFireDown();
          }}
          onMouseUp={() => {
            this.nes.zapperFireUp();
          }}
        />
        <div className="d-block d-lg-none container">
          <div className="spf-controller controller-container">
            <div className="controller-left">
              <div className="cross-layout">
                <button
                  className="position-top btn cross-key-btn"
                  onTouchStart={this.controlBtnDownHandle(Controller.BUTTON_UP)}
                  onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_UP)}
                >
                  <span className="top-mark">▲</span>
                </button>
                <button
                  className="position-left btn cross-key-btn"
                  onTouchStart={this.controlBtnDownHandle(
                    Controller.BUTTON_LEFT
                  )}
                  onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_LEFT)}
                >
                  <span className="left-mark">▲</span>
                </button>
                <button className="position-center btn cross-key-btn">
                  <span className="center-mark">●</span>
                </button>
                <button
                  className="position-right btn cross-key-btn"
                  onTouchStart={this.controlBtnDownHandle(
                    Controller.BUTTON_RIGHT
                  )}
                  onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_RIGHT)}
                >
                  <span className="right-mark">▲</span>
                </button>
                <button
                  className="position-bottom btn cross-key-btn"
                  onTouchStart={this.controlBtnDownHandle(
                    Controller.BUTTON_DOWN
                  )}
                  onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_DOWN)}
                >
                  <span className="bottom-mark">▲</span>
                </button>
              </div>
            </div>
            <div className="controller-center">
              <span className="logo-msg">Controller</span>
              <div className="selectstart-btn-set">
                <button
                  className="btn selectstart-btn"
                  onTouchStart={this.controlBtnDownHandle(
                    Controller.BUTTON_SELECT
                  )}
                  onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_SELECT)}
                ></button>
                <button
                  className="btn selectstart-btn"
                  onTouchStart={this.controlBtnDownHandle(
                    Controller.BUTTON_START
                  )}
                  onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_START)}
                ></button>
              </div>
            </div>
            <div className="controller-right">
              <div className="abxy-btn-set">
                <div className="cross-layout">
                  <button
                    className="btn abxy-btn position-top btn-x"
                    onTouchStart={this.controlBtnDownHandle(
                      Controller.BUTTON_A
                    )}
                    onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_A)}
                  >
                    X
                  </button>
                  <button
                    className="btn abxy-btn position-left btn-y"
                    onTouchStart={this.controlBtnDownHandle(
                      Controller.BUTTON_B
                    )}
                    onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_B)}
                  >
                    Y
                  </button>
                  <button
                    className="btn abxy-btn position-right btn-a"
                    onTouchStart={this.controlBtnDownHandle(
                      Controller.BUTTON_A
                    )}
                    onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_A)}
                  >
                    A
                  </button>
                  <button
                    className="btn abxy-btn position-bottom btn-b"
                    onTouchStart={this.controlBtnDownHandle(
                      Controller.BUTTON_B
                    )}
                    onTouchEnd={this.controlBtnUpHandle(Controller.BUTTON_B)}
                  >
                    B
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
