import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import RomLibrary from "../utitl/RomLibrary";
import config from "../config";
import "./ListPage.css";

export default class ListPage extends Component {
  state = {
    romLibrary: RomLibrary.load(),
    currentPage: 1,
    pageSize: 24,
    totalPages: 1,
    totalDatas: [],
    searchDatas: [],
    currentPageDatas: [],
    searchContent: null,
    homeApplicationIdJson: {},
  };

  /**
   * 删除ROM
   * @param {*} hash
   */
  deleteRom = (hash) => {
    RomLibrary.delete(hash);
    this.updateLibrary();
  };

  /**
   * 加载ROM
   */
  updateLibrary = () => {
    this.setState({ romLibrary: RomLibrary.load() });
  };

  /**
   * 拖动方法
   * @param {*} e
   */
  handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  /**
   *拖动方法
   * @param {*} e
   */
  handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.items
      ? e.dataTransfer.items[0].getAsFile()
      : e.dataTransfer.files[0];

    RomLibrary.save(file).then((rom) => {
      this.updateLibrary();
      this.props.history.push({ pathname: "run/local-" + rom.hash });
    });
  };

  /**
   * 获取URL的参数
   * @param {*} name key
   * @returns
   */
  getParam = (name, url) => {
    if (!url) url = window.location.href;
    // let name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  /**
   * 初期化
   */
  componentDidMount() {
    //google analytics
    document.title = "Family Computer Games";
    window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      page_path: this.props.location.pathname,
    });

    //当前页面sessionStorage中存在使用存在的，不存为1
    let currentPage = sessionStorage.getItem("currentPage")
      ? Number(sessionStorage.getItem("currentPage"))
      : 1;

    // 所有的游戏数据的list
    let totalDatas = [];
    Object.entries(config.ROMS).forEach((val, idx) => {
      totalDatas.push({
        no: idx + 1,
        name: String(val[0]),
        game_name: String(val[0]).replaceAll("_", " "),
        description: val[1].description,
        developer: val[1].developer,
        platform: val[1].platform,
        genre: val[1].genre,
      });
    });

    //检索内容存在的情况
    const searchContent = sessionStorage.getItem("searchContent");
    let searchDatas = [];
    if (searchContent) {
      searchDatas = totalDatas.filter(
        (el) =>
          el.game_name?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
          el.description?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
          el.developer?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
          el.platform?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
          el.genre?.toLowerCase().includes(searchContent.trim().toLowerCase())
      );
    }
    // 总的页数
    let totalPages =
      searchDatas.length > 0
        ? Math.ceil(searchDatas.length / this.state.pageSize) < 1
          ? 1
          : Math.ceil(searchDatas.length / this.state.pageSize)
        : Math.ceil(totalDatas.length / this.state.pageSize) < 1
        ? 1
        : Math.ceil(totalDatas.length / this.state.pageSize);

    // url parame 覆盖sessionStorage
    const page = Number(this.getParam("page"));
    if (page) {
      if (isNaN(page) || page < 1) {
        currentPage = 1;
      } else if (page > totalPages) {
        currentPage = totalPages;
      } else {
        currentPage = page;
      }
    }
    this.props.history.push({ search: "?page=" + currentPage });
    //当前页的第一个
    const first = (currentPage - 1) * this.state.pageSize + 1;
    //当前页的最后一个
    const last = currentPage * this.state.pageSize;
    //当前页的数据
    let currentPageDatas = [];
    //检索到的数据如果不为空
    if (searchDatas.length > 0) {
      searchDatas.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        currentPageDatas.push(item);
      });
    } else {
      totalDatas.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        currentPageDatas.push(item);
      });
    }

    this.setState({
      totalDatas,
      searchContent,
      searchDatas,
      currentPage,
      currentPageDatas,
      totalPages,
      homeApplicationIdJson: {
        "@context": "http://schema.org",
        "@type": "WebSite",
        name: "Family Computer Games",
        url: "https://fc-game.github.io",
      },
    });
  }

  /**
   * 分页
   * @param {*} currentPage 当前的页数
   */
  showPage = (currentPage) => {
    //当前页的第一个
    const first = (currentPage - 1) * this.state.pageSize + 1;
    //当前页的最后一个
    const last = currentPage * this.state.pageSize;
    //当前页的数据
    let currentPageDatas = [];
    if (this.state.searchDatas.length > 0) {
      this.state.searchDatas.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        currentPageDatas.push(item);
      });
    } else {
      this.state.totalDatas.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        currentPageDatas.push(item);
      });
    }

    this.setState({
      currentPageDatas,
    });
  };

  /**
   * 跳转到开始游戏页
   * @param e 事件
   */
  navigationGamePageHandler = (e) => {
    const name = e.target.dataset.name;
    this.props.history.push("/run/" + encodeURIComponent(name));
  };

  /**
   * 改页
   */
  changePage = (e) => {
    const val = e.target.dataset.value;
    let currentPage = 1;
    try {
      if (val === "-1") {
        currentPage =
          this.state.currentPage === 1 ? 1 : Number(this.state.currentPage) - 1;
      } else if (val === "+1") {
        currentPage =
          this.state.currentPage === this.state.totalPages
            ? this.state.totalPages
            : Number(this.state.currentPage) + 1;
      } else {
        currentPage = Number(val);
      }
    } catch (e) {
      currentPage = 1;
    }
    //现在的Page保存在Sessioin缓存中
    sessionStorage.setItem("currentPage", currentPage);
    this.setState({
      currentPage,
    });
    this.props.history.push({ search: "?page=" + currentPage });
    this.showPage(currentPage);
  };

  /**
   * 获取检索内容
   */
  changeHandler = (e) => {
    const searchContent = e.target.value;
    //检索的数据
    sessionStorage.setItem("searchContent", searchContent);
    const searchDatas = this.state.totalDatas.filter(
      (el) =>
        el.game_name?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
        el.description?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
        el.developer?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
        el.platform?.toLowerCase().includes(searchContent.trim().toLowerCase()) ||
        el.genre?.toLowerCase().includes(searchContent.trim().toLowerCase())
    );

    //当前页的第一个
    const first = 1;
    //当前页的最后一个
    const last = this.state.pageSize;
    //总页数
    let totalPages = 1;
    //当前页的数据
    let currentPageDatas = [];
    if (searchDatas.length > 0) {
      searchDatas.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        currentPageDatas.push(item);
      });
      totalPages =
        Math.ceil(searchDatas.length / this.state.pageSize) < 1
          ? 1
          : Math.ceil(searchDatas.length / this.state.pageSize);
    } else if (!searchContent) {
      this.state.totalDatas.forEach((item, i) => {
        if (i < first - 1 || i > last - 1) return;
        currentPageDatas.push(item);
      });
      totalPages =
        Math.ceil(this.state.totalDatas.length / this.state.pageSize) < 1
          ? 1
          : Math.ceil(this.state.totalDatas.length / this.state.pageSize);
    }

    //清除当前页的Session
    sessionStorage.setItem("currentPage", "1");
    this.props.history.push({ search: "?page=1" });
    this.setState({
      searchContent,
      searchDatas,
      totalPages,
      currentPage: 1,
      currentPageDatas,
    });
  };

  /**
   * 渲染
   */
  render() {
    return (
      <>
        {/* Header */}
        <Header />
        <div
          className="drop-zone list-page"
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
        >
          {/* description */}
          <meta name="description" content={`Family Computer Games`} />
          <meta property="og:description" content={`Family Computer Games`} />
          <meta name="twitter:description" content={`Family Computer Games`} />
          {/* Open Graph / Facebook */}
          <meta content="Family Computer Games" property="og:site_name" />
          <meta content="https://fc-game.github.io" property="og:url" />
          <meta content="website" property="og:type" />
          {/* Twitter */}
          <meta content="@Seiriryu" name="twitter:site" />
          <meta content="@Seiriryu" name="twitter:creator" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="https://fc-game.github.io" name="twitter:url" />
          <meta content="https://fc-game.github.io" name="twitter:domain" />
          <meta content="fcgames,game,FamilyComputerGames" name="keywords" />
          <meta content={`Family Computer Games`} name="twitter:title" />
          {/* JSON+Id */}
          <script type="application/ld+json">
            {JSON.stringify(this.state.homeApplicationIdJson)}
          </script>
          {/* 描述 */}
          {/* <p className="description d-none d-sm-block">
            *You can drag&drop ROM here to execute the game
          </p> */}
          <div className="search-input-container">
            <input
              className="search-input"
              placeholder="Enter Game Name..."
              value={this.state.searchContent ?? ""}
              onChange={this.changeHandler}
            />
          </div>
          {/* 游戏列表 */}
          <div className="game-container">
            {this.state.currentPageDatas.map((el, idx) => {
              return (
                <div
                  key={el.name}
                  title={el.description}
                  className="game-card"
                  onClick={this.navigationGamePageHandler}
                  data-no={el.no}
                  data-name={el.name}
                  data-game-name={el.game_name}
                  data-description={el.description}
                  data-developer={el.developer}
                  data-platform={el.platform}
                  data-genre={el.genre}
                >
                  {el.game_name}
                </div>
              );
            })}
          </div>
          {/* 翻页按钮 */}
          <div className="page-container">
            <button
              className="custom-page-btn"
              data-value="1"
              onClick={this.changePage}
            >
              &lt;&lt;
            </button>
            <button
              className="custom-page-btn"
              data-value="-1"
              onClick={this.changePage}
            >
              &lt;
            </button>{" "}
            <button
              className="custom-page-btn"
              data-value="+1"
              onClick={this.changePage}
            >
              &gt;
            </button>
            <button
              className="custom-page-btn"
              data-value={this.state.totalPages}
              onClick={this.changePage}
            >
              &gt;&gt;
            </button>
          </div>
          {/* 页码 */}
          <div className="page-container">
            {this.state.currentPage} / {this.state.totalPages}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
