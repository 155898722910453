import React from "react";
import { useHistory } from "react-router-dom";
import "./Header.css";

const Header = () => {

  const history = useHistory();

  /**
   * 页面迁移
   * @param {*} pageName 页面 
   */
  const navagatePage = (pageName) => {
    return (e) => {
      e.preventDefault();
      history.push(pageName);
    }
  }


  return (
    <>
      {/* Header */}
      <header className="header">
        {/* title */}
        <h1 className="title">Family Computer Games</h1>
        <nav>
          <ul>
            <li>
              <div onClick={navagatePage("/")}>Home</div>
            </li>
            <li>
              <div onClick={navagatePage("/policy")}>Policy</div>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}


export default Header;