import React, { Component } from "react";
import Routes from "./Routes";
import ErrorPage from "./pages/ErrorPage";
import { handleError } from "./utitl/Utils";

export default class App extends Component {
  state = { error: null };

  /**
   * error catch
   * @param {*} error
   * @param {*} errorInfo
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    handleError(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />;
    }
    return (
      <>
        <Routes />
      </>
    );
  }
}
