import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ListPage from "./pages/ListPage";
import RunPage from "./pages/RunPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFoundPage from "./pages/NotFoundPage";

export default class Routes extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={ListPage} />
            <Route exact path="/run" component={RunPage} />
            <Route exact path="/policy" component={PrivacyPolicy} />
            <Route exact path="/run/:slug" component={RunPage} />
            <Route exact path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
